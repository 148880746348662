import {
  FETCHING_SUBMODULES,
  FAIL_FETCH_SUBMODULES,
  SUCCESS_FETCH_SUBMODULES,
  PUT_SUBMODULE,
  PUT_FAIL_SUBMODULE,
  PUT_SUCCESS_SUBMODULE,
  POST_SUBMODULE,
  POST_FAIL_SUBMODULE,
  POST_SUCCESS_SUBMODULE,
  DELETE_SUBMODULE,
  DELETE_FAIL_SUBMODULE,
  DELETE_SUCCESS_SUBMODULE,
  UPDATE_SUBMODULE,
  UPDATE_FAIL_SUBMODULE,
  UPDATE_SUCCESS_SUBMODULE,
  UPDATE_SORT_SUBMODULE,
} from '../actions/submodules'

const initialState = {
  data: {},
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_SUBMODULES:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_FETCH_SUBMODULES:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.parent]: action.data,
        },
      };
    case FAIL_FETCH_SUBMODULES:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PUT_SUBMODULE:
      return {
        ...state,
        loading: true,
      };
    case PUT_FAIL_SUBMODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PUT_SUCCESS_SUBMODULE:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case POST_SUBMODULE:
      return {
        ...state,
        loading: true,
      };
    case POST_FAIL_SUBMODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case POST_SUCCESS_SUBMODULE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SORT_SUBMODULE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.parent]: action.data,
        },
      };
    case DELETE_SUBMODULE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FAIL_SUBMODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SUCCESS_SUBMODULE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SUBMODULE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FAIL_SUBMODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_SUCCESS_SUBMODULE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state
  }
};
