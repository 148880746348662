import React, { useState, useEffect } from 'react'
import moment from 'moment';
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Close, Delete } from '@material-ui/icons'
import InfiniteScroll from 'react-infinite-scroller'
import { makeStyles, Modal, CircularProgress, IconButton, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Input } from '@material-ui/core'
import { fetchVideos } from '../../../../redux/actions/videos'
import { DebounceTableSearch } from '../../SearchNative'
import { getTags } from '../../../../helpers/VideoTags'
import { parseUrl } from '../../../../helpers/utils'
import network from '../../../../utils/network'
import VideoCard from './Video'
import './VideoSelect.scss'

function formatBytes(a, b = 2) {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
  return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
}

function msToTime(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;

  return hrs + ':' + mins + ':' + secs;
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper,
    top: '50%',
    left: '50%',
    width: '60%',
    borderRadius: 4,
    minHeight: '60vh',
    maxHeight: '90vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    transform: 'translate(-50%, -50%)'
  },
  formControl: {
    width: '90%',
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function VideoSelect(props) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const [tags, setTags] = useState([])
  const [option, setOption] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [breakLoad, setBreakLoad] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    preload()
  }, [])

  const preload = async () => {
    const data = await getTags(props.site)
    setTags(data)
  }

  const onChange = (data, index) => {
    props.onClose()
    props.onChange(data, index)
  }

  const parsedTags = () => {
    const parsedTags = []
    for (let i = 0; i < option.length; i++) {
      const tagSelected = option[i];
      const exists = tags.find(item => item.name === tagSelected)
      if (exists) {
        parsedTags.push(exists.id)
      }
    }
    return parsedTags;
  }

  const onSelectTags = (event) => {
    setOption(event.target.value);
  };

  useEffect(() => {
    setPage(0)
    setData([])
    setHasMore(true)
    setBreakLoad(false)
  }, [searchText, option])

  const loadData = async () => {
    if (!loading) {
      if (!breakLoad) {
        await setLoading(true)
        try {
          const searchCols = ["id", "name"];
          const tagsSelected = parsedTags()
          const url = parseUrl(`site/${props.site}/videos?`, 25, page * 25, null, null, searchCols, searchText, tagsSelected)
          const response = await network.get(url)
          if (response.data && response.data.data) {
            if (response.data.data.length) {
              if (page === 0) {
                setPage(1)
                await setData(response.data.data)
              } else {
                setPage(page + 1)
                await setData([...data, ...response.data.data])
              }
              if (response.data.data.length < 25) {
                setHasMore(false)
              }
            } else {
              setHasMore(false)
            }
          }
        } catch (error) {
          console.log('error', error)
          setBreakLoad(true)
          setHasMore(false)
        }
      } else {
        console.log('else breakLoad', breakLoad)
      }
      await setLoading(false)
    }
  }

  const loadDataDebounce = _.debounce(loadData)

  const renderItems = () => {
    if (data.length) {
      return data.map((item, index) => {
        return (
          <VideoCard
            preview={true}
            urls={item.urls}
            name={item.name}
            size={formatBytes(item.size)}
            duration={msToTime(item.duration)}
            createdAt={moment(new Date(item.createdAt)).format('MMMM D YYYY')}
            key={`Video-Card-${index}`}
            onChange={() => onChange(item, index)}

            value={item.value}
            loadingDownload={item.loadingDownload}
            onEdit={index.onEdit}
            onDelete={item.onDelete}
            onDownload={item.onDownload}
          />
        )
      })
    } else {
      if (breakLoad) {
        return (
          <div>
            <p>No hay registros</p>
          </div>
        )
      } else {
        return null
      }
    }
  }

  return (
    <>
      <Modal open={props.open} onClose={props.onClose}>
        <div className={classes.paper}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <label>Lista de videos</label>
            <IconButton onClick={props.onClose}>
              <Close />
            </IconButton>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <DebounceTableSearch
                debounceWait={500}
                onSearch={(text) => setSearchText(text)}
              />
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <FormControl className={classes.formControl}>
                <InputLabel>Filtro por etiqueta</InputLabel>
                <Select
                  multiple
                  value={option}
                  input={<Input />}
                  onChange={onSelectTags}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {tags.map((item) => (
                    <MenuItem key={item.name} value={item.name}>
                      <Checkbox checked={option.indexOf(item.name) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='video-select-list'>
            <InfiniteScroll
              pageStart={page}
              hasMore={hasMore}
              loadMore={loadDataDebounce}
              className='video-infinite-scroll'
            >
              {renderItems()}
              {
                hasMore && (
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress style={{ color: '#00A8D8' }} />
                  </div>
                )
              }
            </InfiniteScroll>
          </div>
        </div>
      </Modal>
      {props.video ? (
        <div style={{ width: '100%', height: 200, marginBottom: 10 }}>
          <div
            onClick={() => props.onChange()}
            style={{ cursor: 'pointer', position: 'absolute', top: 5, right: 5, zIndex: 10 }}
          >
            <Delete
              style={{ fill: 'red' }}
            />
          </div>
          <video controls={true} src={props.video.url} style={{ width: '100%', height: 200 }} />
        </div>
      ) : null}
    </>
  )
}

VideoSelect.propTypes = {
  open: PropTypes.bool,
  site: PropTypes.string,
  video: PropTypes.object,
  videos: PropTypes.object,
  sites: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  fetchVideos: PropTypes.func,
  customSearch: PropTypes.func.isRequired,
}

const mapStateToProps = ({ videos, sites }) => {
  return { videos, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchVideos }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSelect)
