import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import FormErrors from "../FormErrors";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Validate from "../utility/FormValidation";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ContainerBackground, Title, SubTitle, Label, Box, Input, Text, Row, Button } from '../StyledComponents';
import styled from 'styled-components';

class Register extends Component {
  state = {
    fullname: '',
    email: '',
    password: '',
    terms: false,
    errors: {
      cognito: null,
      blankfield: null,
      passwordmatch: false
    },
    passwordValidationStatus: {},
    passwordMasked: true
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: null,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const { fullname, email, password } = this.state;
    try {
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: email,
          name: fullname
        }
      });
      
      this.props.history.push({
        pathname: "/welcome",
        state: { uid: user.userSub }
      });

    }
    catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  submitEnabled = () => {
    let { fullname, email, password, terms } = this.state
    let { lowercase, uppercase, special, number, min } = this.state.passwordValidationStatus
    return (
            fullname !== '' &&
            email !== '' &&
            password !== '' &&
            terms &&
            lowercase &&
            uppercase &&
            special &&
            number &&
            min
          )
  }

  validatePassword = event => {
    let value = event.target.value
    this.setState({ passwordValidationStatus: {
      uppercase: value.match('(?=.*[A-Z])') !== null ? true : false,
      lowercase: value.match('(?=.*[a-z])') !== null ? true : false,
      special: value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) !== null ? true : false,
      number: value.match('([0-9])') !== null ? true : false,
      min: value.length >= 8 ? true : false,
    }})
  }

  render() {
    return (
      <>
        <Navbar />
          <section className="section-signup auth">
            <Content>
              <BoxWrapper>
                <Box style={styles.box}>
                  <Title style={styles.title}>Regístrate</Title>
                  <SubTitle style={styles.subtitle}>Llena el formato y comienza a aprender ahora</SubTitle>
                  <FormErrors formerrors={this.state.errors} />

                  <form onSubmit={this.handleSubmit}>
                    <div style={styles.field} className="field">
                      <Label style={styles.label}>Nombre completo</Label>
                      <Input
                        type="text"
                        id="fullname"
                        aria-describedby="userNameHelp"
                        value={this.state.fullname}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div style={styles.field} className="field">
                      <Label style={styles.label}>Correo</Label>
                      <Input
                        type="email"
                        id="email"
                        aria-describedby="emailHelp"
                        value={this.state.email}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div style={{...styles.field, position:'relative'}} className="field">
                      <Label style={styles.label}>Contraseña</Label>
                      <Input
                        id="password"
                        type={this.state.passwordMasked ? "password" : "text"}
                        value={this.state.password}
                        onChange={(e)=>{
                          this.onInputChange(e);
                          this.validatePassword(e);
                        }
                        }
                      />
                      <Mask 
                        onClick={()=>this.setState(prevState => ({passwordMasked: !prevState.passwordMasked}) )} 
                      >
                        { this.state.passwordMasked ? <VisibilityOffIcon style={{fill: '#313540'}} /> : <VisibilityIcon style={{fill: '#313540'}} /> }
                      </Mask>
                    </div>
                    <PasswordValidation className="field">
                      <PVItem>
                        <PVDot style={this.state.passwordValidationStatus.lowercase ? {background: '#09C269'} : null }/>
                        <PVText>Un carácter en minúscula</PVText>
                      </PVItem>
                      <PVItem>
                        <PVDot style={this.state.passwordValidationStatus.uppercase ? {background: '#09C269'} : null }/>
                        <PVText>Un carácter en mayúscula</PVText>
                      </PVItem>
                      <PVItem>
                        <PVDot style={this.state.passwordValidationStatus.special ? {background: '#09C269'} : null }/>
                        <PVText>Un carácter especial</PVText>
                      </PVItem>
                      <PVItem>
                        <PVDot style={this.state.passwordValidationStatus.number ? {background: '#09C269'} : null }/>
                        <PVText>Un número</PVText>
                      </PVItem>
                      <PVItem>
                        <PVDot style={this.state.passwordValidationStatus.min ? {background: '#09C269'} : null }/>
                        <PVText>8 caracteres como mínimo</PVText>
                      </PVItem>
                    </PasswordValidation>
                    <hr />
                    <Text style={{fontSize: 14}}>
                    <input 
                      type="checkbox"  
                      defaultChecked={this.state.terms} 
                      onChange={(e) => this.setState({terms: e.target.checked}) } 
                    /> Al crear esta cuenta aceptas los <Link style={{fontSize:14}} to="Terms" className="link bold">términos de uso</Link> y nuestras <Link style={{fontSize:14}} to="PrivacyPolicy" className="link bold">políticas de privacidad</Link>
                    </Text>
                    <div className="field">
                      <p className="control">
                        <Button style={{fontSize: 18}} disabled={!this.submitEnabled()}>
                          Crear Cuenta
                        </Button>
                      </p>
                    </div>
                  </form>

                  <Row justify="center">
                    <Label style={{fontSize:18}}>Si ya tienes una cuenta <Link style={{fontSize:18}} className="link link-bold" to="/login">
                      Inicia Sesión
                      </Link>
                    </Label>
                  </Row>
                </Box>
              </BoxWrapper>

              <BackgroundWrapper>
                <ContainerBackground 
                  style={{ 
                    flexShrink: 1,
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}  
                  background="/images/azul.png" 
                />
              </BackgroundWrapper>
          
            </Content>
          </section>
        <Footer />
      </>
    );
  }
}

const Content = styled.div`
  display: flex; 
  justifyContent: space-between;
`

const BoxWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 50px 30px;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 35px 30px;
  }
`;

const Mask = styled.div`
  width: 24px; 
  height: 24px; 
  position: absolute;
  right: 14px;
  bottom: 8px;
  cursor: pointer;
  transition: opacity 0.4s;
  :hover{
    opacity: 0.9;
  }
`;


const BackgroundWrapper = styled.div`
  width: 650px;
  flex-shrink: 1;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const PasswordValidation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const PVItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const PVDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #ddd;
  transition: background 0.2s;
`;

const PVText = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  color: rgb(136, 145, 166);
`;

const styles = {
  title: {
    lineHeight: '40px', 
    fontSize: 32, 
    marginBottom: 0
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  label: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: '16px'
  },
  box: {
    padding: '36px 80px'
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '24px',
    marginTop: 6,
    marginBottom: 28
  }
}

export default Register;
