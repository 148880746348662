import { Auth, Analytics } from 'aws-amplify';
import network from '../utils/network';

export const setSocket = () => {
  return new Promise((resolve, reject) => {
    Auth.currentCredentials().then((info) => {
      const cognitoIdentityId = info.identityId;
      network.get('attach/policy/iot', { 
        cognitoidentityid: cognitoIdentityId,
      });
      resolve(cognitoIdentityId);
    }).catch(error => {
      reject(error);
    });
  });
}

const mappedobjects = f => obj =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const Arrayofourstrings = value => [`${value}`];
const mapArrayofourstrings = mappedobjects(Arrayofourstrings);

export const trackUserIdforPinpoint = async () => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const userAttributes = mapArrayofourstrings(attributes);
    Analytics.updateEndpoint({
      address: attributes.email,
      channelType: 'EMAIL',
      optOut: 'NONE',
      userId: attributes.sub,
      userAttributes,
    });
  } catch (error) {
    console.log('trackUserIdforPinpoint Error', error);
  }
}
