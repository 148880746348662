import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { postModule, fetchModules } from '../../../redux/actions/modules';
import ListModules from './components/ListModules';
import AddModule from './components/AddModule';

const useStyles = makeStyles({
  typographyRoot: {
    margin: '0px !important',
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976'
  }
});

const styles = {
  header: {
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center', 
    marginBottom: 15 
  },
  addModuleButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    backgroundColor: '#00ace7', 
    marginBottom: 0, 
    fontSize: 14,
    textTransform: 'none',
    padding: '18px 20px'
  }
}

function Modulos(props) {
  const { sites } = props;
  const classes = useStyles();
  const { course } = useParams();
  const { enqueueSnackbar } = useSnackbar()
  const [modalModulo, setModalModulo] = useState(false);

  const saveModule = async (data) => {
    let last = 0
    if (props.modules.data && props.modules.data.length) {
      last = Math.max(...props.modules.data.map(item => item.order)) + 1
    }
    await props.postModule(sites.defaultSite.id, course, {...data, order: last});
    makeToast(enqueueSnackbar, 'Modulo creado')
    await setModalModulo(false);
    await props.fetchModules(sites.defaultSite.id, course);
  }

  return (
    <div>
      <div style={styles.header}>
        <Typography
          classes={{
            root: classes.typographyRoot
          }}
        >
          Modulos
        </Typography>
        <Button 
          onClick={()=>setModalModulo(true)}
          style={styles.addModuleButton} 
          className="button is-light"
        >
          Agregar Módulo
        </Button>
      </div>
      <ListModules
        site={sites.defaultSite.id} 
        course={course} 
      />
      <AddModule 
        show={modalModulo}
        onSave={saveModule} 
        onClose={() => setModalModulo(false)} 
      />
    </div>
  );
}

const mapStateToProps = ({ sites, modules }) => {
  return { sites, modules };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ postModule, fetchModules }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Modulos);