import React, { useState } from 'react';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../../utils'
import { FormControl, FormGroup, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle,IconButton, makeStyles } from '@material-ui/core';
import { createCategory } from '../../../../helpers/categories';
import { uploadFile } from '../../../../helpers/files';
import { Close, AddBox } from '@material-ui/icons';
import { Input } from '../../StyledComponents';
import ImageUploader from './ImageUploader';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot:{
    padding: 2
  }
});

const AddCategory = ({ show, onClose, siteId, onSave }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [nameCategory, setNameCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const saveCategory = async (ev) => {
    ev.preventDefault();
    if (nameCategory.trim() !== '') {
      try {
        await setLoading(true);
        let background = null;
        if (files.length) {
          background = await uploadFile(siteId, files[0]);
        }
        const response = await createCategory(siteId, { name: nameCategory, view: { background } });
        await onSave(response);
      } catch (error) {
        console.log('handleSubmit', );
        makeToast(enqueueSnackbar, error, 'error')
      } finally {
        setNameCategory('');
        setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, 'Todos los datos son requeridos', 'warning')
    }
  }

  return (
    <Dialog classes={{paper:classes.dialogPaper}} open={show} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogContent style={{paddingBottom: 18}}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{display:'flex', columnGap: 12, alignItems: 'center'}}>
            <AddBox style={{fill: '#024976'}}/>
            <DialogTitle classes={{root:classes.dialogTitleRoot}} id="form-dialog-title">Agregar Categoría</DialogTitle>
          </div>
          <IconButton classes={{root:classes.iconButtonRoot}} onClick={onClose}>
            <Close style={{fill: '#333333'}}/>
          </IconButton>
        </div>
      </DialogContent>
      <div style={{height: 1.6, width: '100%', backgroundColor: '#e4e4e4'}}/>
      <DialogContent style={{paddingTop: 24}}>
        <FormControl style={{ width: '100%' }} onSubmit={saveCategory}>
          
          <FormGroup>
            <div style={{ textAlign: 'left', marginBottom: 20}} className="field">
              <Input
                type="text"
                id="name"
                value={nameCategory}
                disabled={loading}
                style={{ height: 56, borderRadius: 14}}
                placeholder="Ingresa el nombre de la categoría"
                aria-describedby="nameHelp"
                onChange={(e)=>setNameCategory(e.target.value)}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <ImageUploader
              files={files}
              onChange={(acceptedFiles) => {
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                  preview: URL.createObjectURL(file)
                })));
              }}
              showIcon={true}
              dropContainerStyle={{ height: 140 }}
              placeholder="Haga clic aquí para subir una imagen o video"
            />
          </FormGroup>

        </FormControl>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px 24px' }}>

        <button 
          onClick={onClose}
          disabled={loading}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }}
          className="button is-secondary"
        >
          Cancelar
        </button>

        <button 
          disabled={loading} 
          onClick={saveCategory}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }} 
          className="button is-light"
        >
          {loading ? <CircularProgress size={18} style={{ color: '#00A8D8' }} /> : 'Crear'}
        </button>
    
      </DialogActions>
    </Dialog>
  );
}

export default AddCategory;