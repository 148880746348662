import {
  FETCHING_CONTENT,
  FAIL_FETCH_CONTENT,
  SUCCESS_FETCH_CONTENT,
  PUT_CONTENT,
  PUT_FAIL_CONTENT,
  PUT_SUCCESS_CONTENT,
  POST_CONTENT,
  POST_FAIL_CONTENT,
  POST_SUCCESS_CONTENT,
  DELETE_CONTENT,
  DELETE_FAIL_CONTENT,
  DELETE_SUCCESS_CONTENT,
  UPDATE_CONTENT,
  UPDATE_FAIL_CONTENT,
  UPDATE_SUCCESS_CONTENT,
  UPDATE_SORT_CONTENT,
} from '../actions/content'

const initialState = {
  data: {},
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case SUCCESS_FETCH_CONTENT:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.parent]: action.data,
        },
      };
    case FAIL_FETCH_CONTENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PUT_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case PUT_FAIL_CONTENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PUT_SUCCESS_CONTENT:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case POST_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case POST_FAIL_CONTENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case POST_SUCCESS_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SORT_CONTENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.parent]: action.data,
        },
      };
    case DELETE_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case DELETE_FAIL_CONTENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SUCCESS_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CONTENT:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FAIL_CONTENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_SUCCESS_CONTENT:
      return {
        ...state,
        loading: false,
      };
    default:
      return state
  }
};
