import { API } from 'aws-amplify'

const parseError = (error) => {
  let newError = null
  if (error) {
    newError = error
    if (error.response) {
      newError = error.response
      if (error.response.data) {
        newError = error.response.data.error
        if (error.response.data.error) {
          newError = error.response.data.error
          if (error.response.data.error.message) {
            newError = error.response.data.error.message
          }
        }
      }
    } 
  }
  return newError
}

export default {
  post: async (url, body, headers) => {
    try {
      const data = await API.post('API', url, { body, headers })
      return { data }
    } catch (error) {
      throw parseError(error)
    }
  },
  get: async (url, headers) => {
    try {
      const data = await API.get('API', url, { headers })
      return { data }
    } catch (error) {
      throw parseError(error)
    }
  },
  put: async (url, body) => {
    try {
      const data = await API.put('API', url, { body: body })
      return { data }
    } catch (error) {
      throw parseError(error)
    }
  },
  del: async url => {
    try {
      const data = await API.del('API', url)
      return { data }
    } catch (error) {
      throw parseError(error)
    }
  }
}
