import network from '../utils/network';

export const getUser = async (site, username) => {
  try {
    const { data } = await network.get(`site/${site}/people/${username}`);
    return data;
  } catch (error) {
    console.error('getUser', error);
    throw error;
  }
}

export const updateUser = async (site, username, body) => {
  try {
    const { data } = await network.put(`site/${site}/people/${username}`, body);
    return data;
  } catch (error) {
    console.error('updateUser', error);
    throw error;
  }
}

export const deleteUser = async (site, username) => {
  try {
    await network.del(`site/${site}/people/${username}`);
  } catch (error) {
    console.error('updateUser', error);
    throw error;
  }
}

export const createUser = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/people`, body);
    if (data.error) {
      throw(data.error);
    }
    return data;
  } catch (error) {
    console.error('createUser', error);
    throw error;
  }
}

export const getUserCourses = async (site, username) => {
  try {
    const { data } = await network.get(`site/${site}/people/${username}/courses`);
    return data;
  } catch (error) {
    console.error('getUserCourses', error);
    throw error;
  }
}

export const assignCourse = async (site, userId, courseId) => {
  try {
    const { data } = await network.post(`site/${site}/people/${userId}/courses/${courseId}`);
    if (data.error) {
      throw(data.error);
    }
    return data;
  } catch (error) {
    console.error('assignCourse', error);
    throw error;
  }
}