import React, { Component } from 'react';
import Footer from "../Footer";
import Navbar from "../Navbar";
import {Label, Row, Link, Button} from '../StyledComponents';
import styled from 'styled-components';

class ForgotPasswordEmailSent extends Component {

  render() {
    return (
      <>
        <Navbar />
        <div style={styles.container} className="auth">
          <Box className="content">
            <img style={styles.image} src="/images/check.png"/>
            <h1 style={styles.title}>Correo enviado exitosamente</h1>
            <p style={styles.subtitle}>
              Porfavor revisa tu correo electrónico y da
              click en el link recibido para restablecer tu
              contraseña
            </p>
            <Button onClick={()=>window.location.href = "/login"} style={{fontSize: 16, padding: 10}} backgroundColor="#1a9e75" color="#fff">Iniciar Sesión</Button>
            <Row justify="center">
              <Label style={{fontSize: 14, fontWeight: '500'}}>No recibí el correo <Link style={{fontSize: 14,fontWeight: '600'}} className="link link-bold" href="/forgotpassword">
                Enviar Nuevamente
                  </Link>
              </Label>
            </Row>
          </Box>
        </div>
        <Footer />
      </>
    );
  }
}

const styles = {
  container: {
    width: '100%',
    padding: 30,
    minHeight: 'calc(100vh - 206.99px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f8fa'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 30,
    marginBottom: 15
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    color: '#959ca8',
    lineHeight: '28px',
    marginBottom: 20
  },
  image: {
    borderRadius: '50%',
    width: 100,
    boxShadow: '2px 2px 25px 0px #ddd'
  }
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
`;

export const Box = styled.div`
  padding: 50px 65px;
  width: 570px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;

export default ForgotPasswordEmailSent;
