import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  FormControl,
  FormGroup,
  makeStyles,
  TextField
} from '@material-ui/core'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { ArrowDropDown } from '@material-ui/icons'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { getCategories } from '../../../helpers/categories'
import { putCourse } from '../../../redux/actions/courses'
import { makeToast, filterChanges } from '../../../utils'
import { createCourse } from '../../../helpers/courses'
import { Select, TextArea } from '../StyledComponents'
import AddCategory from './components/AddCategory'

const Information = props => {
  const classes = useStyles()
  const { course } = useParams()
  const { sites, courses } = props
  const { enqueueSnackbar } = useSnackbar()
  const [summary, setSummary] = useState('')
  const [dialog, setDialog] = useState(false)
  const [category, setCategory] = useState('')
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [description, setDescription] = useState('')
  const [enableEdit, setEnableEdit] = useState(false)
  const [renderCKEditor, setRenderCKEditor] = useState(false)
  const [expected_duration, setExpected_duration] = useState(0)
  const [data, setData] = useState({})

  useEffect(() => {
    preload()
    setRenderCKEditor(true)
  }, [])

  const preload = async () => {
    try {
      const { data } = await getCategories(sites.defaultSite.id)
      await setCategories(data)
      if (course) {
        const data = courses.course.data
        await setData(data)
        await setDescription(data.description)
        await setSummary(!data.summary ? '' : data.summary)
        await setExpected_duration(data.expected_duration)
        await setCategory(data.categoryId)
      }
    } catch (error) {
      console.log(error)
      makeToast(enqueueSnackbar, 'Error precargando', 'error')
    }
  }

  useEffect(() => {
    if (Object.keys(data).length === 0) return setEnableEdit(false)
    if (category !== data.categoryId) return setEnableEdit(true)
    if (description !== data.description) return setEnableEdit(true)
    if (summary !== data.summary) return setEnableEdit(true)
    if (expected_duration !== data.expected_duration) return setEnableEdit(true)
    setEnableEdit(false)
  }, [category, categories, description, summary, expected_duration])

  const validate = () => {
    if (description.trim() === '')
      return { success: false, error: 'Descripción requerido' }
    if (summary.trim() === '')
      return { success: false, error: 'Resumen requerido' }
    if (!expected_duration)
      return { success: false, error: 'Duración requerido' }
    return { success: true }
  }

  const onSaveCategory = async response => {
    try {
      const { data } = await getCategories(sites.defaultSite.id)
      await setCategories(data)
      setCategory(response.id)
    } catch (error) {
      console.log('handleSubmit', error)
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      setDialog(false)
    }
  }

  const handleSubmit = async (ev, body = {}) => {
    ev && ev.preventDefault()
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true)
      try {
        const siteId = sites.defaultSite.id
        const updateCourse = {
          summary,
          description,
          expected_duration,
          categoryId: category !== -1 ? category : null
        }
        if (course) {
          const newBody = filterChanges(updateCourse, data)
          await props.putCourse(siteId, course, newBody)
          makeToast(enqueueSnackbar, 'El curso ha sido actualizado')
        } else {
          await createCourse(siteId, updateCourse)
          makeToast(enqueueSnackbar, 'El curso ha sido creado')
        }
      } catch (error) {
        console.log('handleSubmit', error)
        makeToast(enqueueSnackbar, error, 'error')
      }
      setLoading(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'error')
    }
  }

  return (
    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
      <FormGroup style={{ marginBottom: 10 }}>
        <div style={styles.field} className='field'>
          <div
            style={{
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: 'Montserrat',
              position: 'relative',
              zIndex: 1,
              padding: '0px 13px'
            }}
          >
            Descripción
          </div>
          <TextArea
            type='text'
            disabled={loading}
            value={description}
            label='Descripción'
            aria-describedby='descriptionHelp'
            classes={{ root: classes.textAreaRoot }}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </FormGroup>

      <FormGroup style={{ marginBottom: 10 }}>
        <div style={{ ...styles.field }} className='field'>
          <div
            style={{
              fontSize: 12,
              color: 'rgba(0, 0, 0, 0.54)',
              fontFamily: 'Montserrat',
              marginBottom: -8,
              position: 'relative',
              zIndex: 1,
              padding: '0px 13px'
            }}
          >
            Resumen del curso
          </div>
          {renderCKEditor && (
            <CKEditor
              data={summary}
              config={{
                placeholder: 'Ingresa el resumen aqui',
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  '|',
                  'undo',
                  'redo'
                ],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Paragraph',
                      class: 'ck-heading_paragraph'
                    },
                    {
                      model: 'heading1',
                      view: 'h1',
                      title: 'Heading 1',
                      class: 'ck-heading_heading1'
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2'
                    },
                    {
                      model: 'heading3',
                      view: 'h3',
                      title: 'Heading 3',
                      class: 'ck-heading_heading3'
                    }
                  ]
                }
              }}
              editor={ClassicEditor}
              onChange={(event, editor) => setSummary(editor.getData())}
            />
          )}
        </div>
      </FormGroup>

      <FormGroup style={{ marginBottom: 10 }}>
        <div
          style={{
            marginTop: 30,
            marginBottom: 15,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={styles.panelTitle}>Categoría</div>
          <button
            disabled={courses.course.loading}
            onClick={() => setDialog(true)}
            style={{
              ...styles.actionButton,
              backgroundColor: '#00ace7',
              marginBottom: 0,
              fontSize: 14,
              padding: '18px 20px'
            }}
            className='button is-light'
          >
            Agregar Categoría
          </button>
        </div>

        <div style={{ position: 'relative' }}>
          <Select
            id='categoryId'
            value={category}
            disabled={loading}
            style={{
              ...styles.input,
              backgroundColor: 'white',
              border: 'rgb(232, 232, 232) 1px solid'
            }}
            onChange={ev => setCategory(ev.target.value)}
          >
            <option value={-1} selected='selected'>
              Ninguna
            </option>
            {categories.map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
          <ArrowDropDown
            style={{
              fill: '#144974',
              position: 'absolute',
              top: 16,
              right: 16
            }}
          />
        </div>
      </FormGroup>

      <AddCategory
        show={dialog}
        onClose={() => setDialog(false)}
        siteId={sites.defaultSite.id}
        onSave={onSaveCategory}
      />

      <FormGroup style={{ marginBottom: 10, marginTop: 15 }}>
        <div style={styles.field} className='field'>
          <TextField
            type='number'
            id='expected_duration'
            value={expected_duration}
            disabled={loading}
            label='Duración (mins)'
            aria-describedby='expected_durationHelp'
            onChange={e => setExpected_duration(e.target.value)}
            variant='outlined'
            classes={{ root: classes.textFieldRoot }}
          />
        </div>
      </FormGroup>

      <button
        disabled={!enableEdit}
        onClick={handleSubmit}
        className='button is-light'
        style={styles.submitButton}
      >
        {loading ? <CircularProgress color="#fff" size={20} /> : 'Guardar'}
      </button>
    </FormControl>
  )
}

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  textAreaRoot: {
    minHeight: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  formLabelRoot: {
    fontSize: 16,
    color: '#024976',
    fontFamily: 'Montserrat',
    fontWeight: '900'
  },
  formControlLabelRoot: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: '#9e9e9e'
    },
    '& .Mui-checked + span.MuiTypography-root': {
      color: '#00ace7'
    }
  },
  formControlLabelRootCheckbox: {
    margin: 0,
    '& .Mui-checked .MuiIconButton-label': {
      color: '#00ace7 !important'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '500',
      color: 'rgb(2, 73, 118)'
    }
  },
  radioGroupRoot: {
    flexDirection: 'row',
    columnGap: 28
  },
  radioRoot: {
    '&:not(.Mui-checked) .MuiIconButton-label': {
      color: 'gainsboro'
    },
    '&.Mui-checked .MuiIconButton-label': {
      color: 'transparent'
    },
    '&.Mui-checked .MuiIconButton-label div svg:first-child': {
      backgroundColor: '#00ace7',
      borderRadius: '50%'
    },
    '&.Mui-checked .MuiIconButton-label div svg:last-child': {
      fill: 'white'
    }
  }
})

const styles = {
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
  submitButton: {
    fontSize: 16,
    color: '#fff',
    borderRadius: 8,
    padding: '26px 0px'
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976'
  }
}

const mapStateToProps = ({ sites, courses, modules }) => {
  return { sites, courses, modules }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ putCourse }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Information)
