import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { Close, AddBox } from '@material-ui/icons';
import { FormControl, FormGroup, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { duplicate } from '../../../redux/actions/pages';
import { Input } from '../../components/StyledComponents';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot: {
    padding: 2
  }
});

const DuplicatePage = ({ show, onClose, siteId, pageId, onSave, duplicate }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false);

  const escapeText = (text = '') => {
    let result = text.toLowerCase();
    result = result.replace(/[^\w\s]/gi, '');
    result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return result;
  }

  const saveCategory = async (ev) => {
    ev.preventDefault();
    if (title.trim() !== '') {
      try {
        await setLoading(true);
        const name = escapeText(title);
        const response = await duplicate(siteId, pageId, {
          title: name,
          data: { seo: { url: name.replaceAll(" ", '-'), title: name } },
        });
        await onSave(response);
      } catch (error) {
        console.log('handleSubmit', error);
        makeToast(enqueueSnackbar, error, 'error')
      } finally {
        setTitle('');
        setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, 'Todos los datos son requeridos', 'warning')
    }
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={show} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogContent style={{ paddingBottom: 18 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
            <AddBox style={{ fill: '#024976' }} />
            <DialogTitle classes={{ root: classes.dialogTitleRoot }} id="form-dialog-title">Duplicar pagina</DialogTitle>
          </div>
          <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onClose}>
            <Close style={{ fill: '#333333' }} />
          </IconButton>
        </div>
      </DialogContent>
      <div style={{ height: 1.6, width: '100%', backgroundColor: '#e4e4e4' }} />
      <DialogContent style={{ paddingTop: 24 }}>
        <FormControl style={{ width: '100%' }} onSubmit={saveCategory}>
          <FormGroup>
            <div style={{ textAlign: 'left', marginBottom: 20 }} className="field">
              <Input
                type="text"
                value={title}
                disabled={loading}
                aria-describedby="nameHelp"
                placeholder="Ingresa el Titulo"
                style={{ height: 56, borderRadius: 14 }}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px 24px' }}>
        <button
          onClick={onClose}
          disabled={loading}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }}
          className="button is-secondary"
        >
          Cancelar
        </button>
        <button
          disabled={loading}
          onClick={saveCategory}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }}
          className="button is-light"
        >
          {loading ? <CircularProgress size={18} style={{ color: '#00A8D8' }}/> : 'Crear'}
        </button>

      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ duplicate }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicatePage);