import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import FormErrors from '../FormErrors'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import Validate from '../utility/FormValidation'
import { Label, Input } from '../StyledComponents'

const PasswordValidation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`
const PVItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px;
  @media (max-width: 480px) {
    width: 100%;
  }
`
const PVDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #ddd;
  transition: background 0.2s;
`
const PVText = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  color: rgb(136, 145, 166);
`
const Box = styled.div`
  padding: 50px 65px;
  width: 570px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`

class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: '',
    email: '',
    newpassword: '',
    errors: {
      cognito: null,
      blankfield: false
    },
    passwordValidationStatus: {},
    passwordMasked: true
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    })
  }

  componentDidMount () {
    const search = this.props.location.search
    const verificationcode = new URLSearchParams(search).get('code')
    const email = decodeURIComponent(new URLSearchParams(search).get('email'))
    this.setState({ email, verificationcode })
  }

  passwordVerificationHandler = async event => {
    event.preventDefault()
    // Form validation
    this.clearErrorState()
    const error = Validate(event, this.state)
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      })
    }
    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.verificationcode,
        this.state.newpassword
      )
      this.props.history.push('/changepasswordconfirmation')
    } catch (error) {
      console.log(error)
      this.setState({
        errors: {
          cognito: error,
        }
      })
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
    document.getElementById(event.target.id).classList.remove('is-danger')
  }

  validatePassword = event => {
    let value = event.target.value
    this.setState({
      passwordValidationStatus: {
        uppercase: value.match('(?=.*[A-Z])') != null ? true : false,
        lowercase: value.match('(?=.*[a-z])') != null ? true : false,
        special:
          value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) != null
            ? true
            : false,
        number: value.match('([0-9])') != null ? true : false,
        min: value.length >= 8 ? true : false
      }
    })
  }

  submitEnabled = () => {
    let { verificationcode, email, newpassword } = this.state
    let {
      lowercase,
      uppercase,
      special,
      number,
      min
    } = this.state.passwordValidationStatus
    return (
      verificationcode !== '' &&
      email !== '' &&
      newpassword !== '' &&
      lowercase &&
      uppercase &&
      special &&
      number &&
      min
    )
  }

  render () {
    return (
      <>
        <Navbar />
        <section style={styles.container} className='auth'>
          <Box className='content'>
            <h1>Establecer nueva contraseña</h1>
            <p>
              Ingrese el código de verificación enviado a su dirección de correo
              electrónico a continuación, su dirección de correo electrónico y
              una nueva contraseña.
            </p>
            <FormErrors formerrors={this.state.errors} />

            <form onSubmit={this.passwordVerificationHandler}>
              <div style={styles.field} className='field'>
                <Label style={styles.fieldLabel}>
                  Ingrese el código de verificación
                </Label>
                <Input
                  type='text'
                  className='input'
                  id='verificationcode'
                  onChange={this.onInputChange}
                  value={this.state.verificationcode}
                  aria-describedby='verificationCodeHelp'
                  placeholder='Ingrese el código de verificación'
                />
              </div>
              <div style={styles.field} className='field'>
                <Label style={styles.fieldLabel}>Correo electrónico</Label>
                <Input
                  className='input'
                  type='email'
                  id='email'
                  aria-describedby='emailHelp'
                  placeholder='Correo electrónico'
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
              </div>
              <div style={styles.field} className='field'>
                <Label style={styles.fieldLabel}>Nueva contraseña</Label>
                <Input
                    type='password'
                    className='input'
                    id='newpassword'
                    placeholder='Nueva contraseña'
                    value={this.state.newpassword}
                    onChange={e => {
                      this.onInputChange(e)
                      this.validatePassword(e)
                    }}
                />
              </div>
              <PasswordValidation className='field'>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.lowercase
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un carácter en minúscula</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.uppercase
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un carácter en mayúscula</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.special
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un carácter especial</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.number
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un número</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.min
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>8 caracteres como mínimo</PVText>
                </PVItem>
              </PasswordValidation>
              <div className='field'>
                <p className='control'>
                  <button
                    disabled={!this.submitEnabled()}
                    className='button is-success'
                  >
                    Cambiar Contraseña
                  </button>
                </p>
              </div>
            </form>
          </Box>
        </section>
        <Footer />
      </>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    padding: 30,
    minHeight: 'calc(100vh - 206.99px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f8fa'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 30,
    marginBottom: 28
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    color: '#959ca8',
    lineHeight: '28px',
    marginBottom: 20
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: 25
  },
  fieldLabel: {
    fontSize: 16,
    lineHeight: '16px'
  },
  icon: {
    position: 'absolute',
    top: 34,
    left: 14
  },
  input: {
    paddingLeft: 42
  }
}

export default ForgotPasswordVerification
