import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_INSTRUCTORS = 'FETCHING_INSTRUCTORS';
export const FAIL_FETCH_INSTRUCTORS = 'FAIL_FETCH_INSTRUCTORS';
export const SUCCESS_FETCH_INSTRUCTORS = 'SUCCESS_FETCH_INSTRUCTORS';

export const FETCHING_INSTRUCTOR = 'FETCHING_INSTRUCTOR';
export const FAIL_FETCH_INSTRUCTOR = 'FAIL_FETCH_INSTRUCTOR';
export const SUCCESS_FETCH_INSTRUCTOR = 'SUCCESS_FETCH_INSTRUCTOR';

export const POST_INSTRUCTOR = 'POST_INSTRUCTOR';
export const POST_FAIL_INSTRUCTOR = 'POST_FAIL_INSTRUCTOR';
export const POST_SUCCESS_INSTRUCTOR = 'POST_SUCCESS_INSTRUCTOR';

export const PUT_INSTRUCTOR = 'PUT_INSTRUCTOR';
export const PUT_FAIL_INSTRUCTOR = 'PUT_FAIL_INSTRUCTOR';
export const PUT_SUCCESS_INSTRUCTOR = 'PUT_SUCCESS_INSTRUCTOR';

export const DELETE_INSTRUCTOR = 'DELETE_INSTRUCTOR';
export const DELETE_FAIL_INSTRUCTOR = 'DELETE_FAIL_INSTRUCTOR';
export const DELETE_SUCCESS_INSTRUCTOR = 'DELETE_SUCCESS_INSTRUCTOR';

export const fetchInstructors = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_INSTRUCTORS });
    try {
      let url = parseUrl(`site/${siteId}/instructors?`, limit, offset, column, direction, search, searchtext);
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_INSTRUCTORS,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_INSTRUCTORS,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_INSTRUCTORS,
      });
    }
  };
};

export const fetchInstructor = (siteId, user) => {
  return async dispatch => {
    dispatch({ type: FETCHING_INSTRUCTOR });
    try {
      const response = await network.get(`site/${siteId}/instructors/${user}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_INSTRUCTOR,
        });
      } else {
        dispatch({
          instructor: response.data,
          type: SUCCESS_FETCH_INSTRUCTOR,
        });
        return response.data;
      }
    } catch (error) {

      dispatch({
        error,
        type: FAIL_FETCH_INSTRUCTOR,
      });
    }
  };
};

export const postInstructor = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_INSTRUCTOR });
    const response = await network.post(`site/${siteId}/instructors`, body);
    try {
      dispatch({
        data: response.data,
        type: POST_SUCCESS_INSTRUCTOR,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_INSTRUCTOR,
      });
    }
  };
};

export const putInstructor = (siteId, id, body) => {
  return async dispatch => {
    dispatch({ type: POST_INSTRUCTOR });
    try {
      const response = await network.put(`site/${siteId}/instructors/${id}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_INSTRUCTOR,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_INSTRUCTOR,
      });
    }
  };
};

export const deleteInstructor = (siteId, ids) => {
  return async dispatch => {
    const promises = ids.map(async item => {
      await network.del(`site/${siteId}/instructors/${item}`);
    });
    dispatch({ type: DELETE_INSTRUCTOR });
    try {
      await Promise.all(promises);
      dispatch({
        type: DELETE_SUCCESS_INSTRUCTOR,
      });
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_INSTRUCTOR,
      });
      return error;
    }
  };
};
