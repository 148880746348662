import styled from 'styled-components';

export const Container = styled.div` 
    flex: 1;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    padding: 0;
`
export const Header = styled.div`
  display: flex;
  justify-content:space-between;  
  padding-bottom: 15px;
  width: '100%';
  padding-bottom: 12;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
`
export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 3px;
    padding: 0px;
`
export const Title = styled.div`
  display: flex:
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
  color: #024976;
  column-gap: 2px;
  align-items: center;
  cursor: pointer;
  marginTop: 12,
`