import {
  FETCHING_INSTRUCTORS,
  FAIL_FETCH_INSTRUCTORS,
  SUCCESS_FETCH_INSTRUCTORS,
  FETCHING_INSTRUCTOR,
  FAIL_FETCH_INSTRUCTOR,
  SUCCESS_FETCH_INSTRUCTOR,
  DELETE_INSTRUCTOR,
  DELETE_FAIL_INSTRUCTOR,
  DELETE_SUCCESS_INSTRUCTOR
} from '../actions/instructors'

const initialState = {
  data: [],
  error: null,
  loading: false,
  instructor: {},
  page: null,
  order: {},
  count: 0,
  limit: 25,
  offset: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_INSTRUCTORS:
      return {
        ...state,
        data: [],
        error: null,
        loading: true
      }
    case SUCCESS_FETCH_INSTRUCTORS:
      return {
        ...state,
        loading: false,
        ...action.data
      }
    case FAIL_FETCH_INSTRUCTORS:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error
      }
    case FETCHING_INSTRUCTOR:
      return {
        ...state,
        error: null,
        loading: true,
        instructor: {}
      }
    case SUCCESS_FETCH_INSTRUCTOR:
      return {
        ...state,
        loading: false,
        error: action.error,
        instructor: action.data
      }
    case FAIL_FETCH_INSTRUCTOR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error
      }
    case DELETE_INSTRUCTOR:
      return {
        ...state,
        loading: true
      }
    case DELETE_FAIL_INSTRUCTOR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case DELETE_SUCCESS_INSTRUCTOR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
