import React, {useRef} from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import './ImageUploader.scss'

const duration = 0

const ImageUploader = ({ loading, setDuration, progress, placeholder, showIcon, dropContainerStyle, onChange, previousImage, video = false, preview = true, multiple = false, accept = 'image/*' }) => {

  const styles = {
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16
    },
    dropContainer: {
      padding: 10,
      borderWidth: 1,
      borderColor: '#555',
      borderRadius: 6,
      backgroundColor: 'rgb(250, 250, 250)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    thumb: {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: '100%',
      height: 'auto',
      maxHeight: 300,
      padding: 4,
      boxSizing: 'border-box'
    },
    thumbInner: {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    },
    imageContainer: {
      width: '100%',
      height: '100%'
    },
    img: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    video: {
      display: 'block',
      width: '100%',
      height: 250,
      objectFit: 'contain',
    },
    placeholder: {
      margin: 0,
      fontFamily: 'Montserrat',
      fontSize: 14,
      color: '#495057',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 6
    }
  }

  const videoPlayer = useRef(null)

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    multiple,
    maxFiles: 0,
    onDrop: acceptedFiles => onChange(acceptedFiles, duration),
  });

  const renderThumbs = () => {
    return acceptedFiles.map((file, index) => (
      <div key={`thumbs-${index}`} style={styles.thumb}>
        <div style={{...styles.thumbInner, ...styles.imageContainer}}>
          <img style={styles.img} src={URL.createObjectURL(file)} alt="" />
        </div>
      </div>
    ));
  };

  const onLoadedMetadata = () => {
    setDuration(videoPlayer.current.duration)
  }

  const renderPreview = () => {
    return acceptedFiles.map((file, index) => (
      <div style={styles.imageContainer} key={`preview-${index}`}>
        {
          video ? (
            <video ref={videoPlayer} controls onLoadedMetadata={onLoadedMetadata} style={styles.video} src={URL.createObjectURL(file)} alt="" />
            ) : (
            <img style={styles.img} src={URL.createObjectURL(file)} alt="" />
          )
        }
      </div>
    ));
  };

  const renderPlaceholder = () => {
    return  (
      <div style={styles.placeholder}>
        { showIcon && (<InsertPhotoIcon style={{fill: '#00ace7'}} />)}
        { placeholder }
      </div>
    );
  };

  const renderPreviousImage = () => {
    return  (
      <div style={styles.imageContainer} key={`previous-image`}>
        <img style={styles.img} src={previousImage} alt="" />
      </div>
    );
  };

  if (loading) {
    return (
      <div className="imageUploader-loader-container">
        <CircularProgress style={{ color: '#00A8D8' }} className="imageUploader-loader-progress"/>
        <div className="imageUploader-loader-text">
          Tu adjunto se está cargando {`${Math.round(progress)}%`}
        </div> 
      </div>
    )
  } 

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}
        style={{...styles.dropContainer, ...dropContainerStyle}}>
        <input {...getInputProps()} />
          { 
            (multiple === false && preview) ?
                acceptedFiles.length > 0 ? 
                  renderPreview()
                :
                previousImage ? 
                  renderPreviousImage()
                :
                  renderPlaceholder()
            :
            renderPlaceholder()
          }
      </div>
      <div style={styles.thumbsContainer}>
        {multiple && preview && renderThumbs()}
      </div>
    </div>
  );
}

export default ImageUploader;