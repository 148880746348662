import React, { useState } from 'react'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import { CircularProgress } from '@material-ui/core'
import network from '../../utils/network'
import ActivityItem from './ActivityItem'

const ActivitiesList = props => {
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])

  const loadActivity = async page => {
    try {
      await setLoading(true)
      const { data } = await network.get(`${props.url}?page=${page}`)
      if (data && data.data) {
        if (data.data.length) {
          await setActivities([...activities, ...data.data])
        } else {
          setHasMore(false)
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const loadActivityDebounce = _.debounce(loadActivity)

  const renderItems = () => {
    if (activities.length) {
      return activities.map(item => {
        return (
          <ActivityItem
            id={item.id}
            user={item.user}
            date={item.date}
            type={item.object}
            users={props.users}
            userId={item.userId}
            action={item.action}
            tableName={item.tableName}
          />
        )
      })
    } else {
      if (!loading) {
        return (
          <div>
            <p>No hay registros</p>
          </div>
        )
      } else {
        return null
      }
    }
  }

  return (
    <>
      <div style={{ ...styles.panelSection }}>
        <InfiniteScroll
          pageStart={-1}
          hasMore={hasMore}
          loader={<CircularProgress style={{ color: '#00A8D8' }} />}
          loadMore={loadActivityDebounce}
        >
          {renderItems()}
        </InfiniteScroll>
      </div>
    </>
  )
}

const styles = {
  panel: {
    minWidth: 330,
    backgroundColor: 'white',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  feedPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  panelSection: {
    padding: '20px 30px',
    width: '100%'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  }
}

export default ActivitiesList
