import React from 'react'
import { PubSub } from 'aws-amplify';
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { Visibility, AddCircle } from '@material-ui/icons'
import { fetchPages, deletePage } from '../../../redux/actions/pages'
import network from '../../../utils/network'
import FullDataTable from '../CoursesMain/FullDataTable'
import ModalRight from '../ModalRight'
import Create from './Create'

const columns = [
  {
    name: 'title',
    options: {
      display: 'excluded'
    }
  },
  {
    label: 'Título',
    name: 'config',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value && value.seo && value.seo.title) {
          return <>{value.seo.title}</>
        }
      }
    }
  },
  {
    label: 'Url',
    name: 'config',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value && value.seo && value.seo.url) {
          return <>{value.seo.url}</>
        }
      }
    }
  },
  {
    label: 'Acciones',
    name: 'id',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <>
              <Link to={`/marketing/pages/view/${value}`}>
                <Visibility />
              </Link>
            </>
          )
        }
      }
    }
  }
]
class UsersList extends React.Component {
  state = {
    edit: false,
    loading: false,
  }

  publish = async () => {
    const { sites: { defaultSite } } = this.props;
    if (defaultSite && defaultSite.id) {
      try {
        await this.setState({ loading: true });
        const response = await network.get(`site/${defaultSite.id}/invalidateCache`);
        if (response.data) {
          this.listenPublish(defaultSite.id);
          makeToast(this.props.enqueueSnackbar, 'Publicando...', 'info')
        }
      } catch (error) {
        console.log('error', error);
      }
    }
    this.setState({ loading: false });
  }

  listenPublish = (site) => {
    console.log('listening...', `site/${site}/publish`);
    PubSub.subscribe(`site/${site}/publish`).subscribe({
      error: error => console.log(error),
      next: data => {
        makeToast(this.props.enqueueSnackbar, 'Publicado')
      },
      close: () => { console.log('PubSub.subscribe close') },
    });
  }

  toggle = async () => {
    await this.setState(prev => ({ edit: !prev.edit }))
  }

  onDelete = async id => {
    const { sites } = this.props
    await deletePage(sites.defaultSite.id, id)
    this.props.fetchPages(sites.defaultSite.id)
  }

  customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    const { sites } = this.props
    if ((searchText && searchText !== '', searchText !== ' ')) {
      const names = columns.map(item => item.name)
      this.props.fetchPages(
        sites.defaultSite.id,
        rowsPerPage,
        page,
        sortOrder.name,
        sortOrder.direction,
        names,
        searchText
      )
    } else {
      this.props.fetchPages(
        sites.defaultSite.id,
        rowsPerPage,
        page,
        sortOrder.name,
        sortOrder.direction
      )
    }
  }

  render() {
    const { edit } = this.state
    const {
      sites,
      pages: { data, loading, limit, offset, count, sort }
    } = this.props
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={styles.content} className='content'>
          <div>
            <FullDataTable
              data={data}
              title={'Páginas'}
              loading={loading}
              columns={columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              theme={theme}
              onRowPress={(rowData, meta, rowIndex) => {
                this.props.history.push(
                  `/marketing/pages/view/${data[meta.dataIndex].id}`
                )
              }}
              onRowsDelete={async rows => {
                await this.props.deletePage(sites.defaultSite.id, rows)
                makeToast(
                  this.props.enqueueSnackbar,
                  'Se han eliminado los elementos'
                )
                this.props.fetchPages(sites.defaultSite.id, limit, offset)
              }}
              preload={() =>
                this.props.fetchPages(sites.defaultSite.id, limit, offset)
              }
              sort={this.customSearch}
              search={this.customSearch}
              changePage={this.customSearch}
              changePageSize={this.customSearch}
              renderCreate={() => (
                <div style={styles.actions}>
                  <button
                    onClick={this.publish}
                    disabled={this.state.loading}
                    style={styles.actionButton}
                    className='button is-light'
                  >
                    Publicar
                </button>
                  <button
                    onClick={() => this.toggle()}
                    style={styles.actionButton}
                    className='button is-light'
                  >
                    <AddCircle
                      style={{ width: 20, height: 20, marginRight: 5 }}
                    />{' '}
                  Crear Página
                </button>
                </div>
              )}
            />
          </div>
        </section>
        <ModalRight
          show={edit}
          onClick={() => this.toggle()}
          hideCloseButton={true}
        >
          <Create onCloseModal={() => this.toggle()} />
        </ModalRight>
      </div>
    )
  }
}

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  count: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {}
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600'
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ pages, sites }) => {
  return { pages, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchPages, deletePage }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(UsersList)))
