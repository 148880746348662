import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { Redirect, Link, withRouter } from "react-router-dom";
import {
  ContainerBackground,
  Title,
  SubTitle,
  Label,
  Box,
  Input,
  Row,
  Button,
} from "../StyledComponents";
import { trackUserIdforPinpoint } from "../../../utils/cognito";
import { loadSites } from "../../../redux/actions/sites";
import { login } from "../../../redux/actions/session";
import Validate from "../utility/FormValidation";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FormErrors from "../FormErrors";

class LogIn extends Component {
  state = {
    username: "",
    password: "",
    errors: {
      cognito: null,
      blankfield: null,
    },
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: null,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error },
      });
    } else {
      try {
        const result = await this.props.login(
          this.state.username,
          this.state.password
        );
        if (result.success) {
          this.setState({
            errors: {
              ...this.state.errors,
              cognito: {
                type: result.type,
                message: result.error,
              },
            },
          });
          await this.props.loadSites();
          trackUserIdforPinpoint();
        } else if (result.changepassword) {
          const { history } = this.props;
          history.push(
            `/changepassword?user=${this.state.username}&password=${this.state.password}`
          );
        }
      } catch (error) {
        this.setState({
          errors: {
            ...this.state.errors,
            cognito: error,
          },
        });
        console.log(error);
      }
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    const { error, isAuthenticated } = this.props.session;
    if (isAuthenticated) {
      return <Redirect to="/website" />;
    }

    return (
      <>
        <Navbar />

        <section className="section-signup auth">
          <Content>
            <BoxWrapper>
              <Box style={styles.box}>
                <Title style={styles.title}>Hola de nuevo</Title>
                <SubTitle style={styles.subtitle}>
                  Ingresa tus datos y accede
                </SubTitle>
                <FormErrors formerrors={this.state.errors} />

                {error && (
                  <div className="error container help is-danger">
                    <div className="row justify-content-center">
                      {error.message}
                    </div>
                  </div>
                )}

                <form onSubmit={this.handleSubmit}>
                  <div style={styles.field} className="field">
                    <Label style={styles.label}>Correo electrónico</Label>
                    <Input
                      type="text"
                      id="username"
                      value={this.state.username}
                      onChange={this.onInputChange}
                      aria-describedby="usernameHelp"
                    />
                  </div>
                  <div style={styles.field} className="field">
                    <Row style={{ width: "100%" }}>
                      <Label style={styles.label}>Contraseña</Label>
                      <Link className="link" to="/forgotpassword">
                        Olvide mi contraseña
                      </Link>
                    </Row>
                    <Input
                      id="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <Button>Iniciar Sesión</Button>
                </form>
                <br />
                <Row justify="center">
                  <Label style={{ fontSize: 18 }}>
                    Si no tienes una cuenta{" "}
                    <Link
                      style={{ fontSize: 18 }}
                      className="link link-bold"
                      to="/register"
                    >
                      Regístrate
                    </Link>
                  </Label>
                </Row>
              </Box>
            </BoxWrapper>

            <BackgroundWrapper>
              <ContainerBackground
                style={{
                  flexShrink: 1,
                  width: "100%",
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                background="/images/Verde.png"
              />
            </BackgroundWrapper>
          </Content>
        </section>

        <Footer />
      </>
    );
  }
}

const Content = styled.div`
  display: flex;
  justifycontent: space-between;
`;

const BoxWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 75px 30px;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 35px 30px;
  }
`;

const BackgroundWrapper = styled.div`
  width: 650px;
  flex-shrink: 1;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const styles = {
  title: {
    lineHeight: "40px",
    fontSize: 32,
    marginBottom: 0,
  },
  field: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 24,
  },
  label: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: "16px",
  },
  box: {
    padding: "50px 80px",
  },
  subtitle: {
    fontSize: 18,
    lineHeight: "24px",
    marginTop: 6,
    marginBottom: 38,
  },
};

const mapStateToProps = ({ session }) => {
  return { session };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ login, loadSites }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogIn));
