import { Auth } from 'aws-amplify';
import { setSocket } from "../../utils/cognito";

export const FETCH_SESSION = 'FETCH_SESSION';
export const FAIL_FETCH_SESSION = 'FAIL_FETCH_SESSION';
export const SUCCESS_FETCH_SESSION = 'SUCCESS_FETCH_SESSION';

export const LOGIN = 'LOGIN';
export const FAIL_LOGIN = 'FAIL_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';

export const LOGOUT = 'LOGOUT';
export const FAIL_LOGOUT = 'FAIL_LOGOUT';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';

export const loadSession = () => {
  return async dispatch => {
    dispatch({ type: FETCH_SESSION });
    try {
      const user = await Auth.currentAuthenticatedUser();
      dispatch({
        data: user,
        type: SUCCESS_FETCH_SESSION,
      });
      return user;
    } catch (error) {
      dispatch({
        error: null,
        type: FAIL_FETCH_SESSION,
      });
      throw error;
    }
  };
};

export const login = (username, password) => {
  return async dispatch => {
    dispatch({ type: LOGIN });
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return {success:false, changepassword: true}
      } else {
        await setSocket();
        dispatch({
          data: user,
          type: SUCCESS_LOGIN,
        });
        return {success:true}
      }
    } catch (error) {
      dispatch({
        type: FAIL_LOGIN,
        error: error && error.message ? error.message : error,
      });
      throw error;
    }
  };
};

export const logout = () => {
  return async dispatch => {
    dispatch({ type: LOGOUT });
    try {
      await Auth.signOut();
      dispatch({ type: SUCCESS_LOGOUT });
    } catch (error) {
      
      dispatch({ type: FAIL_LOGOUT, error: error && error.message ? error.message : error });
    }
  };
};
