import React from 'react';
import { useDropzone } from 'react-dropzone';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

const ImageUploader = ({ placeholder, showIcon, dropContainerStyle, onChange, previousImage, preview = true, multiple = false, accept = 'image/*' }) => {

  const styles = {
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16
    },
    dropContainer: {
      padding: 10,
      borderWidth: 1,
      borderColor: '#555',
      borderRadius: 6,
      backgroundColor: 'rgb(250, 250, 250)',
      height: 130,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    thumb: {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: '100%',
      height: 'auto',
      maxHeight: 300,
      padding: 4,
      boxSizing: 'border-box'
    },
    thumbInner: {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    },
    imageContainer: {
      width: '100%',
      height: '100%'
    },
    img: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    placeholder: {
      margin: 0,
      fontFamily: 'Montserrat',
      fontSize: 14,
      color: '#495057',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 6
    }
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    multiple,
    maxFiles: 0,
    onDrop: acceptedFiles => onChange(acceptedFiles),
  });

  const renderThumbs = () => {
    return acceptedFiles.map((file, index) => (
      <div key={`thumbs-${index}`} style={styles.thumb}>
        <div style={{...styles.thumbInner, ...styles.imageContainer}}>
          <img style={styles.img} src={URL.createObjectURL(file)} alt="" />
        </div>
      </div>
    ));
  };

  const renderPreview = () => {
    return acceptedFiles.map((file, index) => (
      <div style={styles.imageContainer} key={`preview-${index}`}>
        <img style={styles.img} src={URL.createObjectURL(file)} alt="" />
      </div>
    ));
  };

  const renderPlaceholder = () => {
    return  (
      <div style={styles.placeholder}>
        { showIcon && (<InsertPhotoIcon style={{fill: '#00ace7'}} />)}
        { placeholder }
      </div>
    );
  };

  const renderPreviousImage = () => {
    return  (
      <div style={styles.imageContainer} key={`previous-image`}>
        <img style={styles.img} src={previousImage} alt="" />
      </div>
    );
  };

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}
        style={{...styles.dropContainer, ...dropContainerStyle}}>
        <input {...getInputProps()} />
          { 
            (multiple === false && preview) ?
                acceptedFiles.length > 0 ? 
                  renderPreview()
                :
                previousImage ? 
                  renderPreviousImage()
                :
                  renderPlaceholder()
            :
            renderPlaceholder()
          }
      </div>
      <div style={styles.thumbsContainer}>
        {multiple && preview && renderThumbs()}
      </div>
    </div>
  );
}

export default ImageUploader;