import network from '../utils/network';

export const getAttachments = async (site, course, module, content) => {
  try {
    const { data } = await network.get(`site/${site}/course/${course}/modules/${module}/content/${content}/attachment`);
    return data;
  } catch (error) {
    console.error('getAttachments', error);
    return error;
  }
}

export const postAttachment = async (site, course, module, content, body = {}) => {
  try {
    const { data } = await network.post(`site/${site}/course/${course}/modules/${module}/content/${content}/attachment`, body);
    return data;
  } catch (error) {
    console.error('getAttachments', error);
    return error;
  }
}

export const deleteAttachment = async (site, course, module, content, id) => {
  try {
    await network.del(`site/${site}/course/${course}/modules/${module}/content/${content}/attachment/${id}`);
  } catch (error) {
    console.error('deleteAttachment', error);
    return error;
  }
}
