import {PubSub} from 'aws-amplify';
import network from '../utils/network';
import Globals from '../Globals';

export class CustomMediaStore {
  baseUrl = '/';
  accept = '*';
  site = null;

  constructor(site) {
    if (!site) {
      throw new Error('Missing site in MediaStore')
    }
    this.site = site;
  }
  
  listening(fileId) {
    return new Promise((resolve, reject) => {
      console.log('listening...', `uploads/${fileId}`);
      PubSub.subscribe(`uploads/${fileId}`).subscribe({
        next: data => resolve(data.value),
        error: error => reject(error),
        close: () => {console.log('PubSub.subscribe close')},
      });
    });
  }

  async delete(file) {
    const url = `site/${this.site}/media/${file.id}`;
    await network.del(url);
  }

  async persist(files) {
    const uploaded = []
    for (const { file } of files) {
      try {
        const fileId = await this.uploadFile(file)
        const response = await this.listening(fileId);
        const video = response.type.includes('video');
        uploaded.push({
          type: 'file',
          filename: response.name,
          directory: video ? `${Globals.BASE_VIDEO}${this.site}/` :`${Globals.BASE_MEDIA}${this.site}/`,
        });
      } catch (error) {
        console.log('error uploaded', error);
      }
    }
    return uploaded;
  }

  async signedUrl({name, type, path, size}) {
    const site = `${this.site}`;
    const url = `site/${site}/media/sign?key=${name}&filetype=${type}&expires=300&duration=10`;
    const {data} = await network.get(url);
    return data;
  }

  async uploadFile(file) {
    const {url, fileId} = await this.signedUrl(file);
    const uploadResponse = await fetch(url, {
      body: file,
      method: 'put',
    })
    if (uploadResponse.status !== 200) {
      throw Error(uploadResponse.statusText)
    }
    return fileId;
  }

  async previewSrc(src) {
    return this.baseUrl + this.getFilePath(src)
  }

  async list(options) {
    const offset = options?.offset ?? 0
    const limit = options?.limit ?? 50
    const response = await network.get(`site/${this.site}/media`);
    const mediaData = response.data
    const nextOffset = offset + limit

    return {
      items: mediaData.slice(offset, limit + offset).map((item) => {
        return {
          key: item.id, 
          id: item.id,
          filename: item.name,
          directory: '/',
          type: 'file',
          previewSrc: item.url,
        }
      }),
      limit,
      offset,
      totalCount: mediaData.length,
      nextOffset: nextOffset < mediaData.length ? nextOffset : undefined,
    }
  }

  getFilePath(fileUrl) {
    return fileUrl.split('?')[0]
  }

  getFileId(fileUrl) {
    return fileUrl.split('?')[1]
  }

  getAbsolutePath(fileUrl) {
    return this.baseUrl + fileUrl
  }
}
