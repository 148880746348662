export const defaultTemplate = {
    "page": {
        "rows": [
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center fixedwidth",
                                        "width": 187.5
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_logo_3.png"
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "background-color": "transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "36px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "20px",
                                        "padding-right": "5px",
                                        "padding-top": "0px",
                                        "padding-left": "5px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 18px; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif;\" data-mce-style=\"font-size: 12px; line-height: 18px; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif;\">\n<p style=\"font-size: 18px; line-height: 27px; text-align: center;\" data-mce-style=\"font-size: 18px; line-height: 27px; text-align: center;\"><span style=\"font-size: 15px; line-height: 22px;\" data-mce-style=\"font-size: 15px; line-height: 22px;\"><strong><span style=\"line-height: 22px; font-size: 15px;\" data-mce-style=\"line-height: 22px; font-size: 15px;\"><span style=\"line-height: 22px; font-size: 15px;\" data-mce-style=\"line-height: 22px; font-size: 15px;\">Time for great email design</span></span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#7c4b96",
                                            "line-height": "150%",
                                            "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "background-color": "transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "#E5E5E5",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "10px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "class": "center  autowidth  fullwidth",
                                        "width": 500
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "",
                                        "src": "img/placeholder_big_2.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "10px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "0px",
                            "background-color": "transparent",
                            "padding-top": "0px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "#f3f3f3",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#000000",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "10px solid transparent",
                                            "width": "100%",
                                            "height": "0px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "background-color": "transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "0px",
                                        "padding-top": "5px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" data-mce-style=\"font-size: 12px; line-height: 14px;\" style=\"font-size: 12px; line-height: 14px;\"><p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\">I'm a block of text and I like latin.</p></div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "10px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px; text-align: center;\" data-mce-style=\"font-size: 12px; line-height: 14px; text-align: center;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">More info</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#888888",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "20px",
                            "background-color": "transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "0px",
                                        "padding-top": "5px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" data-mce-style=\"font-size: 12px; line-height: 14px;\" style=\"font-size: 12px; line-height: 14px;\"><p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\">I'm a block of text and I like latin.</p></div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "10px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px; text-align: center;\" data-mce-style=\"font-size: 12px; line-height: 14px; text-align: center;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">More info</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#888888",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "30px",
                            "background-color": "transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "1px solid #dddddd",
                                            "width": "100%"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "20px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "background-color": "transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#000000",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "0px",
                                        "padding-top": "5px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" data-mce-style=\"font-size: 12px; line-height: 14px;\" style=\"font-size: 12px; line-height: 14px;\"><p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\">I'm a block of text and I like latin.</p></div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "10px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px; text-align: center;\" data-mce-style=\"font-size: 12px; line-height: 14px; text-align: center;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">More info</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#888888",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "20px",
                            "background-color": "transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "0px",
                                        "padding-top": "5px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" data-mce-style=\"font-size: 12px; line-height: 14px;\" style=\"font-size: 12px; line-height: 14px;\"><p style=\"font-size: 14px; line-height: 16px; text-align: center;\" data-mce-style=\"font-size: 14px; line-height: 16px; text-align: center;\">I'm a block of text and I like latin.</p></div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "10px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px; text-align: center;\" data-mce-style=\"font-size: 12px; line-height: 14px; text-align: center;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">More info</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#888888",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "30px",
                            "background-color": "transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "10px solid transparent",
                                            "width": "100%"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "5px",
                            "background-color": "transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "iconsDefaultWidth": 32,
                                        "padding": "0 5px 0 0",
                                        "width": 151,
                                        "height": 52
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px",
                                        "text-align": "center"
                                    },
                                    "iconsList": {
                                        "icons": [
                                            {
                                                "name": "facebook",
                                                "image": {
                                                    "alt": "Facebook",
                                                    "prefix": "https://www.facebook.com/",
                                                    "src": "repository_path/img/t-only-logo-white/facebook.png",
                                                    "title": "Facebook",
                                                    "href": "https://www.facebook.com/"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            },
                                            {
                                                "name": "twitter",
                                                "image": {
                                                    "alt": "Twitter",
                                                    "prefix": "http://twitter.com/",
                                                    "src": "repository_path/img/t-only-logo-white/twitter.png",
                                                    "title": "Twitter",
                                                    "href": "http://twitter.com/"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            },
                                            {
                                                "name": "instagram",
                                                "image": {
                                                    "alt": "Instagram",
                                                    "prefix": "https://instagram.com/",
                                                    "src": "repository_path/img/t-only-logo-white/instagram@2x.png",
                                                    "title": "Instagram",
                                                    "href": "https://instagram.com/"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            }
                                        ]
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-social"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif;\" data-mce-style=\"font-size: 12px; line-height: 14px; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif;\">\n<p style=\"font-size: 18px; line-height: 21px; text-align: center;\" data-mce-style=\"font-size: 18px; line-height: 21px; text-align: center;\"><span style=\"font-size: 13px; line-height: 15px;\" data-mce-style=\"font-size: 13px; line-height: 15px;\">This is a sample template from BEE free email editor</span><br><span style=\"font-size: 13px; line-height: 15px;\" data-mce-style=\"font-size: 13px; line-height: 15px;\">Visit <span style=\"text-decoration: underline; font-size: 13px; line-height: 15px;\" data-mce-style=\"text-decoration: underline; font-size: 13px; line-height: 15px;\"><span style=\"color: rgb(255, 255, 255); line-height: 15px; font-size: 13px; text-decoration: underline;\" data-mce-style=\"color: rgb(255, 255, 255); line-height: 15px; font-size: 13px; text-decoration: underline;\"><span style=\"line-height: 15px; font-size: 13px;\" data-mce-style=\"line-height: 15px; font-size: 13px;\"><a style=\"color: #ffffff; text-decoration: underline;\" href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\">beefree.io</a></span></span></span><span style=\"font-size: 13px; line-height: 15px;\" data-mce-style=\"font-size: 13px; line-height: 15px;\"><span style=\"color: rgb(255, 255, 255); line-height: 15px; font-size: 13px;\" data-mce-style=\"color: rgb(255, 255, 255); line-height: 15px; font-size: 13px;\">&nbsp;</span></span>to create beautiful and rich email messages at no cost.</span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#bbbbbb",
                                            "line-height": "120%",
                                            "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "border-top": "0px solid transparent",
                            "padding-bottom": "25px",
                            "background-color": "transparent",
                            "padding-top": "25px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "container": {
                    "style": {
                        "background-color": "#2C2C2C",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "type": "one-column-empty"
            }
        ],
        "description": "",
        "title": "",
        "template": {
            "name": "template-base",
            "type": "basic",
            "version": "2.0.0"
        },
        "body": {
            "webFonts": [],
            "container": {
                "style": {
                    "background-color": "#FFFFFF"
                }
            },
            "content": {
                "computedStyle": {
                    "messageWidth": "500px",
                    "messageBackgroundColor": "transparent",
                    "linkColor": "#7c4b96"
                },
                "style": {
                    "color": "#000000",
                    "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                }
            },
            "type": "mailup-bee-page-properties"
        }
    }
}

export const defaultTemplateHtml = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
	<!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
	<meta name="viewport" content="width=device-width">
	<!--[if !mso]><!-->
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<!--<![endif]-->
	<title></title>
	<!--[if !mso]><!-->
	<!--<![endif]-->
	<style type="text/css">
		body {
			margin: 0;
			padding: 0;
		}

		table,
		td,
		tr {
			vertical-align: top;
			border-collapse: collapse;
		}

		* {
			line-height: inherit;
		}

		a[x-apple-data-detectors=true] {
			color: inherit !important;
			text-decoration: none !important;
		}
	</style>
	<style type="text/css" id="media-query">
		@media (max-width: 520px) {

			.block-grid,
			.col {
				min-width: 320px !important;
				max-width: 100% !important;
				display: block !important;
			}

			.block-grid {
				width: 100% !important;
			}

			.col {
				width: 100% !important;
			}

			.col_cont {
				margin: 0 auto;
			}

			img.fullwidth,
			img.fullwidthOnMobile {
				max-width: 100% !important;
			}

			.no-stack .col {
				min-width: 0 !important;
				display: table-cell !important;
			}

			.no-stack.two-up .col {
				width: 50% !important;
			}

			.no-stack .col.num2 {
				width: 16.6% !important;
			}

			.no-stack .col.num3 {
				width: 25% !important;
			}

			.no-stack .col.num4 {
				width: 33% !important;
			}

			.no-stack .col.num5 {
				width: 41.6% !important;
			}

			.no-stack .col.num6 {
				width: 50% !important;
			}

			.no-stack .col.num7 {
				width: 58.3% !important;
			}

			.no-stack .col.num8 {
				width: 66.6% !important;
			}

			.no-stack .col.num9 {
				width: 75% !important;
			}

			.no-stack .col.num10 {
				width: 83.3% !important;
			}

			.video-block {
				max-width: none !important;
			}

			.mobile_hide {
				min-height: 0px;
				max-height: 0px;
				max-width: 0px;
				display: none;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide {
				display: block !important;
				max-height: none !important;
			}
		}
	</style>
</head>

<body class="clean-body" style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #FFFFFF;">
	<!--[if IE]><div class="ie-browser"><![endif]-->
	<table class="nl-container" style="table-layout: fixed; vertical-align: top; min-width: 320px; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF; width: 100%;" cellpadding="0" cellspacing="0" role="presentation" width="100%" bgcolor="#FFFFFF" valign="top">
		<tbody>
			<tr style="vertical-align: top;" valign="top">
				<td style="word-break: break-word; vertical-align: top;" valign="top">
					<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#FFFFFF"><![endif]-->
					<div style="background-color:#E5E5E5;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#E5E5E5;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center fixedwidth" align="center" style="padding-right: 10px;padding-left: 10px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 10px;padding-left: 10px;" align="center"><![endif]-->
												<div style="font-size:1px;line-height:10px">&nbsp;</div><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center fixedwidth" align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 187px; display: block;" width="187"></a>
												<div style="font-size:1px;line-height:10px">&nbsp;</div>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="mobile_hide">
												<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
													<tbody>
														<tr style="vertical-align: top;" valign="top">
															<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
																<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 36px; width: 100%;" align="center" role="presentation" height="36" valign="top">
																	<tbody>
																		<tr style="vertical-align: top;" valign="top">
																			<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="36" valign="top"><span></span></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 5px; padding-left: 5px; padding-top: 0px; padding-bottom: 20px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#7c4b96;font-family:Arial, 'Helvetica Neue', Helvetica, sans-serif;line-height:1.5;padding-top:0px;padding-right:5px;padding-bottom:20px;padding-left:5px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.5; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; color: #7c4b96; mso-line-height-alt: 18px;">
													<p style="font-size: 15px; line-height: 1.5; text-align: center; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 23px; margin: 0;"><span style="font-size: 15px;"><strong><span style="font-size: 15px;"><span style="font-size: 15px;">Time for great email design</span></span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:#f3f3f3;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#f3f3f3;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:0px; padding-bottom:0px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="mobile_hide">
												<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
													<tbody>
														<tr style="vertical-align: top;" valign="top">
															<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
																<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 10px; width: 100%;" align="center" role="presentation" height="10" valign="top">
																	<tbody>
																		<tr style="vertical-align: top;" valign="top">
																			<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="10" valign="top"><span></span></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="img-container center  autowidth  fullwidth" align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]-->
												<div></div>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<div class="mobile_hide">
												<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
													<tbody>
														<tr style="vertical-align: top;" valign="top">
															<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
																<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 10px; width: 100%;" align="center" role="presentation" height="10" valign="top">
																	<tbody>
																		<tr style="vertical-align: top;" valign="top">
																			<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="10" valign="top"><span></span></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 10px solid transparent; height: 0px; width: 100%;" align="center" role="presentation" height="0" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="0" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:20px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 5px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:5px;padding-right:0px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#888888;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #888888; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">More info</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 10px; padding-top:20px; padding-bottom:30px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:30px; padding-right: 0px; padding-left: 10px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 5px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:5px;padding-right:0px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#888888;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #888888; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">More info</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 20px; padding-left: 10px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 1px solid #dddddd; width: 100%;" align="center" role="presentation" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:20px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 5px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:5px;padding-right:0px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#888888;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #888888; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">More info</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 10px; padding-top:20px; padding-bottom:30px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:30px; padding-right: 0px; padding-left: 10px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 5px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:5px;padding-right:0px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#888888;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #888888; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">More info</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 10px solid transparent; width: 100%;" align="center" role="presentation" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:#2C2C2C;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#2C2C2C;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:25px; padding-bottom:25px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:25px; padding-bottom:25px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="social_icons" cellpadding="0" cellspacing="0" width="100%" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td style="word-break: break-word; vertical-align: top; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															<table class="social_table" align="center" cellpadding="0" cellspacing="0" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;" valign="top">
																<tbody>
																	<tr style="vertical-align: top; display: inline-block; text-align: center;" align="center" valign="top">
																		<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://www.facebook.com/" target="_blank"><img width="32" height="32" src="repository_path/img/t-only-logo-white/facebook.png" alt="Facebook" title="Facebook" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																		<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 5px; padding-left: 0;" valign="top"><a href="http://twitter.com/" target="_blank"><img width="32" height="32" src="repository_path/img/t-only-logo-white/twitter.png" alt="Twitter" title="Twitter" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																		<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://instagram.com/" target="_blank"><img width="32" height="32" src="repository_path/img/t-only-logo-white/instagram@2x.png" alt="Instagram" title="Instagram" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#bbbbbb;font-family:Arial, 'Helvetica Neue', Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; color: #bbbbbb; mso-line-height-alt: 14px;">
													<p style="font-size: 13px; line-height: 1.2; text-align: center; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 16px; mso-ansi-font-size: 14px; margin: 0;"><span style="font-size: 13px; mso-ansi-font-size: 14px;">This is a sample template from BEE free email editor</span><br><span style="font-size: 13px; mso-ansi-font-size: 14px;">Visit <span style="text-decoration: underline; font-size: 13px; mso-ansi-font-size: 14px;"><span style="color: #ffffff; font-size: 13px; text-decoration: underline; mso-ansi-font-size: 14px;"><span style="font-size: 13px; mso-ansi-font-size: 14px;"><a style="color: #ffffff; text-decoration: underline;" href="https://beefree.io" target="_blank" rel="noopener">beefree.io</a></span></span></span><span style="font-size: 13px; mso-ansi-font-size: 14px;"><span style="color: #ffffff; font-size: 13px; mso-ansi-font-size: 14px;">&nbsp;</span></span>to create beautiful and rich email messages at no cost.</span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
				</td>
			</tr>
		</tbody>
	</table>
	<!--[if (IE)]></div><![endif]-->
</body>

</html>
`

export const baseNewsletterJson = {
    "page": {
        "rows": [
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center fixedwidth",
                                        "width": 187.5
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_logo_3.png"
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "36px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "20px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 18px;\" data-mce-style=\"font-size: 12px; line-height: 18px;\">\n<p style=\"font-size: 18px; line-height: 27px; text-align: center;\" data-mce-style=\"font-size: 18px; line-height: 27px; text-align: center;\"><span style=\"font-size: 15px; line-height: 22px;\" data-mce-style=\"font-size: 15px; line-height: 22px;\"><strong><span style=\"line-height: 22px; font-size: 15px;\" data-mce-style=\"line-height: 22px; font-size: 15px;\">Time for great email design</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#7c4b96",
                                            "line-height": "150%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "#E5E5E5",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "10px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth  fullwidth",
                                        "width": 500
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_big_2.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "10px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": true,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "0px",
                            "border-top": "0px solid transparent",
                            "padding-top": "0px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#000000",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "#f3f3f3",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "10px solid transparent",
                                            "width": "100%"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "src": "img/placeholder_medium_1.png",
                                        "href": "https://beefree.io",
                                        "dynamicSrc": ""
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "20px",
                            "border-top": "0px solid transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "25px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">Read More</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "background-color": "transparent",
                            "padding-bottom": "30px",
                            "border-top": "0px solid transparent",
                            "padding-top": "30px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "20px",
                            "border-top": "0px solid transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "25px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">Read More</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "background-color": "transparent",
                            "padding-bottom": "30px",
                            "border-top": "0px solid transparent",
                            "padding-top": "30px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "class": "center  autowidth ",
                                        "width": 180
                                    },
                                    "image": {
                                        "alt": "Image",
                                        "href": "https://beefree.io",
                                        "src": "img/placeholder_medium_1.png"
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px",
                                        "width": "100%"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-image"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "20px",
                            "border-top": "0px solid transparent",
                            "padding-top": "20px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    },
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "0px solid transparent",
                                            "width": "100%",
                                            "height": "25px"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "computedStyle": {
                                        "hideContentOnMobile": false,
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "0px",
                                        "padding-right": "0px",
                                        "padding-top": "0px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"font-size: 20px; line-height: 24px;\" data-mce-style=\"font-size: 20px; line-height: 24px;\"><strong><span style=\"line-height: 24px; font-size: 20px;\" data-mce-style=\"line-height: 24px; font-size: 20px;\">I'm a very important title</span></strong></span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "5px",
                                        "padding-right": "5px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#aaaaaa",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "0px",
                                        "padding-top": "15px",
                                        "padding-left": "0px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\"><a href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\"><strong><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\"><span style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\" data-mce-style=\"text-decoration: underline; font-size: 14px; line-height: 16px;\">Read more</span></span></strong></a></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#2C2C2C",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "10px",
                            "background-color": "transparent",
                            "padding-bottom": "30px",
                            "border-top": "0px solid transparent",
                            "padding-top": "30px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 6
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "two-columns-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "divider": {
                                        "style": {
                                            "border-top": "10px solid transparent",
                                            "width": "100%"
                                        }
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "computedStyle": {
                                        "align": "center"
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-divider"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "5px",
                            "border-top": "0px solid transparent",
                            "padding-top": "5px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "transparent",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            },
            {
                "columns": [
                    {
                        "modules": [
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "iconsDefaultWidth": 32,
                                        "padding": "0 5px 0 0",
                                        "width": 151,
                                        "height": 52
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px",
                                        "text-align": "center"
                                    },
                                    "iconsList": {
                                        "icons": [
                                            {
                                                "name": "facebook",
                                                "image": {
                                                    "alt": "Facebook",
                                                    "prefix": "https://www.facebook.com/",
                                                    "src": "repository_path/img/t-circle-white/facebook.png",
                                                    "href": "https://www.facebook.com/",
                                                    "title": "Facebook"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            },
                                            {
                                                "name": "twitter",
                                                "image": {
                                                    "alt": "Twitter",
                                                    "prefix": "http://twitter.com/",
                                                    "src": "repository_path/img/t-circle-white/twitter.png",
                                                    "href": "http://twitter.com/",
                                                    "title": "Twitter"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            },
                                            {
                                                "name": "instagram",
                                                "image": {
                                                    "alt": "Instagram",
                                                    "prefix": "https://instagram.com/",
                                                    "src": "repository_path/img/t-circle-white/instagram@2x.png",
                                                    "href": "https://instagram.com/",
                                                    "title": "Instagram"
                                                },
                                                "type": "follow",
                                                "text": ""
                                            }
                                        ]
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-social"
                            },
                            {
                                "locked": false,
                                "descriptor": {
                                    "computedStyle": {
                                        "hideContentOnMobile": false
                                    },
                                    "style": {
                                        "padding-bottom": "10px",
                                        "padding-right": "10px",
                                        "padding-top": "10px",
                                        "padding-left": "10px"
                                    },
                                    "text": {
                                        "html": "<div class=\"txtTinyMce-wrapper\" style=\"font-size: 12px; line-height: 14px;\" data-mce-style=\"font-size: 12px; line-height: 14px;\">\n<p style=\"font-size: 18px; line-height: 21px; text-align: center;\" data-mce-style=\"font-size: 18px; line-height: 21px; text-align: center;\"><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">This is a sample template from BEE free email editor</span><br><span style=\"font-size: 14px; line-height: 16px;\" data-mce-style=\"font-size: 14px; line-height: 16px;\">Visit<span style=\"color: rgb(255, 255, 255); font-size: 14px; line-height: 16px;\" data-mce-style=\"color: rgb(255, 255, 255); font-size: 14px; line-height: 16px;\"> <span style=\"text-decoration: underline; line-height: 16px; font-size: 14px;\" data-mce-style=\"text-decoration: underline; line-height: 16px; font-size: 14px;\"><a style=\"color: #ffffff; text-decoration: underline;\" href=\"https://beefree.io\" target=\"_blank\" rel=\"noopener\">beefree.io</a></span>&nbsp;</span>to create beautiful and rich email messages at no cost.</span></p>\n</div>",
                                        "computedStyle": {
                                            "linkColor": "#7c4b96"
                                        },
                                        "style": {
                                            "color": "#bbbbbb",
                                            "line-height": "120%",
                                            "font-family": "inherit"
                                        }
                                    }
                                },
                                "type": "mailup-bee-newsletter-modules-text"
                            }
                        ],
                        "style": {
                            "padding-right": "0px",
                            "border-left": "0px solid transparent",
                            "padding-left": "0px",
                            "background-color": "transparent",
                            "padding-bottom": "25px",
                            "border-top": "0px solid transparent",
                            "padding-top": "25px",
                            "border-bottom": "0px solid transparent",
                            "border-right": "0px solid transparent"
                        },
                        "grid-columns": 12
                    }
                ],
                "locked": false,
                "content": {
                    "computedStyle": {
                        "rowColStackOnMobile": true
                    },
                    "style": {
                        "background-position": "top left",
                        "background-repeat": "no-repeat",
                        "color": "#333",
                        "background-image": "none",
                        "background-color": "transparent",
                        "width": "500px"
                    }
                },
                "container": {
                    "style": {
                        "background-color": "#2C2C2C",
                        "background-repeat": "no-repeat",
                        "background-image": "none",
                        "background-position": "top left"
                    }
                },
                "type": "one-column-empty"
            }
        ],
        "description": "",
        "title": "",
        "template": {
            "name": "template-base",
            "type": "basic",
            "version": "2.0.0"
        },
        "body": {
            "webFonts": [],
            "container": {
                "style": {
                    "background-color": "#FFFFFF"
                }
            },
            "content": {
                "computedStyle": {
                    "messageWidth": "500px",
                    "messageBackgroundColor": "transparent",
                    "linkColor": "#7c4b96"
                },
                "style": {
                    "color": "#000000",
                    "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
                }
            },
            "type": "mailup-bee-page-properties"
        }
    }
}

export const baseNewsletterHtml = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
	<!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
	<meta name="viewport" content="width=device-width">
	<!--[if !mso]><!-->
	<meta http-equiv="X-UA-Compatible" content="IE=edge">
	<!--<![endif]-->
	<title></title>
	<!--[if !mso]><!-->
	<!--<![endif]-->
	<style type="text/css">
		body {
			margin: 0;
			padding: 0;
		}

		table,
		td,
		tr {
			vertical-align: top;
			border-collapse: collapse;
		}

		* {
			line-height: inherit;
		}

		a[x-apple-data-detectors=true] {
			color: inherit !important;
			text-decoration: none !important;
		}
	</style>
	<style type="text/css" id="media-query">
		@media (max-width: 520px) {

			.block-grid,
			.col {
				min-width: 320px !important;
				max-width: 100% !important;
				display: block !important;
			}

			.block-grid {
				width: 100% !important;
			}

			.col {
				width: 100% !important;
			}

			.col_cont {
				margin: 0 auto;
			}

			img.fullwidth,
			img.fullwidthOnMobile {
				max-width: 100% !important;
			}

			.no-stack .col {
				min-width: 0 !important;
				display: table-cell !important;
			}

			.no-stack.two-up .col {
				width: 50% !important;
			}

			.no-stack .col.num2 {
				width: 16.6% !important;
			}

			.no-stack .col.num3 {
				width: 25% !important;
			}

			.no-stack .col.num4 {
				width: 33% !important;
			}

			.no-stack .col.num5 {
				width: 41.6% !important;
			}

			.no-stack .col.num6 {
				width: 50% !important;
			}

			.no-stack .col.num7 {
				width: 58.3% !important;
			}

			.no-stack .col.num8 {
				width: 66.6% !important;
			}

			.no-stack .col.num9 {
				width: 75% !important;
			}

			.no-stack .col.num10 {
				width: 83.3% !important;
			}

			.video-block {
				max-width: none !important;
			}

			.mobile_hide {
				min-height: 0px;
				max-height: 0px;
				max-width: 0px;
				display: none;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide {
				display: block !important;
				max-height: none !important;
			}
		}
	</style>
</head>

<body class="clean-body" style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #FFFFFF;">
	<!--[if IE]><div class="ie-browser"><![endif]-->
	<table class="nl-container" style="table-layout: fixed; vertical-align: top; min-width: 320px; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF; width: 100%;" cellpadding="0" cellspacing="0" role="presentation" width="100%" bgcolor="#FFFFFF" valign="top">
		<tbody>
			<tr style="vertical-align: top;" valign="top">
				<td style="word-break: break-word; vertical-align: top;" valign="top">
					<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#FFFFFF"><![endif]-->
					<div style="background-color:#E5E5E5;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#E5E5E5;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center fixedwidth" align="center" style="padding-right: 10px;padding-left: 10px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 10px;padding-left: 10px;" align="center"><![endif]-->
												<div style="font-size:1px;line-height:10px">&nbsp;</div><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center fixedwidth" align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 187px; display: block;" width="187"></a>
												<div style="font-size:1px;line-height:10px">&nbsp;</div>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="mobile_hide">
												<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
													<tbody>
														<tr style="vertical-align: top;" valign="top">
															<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
																<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 36px; width: 100%;" align="center" role="presentation" height="36" valign="top">
																	<tbody>
																		<tr style="vertical-align: top;" valign="top">
																			<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="36" valign="top"><span></span></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 20px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#7c4b96;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.5;padding-top:0px;padding-right:0px;padding-bottom:20px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.5; color: #7c4b96; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 18px;">
													<p style="font-size: 15px; line-height: 1.5; text-align: center; word-break: break-word; mso-line-height-alt: 23px; margin: 0;"><span style="font-size: 15px;"><strong><span style="font-size: 15px;">Time for great email design</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:#f3f3f3;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#f3f3f3;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:0px; padding-bottom:0px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="mobile_hide">
												<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
													<tbody>
														<tr style="vertical-align: top;" valign="top">
															<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
																<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 10px; width: 100%;" align="center" role="presentation" height="10" valign="top">
																	<tbody>
																		<tr style="vertical-align: top;" valign="top">
																			<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="10" valign="top"><span></span></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<div class="img-container center  autowidth  fullwidth" align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth  fullwidth" align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 500px; display: block;" width="500"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<div class="mobile_hide">
												<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
													<tbody>
														<tr style="vertical-align: top;" valign="top">
															<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
																<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 10px; width: 100%;" align="center" role="presentation" height="10" valign="top">
																	<tbody>
																		<tr style="vertical-align: top;" valign="top">
																			<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="10" valign="top"><span></span></td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 10px solid transparent; width: 100%;" align="center" role="presentation" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:20px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 10px; padding-top:30px; padding-bottom:30px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:30px; padding-right: 0px; padding-left: 10px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 25px; width: 100%;" align="center" role="presentation" height="25" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="25" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 5px; padding-left: 0px; padding-top: 15px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:5px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">Read More</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:20px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 10px; padding-top:30px; padding-bottom:30px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:30px; padding-right: 0px; padding-left: 10px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 25px; width: 100%;" align="center" role="presentation" height="25" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="25" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 5px; padding-left: 0px; padding-top: 15px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:5px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">Read More</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid two-up" style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:20px; padding-bottom:20px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<div class="img-container center  autowidth " align="center" style="padding-right: 0px;padding-left: 0px;">
												<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><a href="https://beefree.io" target="_blank" style="outline:none" tabindex="-1"><img class="center  autowidth " align="center" border="0" src alt="Image" title="Image" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 180px; display: block;" width="180"></a>
												<!--[if mso]></td></tr></table><![endif]-->
											</div>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td><td align="center" width="250" style="background-color:transparent;width:250px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 10px; padding-top:30px; padding-bottom:30px;"><![endif]-->
								<div class="col num6" style="display: table-cell; vertical-align: top; max-width: 320px; min-width: 246px; width: 250px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:30px; padding-bottom:30px; padding-right: 0px; padding-left: 10px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 25px; width: 100%;" align="center" role="presentation" height="25" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="25" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 0px; padding-bottom: 0px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 20px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 24px; margin: 0;"><span style="font-size: 20px;"><strong><span style="font-size: 20px;">I'm a very important title</span></strong></span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 5px; padding-left: 0px; padding-top: 15px; padding-bottom: 5px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#aaaaaa;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:5px;padding-bottom:5px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #aaaaaa; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 17px; margin: 0;">I'm a block of text and I like latin. Pellentesque vel dui sed orci faucibus iaculis. Suspendisse dictum.</p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top: 15px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#2C2C2C;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:15px;padding-right:0px;padding-bottom:10px;padding-left:0px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #2C2C2C; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 12px; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;"><a href="https://beefree.io" target="_blank" rel="noopener" style="color: #7c4b96;"><strong><span style="font-size: 14px;"><span style="text-decoration: underline; font-size: 14px;">Read more</span></span></strong></a></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:transparent;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 10px solid transparent; width: 100%;" align="center" role="presentation" valign="top">
																<tbody>
																	<tr style="vertical-align: top;" valign="top">
																		<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<div style="background-color:#2C2C2C;">
						<div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							<div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#2C2C2C;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								<!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:25px; padding-bottom:25px;"><![endif]-->
								<div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									<div class="col_cont" style="width:100% !important;">
										<!--[if (!mso)&(!IE)]><!-->
										<div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:25px; padding-bottom:25px; padding-right: 0px; padding-left: 0px;">
											<!--<![endif]-->
											<table class="social_icons" cellpadding="0" cellspacing="0" width="100%" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;" valign="top">
												<tbody>
													<tr style="vertical-align: top;" valign="top">
														<td style="word-break: break-word; vertical-align: top; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															<table class="social_table" align="center" cellpadding="0" cellspacing="0" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;" valign="top">
																<tbody>
																	<tr style="vertical-align: top; display: inline-block; text-align: center;" align="center" valign="top">
																		<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://www.facebook.com/" target="_blank"><img width="32" height="32" src="repository_path/img/t-circle-white/facebook.png" alt="Facebook" title="Facebook" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																		<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 5px; padding-left: 0;" valign="top"><a href="http://twitter.com/" target="_blank"><img width="32" height="32" src="repository_path/img/t-circle-white/twitter.png" alt="Twitter" title="Twitter" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																		<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 5px; padding-left: 0;" valign="top"><a href="https://instagram.com/" target="_blank"><img width="32" height="32" src="repository_path/img/t-circle-white/instagram@2x.png" alt="Instagram" title="Instagram" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											<div style="color:#bbbbbb;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
												<div class="txtTinyMce-wrapper" style="font-size: 12px; line-height: 1.2; color: #bbbbbb; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													<p style="font-size: 14px; line-height: 1.2; text-align: center; word-break: break-word; mso-line-height-alt: 17px; margin: 0;"><span style="font-size: 14px;">This is a sample template from BEE free email editor</span><br><span style="font-size: 14px;">Visit<span style="color: #ffffff; font-size: 14px;"> <span style="text-decoration: underline; font-size: 14px;"><a style="color: #ffffff; text-decoration: underline;" href="https://beefree.io" target="_blank" rel="noopener">beefree.io</a></span>&nbsp;</span>to create beautiful and rich email messages at no cost.</span></p>
												</div>
											</div>
											<!--[if mso]></td></tr></table><![endif]-->
											<!--[if (!mso)&(!IE)]><!-->
										</div>
										<!--<![endif]-->
									</div>
								</div>
								<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								<!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							</div>
						</div>
					</div>
					<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
				</td>
			</tr>
		</tbody>
	</table>
	<!--[if (IE)]></div><![endif]-->
</body>

</html>
`