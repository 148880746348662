import styled from 'styled-components';

export const btn_publish = styled.button`
font-family: 'Montserrat';
background - color: #144974;
color: #F1F0F2;
font - weight: bold;
border: 0;
transition: background - color 0.4s;
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  padding: 12px;
  display: block;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 0px;
  margin-bottom: 10px;
  justify-content: center;
  border: 1px solid #CCD0D6;
  color: ${props => (props.color || '#fff')};
  font: normal normal bold 20px/24px Montserrat;
  background: ${props => (props.backgroundColor || '#1A9E75')} 0% 0% no-repeat padding-box;
  :disabled {
    background: #bdbdbd;
  }
`;

export const TouchableOpacity = styled.div`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s;
  :hover {
    opacity: 0.7;
  }
`;

export const ScrollView = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #e6eaf4;  
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #1a9e75;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;