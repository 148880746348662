import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export const Content = memo(function Content(props) {
  return (
    <div className="section-content">
      {props.children}
    </div>
  )
})

Content.propTypes = {
  children: PropTypes.node.isRequired,
}
