import { parseUrl } from "../../helpers/utils";
import network from "../../utils/network";

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FAIL_FETCH_EVENTS = 'FAIL_FETCH_EVENTS';
export const SUCCESS_FETCH_EVENTS = 'SUCCESS_FETCH_EVENTS';
export const SUCCESS_FETCH_EVENTS_TABLE = 'SUCCESS_FETCH_EVENTS_TABLE';

export const SELECT_EVENT = 'SELECT_EVENT';
export const FAIL_SELECT_EVENT = 'FAIL_SELECT_EVENT';
export const SUCCESS_SELECT_EVENT = 'SUCCESS_SELECT_EVENT';

export const FETCHING_EVENT = 'FETCHING_EVENT';
export const FAIL_FETCHING_EVENT = 'FAIL_FETCHING_EVENT';
export const SUCCESS_FETCHING_EVENT = 'SUCCESS_FETCHING_EVENT';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FAIL_FETCH_PAGE = 'FAIL_FETCH_PAGE';
export const SUCCESS_FETCH_PAGE = 'SUCCESS_FETCH_PAGE';

export const POST_EVENT = "POST_EVENT"
export const POST_FAIL_EVENT = "POST_FAIL_EVENT"
export const POST_SUCCESS_EVENT = "POST_SUCCESS_EVENT"

export const PUT_EVENT = "PUT_EVENT"
export const PUT_FAIL_EVENT = "PUT_FAIL_EVENT"
export const PUT_SUCCESS_EVENT = "PUT_SUCCESS_EVENT"

export const DELETE_EVENT = "DELETE_EVENT"
export const DELETE_FAIL_EVENT = "DELETE_FAIL_EVENT"
export const DELETE_SUCCESS_EVENT = "DELETE_SUCCESS_EVENT"

export const UPDATE_SORT_EVENT = "UPDATE_SORT_EVENT"
export const UPDATE_FAIL_EVENT = "UPDATE_FAIL_EVENT"

export const fetchEvents = (site, limit, offset = null, column, direction, search, searchtext) => {
  const url = parseUrl(`site/${site}/event?`, limit, offset, column, direction, search, searchtext)
  return async dispatch => {
    dispatch({ type: FETCH_EVENTS });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_EVENTS,
        });
      } else {
        dispatch({
          data: {
            limit,
            offset,
            search,
            searchtext,
            data: response.data.data,
            count: response.data.count,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_EVENTS_TABLE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_EVENTS,
      });
    }
  };
};

export const fetchEvent = (site, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_EVENT });
    try {
      const response = await network.get(`site/${site}/event/${id}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCHING_EVENT,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCHING_EVENT,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCHING_EVENT,
      });
    }
  };
};

export const putEvent = (site, id, body) => {
  return async dispatch => {
    dispatch({ type: PUT_EVENT });
    try {
      await network.put(`site/${site}/event/${id}`, body);
      dispatch({
        data: body,
        type: PUT_SUCCESS_EVENT,
      });
    } catch (error) {
      dispatch({
        error,
        type: PUT_FAIL_EVENT,
      });
    }
  };
};

export const postEvent = (site, body) => {
  return async dispatch => {
    dispatch({ type: POST_EVENT });
    try {
      const response = await network.post(`site/${site}/event`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_EVENT,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_EVENT,
      });
    }
  };
};

export const deleteEvents = (site, ids = []) => {
  const promises = ids.map((item) => {
    return network.del(`/site/${site}/event/${item}`);
  });
  return async dispatch => {
    dispatch({ type: DELETE_EVENT });
    try {
      const response = await Promise.all(promises)
      dispatch({
        data: response.data,
        type: DELETE_SUCCESS_EVENT,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_EVENT,
      });
      return error;
    }
  };
};