import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { fetchEmailTemplates, fetchEmailTemplate, putEmailTemplate, deleteEmailTemplate, postEmailTemplate } from '../../../redux/actions/emailTemplates'
import Loader from '../Loader'
import Navbar from '../Navbar'
import Bee from '@mailupinc/bee-plugin'
import Globals from '../../../Globals'

function EmailTemplateView (props) {
  const { sites } = props
  const { emailTemplate } = useParams()

  const [data, setData] = useState({})
  const [name, setName] = useState('')
  const [body, setBody] = useState('')
  const [beeJson, setBeeJson] = useState({})
  const [emailTemplateReady, setEmailTemplateReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [enableEdit, setEnableEdit] = useState(false)

  const loadEmailTemplate = async () => {
    if (emailTemplate) {
      const res = await props.fetchEmailTemplate(sites.defaultSite.id, emailTemplate)
      setData(res.emailTemplate)
      setName(res.emailTemplate.name)
      setBody(res.emailTemplate.body)
      setBeeJson(res.emailTemplate.bee_json)
      initializeBeeFreePlugin(res.emailTemplate.bee_json)
    }
    setEmailTemplateReady(true)
    setLoading(false)
  }

  const beeConfig = {
    uid: `${sites.defaultSite.id}`,
    container: 'bee-plugin-container',
    language: 'es-ES',
    onSave: (jsonFile, htmlFile) => {
        handleSubmit(jsonFile, htmlFile)
    },
    onSaveAsTemplate: (jsonFile) => {
      console.log('onSaveAsTemplate', jsonFile)
    },
    onSend: (htmlFile) => {
      console.log('onSend', htmlFile)
    },
    onError: (errorMessage) => {
      console.log('onError ', errorMessage)
    }
}

  const initializeBeeFreePlugin = (json) => {

    const beeTest = new Bee()
    
    beeTest.getToken(Globals.BEE_FREE_CLIENT_ID, Globals.BEE_FREE_CLIENT_SECRET)
      .then(() => beeTest.start(beeConfig, json))

  }

  const handleSubmit = async (jsonFile, htmlFile) => {
    await setLoading(true)
    try {
        const siteId = sites.defaultSite.id
        if (emailTemplate) {
            await props.putEmailTemplate(siteId, emailTemplate, {
                body: htmlFile,
                bee_json: JSON.parse(jsonFile)
            })
        } else {
            await props.postEmailTemplate(siteId, { name, body, beeJson })
        }
        props.fetchEmailTemplates(siteId)
    } catch (error) {
        console.log('handleSubmit', error)
    } finally {
        await setLoading(false)
    }
  }

  useEffect(() => {
    loadEmailTemplate()
  }, [])

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={styles.container}>

        <div style={styles.content}>
          {
              emailTemplateReady ? (
                  <div style={{ width: '100%', height: 'calc(100vh - 60px)' }} id="bee-plugin-container"/>
              ) : (
                  <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Loader />
                  </div>
              )
          }
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#f6f6f6',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  header: {
    display: 'flex',
    padding: '30px 30px 15px 30px',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    padding: 30,
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 30
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  panel: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  panelSection: {
    padding: '20px 30px',
    width: '100%'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)'
  },
  name: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginTop: 12
  },
  email: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '500',
    color: '#565656',
    marginTop: 3
  },
  createdAt: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  subscribed: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  actions: {
    display: 'flex',
    columnGap: 10,
    justifyContent: 'space-between',
    marginTop: 15
  },
  actionButton: {
    fontSize: 14,
    padding: '26px 36px',
    borderRadius: 8
  },
  form: {
    marginTop: 10
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  submitButton: {
    fontSize: 16,
    padding: '26px 0px',
    borderRadius: 8,
    width: '100%'
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  statValue: {
    fontFamily: 'Montserrat',
    color: '#024976'
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 8
  },
  tag: {
    backgroundColor: 'rgb(246, 246, 246)',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: 12,
    borderRadius: 10,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    color: 'rgb(177, 177, 177)'
  }
}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchEmailTemplates, fetchEmailTemplate, putEmailTemplate, deleteEmailTemplate }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateView)
