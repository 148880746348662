import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Switch, matchPath } from 'react-router-dom';
import Tina from '../editor/App';
import BeeFree from '../beefree/App';
import Home from './components/Home';
import Terms from './components/Terms/Terms';
import PrivateRoute from './PrivateRoute';
import Website from './components/Website/Website';
import LogIn from './components/auth/LogIn';
import UserList from './components/users/List';
import UserView from './components/users/View';
import SiteList from './components/sites/List';
import PagesList from './components/pages/List';
import Welcome from './components/auth/Welcome';
import PagesView from './components/pages/View';
import FormsList from './components/forms/List';
import FormsView from './components/forms/View';
import FormsCreate from './components/forms/Create';
import UserCreate from './components/users/Create';
import SiteCreate from './components/sites/Create';
import Register from './components/auth/Register';
import PeoplesList from './components/people/List/List';
import VideosList from './components/videos/List';
import PeoplesView from './components/people/View';
import CoursesList from './components/courses/List';
import CoursesEdit from './components/courses/edit/Edit';
import WebinarsList from './components/webinars/List';
import WebinarsEdit from './components/webinars/Edit';
import PrivacyPolicy from './components/PrivacyPolicy';
import PeoplesCreate from './components/people/Create';
import Dashboard from './components/dashboard/Dashboard';
import CategoriesList from './components/categories/List';
import CategoriesCreate from './components/categories/Create';
import InstructorsList from './components/instructors/List';
import InstructorsCreate from './components/instructors/Create';
import ForgotPassword from './components/auth/ForgotPassword';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import ForgotPasswordEmailSent from './components/auth/ForgotPasswordEmailSent';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import EventList from './components/events/List';
import Settings from './components/Settings';
import NotFound from './components/NotFound';
import EmailList from './components/emails/List';
import Zoom from './components/zoom';
import EmailTemplatesList from './components/emailTemplates/List';
import EmailTemplatesView from './components/emailTemplates/View';
import PageBuilder from './components/PageBuilder';
import DomainsList from './components/domains/List';

// actions
import { loadSession } from '../redux/actions/session';
import { loadSites, setCollapse } from "../redux/actions/sites";
import Loader from "./components/Loader";
import "../App.css";
import Navbar from "./components/Navbar";
import RouteChangeListener from "./components/RouteChangeListener";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

function Routes(props) {
  const forceUpdate = useForceUpdate();

  const { collapse } = props.sites;
  const { isAuthenticated, isAuthenticating } = props.session;

  const toggleNavbarExpanded = (option) => {
    props.setCollapse(option ? option : !collapse)
  }

  const load = async () => {
    try {
      await props.loadSession();
      await props.loadSites();
    } catch (error) {
      console.log('error', error)
    }
  };

  const match = (path) => {
    const res = matchPath(window.location.pathname, {
      path: path,
      exact: true,
      strict: false,
    });
    return res ? true : false;
  };

  const showNavbar = () => {
    if (
      match("/marketing/pages/builder/:id/:page") ||
      match("/marketing/pages/edit/:id/:page") ||
      match("/tina/:id/:page/:param1?")
    ) {
      return false;
    }
    return true;
  };

  const siteDataReady = () => {
    return isAuthenticated
      ? !props.sites.data || !props.sites.data.length
        ? false
        : true
      : true;
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="App">
      <Router>
        <RouteChangeListener onChange={forceUpdate} />
        <Switch>
          <Route exact path="/welcome" component={Welcome} />
          <Route exact path="/PrivacyPolicy" render={PrivacyPolicy} />
          <Route exact path="/Terms" render={Terms} />
          <Route exact path="/changepassword" component={ChangePassword} />
          <Route exact path="/login" render={(props) => <LogIn {...props} />} />
          <Route exact path="/register" render={(props) => <Register {...props} />} />
          <Route exact path="/changepasswordconfirmation" component={ChangePasswordConfirm} />
          <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props} />} />
          <Route exact path="/forgotpasswordverification" render={(props) => <ForgotPasswordVerification {...props} />} />
          <Route exact path="/forgotpasswordemailsent" render={(props) => <ForgotPasswordEmailSent {...props} />} />
          {
            (isAuthenticating || !siteDataReady()) && (<Loader />)
          }
          {
            isAuthenticated && props.sites.data && props.sites.data.length ? (
              <div style={{ ...(showNavbar() ? { display: 'flex', flex: 1 } : {}) }}>
                {
                  showNavbar() && (
                    <Navbar key={'navbar'} toggleExpanded={() => toggleNavbarExpanded()} expanded={collapse} />
                  )
                }
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/website" component={Website} />
                <PrivateRoute exact path="/tina/:id/:page/:param1?" component={Tina} />

                <PrivateRoute exact path="/people/" component={PeoplesList} />
                <PrivateRoute exact path="/people/create" component={PeoplesCreate} />
                <PrivateRoute exact path="/people/view/:people" component={PeoplesView} />

                <PrivateRoute exact path="/admin/users/" component={UserList} />
                <PrivateRoute exact path="/admin/users/create" component={UserCreate} />
                <PrivateRoute exact path="/admin/users/view/:user" component={UserView} />

                <PrivateRoute exact path="/admin/sites/" component={SiteList} />
                <PrivateRoute exact path="/admin/sites/create" component={SiteCreate} />

                <PrivateRoute exact path="/marketing/forms/" component={FormsList} />
                <PrivateRoute exact path="/marketing/forms/create" component={FormsCreate} />
                <PrivateRoute exact path="/marketing/forms/view/:form" component={FormsView} />

                <PrivateRoute exact path="/marketing/automate/" component={EventList} />

                <PrivateRoute exact path="/content/videos/" component={VideosList} />

                <PrivateRoute exact path="/content/courses/" component={CoursesList} />
                <PrivateRoute exact path="/content/courses/edit/:course" component={CoursesEdit} />

                <PrivateRoute exact path="/content/events/" component={WebinarsList} />
                <PrivateRoute exact path="/content/events/edit/:event" component={WebinarsEdit} />

                <PrivateRoute exact path="/marketing/pages/" component={PagesList} />
                <PrivateRoute exact path="/marketing/pages/view/:page" component={PagesView} />
                <PrivateRoute exact path="/marketing/pages/edit/:id/:page" component={Tina} />
                <PrivateRoute exact path="/marketing/pages/builder/:id/:page" component={BeeFree} />

                <PrivateRoute exact path="/content/categories/" component={CategoriesList} />
                <PrivateRoute exact path="/content/categories/create" component={CategoriesCreate} />

                <PrivateRoute exact path="/content/instructors/" component={InstructorsList} />
                <PrivateRoute exact path="/content/instructors/create" component={InstructorsCreate} />

                <PrivateRoute exact path="/admin/domains" component={DomainsList} />
                <PrivateRoute exact path="/admin/settings" component={Settings} />
                <PrivateRoute exact path="/admin/settings/emails" component={EmailList} />

                <PrivateRoute exact path="/marketing/email-templates" component={EmailTemplatesList} />
                <PrivateRoute exact path="/marketing/email-templates/view/:emailTemplate" component={EmailTemplatesView} />

                <PrivateRoute exact path="/test/page-builder" component={PageBuilder} />
                <PrivateRoute exact path="/zoom-auth" component={Zoom} />

              </div>
            ) : (
              <>
                <Route exact path="/" component={Home} />
              </>
            )
          }
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = ({ session, sites }) => {
  return { session, sites };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loadSession, loadSites, setCollapse }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
