import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export const Header = memo(function Header(props) {
  return (
    <div className="section-header">
      {props.children}
    </div>
  )
})

Header.propTypes = {
  children: PropTypes.node.isRequired,
}
