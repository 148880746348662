import React, { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useHistory } from 'react-router-dom'
import network from '../../utils/network'

const RouteChangeListener = props => {
  const { user } = props.session

  const history = useHistory()
  const session = useRef(null)
  const fingerPrint = useRef(null)

  const handleRouteChange = () => {
    props.onChange()
    setTimeout(async () => {
      const eventData = {
        session_id: session.current,
        uuid: fingerPrint.current.visitorId,
        event_time: new Date().toISOString(),
        source: 'web',
        event_type: 'traffic',
        url_origin: window.location.origin,
        url_path: window.location.pathname,
        referrer: document.referrer,
        os_platform: window.navigator.platform,
        device_lang: window.navigator.language,
        extra_data: {}
      }
      const apiUrl = 'public/l3rn/track'
      if (user && user.username) {
        eventData.user_id = user.username
      }
      await network.post(apiUrl, { eventData })
    }, 200)
  }

  const asyncFP = async () => {
    const fp = await FingerprintJS.load()
    fingerPrint.current = await fp.get()
    history.listen(handleRouteChange)
    handleRouteChange()
  }

  useEffect(() => {
    session.current = sessionStorage.getItem('l3rn-session')
    if (!session.current) {
      session.current = uuidv4()
      sessionStorage.setItem('l3rn-session', session.current)
    }
    asyncFP()
  }, [])

  return <></>
}

const mapStateToProps = ({ session, sites }) => {
  return { session, sites }
}

export default connect(mapStateToProps)(RouteChangeListener)
