import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_PEOPLE = 'FETCHING_PEOPLE';
export const FAIL_FETCH_PEOPLE = 'FAIL_FETCH_PEOPLE';
export const SUCCESS_FETCH_PEOPLE = 'SUCCESS_FETCH_PEOPLE';

export const FETCHING_ALL_PEOPLE = 'FETCHING_ALL_PEOPLE';
export const FAIL_FETCH_ALL_PEOPLE = 'FAIL_FETCH_ALL_PEOPLE';
export const SUCCESS_FETCH_ALL_PEOPLE = 'SUCCESS_FETCH_ALL_PEOPLE';

export const FETCHING_PERSON = 'FETCHING_PERSON';
export const FAIL_FETCH_PERSON = 'FAIL_FETCH_PERSON';
export const SUCCESS_FETCH_PERSON = 'SUCCESS_FETCH_PERSON';

export const POST_PERSON = 'POST_PERSON';
export const POST_FAIL_PERSON = 'POST_FAIL_PERSON';
export const POST_SUCCESS_PERSON = 'POST_SUCCESS_PERSON';

export const PUT_PERSON = 'PUT_PERSON';
export const PUT_FAIL_PERSON = 'PUT_FAIL_PERSON';
export const PUT_SUCCESS_PERSON = 'PUT_SUCCESS_PERSON';

export const DELETE_PERSON = 'DELETE_PERSON';
export const DELETE_FAIL_PERSON = 'DELETE_FAIL_PERSON';
export const DELETE_SUCCESS_PERSON = 'DELETE_SUCCESS_PERSON';

export const fetchUsers = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_PEOPLE });
    try {
      let url = parseUrl(`site/${siteId}/people?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_PEOPLE,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_PEOPLE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_PEOPLE,
      });
    }
  };
};

export const fetchAllUsers = (siteId) => {
  return async dispatch => {
    dispatch({ type: FETCHING_ALL_PEOPLE });
    try {
      const response = await network.get(`site/${siteId}/people/all`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_ALL_PEOPLE,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCH_ALL_PEOPLE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_ALL_PEOPLE,
      });
    }
  };
};

export const fetchUser = (siteId, user) => {
  return async dispatch => {
    dispatch({ type: FETCHING_PERSON });
    try {
      const response = await network.get(`site/${siteId}/people/${user}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_PERSON,
        });
      } else {
        dispatch({
          user: response.data,
          type: SUCCESS_FETCH_PERSON,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_PERSON,
      });
    }
  };
};

export const postUser = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_PERSON });
    try {
      const response = await network.post(`site/${siteId}/people`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_PERSON,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_PERSON,
      });

    }
  };
};

export const convertUser = async (siteId, user) => {
  try {
    const response = await network.post(`site/${siteId}/people/${user}/convert`, {});
    return response;
  } catch (error) {
    console.log('error', error)
    throw error;
  }
};

export const putUser = (id, body) => {
  return async dispatch => {
    dispatch({ type: POST_PERSON });
    try {
      const response = await network.put(`site/${id}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_PERSON,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_PERSON,
      });
    }
  };
};

export const deleteUser = (id, ids = []) => {
  return async dispatch => {
    dispatch({ type: DELETE_PERSON });
    try {
      const promises = ids.map(async item => {
        await network.del(`site/${id}/people/${item}`);
      });
      const response = await Promise.all(promises)
      dispatch({
        type: DELETE_SUCCESS_PERSON,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_PERSON,
      });
      return error;
    }
  };
};

export const resetUser = async (site, username) => {
  return network.get(`site/${site}/user/${username}/resetpassword`);
}