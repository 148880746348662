import React from 'react';
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 0px'
  },
  header: {
    width: '100%'
  },
  title: {
    color: 'rgb(67, 67, 74)',
    fontWeight: '800',
    fontSize: 34,
    fontFamily: 'Muli'
  },
  subtitle: {
    fontWeight: '600',
    fontSize: 19,
    fontFamily: 'Muli',
    color: 'rgb(158, 162, 180)',
    marginTop: 14,
    lineHeight: '30px',
    width: 750
  },
  separator: {
    backgroundColor: '#eaebec',
    height: 3,
    width: '100%',
    marginTop: 36,
    marginBottom: 26
  },
  markdown: {
    textAlign: 'left'
  }
};

const PrivacyPolicy = (props) => {
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>L3rn Privacy Policy</title>
        <link rel="canonical" href="https://store.l3rn.io/privacy-policy" />
      </Helmet>
      <Navbar />
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#ffffff' }}>
        <div style={styles.content} className="general-padding">
          <div style={styles.header}>
            <div style={styles.title}>
              Políticas de privacidad
            </div>
          </div>
          <div style={styles.separator} />
          <div className="markdown" style={styles.markdown}>

            ### AVISO DE PRIVACIDAD PARA LA PROTECCIÓN DE DATOS PERSONALES “L3RN”

            **xxxxx fecha de alta en el sitio xxxx**


            Gracias por visitar nuestro sitio web de  **“URL o dirección electrónica a través de la cual se accede al sitio web”**  de conformidad a los lineamientos establecidos, y con el fin de garantizar la privacidad de los usuarios, nuestros clientes y/o de las personas que previo su consentimiento y de manera voluntaria hayan decidido compartir con nosotros sus datos personales, informamos lo siguiente:


            Debido a la naturaleza y ubicación del negocio principal, el presente documento se ajusta en su totalidad a las leyes mexicanas tal como la **LEY DE PROTECCIÓN DE DATOS EN POSESIÓN DE PARTICULARES**, su reglamento y en concurrencia al **REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS.** Adicionalmente, en cumplimiento a la legislación aplicable en la **UNIÓN EUROPEA**, y las leyes reguladas por la **Comisión Federal de Comercio de los Estados Unidos de América** en materia de políticas de privacidad.

            #### I. IDENTIDAD Y DOMICILIO DEL RESPONSABLE

            Para los efectos que deriven del presente documento y/o sus afines; el registro marcario  **“L3RN”** es un signo distintivo registrado bajo las leyes de propiedad intelectual internacional, designado por el **INSTITUTO MEXICANO DE PROPIEDAD INDUSTRIAL**, la cual que se encuentra bajo el nombre de su titular el señor **“Indique el nombre completo, la razón o denominación social de la persona que es propietaria”**, misma que utilizará sus datos personales aquí recabados para **BRINDARLE UN BUEN SERVICIO A NUESTROS USUARIOS.**

            #### II. DEFINICIONES. -

            **I.- Aviso de Privacidad:** El presente documento electrónico o en cualquier otro formato generado por el responsable que es puesto a disposición del titular, previo al tratamiento de sus datos personales-

            **II. Bases de datos:** El conjunto ordenado de datos personales referentes a una persona identificada o identificable.

            **III. Consentimiento:** Manifestación de la voluntad del titular de los datos mediante la cual se efectúa el tratamiento de los mismos.

            **IV. Datos personales:** Cualquier información concerniente a una persona física identificada o identificable. origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.

            **V. Perfil:** se refiere a la recopilación de configuraciones que en conjunto determinan la información que se incluirá o excluirá de un Informe en particular. Por ejemplo, se podría establecer un Perfil para ver una pequeña parte de un sitio web como un Informe único. Puede haber múltiples perfiles establecidos en una sola propiedad.

            **VI. Responsable:** Persona física o moral de carácter privado que decide sobre el tratamiento de datos personales.

            **VII. Servidores:** Se refiere a los servidores controlados por **“L3RN“** y/o proveedores (o sus subsidiarias de propiedad total) en los que se almacenan el software de procesamiento y los datos del cliente.

            **VII. Tercero:** La persona física o moral, nacional o extranjera, distinta del titular o del responsable de los datos.


            Con la finalidad de orientar sobre la información recabada al titular de sus datos personales, de acuerdo a las leyes y reglamentos bajo la legislación mexicana, de la unión europea y estadounidense, a continuación, hacemos una declaración en nuestras políticas de privacidad.


            #### D E C L A R A C I O N E S:


            **A. REVOCACIÓN AL CONSENTIMIENTO DE DATOS PERSONALES.** El titular de los datos tiene derecho a revocar en cualquier momento el consentimiento que otorgó al responsable para el tratamiento de sus datos personales. Esto se podrá llevar a cabo solicitándolo en nuestro **CORREO ELECTRÓNICO: “correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa ”**, mediante la solicitud correspondiente, que se anexa en el siguiente link: **FORMULARIO DE EJERCICIO DE DERECHOS ARCO, REVOCACIÓN DEL CONSENTIMIENTO Y/O LIMITACIÓN DE USO O DIVULGACIÓN DE DATOS PERSONALES. (colocar el link del formulario)** .

            **B. DATOS PERSONALES RECABADOS:** (Para las finalidades señaladas en las presentes políticas de privacidad, podemos recabar sus datos personales de distintas formas: **(i)** Cuando usted nos lo proporciona directamente (de forma personal); **(ii)** cuando usted visita nuestro sitio web o utilizando nuestros servicios en línea (de forma directa), y; **(iii)** cuando obtenemos información a través de otras fuentes que están permitidas por la ley (de forma indirecta):

            **1. De forma personal:** Cuando usted mismo nos lo proporciona por diversos medios electrónicos personalmente, cuando se matricula a nuestros cursos, compra algún producto y/o servicios, envía mensajes de correo electrónico a **“L3RN”**, participa en las encuestas de los usuarios, participa en los foros públicos, se inscribe en los newsletters, cuando participa en nuestras promociones o nos da información con objeto de que le prestemos un servicio o bien cuando solicita la facturación de un producto o servicio. Los datos pueden ser entre otros:
            * Nombre;
            * Correo electrónico;
            * Teléfono;
            * **“L3RN”** no recopila, ni almacena cuenta financieras, los datos bancarios serán recabados por plataformas externas, mediante un certificado de validación y verificación de datos.  Si no quiere enviar la información de autenticación o de pago requerida, no podrá obtener acceder a los cursos de **“L3RN”**.

            Las Redes sociales: (Facebook, twitter, YouTube, entre otras) Son una plataforma de comunicación e interconexión dentro de plataformas digitales de los diferentes usuarios, son ajenas a **“L3RN”** y sus AFILIADAS y por lo tanto, no se encuentran bajo su responsabilidad.

            Le informamos que en nuestra página podremos utilizar cookies, o plataformas externas para reconocer dispositivos, más no para rastrearlos; de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle de publicidad un mejor servicio y experiencia de usuario al navegar en nuestra página.

            Así mismo, las plataformas de almacenamiento de información podrán establecerse en otros países y con terceros proveedores, inherentes al objeto del responsable.

            **2. De forma indirecta:** A través de otras fuentes como formulario por parte de la empresa en la que labora, mismo que debe tener su consentimiento previo para el alta del perfil a su nombre, dentro de los servidores, plataformas de **“L3RN”** y/o sus proveedores.


            **C. FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES.**
            Sus datos personales serán utilizados para las siguientes finalidades:

            1. Creación de perfil de usuario;
            2. Acceso a la plataforma
            3. Área de administración;
            4. Capacitación y asesoría;
            5. Publicidad y marketing;
            6. Servicio al cliente;
            7. Para mantener un registro de sus transacciones con fines de informes financieros, auditoría y cumplimiento; y para cumplir con las obligaciones legales de **“L3RN”**, resolver disputas, hacer cumplir sus acuerdos, y según lo permita la ley aplicable.

            **D. LIMITACIÓN DEL USO DE DIVULGACIÓN DE SUS DATOS PERSONALES:**
            Usted puede limitar o dejar de recibir mensajes promocionales por teléfono, y correo electrónico, comunicándose por medio de nuestro correo electrónico: **correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa **

            **E. MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DE DATOS PERSONALES (ARCO):**
            Usted tiene el derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como rectificarlos en caso de ser inexactos o instruirnos, cancelarlos cuando considere que resulten ser excesivos o innecesarios para la finalidad que justificaron su obtención u oponerse al tratamiento de los mismos para fines específicos. Ingresando a la solicitud correspondiente en el siguiente link: **FORMULARIO DE EJERCICIO DE DERECHOS ARCO, REVOCACIÓN DEL CONSENTIMIENTO Y/O LIMITACIÓN DE USO O DIVULGACIÓN DE DATOS PERSONALES (ANEXAR FORMULARIO PARA DESCARGAR)**

            **F. TRANSFERENCIA DE DATOS:**
            Sus datos personales pueden ser transferidos y tratados dentro del país, por personas distintas a esta empresa **“L3RN”**. En este sentido, su información puede ser compartida con los colaboradores de la plataforma y a las autoridades competentes que lo soliciten.

            Con las finalidades siguientes:
            1. Desarrollar y mantener la seguridad de **“L3RN**;
            2. Con otros usuarios de **“L3RN”**, para crear un entorno interactivo, apoyar la participación en clases y compartir información de los cursos;
            3. Con proveedores de servicios y terceros que ayuda a llevar a cabo la prestación de servicios, entrega de material y productos a **“L3RN”**.

            Si usted no manifiesta su oposición mediante la solicitud de revocación para los derechos **ARCO (derecho sobre (acceso, rectificación, cancelación y oposición)**, para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.

            **G. MODIFICACIÓN AL AVISO DE PRIVACIDAD:**
            Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas del mercado. Estas modificaciones estarán disponibles al público a través de los medios electrónicos correspondientes, a los correos electrónicos que nos haya proporcionado y por medio de nuestra web **“URL o dirección electrónica a través de la cual se accede al sitio web“**


            **H. OBTENCIÓN DE DATOS PERSONALES SENSIBLES:**
            El titular declara que tal vez proporcionó datos sensibles, al momento de establecer su compra, ya que marca el estatus social y económico actual. Los datos sensibles son aquellos datos personales íntimos o cuya utilización debida o indebida pueda dar origen a discriminación o un riesgo grave para este.
            Si usted no manifiesta su oposición mediante la solicitud de revocación para los derechos **ARCO**, para que sus datos personales se entiendan que ha otorgado su consentimiento para ello.


            **I. PROTECCIÓN DE DATOS CONFORME AL REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS, PARA USUARIOS RESIDENTES EN LA UNIÓN EUROPEA.**
            La protección de las personas físicas en relación con el tratamiento de datos personales es un derecho fundamental. El artículo 8, apartado 1, de la Carta de los Derechos Fundamentales de la Unión Europea («la Carta») y el artículo 16, apartado 1, del Tratado de Funcionamiento de la Unión Europea (TFUE) establece que toda persona tiene derecho a la protección de los datos de carácter personal que le concierne.

            Los datos personales que recopilamos para proporcionar ese servicio, cómo se usa y con quién se comparte.

            **¿Cómo recopilamos sus datos personales?**

            Cuando se registra en la página web: cuando se registra en el Servicio de **“L3RN”** recopilamos ciertos datos personales para que pueda utilizar de la plataforma de **“L3RN”**, como su dirección de correo electrónico, nombre completo, su teléfono, entro otros datos de verificación del perfil.

            El presente **“AVISO DE PRIVACIDAD”**, sus especificaciones, cumplen con los lineamientos **REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS, PARA USUARIOS RESIDENTES EN LA UNIÓN EUROPEA.**

            **J. ALMACENAMIENTO Y SEGURIDAD**
            **“L3RN”** almacena información, incluida Información personal, en sus propios servidores y también en servidores y con empresas que **“L3RN”** contrata para proporcionar servicios.

            **“L3RN”** también almacena información, incluida Información personal, en sus propios servidores o en servidores y con empresas que el Miembro contrata para proporcionar servicios. En cada caso, la información puede almacenarse en México, y en otros países donde opera **“L3RN”**, y en países donde operan los respectivos proveedores de servicios de **“L3RN”**. Si se encuentra en la Unión Europea, Suiza u otras regiones con leyes que rigen la recopilación y el uso de datos, usted reconoce que **“L3RN”** y proveedores pueden transferir, procesar y almacenar su información personal en México y otros países, basándose en las políticas de privacidad de la región, las cuales pueden ser más rígidas.

            **“L3RN”** controla su propia copia de la información recopilada a través del Sitio de **“L3RN”** y tiene un programa de seguridad de la información diseñado para proteger la información en su posesión o control. Esto se hace a través de una variedad de políticas, procesos y procedimientos de privacidad y seguridad. **“L3RN”** utiliza resguardos administrativos, físicos y técnicos que protegen de manera razonable y adecuada la confidencialidad, integridad y disponibilidad de la Información que recopila, recibe, almacena o transmite.

            No obstante, ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro; y, por lo tanto, **“L3RN”** no puede garantizar su absoluta seguridad.

            Mientras que **“L3RN”** trabaja arduamente para garantizar la integridad y seguridad de su red y sus sistemas, **“L3RN”** no puede garantizar que sus medidas de seguridad evitarán que los “piratas informáticos” u otras personas no autorizadas accedan u obtengan información ilegalmente.

            Si **“L3RN”** se entera de una violación de seguridad que involucre a su copia de su Información personal, **“L3RN”** puede intentar notificarle electrónicamente para que pueda tomar las medidas de protección apropiadas.

            Al usar el Sitio de **“L3RN”** o al proporcionar Información personal a **“L3RN”**, usted acepta que **“L3RN”** puede comunicarse con usted electrónicamente en relación con cuestiones de seguridad, privacidad y administrativas relacionadas con su uso del Sitio de **“L3RN”**

            Dependiendo de dónde se encuentre ubicado, puede tener derecho legal a recibir un aviso de una violación de seguridad, que involucre su Información personal, por escrito.


            **K. QUEJAS Y DENUNCIAS  POR EL TRATAMIENTO INDEBIDO DE SUS DATOS PERSONALES:**
            Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el **INAI**, para mayor información visite www.inai.org.mx.

            **L. INFORMACIÓN DE CONTACTO**
            Cualquier duda en materia de privacidad de datos que le competa a nuestra empresa, por favor envíe un correo electrónico a **correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado o afiliado con la empresa **


            **M. FECHA DE ENTRADA EN VIGENCIA.  XXXX ALTA EN SITIO WEB XXX**

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
