import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppBar, Tabs, Tab, Box, makeStyles } from '@material-ui/core'
import {
  postModule,
  fetchModules,
  deleteModule,
  updateModule
} from '../../../redux/actions/modules'
import ActivitiesList from '../ActivitiesList'
import Suscriptores from './Suscriptores'
import Infomation from './Infomation'
import Registros from './Registros'

const useStyles = makeStyles(theme => ({
  appBarRoot: {
    boxShadow: 'none',
    marginBottom: 20
  },
  tabsRoot: {
    backgroundColor: 'white',
    float: 'left'
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    fontFamily: 'Montserrat',
    backgroundColor: 'rgba(33,150,243,0.08)',
    color: '#2196F3',
    fontWeight: 'bold',
    border: 0,
    textTransform: 'none',
    transition: 'background-color 0.4s',
    fontSize: 16,
    marginRight: 12,
    height: 40,
    minHeight: 40,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: 'rgba(33,150,243,0.2)',
      color: '#2196F3'
    },
    '&[aria-selected="false"]': {
      color: '#2196F3',
      backgroundColor: 'white'
    }
  },
  paper: {
    width: 400,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  }
}))

export function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ padding: 20, width: '100%' }}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function ModulesTabs (props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div style={{ width: '100%' }}>
      <AppBar
        classes={{
          root: classes.appBarRoot
        }}
        position='static'
      >
        <Tabs
          value={value}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator
          }}
          onChange={handleChange}
        >
          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='Información'
          />

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='Suscriptores'
          />

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='Instructores'
          />

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='Actividad'
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Infomation />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Suscriptores />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Registros />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <ActivitiesList
          url={`site/${props.site}/webinar/${props.webinar}/activity`}
        />
      </TabPanel>
    </div>
  )
}

const mapStateToProps = ({ sites, webinars, modules }) => {
  return { sites, webinars, modules }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { postModule, fetchModules, deleteModule, updateModule },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulesTabs)
