import {
  FETCHING_WEBINARS,
  FAIL_FETCH_WEBINARS,
  SUCCESS_FETCH_WEBINARS,
  FETCHING_WEBINAR,
  FAIL_FETCH_WEBINAR,
  SUCCESS_FETCH_WEBINAR,
  PUT_WEBINAR,
  PUT_FAIL_WEBINAR,
  PUT_SUCCESS_WEBINAR,
  DELETE_WEBINAR,
  DELETE_FAIL_WEBINAR,
  DELETE_SUCCESS_WEBINAR,
  FETCHING_INSTRUCTORS,
  FAIL_FETCH_INSTRUCTORS,
  SUCCESS_FETCH_INSTRUCTORS,
  FETCHING_SUBSCRIBERS,
  FAIL_FETCH_SUBSCRIBERS,
  SUCCESS_FETCH_SUBSCRIBERS,
} from '../actions/webinars'

const initialState = {
  data: [],
  error: null,
  loading: false,
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
  webinar: {
    data: {},
    error: null,
    loading: false,
  },
  users: {
    data: [],
    order: {},
    count: 0,
    limit: 25,
    offset: 0,
    error: null,
    loading: false,
  },
  instructors: {
    data: [],
    order: {},
    count: 0,
    limit: 25,
    offset: 0,
    error: null,
    loading: false,
  },
  modules: {
    data: [],
    error: null,
    loading: false,
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_WEBINARS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_WEBINARS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case FAIL_FETCH_WEBINARS:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
    case FETCHING_INSTRUCTORS:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          error: null,
          loading: true,
        }
      };
    case SUCCESS_FETCH_INSTRUCTORS:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          ...action.data,
          loading: false,
        }
      };
    case FAIL_FETCH_INSTRUCTORS:
      return {
        ...state,
        instructors: {
          ...state.instructors,
          error: action.error,
          loading: false,
        }
      };
    case FETCHING_SUBSCRIBERS:
      return {
        ...state,
        users: {
          ...state.users,
          error: null,
          loading: true,
        }
      };
    case SUCCESS_FETCH_SUBSCRIBERS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.data,
          loading: false,
        }
      };
    case FAIL_FETCH_SUBSCRIBERS:
      return {
        ...state,
        users: {
          ...state.users,
          error: action.error,
          loading: false,
        }
      };
    case FETCHING_WEBINAR:
      return {
        ...state,
        webinar: {
          data: {},
          error: null,
          loading: true,
        },
      };
    case SUCCESS_FETCH_WEBINAR:
      return {
        ...state,
        webinar: {
          ...state.course,
          loading: false,
          data: action.data,
        },
      };
    case FAIL_FETCH_WEBINAR:
      return {
        ...state,
        webinar: {
          ...state.course,
          loading: false,
          error: action.error,
        }
      };
    case PUT_WEBINAR:
      return {
        ...state,
      };
    case PUT_FAIL_WEBINAR:
      return {
        ...state,
        webinar: {
          ...state.course,
          error: action.error,
        },
      };
    case PUT_SUCCESS_WEBINAR:
      return {
        ...state,
      };
    case DELETE_WEBINAR:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FAIL_WEBINAR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SUCCESS_WEBINAR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state
  }
};
