import React from 'react'
import PropTypes from 'prop-types'
import { MenuList } from "./../../videos/List"
import './Video.scss'

function Video(props) {
  return (
    <div onClick={props.onChange} className='video-item-container'>
      <div className="video-item-label">
        <div className="_info">
          <label className='video-name'>{props.name}</label>
          <label className='video-size'>{props.size} - {props.duration}</label>
          <label className='video-date'>{props.createdAt}</label>
        </div>
        {
          !props.preview && (
            <div className="_menu">
              <MenuList
                value={props.value}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onDownload={props.onDownload}
                loadingDownload={props.loadingDownload}
              />
            </div>
          )
        }
      </div>

      {
        props.urls && props.urls.length ? (
          <img className='video-item-image' src={props.urls[0]} />
        ) : (
          <img
            src='/images/video.png'
            className='video-item-image'
          />
        )
      }
    </div >
  )
}

Video.propTypes = {
  preview: PropTypes.bool,
  urls: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Video
