import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { filterChanges, makeToast } from '../../../../utils'
import { bindActionCreators } from 'redux';
import { Close, AddBox } from '@material-ui/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import { Grid, makeStyles, IconButton } from '@material-ui/core';
  import { FormGroup, FormLabel, RadioGroup, Radio, FormControlLabel, Modal, TextField, CircularProgress } from '@material-ui/core';
import { getAttachments, postAttachment, deleteAttachment } from '../../../../helpers/attachments';
import { postContent, updateContent } from '../../../../redux/actions/content';
import { unlock, uploadFile, listening } from '../../../../helpers/files';
import { getContent, deleteContentMedia, postContentInstructors } from '../../../../helpers/courses';
import { isVideo } from '../../../../helpers/utils';
import CheckInstructors from './CheckInstructors';
import ImageUploader from './ImageUploader';
import VideoSelect from './VideoSelect';
import Attachment from './Attachment';
import { Alert } from '../../Alert';
import '../../../../App.css';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minWidth: 420
  },
  iconButtonRoot: {
    padding: 2
  },
  formLabelRoot: {
    fontSize: 16,
    color: '#024976',
    fontFamily: 'Montserrat',
    fontWeight: '900'
  },
  formControlLabelRoot: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: '#9e9e9e'
    },
    '& .Mui-checked + span.MuiTypography-root': {
      color: '#00ace7'
    }
  },
  radioGroupRoot: {
    flexDirection: 'row',
    columnGap: 28
  },
  radioRoot: {
    '&:not(.Mui-checked) .MuiIconButton-label': {
      color: 'gainsboro'
    },
    '&.Mui-checked .MuiIconButton-label': {
      color: 'transparent'
    },
    '&.Mui-checked .MuiIconButton-label div svg:first-child': {
      backgroundColor: '#00ace7',
      borderRadius: '50%'
    },
    '&.Mui-checked .MuiIconButton-label div svg:last-child': {
      fill: 'white'
    }
  },
  indicator: {
    backgroundColor: "#555",
  },
  root: {
    color: '#000',
    backgroundColor: "#ddd",
  },
  paper: {
    width: 400,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper,
  },
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
}));

const AddContent = ({ id, site, course, type, open, onClose, content, onSave, onUpdate, module, postContent, updateContent, sites, ...props }) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [status, setStatus] = useState('Borrador');
  const [attachments, setAttachments] = useState([]);
  const [editorState, setEditorState] = useState([]);
  const [instructorsId, setInstructorsId] = useState([]);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [videoSelected, setVideoSelected] = useState(null);
  const [imageSelected, setImageSelected] = useState(null);
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [allowComments, setAllowComments] = useState('false');
  const [videoSelectModal, setVideoSelectModal] = useState(false)
  const [progressAttachment, setProgressAttachment] = useState(0);
  const [loadingAttachment, setLoadingAttachment] = useState(false);

  useEffect(() => {
    if (open && id) {
      preload();
    }
  }, []);

  const preload = async () => {
    await setLoading(true);
    await setLoadingVideo(true);
    loadAttachments();
    const data = await getContent(site, course, module, id);
    await setData(data)
    await setTitle(data.title);
    await setStatus(data.status);
    await setAllowComments(data.allowComments ? 'true' : 'false');
    if (data.media) {
      await setFiles([{ name: data.media }]);
      await setMediaUrl(data.media);
    }
    if (data.content) {
      await setEditorState(data.content.description);
    }
    if (data.mediaData) {
      await setVideoSelected(data.mediaData)
    }
    await setLoading(false);
    await setLoadingVideo(false);
  }

  const loadAttachments = async () => {
    const data = await getAttachments(site, course, module, id);
    await setAttachmentsList(data);
  }

  const onDeleteAttachment = async (fileid, index) => {
    Alert({
      title: '¿Estas seguro?',
      message: 'Se eliminara este recurso',
      onSuccess: async () => {
        if (fileid) {
          await deleteAttachment(site, course, module, id, fileid);
          loadAttachments();
        } else {
          attachmentsList.splice(index, 1);
          setAttachmentsList([...attachmentsList]);
        }
      },
      onFail: () => console.log('cancel'),
    });
  }
  
  const onProgress = (ev) => {
    const percent = Math.round((100 * ev.loaded) / ev.total)
    if (percent === 100) {
      setProgress(0)
    } else {
      setProgress(percent)
    }
  }

  const onProgressAttatchment = (ev) => {
    const percent = Math.round((100 * ev.loaded) / ev.total)
    if (percent === 100) {
      setProgressAttachment(0)
    } else {
      setProgressAttachment(percent)
    }
  }

  const setImage = async (acceptedFiles) => {
    await setLoading(true)
    makeToast(enqueueSnackbar, 'Cargando imágen', 'info')
    await setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
    const specific = {};
    const fileId = await uploadFile(site, acceptedFiles[0], specific, false, null, onProgress);
    const { url, ...image } = await listening(site, fileId);
    console.log('url, ...image', {url, ...image})
    // await action(null, {media: url, imageId: image.id, videoId: null});
    const unlockedMedia = await unlock(site, url);
    await setMediaUrl(unlockedMedia);
    await setImageSelected({
      ...image,
      url: unlockedMedia,
    })
    makeToast(enqueueSnackbar, 'Imágen cargada')
    await setLoading(false)
  }

  const setFilesUpload = async (acceptedFiles) => {
    makeToast(enqueueSnackbar, 'Cargando adjunto', 'info')
    await setLoadingAttachment(true);
    await setAttachments(acceptedFiles);
    const filename = acceptedFiles[0].name;
    const fileId = await uploadFile(site, acceptedFiles[0], {}, false, null, onProgressAttatchment);
    const attachment = await listening(site, fileId);
    setAttachmentsList([...attachmentsList, { name: filename, file: attachment }]);
    setAttachments([]);
    await setLoadingAttachment(false);
    makeToast(enqueueSnackbar, 'Adjunto cargado')
  }

  const validate = () => {
    if (title.trim() === '') return { success: false, error: 'Titulo requerido' };
    return { success: true };
  }

  const save = async (body) => {
    const isValid = validate();
    if (isValid.success) {
      try {
        await setLoading(true);
        makeToast(enqueueSnackbar, 'Guardando lección', 'info')
        let last = 0
        if (content && content.data && content.data[id]) {
          last = Math.max(...content.data[id].map(item => item.order))
          last = last + 1
        }
        const response = await postContent(site, course, module,
          {
            title,
            status,
            typeMedia: type,
            videoId: videoSelected && videoSelected.id || null,
            imageId: imageSelected && imageSelected.id || null,
            content: {
              description: editorState,
            },
            allowComments: allowComments === 'true',
            ...body,
            order: last,
          });
        for (let i = 0; i < attachmentsList.length; i++) {
          const element = attachmentsList[i];
          await postAttachment(site, course, module, response.id, { ...element });
        }
        await postContentInstructors(site, { ids: instructorsId, contentId: response.id, courseId: course });
        await setData(response)
        await loadAttachments()
        await onSave(response);
        makeToast(enqueueSnackbar, 'Se creó la lección')
      } catch (error) {
        console.log('error', error)
        makeToast(enqueueSnackbar, "Ocurrio un error al guardar", 'error')
      } finally {
        setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
      console.log('onSave error', isValid);
    }
  }

  const update = async (body) => {
    const isValid = validate();
    if (isValid.success) {
      try {
        await setLoading(true);
        makeToast(enqueueSnackbar, 'Guardando lección', 'info')
        const newData = {
          title,
          status,
          typeMedia: type,
          videoId: videoSelected && videoSelected.id || null,
          imageId: imageSelected && imageSelected.id || null,
          content: {
            description: editorState,
          },
          allowComments: allowComments === 'true',
          ...body,
        };
        const newBody = filterChanges(newData, data)
        await updateContent(site, course, module, id, newBody);
        for (let i = 0; i < attachmentsList.length; i++) {
          const element = attachmentsList[i];
          if (!element.id) {
            await postAttachment(site, course, module, id, { ...element });
          }
        }
        await loadAttachments()
        await onUpdate({id});
        makeToast(enqueueSnackbar, 'Se guardó la lección')
      } catch (error) {
        console.log('error', error)
        makeToast(enqueueSnackbar, "Ocurrio un error al guardar", 'error')
      } finally {
        setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const action = async (ev, body) => {
    if (id) {
      update(body);
    } else {
      save(body);
    }
  }

  const onDeleteMedia = () => {
    Alert({
      title: '¿Estas seguro?',
      message: 'Se eliminara este recurso',
      onSuccess: async () => {
        deleteMedia()
      },
      onFail: () => console.log('cancel'),
    });
  }

  const deleteMedia = async () => {
    try {
      await setLoading(true)
      await deleteContentMedia(site, course, module, id);
      setMediaUrl(null)
      setFiles([]);
      makeToast(enqueueSnackbar, 'Se elimino el archivo')
    } catch (error) {
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      setLoading(false);
    }
  }

  const renderMedia = () => {
    if (!mediaUrl) return null;
    const video = isVideo(mediaUrl);
    if (video) {
      return (
        <video controls>
          <source src={mediaUrl} />
        </video>
      );
    }
    return (
      <img src={mediaUrl} alt="" />
    )
  }

  const onSelectVideo = (ev) => {
    setVideoSelectModal(true)
  }

  const onVideoSelected = async (data) => {
    if (data) {
      await setLoadingVideo(true)
      makeToast(enqueueSnackbar, 'Selecionando video...')
      const {url} = await unlock(site, data.url)
      await setVideoSelected({...data, url})
      await setLoadingVideo(false)
    } else {
      setVideoSelected(null)
    }
  }

  const onChangeInstructors = (ids) => {
    setInstructorsId(ids)
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          top: '50%',
          padding: 0,
          left: '50%',
          width: '75%',
          borderRadius: 4,
          maxHeight: '90vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          transform: 'translate(-50%, -50%)',
        }}
        className={classes.paper}
      >
        <div style={{ padding: '20px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
              <AddBox style={{ fill: '#024976' }} />
              <div style={{ fontSize: 16, color: '#024976', fontFamily: 'Montserrat', fontWeight: '900' }}>Agregar Lección</div>
            </div>
            <IconButton classes={{ root: classes.iconButtonRoot }} onClick={onClose}>
              <Close style={{ fill: '#333333' }} />
            </IconButton>
          </div>
        </div>
        <div style={{ height: 1.6, width: '100%', backgroundColor: '#e4e4e4' }} />
        <Grid container item xs={12}>
          <Grid style={{ padding: '20px 24px' }} item xs={9}>
            {
              loadingVideo ? (
                <CircularProgress size={20} style={{ color: '#00A8D8' }} />
              ) : (
                <>
                  {
                    type === "image" && (
                      <FormGroup style={{ position: 'relative', marginBottom: 30, padding: 10, borderRadius: 6, backgroundColor: 'rgb(250, 250, 250)' }}>
                        <div style={{
                          zIndex: 1,
                          fontSize: 13,
                          marginBottom: -8,
                          padding: '0px 13px',
                          position: 'relative',
                          fontFamily: 'Montserrat',
                          color: 'rgba(0, 0, 0, 0.54)',
                        }}>Sube una imagen</div>
                        <ImageUploader
                          files={files}
                          preview={true}
                          showIcon={true}
                          accept="image/*"
                          loading={progress}
                          onChange={setImage}
                          progress={progress}
                          previousImage={mediaUrl}
                          dropContainerStyle={{ height: 140, textAlign: 'center' }}
                          placeholder="Haga clic aquí para subir una imagen"
                        />
                      </FormGroup>
                    ) 
                  }
                  {
                    type === "video" && (
                      <FormGroup style={{ position: 'relative', marginBottom: 30, padding: 10, borderRadius: 6, backgroundColor: 'rgb(250, 250, 250)' }}>
                        <div style={{
                          fontSize: 13,
                          color: 'rgba(0, 0, 0, 0.54)',
                          fontFamily: 'Montserrat',
                          marginBottom: -8,
                          position: 'relative',
                          zIndex: 1,
                          padding: '0px 13px'
                        }}>Selecciona un video</div>
                        <hr style={{width: '100%'}} />
                          <VideoSelect
                            video={videoSelected}
                            open={videoSelectModal}
                            site={sites.defaultSite.id}
                            onClose={() => setVideoSelectModal(false)}
                            onChange={(data, index) => onVideoSelected(data)}
                          />
                          {
                            !videoSelected ? (
                              <button
                                className="button"
                                onClick={onSelectVideo}
                                style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: 160 }}
                              >
                                Seleccionar video
                              </button>
                            ) : null
                          }
                      </FormGroup>
                    )
                  }
                </>
              )
            }
            <FormGroup>
              <div style={{ textAlign: 'left', marginBottom: 20 }} className="field">
                <TextField 
                  type="text"
                  id="name"
                  value={title}
                  disabled={loading}
                  label="Ingresa el título de la lección"
                  aria-describedby="nameHelp"
                  onChange={(e) => setTitle(e.target.value)}
                  variant="outlined"
                  classes={{root:classes.textFieldRoot}}
                />
              </div>
            </FormGroup>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={{
                fontSize: 13,
                color: 'rgba(0, 0, 0, 0.54)',
                fontFamily: 'Montserrat',
                marginBottom: -8,
                position: 'relative',
                zIndex: 1,
                padding: '0px 13px'
              }}>Ingresa la lección</div>
              <CKEditor
                data={editorState}
                disabled={loading}
                config={{
                  placeholder: 'Ingresa la lección aqui',
                  toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'],
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    ]
                  }
                }}
                editor={ClassicEditor}
                onChange={(event, editor) => setEditorState(editor.getData())}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 26 }}>
              <FormLabel classes={{ root: classes.formLabelRoot }} component="legend">Comentarios</FormLabel>
              <RadioGroup classes={{ root: classes.radioGroupRoot }} onChange={(ev) => setAllowComments(ev.target.value)} value={allowComments} name="comments">
                <FormControlLabel
                  classes={{ root: classes.formControlLabelRoot }}
                  value="true"
                  label="Habilitado"
                  control={
                    <Radio classes={{ root: classes.radioRoot }} />
                  }
                />
                <FormControlLabel
                  classes={{ root: classes.formControlLabelRoot }}
                  value="false"
                  label="Deshabilitados"
                  control={
                    <Radio classes={{ root: classes.radioRoot }} />
                  }
                />
              </RadioGroup>
            </FormGroup>
            <FormGroup style={{ marginTop: 12 }}>
              <FormLabel classes={{ root: classes.formLabelRoot }} component="legend">Estatus</FormLabel>
              <RadioGroup classes={{ root: classes.radioGroupRoot }} onChange={(ev) => setStatus(ev.target.value)} value={status} name="status">
                <FormControlLabel
                  classes={{ root: classes.formControlLabelRoot }}
                  value={"Publicado"}
                  control={
                    <Radio classes={{ root: classes.radioRoot }} />
                  }
                  label="Publicado"
                />
                <FormControlLabel
                  classes={{ root: classes.formControlLabelRoot }}
                  value={"Borrador"}
                  control={
                    <Radio classes={{ root: classes.radioRoot }} />
                  }
                  label="Borrador"
                />
              </RadioGroup>
            </FormGroup>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', columnGap: 30, marginTop: 48 }}>
              <button
                onClick={onClose}
                style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: 160 }}
                className="button is-secondary"
              >
                Cancelar
              </button>
              <button
                disabled={loading}
                onClick={(ev) => action(ev)}
                style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: 160 }}
                className="button is-light"
              >
                {
                  loading ? (
                    <CircularProgress size={20} style={{ color: '#00A8D8' }} />
                  ) : (
                    <>
                    {id ? 'Guardar' : 'Crear'}
                    </>
                  )
                }
              </button>
            </div>
          </Grid>
          <Grid style={{ padding: '20px 24px', borderLeft: '#e4e4e4 solid 1.6px' }} item xs={3}>
            <FormGroup>
              <FormLabel classes={{ root: classes.formLabelRoot }}>Carga adjuntos</FormLabel>
              <ImageUploader
                preview={false}
                files={attachments}
                onChange={setFilesUpload}
                loading={loadingAttachment}
                progress={progressAttachment}
                placeholder="Selecciona o arrastra"
                accept=".doc,.docx,.ppt,.pptx,.txt,.zip,.xls,.xlsx,.mp3,.epub,.csv,.pdf"
              />
              {!attachmentsList.length && 'Sin adjuntos'}
              {attachmentsList && attachmentsList.map((item, index) => {
                return (
                  <Attachment
                    key={`Attachment-${index}`}
                    name={item.name}
                    onClick={() => onDeleteAttachment(item.id, index)}
                  />
                )
              })}
            </FormGroup>
            <CheckInstructors onChange={id ? null : onChangeInstructors} site={site} course={course} content={id} />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ sites, courses, modules, submodules, content }) => {
  return { sites, courses, modules, submodules, content };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ postContent, updateContent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContent);
