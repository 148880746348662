import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import {
  TextField,
  makeStyles,
  Chip,
  CircularProgress
} from '@material-ui/core'
import {
  Title,
  SubTitle,
  Header,
  Content,
  Container,
  FormGroup
} from '../../../components'
import { Select as CustomSelect } from '../StyledComponents'
// import { fetchAllCourses } from '../../../redux/actions/courses'
import { putForm, fetchForm } from '../../../redux/actions/forms'
import { fetchAllEmailTemplates } from '../../../redux/actions/emailTemplates'
import { makeToast } from '../../../utils'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
}))

function Actions (props) {
  const classes = useStyles()
  const { form } = useParams()
  const [emails, setEmails] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [action, setAction] = useState(null)
  const [courses, setCourses] = useState([])
  const [subject, setSubject] = useState('')
  const [summary, setSummary] = useState('')
  const [templateId, setTemplateId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [posting, setPosting] = useState(false)
  const [loadingEmails, setLoadingEmails] = useState(false)
  const [coursesSelected, setCoursesSelected] = useState([])
  const [renderCKEditor, setRenderCKEditor] = useState(false)

  const preload = async () => {
    try {
      if (
        props.forms.form &&
        props.forms.form.action
      ) {
        const action = props.forms.form.action
        console.log('console', action)
        if (action) {
          setAction(action.key)
          switch (action.key) {
            case 'send_mail':
            await loadEmails()
              setTemplateId(props.forms.form.templateId)
              setSubject(action.extras.subject)
              setSummary(action.extras.summary)
              break
            case 'asignar_curso':
              // const data = await fetchAllCourses(props.sites.defaultSite.id)
              // setCourses(data)
              setCoursesSelected(action.extras)
              break
            default:
              break
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const loadEmails = async () => {
    await setLoadingEmails(true)
    const data = await props.fetchAllEmailTemplates(props.sites.defaultSite.id)
    await setEmails(data)
    await setLoadingEmails(false)
  }

  useEffect(() => {
    switch (action) {
      case 'send_mail':
        loadEmails()
      break
      default:
        break
    }
  }, [action])

  useEffect(() => {
    preload()
    setRenderCKEditor(true)
  }, [])

  const handleChange = event => {
    setCoursesSelected(event.target.value)
  }

  const renderChips = selected => {
    return (
      <div className={classes.chips}>
        {selected.map((item, index) => {
          const courseIndex = courses.findIndex(course => course.id === item)
          return (
            <Chip
              key={`Chip-${index}`}
              className={classes.chip}
              label={courses[courseIndex].title}
            />
          )
        })}
      </div>
    )
  }

  const validate = () => {
    if (!action) return { success: false, error: 'Selecciona una acción' }
    return { success: true }
  }

  const send_mail_post = async () => {
    const body = {
      templateId,
      action: {
        key: action,
        extras: {
          subject,
        }
      }
    }
    await props.putForm(props.sites.defaultSite.id, form, body)
    await props.fetchForm(props.sites.defaultSite.id, form)
    makeToast(enqueueSnackbar, 'Actualizado')
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = validate()
    if (isValid.success) {
      if (form) {
        await setPosting(true)
        try {
          switch (action) {
            case 'send_mail':
              send_mail_post()
              break
            case 'asignar_curso':
              // asign_course()
              break
            default:
              break
          }
        } catch (error) {
          console.log('error', error)
          makeToast(enqueueSnackbar, 'Ocurrio un error', 'error')
        } finally {
          await setPosting(false)
        }
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const renderForm = () => {
    switch (action) {
      case 'send_mail':
        return (
          <>
            <FormGroup style={{ marginBottom: 10 }}>
              <TextField
                type='text'
                value={subject}
                label='Subject'
                disabled={loading}
                variant='outlined'
                aria-describedby='subjectHelp'
                onChange={e => setSubject(e.target.value)}
                classes={{ root: classes.textFieldRoot }}
              />
            </FormGroup>

            <label>Email</label>
            <FormGroup style={{ marginBottom: 10 }}>
              <CustomSelect
                value={templateId}
                disabled={loading}
                style={{
                  height: 56,
                  borderRadius: 14,
                  backgroundColor: 'white',
                  border: 'rgb(232, 232, 232) 1px solid'
                }}
                onChange={ev => setTemplateId(ev.target.value)}
              >
                <option selected='selected'>Ninguna</option>
                {emails.map(
                  (item, index) => (
                    <option
                      key={`options-${index}`}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  )
                )}
              </CustomSelect>
            </FormGroup>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Container>
        <Header>
          <Title>Fomulario completado</Title>
          <SubTitle>
            La siguiente acción se ejecutará cuando el usuario llene el
            formulario
          </SubTitle>
        </Header>
        {!loading ? (
          <Content>
            <FormGroup>
              <label>Acción</label>
              <CustomSelect
                value={action}
                disabled={loading}
                style={{
                  height: 56,
                  borderRadius: 14,
                  backgroundColor: 'white',
                  border: 'rgb(232, 232, 232) 1px solid'
                }}
                onChange={ev => setAction(ev.target.value)}
              >
                <option selected='selected'>Ninguna</option>
                {[{ title: 'Enviar correo', key: 'send_mail' }].map(
                  (item, index) => (
                    <option
                      key={`options-${index}`}
                      value={item.key}
                      selected={item.key === action && 'selected'}
                    >
                      {item.title}
                    </option>
                  )
                )}
              </CustomSelect>
            </FormGroup>

            {renderForm()}

            <button
              disabled={loadingEmails || loading || posting}
              onClick={handleSubmit}
              className='button is-light'
              style={{
                fontSize: 16,
                borderRadius: 8,
                padding: '20px 20px',
                alignSelf: 'flex-end'
              }}
            >
              {loadingEmails || loading || posting ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Guardar'}
            </button>
          </Content>
        ) :  (
          <Content>
            <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
          </Content>
        )}
      </Container>
    </>
  )
}

Actions.propTypes = {}

const mapStateToProps = ({ forms, sites }) => {
  return { forms, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ putForm, fetchForm, fetchAllEmailTemplates }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Actions)
