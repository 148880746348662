import React from 'react'
import PropTypes from 'prop-types'
import CountUp from 'react-countup'
import { CircularProgress } from '@material-ui/core';
import './index.scss';
export function Counter(props) {

  const { img, label, number, loading = false, format } = props;

  return (
    <div className="counter">
      <div className='data'>
        {
          loading ? (
            <CircularProgress style={{ color: '#00A8D8' }} />
          ) : (
            <>
              <b>{format}<CountUp start={0} end={number} /></b>
              <p>{label}</p>
            </>
          )
        }
      </div>
      <img src={img} />
    </div>
  )
}

Counter.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
}
