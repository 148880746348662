import {
  FETCHING_USERS,
  FAIL_FETCH_USERS,
  SUCCESS_FETCH_USERS,
  FETCHING_USER,
  FAIL_FETCH_USER,
  SUCCESS_FETCH_USER,
  DELETE_USER,
  DELETE_FAIL_USER,
  DELETE_SUCCESS_USER
} from '../actions/users'

const initialState = {
  data: [],
  user: null,
  error: null,
  loading: false,
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_USERS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_USERS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case FAIL_FETCH_USERS:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
    case FETCHING_USER:
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_USER:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case FAIL_FETCH_USER:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FAIL_USER:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SUCCESS_USER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state
  }
};
