import {
  FETCH_SITES,
  FAIL_FETCH_SITES,
  SUCCESS_FETCH_SITES,
  SUCCESS_FETCH_SITES_TABLE,
  SELECT_SITE,
  FETCHING_SITE,
  FAIL_FETCHING_SITE,
  SUCCESS_FETCHING_SITE,
  MENU_COLLAPSE,
} from '../actions/sites'

const initialState = {
  data: [],
  table: [],
  site: null,
  error: null,
  loading: false,
  collapse: false,
  site: {},
  defaultSite: {},
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SITES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FAIL_FETCH_SITES:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SUCCESS_FETCH_SITES:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
        collapse: action.collapse,
        defaultSite: action.default,
      };
    case SUCCESS_FETCH_SITES_TABLE:
      return {
        ...state,
        error: null,
        loading: false,
        ...action.data,
      };
    case SELECT_SITE:
      return {
        ...state,
        error: null,
        loading: false,
        defaultSite: action.default,
      };
    case MENU_COLLAPSE:
      return {
        ...state,
        collapse: action.default,
      };
    case FETCHING_SITE:
      return {
        ...state,
        site: {},
        error: null,
        loading: true,
      };
    case SUCCESS_FETCHING_SITE:
      return {
        ...state,
        loading: false,
        error: action.error,  
        defaultSite: action.data,
      };
    case FAIL_FETCHING_SITE:
      return {
        ...state,
        site: {},
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
