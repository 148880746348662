import {
  FETCHING_MODULES,
  FAIL_FETCH_MODULES,
  SUCCESS_FETCH_MODULES,
  PUT_MODULE,
  PUT_FAIL_MODULE,
  PUT_SUCCESS_MODULE,
  POST_MODULE,
  POST_FAIL_MODULE,
  POST_SUCCESS_MODULE,
  UPDATE_SORT,
  DELETE_MODULE,
  DELETE_FAIL_MODULE,
  DELETE_SUCCESS_MODULE,
  UPDATE_MODULE,
  UPDATE_FAIL_MODULE,
  UPDATE_SUCCESS_MODULE,
} from '../actions/modules'

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_MODULES:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_FETCH_MODULES:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case FAIL_FETCH_MODULES:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PUT_MODULE:
      return {
        ...state,
        loading: true,
      };
    case PUT_FAIL_MODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PUT_SUCCESS_MODULE:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case POST_MODULE:
      return {
        ...state,
        loading: true,
      };
    case POST_FAIL_MODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case POST_SUCCESS_MODULE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SORT:
      return {
        ...state,
        data: action.data,
      };
    case DELETE_MODULE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FAIL_MODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SUCCESS_MODULE:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MODULE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FAIL_MODULE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_SUCCESS_MODULE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state
  }
};
