import React from 'react';
import Hero from './Hero';
import HomeContent from './HomeContent';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <div className="box cta">
        <h1>Tu propia academia digital</h1>
        <p className="has-text-centered">
          Se parte de la plataforma de conocimiento más grande de latinoamérica.
        </p>
      </div>
      <HomeContent />
      <Footer />
    </>
  )
}
