import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { Close } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import {
  FormControl,
  FormGroup,
  CircularProgress,
  IconButton,
  makeStyles,
  TextField
} from '@material-ui/core'
import {
  fetchSite,
  postSite,
  putSite
} from '../../../redux/actions/sites'
import { makeToast, filterChanges } from '../../../utils'
import { TextArea } from '../StyledComponents'

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
})

function UserCreate (props) {
  const { sitioId } = props

  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [data, setData] = useState({})
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [fetching, setFetching] = useState(false);

  const validate = () => {
    if (!title || title.trim() === '')
      return { success: false, error: 'Título requerido' }
    if (!description || description.trim() === '')
      return { success: false, error: 'Descripción requerida' }
    return { success: true }
  }

  const onAction = (ev, body) => {
    ev && ev.preventDefault()
    if (sitioId) {
      onUpdateSite(body)
    } else {
      onSaveSite(body)
    }
  }

  const onSaveSite = async body => {
    const isValid = validate()
    if (isValid.success) {
      await setFetching(true)
      const data = {
        title,
        description,
      }
      const response = await props.postSite(data)
      makeToast(enqueueSnackbar, 'Se creo el sitio')
      props && await props.onCloseModal(response)
      await setFetching(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const onUpdateSite = async body => {
    const isValid = validate()
    if (isValid.success) {
      await setFetching(true)
      const newData = {
        title,
        description,
      }
      const newBody = filterChanges(newData, data)
      const response = await props.putSite(sitioId, newBody)
      makeToast(enqueueSnackbar, 'Se actualizo el sitio')
      props.onCloseModal && await props.onCloseModal(response)
      await setFetching(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const loadSite = async () => {
    try {
      if (sitioId) {
        setLoading(true)
        setFetching(true)
        const response = await props.fetchSite(sitioId)
        await setData(response);
        await setTitle(response.title)
        await setDescription(response.description)
      }
    } catch (error) {
      console.log('error', error, sitioId)
    }
    setLoading(false)
    setFetching(false)
  }

  useEffect(() => {
    loadSite()
  }, [])

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>
          {sitioId ? 'Editar' : 'Crear'} sitio
        </div>
        <IconButton onClick={() => props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        {loading ? (
          <>
            <Skeleton
              variant='text'
              animation='pulse'
              height={70}
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              variant='rect'
              animation='pulse'
              height={100}
              style={{ marginBottom: 15 }}
            />
          </>
        ) : (
          <FormControl style={{ width: '100%' }} onSubmit={e => onAction(e)}>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={styles.field} className='field'>
                <TextField
                  type='text'
                  value={title}
                  disabled={loading}
                  variant='outlined'
                  aria-describedby='titleHelp'
                  onChange={e => setTitle(e.target.value)}
                  label='Ingresa el título'
                  classes={{ root: classes.textFieldRoot }}
                />
              </div>
            </FormGroup>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={styles.field} className='field'>
                <div
                  style={{
                    fontSize: 12,
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontFamily: 'Montserrat',
                    job: 'relative',
                    zIndex: 1,
                    padding: '0px 13px'
                  }}
                >
                  Ingresa la descripción
                </div>
                <TextArea
                  value={description}
                  disabled={loading}
                  aria-describedby='descriptionHelp'
                  onChange={e => setDescription(e.target.value)}
                  style={{ height: 200, borderRadius: 14 }}
                  placeholder='Ingresa la descripción'
                />
              </div>
            </FormGroup>
          </FormControl>
        )}
      </div>
      <div style={styles.actions}>
        <button
          disabled={fetching}
          onClick={e => onAction(e)}
          style={styles.actionButton}
          className='button is-light'
        >
          {fetching ? (
            <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
          ) : sitioId ? (
            'Editar'
          ) : (
            'Crear'
          )}
        </button>
        <button
          onClick={() => props.onCloseModal()}
          style={styles.actionButton}
          className='button is-secondary'
        >
          Cancelar
        </button>
      </div>
    </div>
  )
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0
  },
  title: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  }
}

const mapStateToProps = ({ sites, categories }) => {
  return { sites, categories }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { fetchSite, postSite, putSite },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
