import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../../utils'
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { AddCircle, Videocam, Image } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import { ExpandMore, Edit, Delete, DragIndicator } from '@material-ui/icons';
import { Button, Grid, Accordion, AccordionSummary, AccordionActions, AccordionDetails, Typography, makeStyles, CircularProgress, Tooltip } from '@material-ui/core';
import { fetchSubModules, updateSubModule, deleteSubModule } from '../../../../redux/actions/submodules';
import { postContent, fetchContent } from '../../../../redux/actions/content';
import AddSubModule from './AddSubModule';
import AddContent from './AddContent';
import { Alert } from '../../Alert';
import ListContent from './ListContent'

const useStyles = makeStyles({
  accordionRoot: {
    boxShadow: 'none',
    borderRadius: '6px !important',
    transition: 'background-color 0.4s',
    backgroundColor: 'white',
    '&.Mui-expanded': {
      backgroundColor: '#cae7ff'
    },
    '&:before': {
      visibility: 'hidden'
    }
  },
  accordionSummaryRoot: {
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
      '&.Mui-expanded': {
        transform: 'rotate(0deg)'
      },
      '& span svg': {
        fill: '#024976'
      }
    },
    '&:hover .MuiAccordionSummary-content div div:last-child': {
      visibility: 'visible !important'
    },
    '& .MuiAccordionSummary-content div div:last-child': {
      visibility: 'hidden'
    }
  },
  typographySubtitle1Root: {
    color: '#024976',
    margin: '0px !important',
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontWeight: 200,
    paddingLeft: 10
  },
  accordionActionsRoot: {
    padding: '0px 50px 8px'
  },
  accordionDetailsRoot: {
    paddingLeft: 50,
    paddingRight: 12
  }
});

const styles = {
  submitButton: {
    borderRadius: 8,
    backgroundColor: '#00ace7', 
    marginBottom: 0, 
    fontSize: 14,
    textTransform: 'none',
    padding: '18px 20px'
  },
  itemAction: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    borderRadius: 5,
    backgroundColor: '#00ACE6',
  },
  iconAction: {
    color: '#fff',
    fontSize: 16,
  },
}

export const SubModuleItem = ({ sites, id, title, parentModule, description, content, fetchSubModules, updateSubModule, deleteSubModule, fetchContent, postContent }) => {
  const classes = useStyles();
  const { course } = useParams();
  const [menu, setMenu] = useState(null)
  const [type, setType] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [deleting, setDeleting] = useState(false)
  const [expanded, setExpanded] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [modalEdit, setModalEdit] = useState(false);
  const [subLoading, setSubLoading] = useState(true)
  const [modalSubModule, setModalSubModule] = useState(false);

  const onChange = async () => {
    await setExpanded((prev) => (!prev));
  }

  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const onDeleteModule = (id) => {
    Alert({
      onSuccess: async () => {
        try {
          await setDeleting(true)
          await deleteSubModule(sites.defaultSite.id, course, id);
          makeToast(enqueueSnackbar, 'SubMódulo eliminado')
          await fetchSubModules(sites.defaultSite.id, course, parentModule);
        } catch (error) {
          console.log(error)
        } finally {
          await setDeleting(false)
        }
      },
      onFail: () => console.log('cancel'),
    });
  }

  const onUpdateModule = async (id, data) => {
    await updateSubModule(sites.defaultSite.id, course, id, data);
    await setModalSubModule(false);
    makeToast(enqueueSnackbar, 'SubMódulo actualizado')
    await fetchSubModules(sites.defaultSite.id, course, parentModule);
  }

  const onSaveContent = async (response) => {
    await fetchContent(sites.defaultSite.id, course, id)
    setContentId(response.id) 
  }

  const renderContent = () => {
    if (expanded) {
      return (
        <ListContent status={subLoading} loaded={() => setSubLoading(false)} parentModule={id} />
      );
    }
    return null;
  }

  // const createContent = async () => {
  //   let title = 'Lección Nueva'
  //   let status = 'Borrador'
  //   try {
  //     await setLoading(true);
  //     const last = Math.max(...content.data[id].map(item => item.order))
  //     const res = await postContent(sites.defaultSite.id, course, id, {
  //       title,
  //       status,
  //       content: {
  //         description: '<p>Ingresa la lección</p>',
  //       },
  //       allowComments: 'true',
  //       order: last + 1,
  //     });
  //     await onSaveContent(res);
  //     setContentId(res.id);
  //     // setModalEdit(true)
  //   } catch (error) {
  //     console.log('error', error);
  //     makeToast(enqueueSnackbar, error, 'error')
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const createContentType = (type) => {
    handleClose()
    setType(type)
    setModalEdit(true)
  }

  return (
    <>
      <Accordion
       classes={{root:classes.accordionRoot}}
       expanded={expanded} onChange={onChange}>
        <AccordionSummary
          classes={{root:classes.accordionSummaryRoot}}
          id="panel1a-header"
          aria-controls="panel1a-content"
          expandIcon={<ExpandMore />}
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DragIndicator style={{fill: '#024976', cursor: 'pointer'}}/>
              <Typography classes={{root:classes.typographySubtitle1Root}} variant="subtitle1">
                {title}
              </Typography>
              {
                deleting && (
                  <div 
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0px 10px'
                    }}
                  >
                    <CircularProgress size={20} style={{ color: '#00A8D8' }} />
                  </div>
                )
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 16, columnGap: 8 }}>
              <Tooltip title="Editar" aria-label="Edit">
                <div style={styles.itemAction}  onClick={(ev) => {
                  ev.stopPropagation();
                  setModalSubModule(true);
                }}>
                  <Edit style={styles.iconAction} />
                </div>
              </Tooltip>
              <Tooltip title="Eliminar" aria-label="Delete">
                <div style={styles.itemAction}  onClick={(ev) => {
                  ev.stopPropagation();
                  onDeleteModule(id);
                }}>
                  <Delete style={styles.iconAction}/>
                </div>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>
        <AccordionActions classes={{root: classes.accordionActionsRoot}}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button aria-controls="simple-menu" aria-haspopup="true" style={{backgroundColor: '#004976'}} onClick={handleClick}>
              <AddCircle style={{fill: '#fff'}} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={menu}
              keepMounted
              open={Boolean(menu)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => createContentType("video")}><Videocam style={{fill: '#00C18A', marginRight: 10}} />Agregar en video</MenuItem>
              <MenuItem onClick={() => createContentType("image")}><Image style={{fill: '#00C18A', marginRight: 10}} />Agregar con imágen</MenuItem>
            </Menu>
          </Grid>
        </AccordionActions>
        <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
          <Grid container>
            {subLoading && (
              <div style={{width: '100%', height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress size={20} style={{ color: '#00A8D8' }} />
              </div>
            )}
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {
        modalSubModule &&
        <AddSubModule
          id={id}
          course={course}
          show={modalSubModule}
          site={sites.defaultSite.id}
          onUpdate={onUpdateModule}
          parentModule={parentModule}
          onClose={() => setModalSubModule(false)}
        />
      }
      {
        modalEdit &&
        <AddContent
          module={id}
          type={type}
          id={contentId}
          course={course}
          open={modalEdit}
          onSave={onSaveContent}
          onUpdate={onSaveContent}
          site={sites.defaultSite.id}
          onClose={() => setModalEdit(false)}
        />
      }
    </>
  );
}

const mapStateToProps = ({ sites, courses, modules, submodules, content }) => {
  return { sites, courses, modules, submodules, content };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchSubModules, updateSubModule, deleteSubModule, fetchContent, postContent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubModuleItem);