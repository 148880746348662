import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Videocam, Delete } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import './ImageUploader.scss'

const duration = 0

const ImageUploader = ({ loading, saving, uploading, preparing, progress, placeholder, showIcon, dropContainerStyle, onChange, previousVideo, preview = true, multiple = false, accept = 'video/*' }) => {

  const styles = {
    thumbsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16
    },
    dropContainer: {
      padding: 10,
      borderWidth: 1,
      borderColor: '#555',
      borderRadius: 6,
      backgroundColor: 'rgb(250, 250, 250)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    thumb: {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: '100%',
      height: 'auto',
      maxHeight: 300,
      padding: 4,
      boxSizing: 'border-box'
    },
    thumbInner: {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    },
    imageContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: '#F9F9F9',
      display: 'flex',
      padding: '20px 35.5px 20px 35.5px'
    },
    img: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    video: {
      display: 'block',
      width: '100%',
      height: 250,
      objectFit: 'contain',
    },
    placeholder: {
      margin: 0,
      fontFamily: 'Montserrat',
      fontSize: 14,
      color: '#495057',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 6
    }
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    multiple,
    maxFiles: 0,
    onDrop: acceptedFiles => onChange(acceptedFiles, duration),
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const renderThumbs = () => {
    return acceptedFiles.map((file, index) => (
      <div key={`thumbs-${index}`} style={styles.thumb}>
        <div style={{...styles.thumbInner, ...styles.imageContainer}}>
          <img style={styles.img} src={URL.createObjectURL(file)} alt="" />
        </div>
      </div>
    ));
  };

  const renderPreview = () => {

    if (preparing) {
      return (
        <div style={styles.imageContainer}>
          <div style={{display: 'flex', flex: 1, backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <CircularProgress  style={{ color: '#00A8D8' }} className="imageUploader-loader-progress"/>
            </div>
            <div style={{padding: 10, display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <div>Preparando para subir el video</div>
              <div>{`${Math.round(progress)}%`}</div>
            </div>
          </div>
        </div>
      )
    }

    if (uploading) {
      return (
        <div style={styles.imageContainer}>
          <div style={{display: 'flex', flex: 1, backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <CircularProgress  style={{ color: '#00A8D8' }} className="imageUploader-loader-progress"/>
            </div>
            <div style={{padding: 10, display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <div style={{ fontFamily: 'Montserrat', fontSize: 14, color: 'rgb(73, 80, 87)'}}>
              El archivo se está subiendo {`${Math.round(progress)}%`}
            </div> 
            </div>
          </div>
        </div>
      )
    }

    if (saving) {
      return (
        <div style={styles.imageContainer}>
          <div style={{display: 'flex', flex: 1, backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <CircularProgress  style={{ color: '#00A8D8' }} className="imageUploader-loader-progress"/>
            </div>
            <div style={{padding: 10, display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
              <div>Guardando en la libreria</div>
            </div>
          </div>
        </div>
      )
    }

    return acceptedFiles.map((file, index) => (
      <div style={styles.imageContainer} key={`preview-${index}`}>
        <div style={{display: 'flex', flex: 1, backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Videocam style={{fill: 'rgba(0, 0, 0, 0.54)'}} />
          </div>
          <div style={{padding: 10, display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
            <div>{file.name}</div>
            <div>{formatBytes (file.size)}</div>
          </div>
          <div style={{padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Delete style={{fill: 'rgba(0, 0, 0, 0.54)'}} />
          </div>
        </div>
      </div>
    ));
  };

  const renderPlaceholder = () => {
    return  (
      <div style={styles.placeholder}>
        { showIcon && (<Videocam style={{fill: '#00ace7'}} />)}
        { placeholder }
      </div>
    );
  };

  const renderPreviousVideo = () => {
    return  (
      <div style={styles.imageContainer} key={`previous-image`}>
        <video controls style={styles.video} src={URL.createObjectURL(previousVideo)} alt="" />
      </div>
    );
  };

  if (loading) {
    return (
      <div className="imageUploader-loader-container">
        <CircularProgress  style={{ color: '#00A8D8' }} className="imageUploader-loader-progress"/>
        <div className="imageUploader-loader-text">
          Tu adjunto se está cargando {`${Math.round(progress)}%`}
        </div> 
      </div>
    )
  } 

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}
        style={{...styles.dropContainer, ...dropContainerStyle}}>
        <input {...getInputProps()} />
          { 
            (multiple === false && preview) ?
                acceptedFiles.length > 0 ? 
                  renderPreview()
                :
                previousVideo ? 
                  renderPreviousVideo()
                :
                  renderPlaceholder()
            :
            renderPlaceholder()
          }
      </div>
      <div style={styles.thumbsContainer}>
        {multiple && preview && renderThumbs()}
      </div>
    </div>
  );
}

export default ImageUploader;