import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { AddCircle, MoreVert } from '@material-ui/icons';
import { IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { fetchDomains, deleteDomains } from "../../../redux/actions/domains";
import { makeToast } from '../../../utils'
import FullDataTable from "../CoursesMain/FullDataTable";
import Create from "./Create";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.5) !important',
    }
  }
}));

const MenuList = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        className={classes.root}
      >
        <MenuItem onClick={async (ev) => {
          await props.onEdit(ev)
          handleClose(ev)
        }}>Ver</MenuItem>
        <MenuItem
          style={{ color: 'red' }}
          onClick={async (ev) => {
            await props.onDelete(ev)
            handleClose(ev)
          }}>Eliminar</MenuItem>
      </Menu>
    </>
  )
}

class UsersList extends React.Component {

  state = {
    edit: false,
    createModalVisible: false,
  };

  toggleCreateModal = async () => {
    await this.setState((prev) => ({ createModalVisible: !prev.createModalVisible }));
  }

  toggleEditModal = async () => {
    await this.setState((prev) => ({ editModal: !prev.editModal }));
  }

  close = async () => {
    await this.setState({ edit: false });
  }

  handleEditSite = (id) => {
    this.setState({
      edit: id,
      createModalVisible: false,
    });
  }

  onRowsDelete = async (rows) => {
    try {
      await this.props.deleteDomains(rows);
      makeToast(this.props.enqueueSnackbar, 'Se han eliminado los elementos')
      const { domains: { limit, offset } } = this.props;
      this.props.fetchDomains(this.props.sites.defaultSite.id, limit, offset);
    } catch (error) {
      console.log('error', error)
      makeToast(this.props.enqueueSnackbar, error, 'error')
    }
  }

  onEdit = (id) => {
    this.setState({ edit: id, createModalVisible: true })
  }

  onDelete = () => {

  }

  onCloseModal = () => {
    this.setState({ createModalVisible: false, edit: null })
  }

  render() {
    const { edit, createModalVisible } = this.state;
    const { domains: { table, loading, limit, offset, count, sort } } = this.props;

    const columns = [
      {
        label: 'Dominio',
        name: 'name',
      },
      {
        label: 'Estatus',
        name: 'status',
      },
      {
        label: 'Fecha de creación',
        name: 'createdAt',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return (
                <div>
                  {moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}
                </div>
              )
            }
          }
        }
      },
      {
        label: "Acciones",
        name: 'id',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return (
                <MenuList
                  value={value}
                  onEdit={async () => await this.onEdit(value)}
                  onDelete={async () => await this.onDelete([value])}
                />
              );
            }
          }
        }
      }
    ];

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={styles.content} className="content">
          <div style={styles.tableContainer}>
            <FullDataTable
              data={table}
              title={'Dominios'}
              loading={loading}
              columns={columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              onRowPress={(rowData, meta, rowIndex) => {
                // this.handleEditSite(table[meta.dataIndex].id);
              }}
              onRowsDelete={this.onRowsDelete}
              preload={() => this.props.fetchDomains(this.props.sites.defaultSite.id, limit, offset)}
              changePageSize={({ rowsPerPage }) => this.props.fetchDomains(this.props.sites.defaultSite.id, rowsPerPage, offset)}
              search={({ rowsPerPage, page, sortOrder, searchText, columns }) => {
                if (searchText && searchText !== '') {
                  const names = columns.map(item => (item.name));
                  this.props.fetchDomains(this.props.sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
                } else {
                  this.props.fetchDomains(this.props.sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
                }
              }}
              changePage={({ rowsPerPage, page, sortOrder }) => {
                this.props.fetchDomains(this.props.sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
              }}
              sort={({ rowsPerPage, page, sortOrder }) => {
                this.props.fetchDomains(this.props.sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
              }}
              renderCreate={() => (
                <div style={styles.actions}>
                  <button style={styles.actionButton} onClick={this.toggleCreateModal} className="button is-light">
                    <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} /> Asociar Dominio
                </button>
                </div>
              )}
            />
          </div>
        </section>
        {
          createModalVisible && (
            <Create open={createModalVisible} edit={edit} onCloseModal={this.onCloseModal} />
          )
        }
      </div>
    );
  }
};

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  subtitle: {
    fontSize: 18,
    color: '#7d8595',
    fontFamily: 'Montserrat',
    textAlign: 'left'
  },
  siteCount: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const mapStateToProps = ({ domains, sites }) => {
  return { domains, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchDomains, deleteDomains }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(UsersList)));