import React from 'react'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import promise from 'redux-promise-middleware'
import { createStore, applyMiddleware } from 'redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import rootReducer from './redux/index'
import Routing from './admin/Routing'
import './App.css'

library.add(faEdit)
const store = createStore(rootReducer, applyMiddleware(thunk, promise))

function App () {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <Routing />
      </SnackbarProvider>
    </Provider>
  )
}

export default App
