import React from 'react'

export default function HomeContent() {
  return (
    <section className="container">
        <div className="columns features">
            <div className="column is-4">
                <div className="img1" />
            </div>
            <div className="column is-4">
                <div className="img2" />
            </div>
            <div className="column is-4">
                <div className="img3" />
            </div>
        </div>
        <br/>
    </section>
  )
}
