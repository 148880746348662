import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { Close } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import {
  FormControl,
  FormGroup,
  CircularProgress,
  IconButton,
  makeStyles,
  FormControlLabel,
  Checkbox,
  TextField
} from '@material-ui/core'
import {
  fetchInstructor,
  postInstructor,
  putInstructor
} from '../../../redux/actions/instructors'
import { fetchCategories } from '../../../redux/actions/categories'
import ImageUploader from '../courses/components/ImageUploader'
import { makeToast, filterChanges } from '../../../utils'
import { uploadFile } from '../../../helpers/files'
import { TextArea, Label } from '../StyledComponents'

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
})

function UserCreate (props) {
  const { site, instructorId } = props

  const classes = useStyles()

  const [job, setJob] = useState('')
  const [name, setName] = useState('')
  const [data, setData] = useState({})
  const [email, setEmail] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [picture, setPicture] = useState(null)
  const [loading, setLoading] = useState(false)
  const [biography, setBiography] = useState('')
  const [fetching, setFetching] = useState(false);
  const [pictureUrl, setPictureUrl] = useState(null)
  const [by_default, setBy_default] = useState(false)

  const setImagePicture = async acceptedFiles => {
    try {
      makeToast(enqueueSnackbar, 'Cargando contenido', 'info')
      await setFetching(true)
      await setPicture(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      const specific = { courseId: null }
      const url = await uploadFile(site, acceptedFiles[0], specific)
      if (instructorId) await onAction(null, { picture: url })
      await setPictureUrl(url)
      await setFetching(false)
      makeToast(enqueueSnackbar, 'Contenido cargado')
    } catch (error) {
      makeToast(enqueueSnackbar, 'Error cargando contenido', 'error')
    }
  }

  const validate = () => {
    if (!email || email.trim() === '')
      return { success: false, error: 'Email requerido' }
    if (!name || name.trim() === '')
      return { success: false, error: 'Nombre requerido' }
    if (!job || job.trim() === '')
      return { success: false, error: 'Posición requerido' }
    if (!biography || biography.trim() === '')
      return { success: false, error: 'Biografia requerido' }
    if (!pictureUrl || pictureUrl.trim() === '')
      return { success: false, error: 'Imagen requerido' }
    return { success: true }
  }

  const onAction = (ev, body) => {
    ev && ev.preventDefault()
    if (instructorId) {
      onUpdateInstructor(body)
    } else {
      onSaveInstructor(body)
    }
  }

  const onSaveInstructor = async body => {
    const isValid = validate()
    if (isValid.success) {
      await setFetching(true)
      const data = {
        name,
        email,
        job,
        biography,
        by_default,
        picture: pictureUrl,
        ...body
      }
      const response = await props.postInstructor(site, data)
      makeToast(enqueueSnackbar, 'Se creo el instructor')
      props && props.onCloseModal(response)
      await setFetching(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const onUpdateInstructor = async body => {
    const isValid = validate()
    if (isValid.success) {
      await setFetching(true)
      const newData = {
        name,
        email,
        job,
        biography,
        by_default,
        picture: pictureUrl,
        ...body
      }
      const newBody = filterChanges(newData, data)
      const response = await props.putInstructor(site, instructorId, newBody)
      makeToast(enqueueSnackbar, 'Se actualizo el instructor')
      props.onCloseModal && props.onCloseModal(response)
      await setFetching(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const loadInstructor = async () => {
    if (instructorId) {
      setLoading(true)
      setFetching(true)
      const response = await props.fetchInstructor(site, instructorId)
      await setData(response);
      await setJob(response.job)
      await setName(response.name)
      await setEmail(response.email)
      await setPicture([response.picture])
      await setPictureUrl(response.picture)
      await setBiography(response.biography)
      await setBy_default(response.by_default)
    }
    setLoading(false)
    setFetching(false)
  }

  useEffect(() => {
    loadInstructor()
  }, [])

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>
          {instructorId ? 'Editar' : 'Crear'} instructor
        </div>
        <IconButton onClick={() => props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        {loading ? (
          <>
            <Skeleton
              variant='rect'
              animation='pulse'
              height={140}
              style={{ marginBottom: 20 }}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              height={70}
              style={{ marginBottom: 5 }}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              height={70}
              style={{ marginBottom: 15 }}
            />
            <Skeleton
              variant='rect'
              animation='pulse'
              height={100}
              style={{ marginBottom: 15 }}
            />
            <Skeleton
              variant='text'
              animation='pulse'
              height={40}
              width={100}
              style={{}}
            />
          </>
        ) : (
          <FormControl style={{ width: '100%' }} onSubmit={e => onAction(e)}>
            <FormGroup>
              <div
                style={{ textAlign: 'left', marginBottom: 20 }}
                className='field'
              >
              <Label style={styles.label}>Imagen de Instructor</Label>
                <ImageUploader
                  files={picture}
                  preview={true}
                  showIcon={true}
                  accept='image/*'
                  previousImage={pictureUrl}
                  onChange={setImagePicture}
                  dropContainerStyle={{ height: 140, textAlign: 'center' }}
                  placeholder='Haga clic aquí para subir una imagen'
                />
              </div>
            </FormGroup>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={styles.field} className='field'>
                <TextField
                  id='email'
                  type='email'
                  value={email}
                  disabled={loading}
                  variant='outlined'
                  aria-describedby='emailHelp'
                  onChange={e => setEmail(e.target.value)}
                  label='Ingresa el Email del Instructor'
                  classes={{ root: classes.textFieldRoot }}
                />
              </div>
            </FormGroup>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={styles.field} className='field'>
                <TextField
                  type='text'
                  id='name'
                  value={name}
                  disabled={loading}
                  variant='outlined'
                  aria-describedby='nameHelp'
                  onChange={e => setName(e.target.value)}
                  label='Ingresa el Nombre del Instructor'
                  classes={{ root: classes.textFieldRoot }}
                />
              </div>
            </FormGroup>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={styles.field} className='field'>
                <TextField
                  type='text'
                  id='job'
                  value={job}
                  variant='outlined'
                  disabled={loading}
                  aria-describedby='jobHelp'
                  classes={{ root: classes.textFieldRoot }}
                  label='Ingresa la Posición del Instructor'
                  onChange={e => setJob(e.target.value)}
                  style={{ height: 56, width: '100%', borderRadius: 14 }}
                />
              </div>
            </FormGroup>
            <FormGroup style={{ marginBottom: 10 }}>
              <div style={styles.field} className='field'>
                <div
                  style={{
                    fontSize: 12,
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontFamily: 'Montserrat',
                    job: 'relative',
                    zIndex: 1,
                    padding: '0px 13px'
                  }}
                >
                  Ingresa la Biografía del Instructor
                </div>
                <TextArea
                  value={biography}
                  disabled={loading}
                  aria-describedby='biographyHelp'
                  onChange={e => setBiography(e.target.value)}
                  style={{ height: 200, borderRadius: 14 }}
                  placeholder='Ingresa la Biografía del Instructor'
                />
              </div>
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                label='Default'
                control={
                  <Checkbox
                    color='primary'
                    checked={by_default}
                    onChange={e => setBy_default(e.target.checked)}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        )}
      </div>
      <div style={styles.actions}>
        <button
          disabled={fetching}
          onClick={e => onAction(e)}
          style={styles.actionButton}
          className='button is-light'
        >
          {fetching ? (
            <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
          ) : instructorId ? (
            'Editar'
          ) : (
            'Crear'
          )}
        </button>
        <button
          onClick={() => props.onCloseModal()}
          style={styles.actionButton}
          className='button is-secondary'
        >
          Cancelar
        </button>
      </div>
    </div>
  )
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0
  },
  title: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  }
}

const mapStateToProps = ({ sites, categories }) => {
  return { sites, categories }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { fetchCategories, fetchInstructor, postInstructor, putInstructor },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
