import React, { Component } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import {Button} from '../StyledComponents';
import styled from 'styled-components';

class ChangePasswordConfirmation extends Component {
  render() {
    return (
      <>
        <Navbar />
        <section style={styles.container} className="auth">
          <Box className="content">
            <h1 style={styles.title}>Cambiar Contraseña</h1>
            <p style={styles.subtitle}>Su contraseña ha sido actualizada exitosamente!</p>
            <hr style={{marginBottom: 0, marginTop: 0}} />
            <div style={{marginTop: 28}} className="field">
              <p style={{margin: 0}} className="control">
                <Button onClick={()=>window.location.href="/login"} style={{fontSize: 16, padding: 10, margin: 0}} backgroundColor="#1a9e75" color="#fff">Iniciar Sesión</Button>
              </p>
            </div>
          </Box>
        </section>
        <Footer />
      </>
    );
  }
}

const styles = {
  container: {
    width: '100%',
    padding: 30,
    minHeight: 'calc(100vh - 206.99px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f8fa'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 30,
    marginBottom: 10
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    color: '#959ca8',
    lineHeight: '28px',
    marginBottom: 20
  }
}

export const Box = styled.div`
  padding: 50px 65px;
  width: 570px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;

export default ChangePasswordConfirmation;