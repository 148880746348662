import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, session, ...rest }) => {
  const { isAuthenticated, isAuthenticating } = session;
  
  if (isAuthenticated === false && isAuthenticating === true) {
    return <></>
  }

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (!isAuthenticated) {
            return <Redirect to='/login' />;
          }
          return <Component {...props} />;
        }
      }
    />
  );
};

const mapStateToProps = ({ session }) => {
  return { session };
}

export default connect(mapStateToProps)(PrivateRoute);
