import { parseUrl } from "../../helpers/utils";
import network from "../../utils/network";

export const FETCH_DOMAINS = 'FETCH_DOMAINS';
export const FAIL_FETCH_DOMAINS = 'FAIL_FETCH_DOMAINS';
export const SUCCESS_FETCH_DOMAINS = 'SUCCESS_FETCH_DOMAINS';
export const SUCCESS_FETCH_DOMAINS_TABLE = 'SUCCESS_FETCH_DOMAINS_TABLE'

export const FETCHING_DOMAIN = 'FETCHING_DOMAIN';
export const FAIL_FETCHING_DOMAIN = 'FAIL_FETCHING_DOMAIN';
export const SUCCESS_FETCHING_DOMAIN = 'SUCCESS_FETCHING_DOMAIN';

export const POST_DOMAIN = "POST_DOMAIN"
export const POST_FAIL_DOMAIN = "POST_FAIL_DOMAIN"
export const POST_SUCCESS_DOMAIN = "POST_SUCCESS_DOMAIN"

export const PUT_DOMAIN = "PUT_DOMAIN"
export const PUT_FAIL_DOMAIN = "PUT_FAIL_DOMAIN"
export const PUT_SUCCESS_DOMAIN = "PUT_SUCCESS_DOMAIN"

export const DELETE_DOMAIN = "DELETE_DOMAIN"
export const DELETE_FAIL_DOMAIN = "DELETE_FAIL_DOMAIN"
export const DELETE_SUCCESS_DOMAIN = "DELETE_SUCCESS_DOMAIN"

export const fetchDomains = (siteId, limit, offset = null, column, direction, search, searchtext) => {
  const url = parseUrl(`site/${siteId}/domain?`, limit, offset, column, direction, search, searchtext)
  return async dispatch => {
    dispatch({ type: FETCH_DOMAINS });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_DOMAINS,
        });
      } else {
        const defaultData = localStorage.getItem('SITE_DEFAULT');
        let finded = response.data.data.find(item => item.id === defaultData);
        if (!finded) finded = response.data.data[0];
        dispatch({
          data: {
            limit,
            offset,
            search,
            searchtext,
            table: response.data.data,
            count: response.data.count,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_DOMAINS_TABLE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_DOMAINS,
      });
    }
  };
};

export const fetchDomain = (site, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_DOMAIN });
    try {
      const response = await network.get(`site/${site}/domain/${id}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCHING_DOMAIN,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCHING_DOMAIN,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCHING_DOMAIN,
      });
    }
  };
};

export const putDomain = (site, id, body) => {
  return async dispatch => {
    dispatch({ type: PUT_DOMAIN });
    try {
      const response = await network.put(`site/${site}/domain/${id}`, body);
      dispatch({
        data: response.data,
        type: PUT_SUCCESS_DOMAIN,
      });
    } catch (error) {
      dispatch({
        error,
        type: PUT_FAIL_DOMAIN,
      });
    }
  };
};

export const postDomain = (site, domain, facebookPixel) => {
  return async dispatch => {
    dispatch({ type: POST_DOMAIN });
    try {
      const response = await network.get(`site/${site}/domain/connect?domainName=${domain}&facebookPixel=${facebookPixel}`);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_DOMAIN,
      });
      return response.data
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_DOMAIN,
      });
    }
  };
};

export const deleteDomains = (site, ids = []) => {
  const promises = ids.map((item) => {
    return network.del(`site/${site}/domain/${item}`);
  });
  return async dispatch => {
    dispatch({ type: DELETE_DOMAIN });
    try {
      const response = await Promise.all(promises)
      dispatch({
        type: DELETE_SUCCESS_DOMAIN,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_DOMAIN,
      });
      throw error;
    }
  };
};