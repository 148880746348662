import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactSortable } from "react-sortablejs";
import { CircularProgress } from '@material-ui/core';
import { updateSortLocal, updateSort } from '../../../../redux/actions/modules';
import ModuleItem from './ModuleItem';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8
  }
}

const ListModules = (props) => {

  if (props.modules.loading) {
    return (
      <div style={styles.container}>
        <CircularProgress style={{ color: '#00A8D8' }} />
      </div>
    )
  }

  return (
    <ReactSortable
      tag="div"
      style={styles.container}
      list={props.modules.data}
      setList={(newArray) => props.updateSortLocal(newArray)}
      onEnd={() => props.updateSort(props.site, props.course, props.modules.data)}
    >
      {
        props.modules.data.map((item, index) => (
          <ModuleItem key={`module-item-${index}`} {...item} {...props} />
        ))
      }
    </ReactSortable>
  );
};

const mapStateToProps = ({ modules }) => {
  return { modules };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateSortLocal, updateSort }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListModules);