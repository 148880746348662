import React, { useEffect } from 'react';
import Bee from '@mailupinc/bee-plugin'
import Globals from '../../Globals'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { fetchAllForms } from "../../redux/actions/forms";
import reactModal from '@prezly/react-promise-modal';
import { Modal } from 'react-bootstrap';  
import { Select } from './StyledComponents';
import { ArrowDropDown } from '@material-ui/icons';
import PageBuilderTitle from './PageBuilderTitle'
import { fetchTemplates, fetchTemplate, postTemplate, putTemplate } from '../../redux/actions/templates';
import templatePage from './PageBuilderTemplate.json'

const PageBuilder = (props) => {

    const { sites, postTemplate } = props
    let forms = []

    const openModal = async () => {
      const result = await reactModal(({ show, onSubmit, onDismiss }) => (
        <Modal centered show={show} onHide={onDismiss}>
          <div style={{ padding: 30 }}>
            <div style={{ position: 'relative' }}>
              <Select
                value={-1}
                style={{ height: 56, borderRadius: 14, backgroundColor: 'white', border: 'rgb(232, 232, 232) 1px solid' }}
                onChange={(ev) => {
                  onSubmit(ev.target.value)
                }}
              >
                <option key={-1} value={-1}>
                  Selecciona un formulario
                </option>
                {
                  forms.map(form => (
                    <option key={form.id} value={form.id}>
                      {form.title}
                    </option>
                  ))
                }
              </Select>
              <ArrowDropDown style={{ fill: '#144974', position: 'absolute', top: 16, right: 16 }} />
            </div>
            <button 
              onClick={onDismiss}
              style={{marginTop: 24}}
              className="button is-secondary"
            >
              Cancelar
            </button>
          </div>
        </Modal>
      ));
    
      if (result === undefined) {    
          throw 'The modal was dismissed or cancelled'
      } else {
          return result
      }
    }

    const beeConfig = {
        uid: `${sites.defaultSite.id}`,
        container: 'bee-plugin-container',
        language: 'es-ES',
        onSave: async (jsonFile, htmlFile) => {         
          console.log(jsonFile, htmlFile)

          const result = await reactModal(({ show, onSubmit, onDismiss }) => (
            <PageBuilderTitle 
              show={show}
              onSubmit={onSubmit} 
              onDismiss={onDismiss} 
            />
          ));

          if (result === undefined) {    
              throw 'The modal was dismissed or cancelled'
          } else {
            try {
              const siteId = sites.defaultSite.id;
              const { data } = await postTemplate(siteId, { 
                name: result.title, 
                description: result.description,
                body: htmlFile,
                bee_json: jsonFile
              })
            } catch (error) {
              console.log('onSave Error', error);
            }
          }
        },
        onSaveAsTemplate: async (jsonFile) => {
          console.log('onSaveAsTemplate', jsonFile)

          const result = await reactModal(({ show, onSubmit, onDismiss }) => (
            <PageBuilderTitle 
              show={show}
              onSubmit={onSubmit} 
              onDismiss={onDismiss} 
            />
          ));

          if (result === undefined) {    
              throw 'The modal was dismissed or cancelled'
          } else {
            try {
              const siteId = sites.defaultSite.id;
              const { data } = await postTemplate(siteId, { 
                name: result.title, 
                description: result.description,
                bee_json: jsonFile
              })
            } catch (error) {
              console.log('onSave Error', error);
            }
          }

        },
        onSend: (htmlFile) => {
          console.log('onSend', htmlFile)
        },
        onError: (errorMessage) => {
          console.log('onError ', errorMessage)
        },
        advancedPermissions: {
          content: {
            form: {
              properties: {
               editField: {
                show: false
               }
              }
             }
          },
        },
        contentDialog: {
          manageForm: {
            label: 'Seleccionar formulario',
            handler: async (resolve, reject, args) => { 
              
              await openModal().then(formId => {

                let form = forms.find(element => element.id === formId)
                let fields = {}
                let layout = []

                function getType(type){
                  if(type === "firstName" || type === "fullName" || type === "lastName") return "text"
                  if(type === "phone") return "tel"
                  return type
                }

                function getSelectOptions(options){
                  let res = []
                  let arrayOfLines = options.match(/[^\r\n]+/g);
                  arrayOfLines.forEach(element => {
                    res.push({
                      type: 'option',
                      label: element,
                      value: element
                    })
                  })
                  return res
                }

                form.fields.forEach(element => {
                  fields[element.name] = {
                    type: getType(element.type),
                    label: element.title,
                    attributes: {
                      required: element.required
                    },
                    ...(element.type === 'select' && {
                      options: getSelectOptions(element.options)
                    }),
                    canBeRemovedFromLayout: false
                  }
                  layout.push([element.name])
                })

                fields['submit'] = {type: 'submit', attributes: {value: 'Enviar'}, canBeRemovedFromLayout: false}
                layout.push(['submit'])

                resolve({
                  structure: {
                    attributes: {
                      action: `${Globals.API_URL}public/site/${sites.defaultSite.id}/form/${formId}/entries`,
                      method: 'POST',
                      target: '_self',
                      enctype: 'multipart/form-data'
                    },
                    description: form.description,
                    fields: fields,
                    layout: layout,
                    title: form.title
                  } 
                })

              })
              .catch(() => {
                reject()
              })

            } 
          }
        },
        rowsConfiguration: {
          emptyRows: true,
          defaultRows: true,         
          externalContentURLs: [{
              name: "Rows list 01",
              value: "https://URL-01"
              },{
              name: "Rows list 02",
              value: "https://URL-02"
          }]         
      },

    }

    const initializeBeeFreePlugin = () => {
      const beeTest = new Bee()
      beeTest.getToken(Globals.BEE_FREE_PAGE_BUILDER_CLIENT_ID, Globals.BEE_FREE_PAGE_BUILDER_CLIENT_SECRET)
        .then(() => beeTest.start(beeConfig, templatePage))
    }

    const preload = async () => {
      const data = await fetchAllForms(sites.defaultSite.id)
      if(data){
        forms = data
      }
    }
    
    useEffect(()=>{
        preload()
        initializeBeeFreePlugin()
     },[])

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={{ width: '100%', height: '100vh' }} id="bee-plugin-container"/>
    </div>
  );
}

const mapStateToProps = ({ sites }) => {
    return { sites }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchTemplates, fetchTemplate, postTemplate, putTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PageBuilder)
