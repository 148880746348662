import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { fetchCourse } from '../../../../redux/actions/courses'
import { fetchModules } from '../../../../redux/actions/modules'
import {
  KeyboardBackspace,
  // PeopleAlt,
  // MonetizationOn,
  // Videocam
} from '@material-ui/icons'
import ModulesTabs from '../ModulesTabs'
import CourseForm from '../CourseForm'
import Loader from '../../Loader'
import NotFound from '../../NotFound'
// import VisitsChart from '../../VisitsChart'
import { Container, Header, Content, Title } from './EditStyledComponents'

function UserCreate(props) {
  const { course } = useParams()
  const { sites, courses, modules } = props

  useEffect(() => {
    preload()
  }, [])

  const preload = async () => {
    await props.fetchCourse(sites.defaultSite.id, course)
    props.fetchModules(sites.defaultSite.id, course)
  }

  return (
    <div style={{ display: 'flex', flex: 1, Container: props.sites.collapse ? 'Container.collapse' : 'Container' }}>
      {/* <div style={{ display: 'flex', flex: 1, paddingLeft: sites.collapse ? 'auto' : 'auto' }}> */}
      {courses.course.error ? (
        <NotFound
          title='Oops… Curso no encontrado'
          subtitle='No podemos encontrar el curso que estás buscando.'
          hideNavbar={true}
          hideFooter={true}
        />
      ) : (
        <Container>
          <Content>
            <div style={{ ...styles.panel, ...styles.leftPanel }}>
              <div style={styles.header}>
                <div onClick={() => window.history.back()} style={styles.title}>
                  <KeyboardBackspace style={{ fill: '#024976', marginLeft: 20 }} />
                      CURSOS
                    </div>
              </div>
              <div style={{ ...styles.panelSection }}>
                {!courses.course.loading ? <CourseForm /> : <Loader />}
              </div>
            </div>
            <div style={{ ...styles.panel, ...styles.centerPanel }}>
              <div style={{ ...styles.tabsPanelSection }}>
                <div style={styles.tab}>
                  {!courses.course.loading ? (
                    <ModulesTabs site={sites.defaultSite.id} course={course} />
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            {/* <div style={{...styles.panel, ...styles.rightPanel}}>

                <div style={{...styles.panelSection, paddingBottom: 15}}>
                  <div style={{...styles.panelTitle}}>Estadisticas</div>

                  <div style={styles.stat}>
                    <div style={styles.statName}>
                      <PeopleAlt style={{fill: '#00ace7'}}/>Miembros
                    </div>
                    <div style={styles.statValue}>3</div>
                  </div>

                  <div style={styles.stat}>
                    <div style={styles.statName}>
                      <MonetizationOn style={{fill: '#00ace7'}}/>Ventas
                    </div>
                    <div style={styles.statValue}>$38493</div>
                  </div>

                  <div style={styles.stat}>
                    <div style={styles.statName}>
                      <Videocam style={{fill: '#00ace7'}}/>Videos
                    </div>
                    <div style={styles.statValue}>23</div>
                  </div>

                </div>

                <div style={{...styles.panelSection}}>
                  <div style={styles.panelTitle}>Visitas</div>
                  <VisitsChart
                    data={visits}
                    title={'Visitas'}
                    color='#00ace7'
                  />
                </div>
              </div> */}
          </Content>
        </Container>
      )}
    </div>
  )
}

const styles = {

  panel: {
    minWidth: 330,
    backgroundColor: 'white',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    width: '100%',
    marginLeft: 0,
    paddingBottom: 12,
    justifyContent: 'space-between',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)'
  },
  title: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer',
    marginTop: 8,
  },
  leftPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  centerPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  rightPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  panelSection: {
    padding: '20px 20px 0 25px',
    width: '100%'
  },
  tabsPanelSection: {
    padding: '0',
    width: '100%',
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  Value: {
    fontFamily: 'Montserrat',
    color: '#024976'
  }
}

const visits = [
  {
    label: 'Lun',
    value: 780
  },
  {
    label: 'Mar',
    value: 500
  },
  {
    label: 'Mie',
    value: 270
  },
  {
    label: 'Jue',
    value: 760
  },
  {
    label: 'Vie',
    value: 480
  },
  {
    label: 'Sab',
    value: 1000
  },
  {
    label: 'Dom',
    value: 280
  }
]

const mapStateToProps = ({ sites, courses, modules }) => {
  return { sites, courses, modules }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchCourse, fetchModules }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
