import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, AddCircle } from '@material-ui/icons'
import { fetchEvents, deleteEvents } from '../../../redux/actions/events'
import { loadSites } from '../../../redux/actions/sites'
import { makeToast } from '../../../utils'
import FullDataTable from '../CoursesMain/FullDataTable'
import ModalRight from '../ModalRight'
import Create from './Create'

class UsersList extends React.Component {
  state = {
    edit: null,
    create: false
  }

  toggle = async () => {
    await this.setState(prev => ({ create: !prev.create }))
  }

  close = async () => {
    await this.setState({ create: false, edit: null })
  }

  customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    const {
      sites: { defaultSite }
    } = this.props
    if (searchText && searchText !== '') {
      const names = columns.map(item => item.name)
      this.props.fetchEvents(
        defaultSite.id,
        rowsPerPage,
        page,
        sortOrder.name,
        sortOrder.direction,
        names,
        searchText
      )
    } else {
      this.props.fetchEvents(
        defaultSite.id,
        rowsPerPage,
        page,
        sortOrder.name,
        sortOrder.direction
      )
    }
  }

  render() {
    const { create, edit } = this.state
    const {
      sites: { defaultSite },
      events: { data, loading, limit, offset, count, sort }
    } = this.props

    const columns = [
      {
        name: 'id',
        options: {
          display: 'excluded'
        }
      },
      {
        label: 'Titulo',
        name: 'title'
      },
      {
        label: 'Descripción',
        name: 'description'
      },
      {
        label: 'Estatus',
        name: 'status'
      },
      {
        label: 'Acciones',
        name: 'id',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return (
                <IconButton onClick={() => this.setState({ edit: value })}>
                  <EditIcon style={{ fill: '#007bff' }} />
                </IconButton>
              )
            }
          }
        }
      }
    ]

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={styles.content} className='content'>
          <div style={styles.tableContainer}>
            <FullDataTable
              data={data}
              title={'Eventos'}
              loading={loading}
              columns={columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              theme={theme}
              onRowPress={(data, meta, rowIndex) => {
                this.setState({ edit: data[0] })
              }}
              onRowsDelete={async rows => {
                await this.props.deleteEvents(defaultSite.id, rows)
                makeToast(
                  this.props.enqueueSnackbar,
                  'Se han eliminado los elementos'
                )
                this.props.fetchEvents(defaultSite.id, limit, offset)
              }}
              preload={() =>
                this.props.fetchEvents(defaultSite.id, limit, offset)
              }
              sort={this.customSearch}
              search={this.customSearch}
              changePage={this.customSearch}
              changePageSize={this.customSearch}
              renderCreate={() => (
                <div style={styles.actions}>
                  <button disabled={true} style={styles.actionButton} className='button is-primary'>
                    Importar
                    </button>
                  <button style={styles.actionButton} onClick={this.toggle} className='button is-light'>
                    <AddCircle
                      style={{ width: 20, height: 20, marginRight: 5 }}
                    />{' '}
                      Crear Evento
                    </button>
                </div>
              )}
            />
          </div>
        </section>
        {create && (
          <ModalRight
            show={create}
            onClick={this.toggle}
            hideCloseButton={true}
          >
            <Create onCloseModal={() => this.close()} />
          </ModalRight>
        )}
        {edit && (
          <ModalRight
            show={!!edit}
            onClick={this.toggle}
            hideCloseButton={true}
          >
            <Create event={edit} onCloseModal={() => this.close()} />
          </ModalRight>
        )}
      </div>
    )
  }
}

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  contactCount: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {}
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600'
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ events, sites }) => {
  return { events, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchEvents, loadSites, deleteEvents }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(UsersList)))
