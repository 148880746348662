import {
  FETCH_DOMAINS,
  FAIL_FETCH_DOMAINS,
  SUCCESS_FETCH_DOMAINS,
  SUCCESS_FETCH_DOMAINS_TABLE,
  FETCHING_DOMAIN,
  FAIL_FETCHING_DOMAIN,
  SUCCESS_FETCHING_DOMAIN,
} from '../actions/domains'

const initialState = {
  data: [],
  table: [],
  error: null,
  loading: false,
  collapse: false,
  domain: {},
  defaultSite: {},
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DOMAINS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FAIL_FETCH_DOMAINS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SUCCESS_FETCH_DOMAINS:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
        collapse: action.collapse,
        defaultSite: action.default,
      };
    case SUCCESS_FETCH_DOMAINS_TABLE:
      return {
        ...state,
        error: null,
        loading: false,
        ...action.data,
      };
    case FETCHING_DOMAIN:
      return {
        ...state,
        domain: {},
        error: null,
        loading: true,
      };
    case SUCCESS_FETCHING_DOMAIN:
      return {
        ...state,
        loading: false,
        error: action.error,  
        defaultSite: action.data,
      };
    case FAIL_FETCHING_DOMAIN:
      return {
        ...state,
        domain: {},
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
