import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Close } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { bindActionCreators } from 'redux'
import {
  FormControl,
  TextField,
  FormGroup,
  CircularProgress,
  IconButton,
  makeStyles
} from '@material-ui/core'
import {
  getCategory,
  updateCategory,
  createCategory
} from '../../../helpers/categories'
import { fetchCategories } from '../../../redux/actions/categories'
import ImageUploader from '../courses/components/ImageUploader'
import { makeToast, filterChanges } from '../../../utils'
import { uploadFile } from '../../../helpers/files'
import { Label } from '../StyledComponents'

function UserCreate (props) {
  let response = { name: null }
  const classes = useStyles()

  const [data, setData] = useState({})
  const [name, setName] = useState('')
  const [files, setFiles] = useState([])
  const [banner, setBanner] = useState([]);
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false);
  const [bannerUrl, setBannerUrl] = useState(null);
  const [description, setDescription] = useState('');
  const [backgroundUrl, setBackgroundUrl] = useState(null)

  const loadCategory = async () => {
    if (props.categoryId) {
      setLoading(true)
      setFetching(true)
      response = await getCategory(props.site, props.categoryId)
      await setData(response);
      await setName(response.name)
      await setDescription(response.description);
      if (response.view) {
        await setFiles([response.view.background])
        await setBackgroundUrl(response.view.background)
        await setBanner([response.view.banner])
        await setBannerUrl(response.view.banner)
      }
    }
    setLoading(false)
    setFetching(false)
  }

  useEffect(() => {
    loadCategory()
  }, [])

  const cleanInputs = () => {
    setName('')
  }

  const onAction = (ev, body) => {
    ev && ev.preventDefault()
    if (props.categoryId) {
      onUpdateCategory(body)
    } else {
      onSaveCategory(body)
    }
  }

  const setImagePicture = async acceptedFiles => {
    try {
      makeToast(enqueueSnackbar, 'Cargando contenido', 'info')
      await setFetching(true)
      await setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      const specific = { courseId: null }
      const url = await uploadFile(props.site, acceptedFiles[0], specific)
      if (props.categoryId) await onAction(null, { background: url })
      await setBackgroundUrl(url)
      await setFetching(false)
      makeToast(enqueueSnackbar, 'Contenido cargado')
    } catch (error) {
      console.log('setImagePicture', error)
      makeToast(enqueueSnackbar, 'Error cargando contenido', 'error')
    }
  }

  const setBannerPicture = async acceptedFiles => {
    try {
      makeToast(enqueueSnackbar, 'Cargando banner', 'info')
      await setFetching(true)
      await setBanner(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
      const specific = { courseId: null }
      const url = await uploadFile(props.site, acceptedFiles[0], specific)
      if (props.categoryId) await onAction(null, { banner: url })
      await setBannerUrl(url)
      await setFetching(false)
      makeToast(enqueueSnackbar, 'Banner cargado')
    } catch (error) {
      console.log('setBannerPicture', error)
      makeToast(enqueueSnackbar, 'Error cargando banner', 'error')
    }
  }

  const onUpdateCategory = async body => {
    if (name.trim() !== '') {
      try {
        await setFetching(true)
        const {
          categories: { limit, offset }
        } = props
        const tempBody = {
          name,
          description: description,
          view: { 
            background: backgroundUrl,
            banner: bannerUrl,
            ...body
          }
        };
        const newBody = filterChanges(tempBody, data)
        await updateCategory(props.site, props.categoryId, newBody)
        cleanInputs()
        setFetching(false)
        props.fetchCategories(props.site, limit, offset)
        makeToast(enqueueSnackbar, 'Se actualizo la categoria')
        props.onCloseModal && props.onCloseModal()
      } catch (error) {
        console.log('updateCategory', error)
        makeToast(enqueueSnackbar, 'Error al actualizar categoria', 'error')
      }
    }
  }

  const onSaveCategory = async body => {
    if (name.trim() !== '') {
      await setFetching(true)
      try {
        const {
          categories: { limit, offset }
        } = props
        await createCategory(props.site, {
          name: name,
          description: description,
          view: { 
            background: backgroundUrl,
            banner: bannerUrl, 
            ...body 
          }
        })
        cleanInputs()
        setFetching(false)
        props.fetchCategories(props.site, limit, offset)
        makeToast(enqueueSnackbar, 'Se creo la categoria')
        props.onCloseModal && props.onCloseModal()
      } catch (error) {
        console.log('saveCategory', error)
        makeToast(enqueueSnackbar, 'Error al crear categoria', 'error')
      }
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>
          {props.categoryId ? 'Editar Categoría' : 'Crear Categoría'}
        </div>
        <IconButton onClick={() => props.onCloseModal && props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        {loading ? (
          <>
            <Skeleton variant="text" animation="pulse" height={70} style={{marginBottom: 15}} />
            <Skeleton variant="rect" animation="pulse" height={140} style={{marginBottom: 15}} />
            <Skeleton variant="rect" animation="pulse" height={140} style={{marginBottom: 15}} />
            <Skeleton variant="rect" animation="pulse" height={140} style={{marginBottom: 15}} />
          </>
        ) : (
          <FormControl style={{ width: '100%' }} onSubmit={e => onAction(e)}>
            <FormGroup style={{ marginBottom: 10 }}>
              <TextField
                id='name'
                type='text'
                value={name}
                label='Nombre'
                variant='outlined'
                disabled={fetching}
                aria-describedby='nameHelp'
                onChange={ev => setName(ev.target.value)}
                classes={{ root: classes.textFieldRoot }}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 15, marginBottom: 10 }}>
              <TextField
                id='description'
                type='text'
                multiline={true}
                rows={4}
                value={description}
                label='Descripción'
                disabled={fetching}
                aria-describedby='descriptionHelp'
                onChange={ev => setDescription(ev.target.value)}
                variant='outlined'
                classes={{ root: classes.textAreaRoot }}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 15 }}>
              <Label style={styles.label}>Imágen de la categoría</Label>
              <ImageUploader
                files={files}
                showIcon={true}
                onChange={setImagePicture}
                previousImage={backgroundUrl}
                dropContainerStyle={{ height: 140 }}
                placeholder='Haga clic aquí para subir una imagen o video'
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 15 }}>
              <Label style={styles.label}>Banner de la categoría</Label>
              <ImageUploader
                files={banner}
                showIcon={true}
                onChange={setBannerPicture}
                previousImage={bannerUrl}
                dropContainerStyle={{ height: 140 }}
                placeholder='Haga clic aquí para subir una imagen o video'
              />
            </FormGroup>
          </FormControl>
        )}
      </div>
      <div style={styles.actions}>
        <button
          disabled={fetching}
          onClick={e => onAction(e)}
          style={styles.actionButton}
          className='button is-light'
        >
          {fetching ? (
            <CircularProgress size={20} style={{ color: '#00A8D8' }} />
          ) : props.categoryId ? (
            'Editar Categoría'
          ) : (
            'Crear Categoría'
          )}
        </button>

        <button
          onClick={() => props.onCloseModal && props.onCloseModal()}
          style={styles.actionButton}
          className='button is-secondary'
        >
          Cancelar
        </button>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  textAreaRoot: {
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,
      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
})

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0
  },
  title: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  }
}

const mapStateToProps = ({ sites, categories }) => {
  return { sites, categories }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchCategories }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
