import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Visibility, AddCircle } from '@material-ui/icons';
import { fetchUsers, deleteUser } from '../../../redux/actions/users';
import { loadSites } from "../../../redux/actions/sites";
import FullDataTable from "../CoursesMain/FullDataTable";
import ModalRight from "../ModalRight";
import Create from "./Create";

const customerOrder = (obj1, obj2, order, key) => {
  let value1 = 0; let value2 = 0;
  const attr1 = obj1.data.find(item => (item.Name === key));
  const attr2 = obj2.data.find(item => (item.Name === key));
  if (attr1 && attr1.Value) value1 = attr1.Value;
  if (attr2 && attr2.Value) value2 = attr2.Value;
  let value = 1;
  if (value1 > value2) {
    value = -1;
  }
  return (value) * (order === 'asc' ? 1 : -1);
}

const columns = [
  {
    name: 'Username',
    options: {
      display: 'excluded'
    }
  },
  {
    label: 'Nombre',
    searchKey: "name",
    name: 'fullname',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => customerOrder(obj1, obj2, order, "name");
      },
      customSearch: (value = '', searchQuery = '') => {
        if (value.includes(searchQuery)) {
          return true
        }
        return false;
      }
    }
  },
  {
    label: 'Email',
    name: 'email',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => customerOrder(obj1, obj2, order, "email");
      },
      customSearch: (value = '', searchQuery = '') => {
        if (value.includes(searchQuery)) {
          return true
        }
        return false;
      }
    }
  },
  {
    label: 'Email verificado',
    name: 'email_verified',
  },
  {
    label: 'Activado',
    name: 'Enabled',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <div>{value ? 'Si' : 'No'}</div>
          );
        }
      }
    }
  },
  {
    label: 'Estatus',
    name: 'UserStatus',
  },
  {
    label: 'Fecha de creación',
    name: 'createdAt',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <div>{moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}</div>
          );
        }
      }
    }
  },
  {
    label: 'Ultima actualización',
    name: 'updatedAt',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <div>{moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}</div>
          );
        }
      }
    }
  },
  {
    label: "Acciones",
    name: 'userId',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <Link to={`/admin/users/view/${value}`}>
              <Visibility />
            </Link>
          );
        }
      }
    }
  }
];

class UsersList extends React.Component {

  state = {
    edit: false,
  };

  toggle = async () => {
    await this.setState((prev) => ({ edit: !prev.edit }));
  }

  close = async () => {
    await this.setState({ edit: false });
  }

  render() {
    const { edit } = this.state;
    const { sites, users: { data, loading, limit, offset, count, sort } } = this.props;
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={styles.content} className="content">
          <div style={styles.tableContainer}>
            <FullDataTable
              data={data}
              title={'Usuarios'}
              loading={loading}
              columns={columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              theme={theme}
              onRowPress={(rowData, meta, rowIndex) => {
                this.props.history.push(`/admin/users/view/${data[meta.dataIndex].userId}`);
              }}
              onRowsDelete={async (rows, data) => {
                await this.props.deleteUser(sites.defaultSite.id, data);
                makeToast(this.props.enqueueSnackbar, 'Se han eliminado los elementos')
                this.props.fetchUsers(sites.defaultSite.id, limit, offset);
              }}
              preload={() => this.props.fetchUsers(sites.defaultSite.id, limit, offset)}
              changePageSize={({ rowsPerPage }) => this.props.fetchUsers(sites.defaultSite.id, rowsPerPage, offset)}
              search={({ rowsPerPage, page, sortOrder, searchText, columns }) => {
                if (searchText && searchText !== '') {
                  const names = columns.map(item => (item.name));
                  this.props.fetchUsers(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
                } else {
                  this.props.fetchUsers(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
                }
              }}
              changePage={({ rowsPerPage, page, sortOrder }) => {
                this.props.fetchUsers(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
              }}
              sort={({ rowsPerPage, page, sortOrder }) => {
                this.props.fetchUsers(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
              }}
              renderCreate={() => (
                <div style={styles.actions}>
                  <button style={styles.actionButton} onClick={this.toggle} className="button is-light">
                    <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} /> Crear Usuario
                    </button>
                </div>
              )}
            />
          </div>
        </section>
        {
          edit && (
            <ModalRight show={edit} onClick={this.toggle} hideCloseButton={true}>
              <Create onCloseModal={() => this.close()} />
            </ModalRight>
          )
        }
      </div>
    );
  }
};

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  subtitle: {
    fontSize: 18,
    color: '#7d8595',
    fontFamily: 'Montserrat',
    textAlign: 'left'
  },
  userCount: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {

      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600',
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ users, sites }) => {
  return { users, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchUsers, loadSites, deleteUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(UsersList)));