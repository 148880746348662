import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_WEBINARS = 'FETCHING_WEBINARS';
export const FAIL_FETCH_WEBINARS = 'FAIL_FETCH_WEBINARS';
export const SUCCESS_FETCH_WEBINARS = 'SUCCESS_FETCH_WEBINARS';

export const FETCHING_INSTRUCTORS = 'FETCHING_INSTRUCTORS';
export const FAIL_FETCH_INSTRUCTORS = 'FAIL_FETCH_INSTRUCTORS';
export const SUCCESS_FETCH_INSTRUCTORS = 'SUCCESS_FETCH_INSTRUCTORS';

export const FETCHING_SUBSCRIBERS = 'FETCHING_SUBSCRIBERS';
export const FAIL_FETCH_SUBSCRIBERS = 'FAIL_FETCH_SUBSCRIBERS';
export const SUCCESS_FETCH_SUBSCRIBERS = 'SUCCESS_FETCH_SUBSCRIBERS';

export const FETCHING_WEBINAR = 'FETCHING_WEBINAR';
export const FAIL_FETCH_WEBINAR = 'FAIL_FETCH_WEBINAR';
export const SUCCESS_FETCH_WEBINAR = 'SUCCESS_FETCH_WEBINAR';

export const POST_WEBINAR = 'POST_WEBINAR';
export const POST_FAIL_WEBINAR = 'POST_FAIL_WEBINAR';
export const POST_SUCCESS_WEBINAR = 'POST_SUCCESS_WEBINAR';

export const PUT_WEBINAR = 'PUT_WEBINAR';
export const PUT_FAIL_WEBINAR = 'PUT_FAIL_WEBINAR';
export const PUT_SUCCESS_WEBINAR = 'PUT_SUCCESS_WEBINAR';

export const DELETE_WEBINAR = 'DELETE_WEBINAR';
export const DELETE_FAIL_WEBINAR = 'DELETE_FAIL_WEBINAR';
export const DELETE_SUCCESS_WEBINAR = 'DELETE_SUCCESS_WEBINAR';

export const fetchWebinars = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_WEBINARS });
    try {
      let url = parseUrl(`site/${siteId}/webinar?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_WEBINARS,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_WEBINARS,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_WEBINARS,
      });
    }
  };
};

export const fetchWebinarsInstructors = (siteId, event, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_INSTRUCTORS });
    try {
      let url = parseUrl(`site/${siteId}/webinar/${event}/instructors?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_INSTRUCTORS,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_INSTRUCTORS,
        });
      }
    } catch (error) {
      console.log('error', error)
      dispatch({
        error,
        type: FAIL_FETCH_INSTRUCTORS,
      });
    }
  };
};

export const fetchWebinarsSubscribers = (siteId, event, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_SUBSCRIBERS });
    try {
      let url = parseUrl(`site/${siteId}/webinar/${event}/subscribers?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_SUBSCRIBERS,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_SUBSCRIBERS,
        });
      }
    } catch (error) {
      console.log('error', error)
      dispatch({
        error,
        type: FAIL_FETCH_SUBSCRIBERS,
      });
    }
  };
};

export const postWebinarInstructor = (siteId, webinar, instructorId) => {
  return async dispatch => {
    try {
      const response = await network.post(`site/${siteId}/webinar/${webinar}/instructor`, { instructorId });
      if (response.data && response.data.error) {
        throw response.data.error
      } else {
        return response.data
      }
    } catch (error) {
      throw error
    }
  }
};

export const postWebinarSubscriptor = (siteId, webinar, data) => {
  return async dispatch => {
    try {
      const response = await network.post(`site/${siteId}/webinar/${webinar}/subscriber`, data);
      if (response.data && response.data.error) {
        throw response.data.error
      } else {
        return response.data
      }
    } catch (error) {
      throw error
    }
  }
};

export const deleteWebinarInstructor = (siteId, webinar, ids) => {
  return async dispatch => {
    try {
      const promises = ids.map(async item => {
        await network.del(`site/${siteId}/webinar/${webinar}/instructor/${item}`);
      });
      await Promise.all(promises);
    } catch (error) {
      throw error
    }
  }
};

export const getAllWebinars = (siteId) => {
  return async dispatch => {
    try {
      const response = await network.get(`site/${siteId}/webinar/all`);
      if (response.data && response.data.error) {
        throw response.data.error
      } else {
        return response.data
      }
    } catch (error) {
      throw error
    }
  }
};

export const deleteWebinarSubscriptor = (siteId, webinar, ids) => {
  return async dispatch => {
    try {
      const promises = ids.map(async item => {
        await network.del(`site/${siteId}/webinar/${webinar}/subscriber/${item}`);
      });
      await Promise.all(promises);
    } catch (error) {
      throw error
    }
  }
};

export const fetchWebinar = (siteId, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_WEBINAR });
    try {
      const response = await network.get(`site/${siteId}/webinar/${id}`);
      if ((response.data && response.data.error) || response.data === null) {
        dispatch({
          error: response.message ? response.message : 'Error al cargar curso',
          type: FAIL_FETCH_WEBINAR,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCH_WEBINAR,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_WEBINAR,
      });
    }
  };
};

export const postWebinar = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_WEBINAR });
    try {
      const { data } = await network.post(`site/${siteId}/webinar`, body);
      dispatch({
        data: data,
        type: POST_SUCCESS_WEBINAR,
      });
      return data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_WEBINAR,
      });
    }
  };
};

export const putWebinar = (siteId, id, body) => {
  return async dispatch => {
    dispatch({ type: PUT_WEBINAR });
    try {
      await network.put(`site/${siteId}/webinar/${id}`, body);
      dispatch({
        type: PUT_SUCCESS_WEBINAR,
      });
    } catch (error) {
      dispatch({
        error,
        type: PUT_FAIL_WEBINAR,
      });

    }
  };
};

export const deleteWebinar = (id, ids = []) => {
  return async dispatch => {
    const promises = ids.map(async item => {
      await network.del(`site/${id}/webinar/${item}`);
    });
    dispatch({ type: DELETE_WEBINAR });
    try {
      await Promise.all(promises);
      dispatch({
        type: DELETE_SUCCESS_WEBINAR,
      });
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_WEBINAR,
      });

    }
  };
};