import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export const SubTitle = memo(function SubTitle(props) {
  return (
    <div className="section-subtitle">
      {props.children}
    </div>
  )
})

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
}
