import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Apps, Refresh, ViewList } from '@material-ui/icons'
import { Tooltip, IconButton, LinearProgress } from '@material-ui/core'
import InfiniteList from '../InfiniteList'
import DataTable from '../DataTable'
import './FullDataTable.scss'
import { Container, Section, Header, Title, CoursesCount, Actions } from './FullDataTableStyledComponents'

function FullDataTable(props) {
  const [table, setTable] = useState(true)

  const { count, limit, loading, columns, renderCreate } = props
  const names = columns.filter(item => (!item.nosearch)).map(item => (item.name));

  const reload = () => {
    if (table) {
      props.preload()
    } else {
      props.infiniteScrollLoad(limit, 0, true)
    }
  }

  const customSearch = (searchText) => {
    if (searchText && searchText !== '') {
      props.infiniteScrollLoad(limit, 0, true, names, searchText);
    } else {
      props.infiniteScrollLoad(limit, 0, true);
    }
  }

  const customSearchTags = (selectedTags) => {
    if (selectedTags) {
      props.infiniteScrollLoad(limit, 0, true, null, null, selectedTags);
    } else {
      props.infiniteScrollLoad(limit, 0, true);
    }
  }

  return (
    <Container>
      <Section>
        <Header>
          <div className='main-title'>
            <Title>{props.title}</Title>
            <CoursesCount>
              ({count} {props.title})
            </CoursesCount>
          </div>
          <Actions>
            <Tooltip title='Actualizar'>
              <IconButton onClick={reload} style={{ border: '2px solid #F6F6F6', padding: 4 }}>
                <Refresh style={{ fill: '#757575' }} />
              </IconButton>
            </Tooltip>

            {
              props.infiniteScrollLoad && (
                <>
                  <Tooltip title='Tabla'>
                    <IconButton
                      style={{
                        border: '2px solid #F6F6F6',
                        borderRadius: 5,
                        backgroundColor: table ? '#ddd' : 'rgba(0,0,0,0)', padding: 4
                      }}
                      onClick={() => setTable(true)}
                    >
                      <ViewList
                        style={{
                          fill: '#757575'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Lista'>
                    <IconButton
                      style={{
                        border: '2px solid #F6F6F6',
                        borderRadius: 5,
                        backgroundColor: table ? 'rgba(0,0,0,0)' : '#ddd', padding: 4, marginLeft: -22,
                      }}
                      onClick={() => setTable(false)}
                    >
                      <Apps
                        style={{
                          fill: '#757575',
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )
            }
            {renderCreate && renderCreate()}
          </Actions>
        </Header>
        {loading && <LinearProgress />}
        {
          table ? (
            <DataTable
              {...props}
            />
          ) : (
            <InfiniteList
              customSearch={customSearch}
              customSearchTags={customSearchTags}
              {...props}
            />
          )
        }
      </Section>
    </Container>
  )
}

FullDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  preload: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  renderCreate: PropTypes.func.isRequired,
  infiniteScrollLoad: PropTypes.func.isRequired,
}

export default FullDataTable
