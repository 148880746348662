import network from '../utils/network';

export const getCategories = async (site) => {
  try {
    const { data } = await network.get(`site/${site}/category`);
    return data;
  } catch (error) {
    console.error('getCategories', error);
    return error;
  }
}

export const getCategory = async (site, name) => {
  try {
    const { data } = await network.get(`site/${site}/category/${name}`);
    return data;
  } catch (error) {
    console.error('getCategory', error);
    return error;
  }
}

export const updateCategory = async (site, name, body) => {
  try {
    const { data } = await network.put(`site/${site}/category/${name}`, body);
    return data;
  } catch (error) {
    console.error('updateCategory', error);
    return error;
  }
}

export const deleteCategory = async (site, name) => {
  try {
    await network.del(`site/${site}/category/${name}`);
  } catch (error) {
    console.error('updateCategory', error);
    return error;
  }
}

export const createCategory = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/category`, body);
    if (data.error) {
      throw(data.error);
    }
    return data;
  } catch (error) {
    console.error('createCategory', error);
    return error;
  }
}