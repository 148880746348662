import 'date-fns'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
// import MomentUtils from '@date-io/moment';
import { bindActionCreators } from 'redux'
import { Close } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import IconButton from '@material-ui/core/IconButton'
import {
  FormControl,
  CircularProgress,
  FormGroup,
  Chip,
  makeStyles,
  useTheme,
  MenuItem,
  Input,
  Select
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers'
import {
  Input as CustomInput,
  Select as CustomSelect,
  TextArea
} from '../StyledComponents'
import { makeToast } from '../../../utils'
import {
  fetchEvents,
  fetchEvent,
  postEvent,
  putEvent
} from '../../../redux/actions/events'
import { fetchAllEmailTemplates } from '../../../redux/actions/emailTemplates'
import { getAllWebinars } from '../../../redux/actions/webinars'
import { fetchAllCourses } from '../../../redux/actions/courses'
import { fetchAllForms } from '../../../redux/actions/forms'
import { fetchAllUsers } from '../../../redux/actions/people'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function getStyles (name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

function UserCreate (props) {
  const { sites, event } = props
  const theme = useTheme()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  let response = {}

  const [forms, setForms] = useState([])
  const [loadingForms, setLoadingForms] = useState(false)
  const [title, setTitle] = useState('')
  const [evento, setEvento] = useState('')
  const [accion, setAccion] = useState('')
  const [subject, setSubject] = useState('')
  const [emails, setEmails] = useState([])
  const [events, setEvents] = useState([])
  const [contacts, setContacts] = useState([])
  const [courses, setCourses] = useState([])
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [loadingCourses, setLoadingCourses] = useState(false)
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [loadingEmails, setLoadingEmails] = useState(false)
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [subAccion, setSubAccion] = useState('')
  const [description, setDescription] = useState('')
  const [formSelected, setFormSelected] = useState('')
  const [eventSelected, setEventSelected] = useState('')
  const [emailSelected, setEmailSelected] = useState('')
  const [courseSelected, setCourseSelected] = useState('')
  const [coursesSelected, setCoursesSelected] = useState([])

  const loadEvent = async () => {
    if (event) {
      response = await props.fetchEvent(sites.defaultSite.id, event)
      await setTitle(response.title)
      await setDescription(response.description)
      await setEvento('Fecha y hora')
      await setAccion(response.type)
      switch (response.type) {
        case 'Asignar curso':
          await setDate(new Date(response.date))
          await setTime(new Date(response.date))
          await setSubAccion(response.action.type)
          await setCoursesSelected(response.action.courses)
          await setFormSelected(response.action.form)
          break
        case 'Enviar correo':
          await setSubAccion(response.action.type)
          await setEmailSelected(response.action.emailId)
          await setEventSelected(response.action.webinarId)
          await setSubject(response.action.subject)
          break
        default:
          break
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    loadEvent()
  }, [])

  const loadCourses = async () => {
    await setLoadingCourses(true)
    const data = await fetchAllCourses(sites.defaultSite.id)
    await setCourses(data)
    await setLoadingCourses(false)
  }

  const loadEmails = async () => {
    await setLoadingEmails(true)
    const data = await props.fetchAllEmailTemplates(sites.defaultSite.id)
    await setEmails(data)
    await setLoadingEmails(false)
  }

  const loadEvents = async () => {
    await setLoadingEvents(true)
    const data = await props.getAllWebinars(sites.defaultSite.id)
    await setEvents(data)
    await setLoadingEvents(false)
  }

  const loadContacts = async () => {
    await setLoadingContacts(true)
    const data = await props.fetchAllUsers(sites.defaultSite.id)
    await setContacts(data)
    await setLoadingContacts(false)
  }

  const loadForms = async () => {
    await setLoadingForms(true)
    const data = await fetchAllForms(sites.defaultSite.id)
    await setForms(data)
    await setLoadingForms(false)
  }

  useEffect(() => {
    switch (accion) {
      case 'Asignar curso':
        loadCourses()
        break
      case 'Enviar correo':
        loadEmails()
        break
      default:
        break
    }
  }, [accion])

  useEffect(() => {
    switch (subAccion) {
      case 'Registros en formulario':
        loadForms()
        break
      case 'Eventos':
        loadEvents()
        break
      case 'Contactos':
        loadContacts()
        break
      case 'Formularios':
        loadForms()
        break
      case 'Cursos':
        loadCourses()
        break
      default:
        break
    }
  }, [subAccion])

  const validate = () => {
    if (title.trim() === '') return { success: false, error: 'Título requerido' }
    if (evento.trim() === '')
      return { success: false, error: 'Evento requerido' }
    const tempDate = combineDateAndTime(date, time)
    if (moment(tempDate).diff(new Date(), 'days') < 0)
      return { success: false, error: 'Fecha invalido' }
    const diff = moment(tempDate).diff(new Date(), 'hours')
    if (diff < 0) return { success: false, error: 'Hora invalida' }
    if (diff < 0 && moment(tempDate).diff(new Date(), 'minutes') < 0)
      return { success: false, error: 'Minutos invalidos' }
    if (accion.trim() === '')
      return { success: false, error: 'Acción requerido' }
    switch (accion) {
      case 'Asignar curso':
        if (!coursesSelected.length)
          return { success: false, error: 'Cursos requerido requerido' }
        if (subAccion.trim() === '')
          return { success: false, error: 'SubAccion requerida' }
        if (!formSelected)
          return { success: false, error: 'Formulario requerido' }
        break
      case 'Enviar correo':
        if (subAccion.trim() === '')
          return { success: false, error: 'SubAccion requerida' }
        switch (subAccion) {
          case 'Registros en formulario':
            if (formSelected.trim() === '')
              return { success: false, error: 'Evento requerido' }
            break;
          case 'Eventos':
            if (eventSelected.trim() === '')
              return { success: false, error: 'Evento requerido' }
            break;
          case 'Formularios':
            if (formSelected.trim() === '')
              return { success: false, error: 'Evento requerido' }
            break;
          case 'Cursos':
            if (courseSelected.trim() === '')
              return { success: false, error: 'Evento requerido' }
            break;
        }
        if (subject.trim() === '')
          return { success: false, error: 'Subject requerido' }
        if (emailSelected.trim() === '')
          return { success: false, error: 'Email requerido' }
        break
      default:
        break
    }
    return { success: true }
  }

  const combineDateAndTime = (date, time) => {
    let timeString = time.getHours() + ':' + time.getMinutes() + ':00'
    var year = date.getFullYear()
    var month = date.getMonth() + 1 // Jan is 0, dec is 11
    var day = date.getDate()
    var dateString = '' + year + '-' + month + '-' + day
    var combined = new Date(dateString + ' ' + timeString)
    return combined
  }

  const generateBody = () => {
    switch (accion) {
      case 'Asignar curso':
        return {
          title,
          description,
          type: accion,
          date: combineDateAndTime(date, time),
          action: {
            type: subAccion,
            form: +formSelected,
            courses: coursesSelected
          }
        }
      case 'Enviar correo':
        return {
          title,
          description,
          type: accion,
          date: combineDateAndTime(date, time),
          action: {
            subject,
            type: subAccion,
            formId: formSelected,
            emailId: emailSelected,
            webinarId: eventSelected,
            courseId: courseSelected,
          }
        }
      default:
        return {}
    }
  }

  const handleSubmit = async (ev, toEdit) => {
    ev.preventDefault()
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true)
      try {
        const data = generateBody()
        if (event) {
          const { sites } = props
          const siteId = sites.defaultSite.id
          await props.putEvent(siteId, event, data)
          props.fetchEvents(siteId)
        } else {
          const { sites } = props
          const siteId = sites.defaultSite.id
          await props.postEvent(siteId, data)
          props.fetchEvents(siteId)
        }
      } catch (error) {
        console.log('handleSubmit', error)
      } finally {
        await setLoading(false)
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const handleChange = event => {
    setCoursesSelected(event.target.value)
  }

  const renderChips = selected => {
    return (
      <div className={classes.chips}>
        {selected.map((item, index) => {
          const courseIndex = courses.findIndex(course => course.id === item)
          return (
            <Chip
              key={`Chip-${index}`}
              className={classes.chip}
              label={courses[courseIndex].title}
            />
          )
        })}
      </div>
    )
  }

  const renderSubAccion = () => {
    switch (subAccion) {
      case 'Registros en formulario':
        return (
          <>
            {loadingForms ? (
              <div style={styles.field} className='field'>
                <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
              </div>
            ) : (
              <div style={styles.field} className='field'>
                <div style={styles.label}>De que formulario</div>
                <CustomSelect
                  disabled={loading}
                  value={formSelected}
                  onChange={ev => setFormSelected(ev.target.value)}
                  style={{
                    ...styles.input,
                    backgroundColor: 'white',
                    border: 'rgb(232, 232, 232) 1px solid'
                  }}
                >
                  <option>Ninguna</option>
                  {forms.map((item, index) => (
                    <option key={`actions-${index}`} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </CustomSelect>
              </div>
            )}
          </>
        )
      case 'Eventos':
        return (
          <>
          {
            loadingEvents ? (
              <div style={styles.field} className='field'>
                <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
              </div>
            ) : (
              <div style={styles.field} className='field'>
                <div style={styles.subtitle}>Suscriptores de evento</div>
                <CustomSelect
                  disabled={loading}
                  value={eventSelected}
                  onChange={ev => setEventSelected(ev.target.value)}
                  style={{
                    ...styles.input,
                    backgroundColor: 'white',
                    border: 'rgb(232, 232, 232) 1px solid'
                  }}
                >
                  <option>Seleccionar evento</option>
                  {events.map((item, index) => (
                    <option key={`actions-${index}`} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </CustomSelect>
              </div>
            )
          }
          </>
        )
      case 'Formularios':
        return (
          <>
            {
              loadingForms ? (
                <div style={styles.field} className='field'>
                  <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
                </div>
              ) : (
                <div style={styles.field} className='field'>
                  <div style={styles.subtitle}>De que formulario</div>
                  <CustomSelect
                    disabled={loading}
                    value={formSelected}
                    onChange={ev => setFormSelected(ev.target.value)}
                    style={{
                      ...styles.input,
                      backgroundColor: 'white',
                      border: 'rgb(232, 232, 232) 1px solid'
                    }}
                  >
                    <option>Seleccionar formulario</option>
                    {forms.map((item, index) => (
                      <option key={`actions-${index}`} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
              )
            }
          </>
        )
      case 'Cursos':
        return (
          <>
            {
              loadingCourses ? (
                <div style={styles.field} className='field'>
                  <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
                </div>
              ) : (
                <div style={styles.field} className='field'>
                  <div style={styles.subtitle}>De que curso</div>
                  <CustomSelect
                    disabled={loading}
                    value={courseSelected}
                    onChange={ev => setCourseSelected(ev.target.value)}
                    style={{
                      ...styles.input,
                      backgroundColor: 'white',
                      border: 'rgb(232, 232, 232) 1px solid'
                    }}
                  >
                    <option>Seleccionar curso</option>
                    {courses.map((item, index) => (
                      <option key={`actions-${index}`} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
              )
            }
          </>
        )
      default:
        return null
    }
  }

  const renderOptions = () => {
    switch (accion) {
      case 'Asignar curso':
        return (
          <>
            {loadingCourses ? (
              <div style={styles.field} className='field'>
                <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
              </div>
            ) : (
              <>
                <FormGroup>
                  <Select
                    labelId='demo-mutiple-chip-label'
                    id='demo-mutiple-chip'
                    multiple
                    MenuProps={MenuProps}
                    value={coursesSelected}
                    onChange={handleChange}
                    renderValue={renderChips}
                    input={<Input id='select-multiple-chip' />}
                  >
                    {courses.map((item, index) => (
                      <MenuItem
                        key={`MenuItem-${index}`}
                        value={item.id}
                        style={getStyles(item.id, coursesSelected, theme)}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormGroup>
                <div style={styles.field} className='field'>
                  <div style={styles.label}>
                    A quien se le asginará el curso
                  </div>
                  <CustomSelect
                    value={subAccion}
                    disabled={loading}
                    onChange={ev => setSubAccion(ev.target.value)}
                    style={{
                      ...styles.input,
                      backgroundColor: 'white',
                      border: 'rgb(232, 232, 232) 1px solid'
                    }}
                  >
                    <option>Ninguna</option>
                    {['Registros en formulario'].map((item, index) => (
                      <option key={`actions-${index}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                {renderSubAccion()}
              </>
            )}
          </>
        )
      case 'Enviar correo':
        return (
          <>
            {loadingEmails && loadingEvents ? (
              <div style={styles.field} className='field'>
                <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
              </div>
            ) : (
              <>
                <FormGroup>
                  <div style={styles.field} className='field'>
                    <div style={styles.label}>
                      Fuente de mails
                    </div>
                    <CustomSelect
                      value={subAccion}
                      disabled={loading}
                      onChange={ev => setSubAccion(ev.target.value)}
                      style={{
                        ...styles.input,
                        backgroundColor: 'white',
                        border: 'rgb(232, 232, 232) 1px solid'
                      }}
                    >
                      <option>Ninguna</option>
                      {['Eventos', 'Cursos', 'Contactos', 'Formularios'].map((item, index) => (
                        <option key={`actions-${index}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </CustomSelect>
                  </div>
                  {renderSubAccion()}
                  <div style={styles.field} className='field'>
                    <CustomInput
                      id='subject'
                      type='subject'
                      value={subject}
                      disabled={loading}
                      style={styles.input}
                      placeholder='Subject'
                      aria-describedby='subjectHelp'
                      onChange={e => setSubject(e.target.value)}
                    />
                  </div>
                  <div style={styles.field} className='field'>
                    <div style={styles.subtitle}>Emails</div>
                    <CustomSelect
                      disabled={loading}
                      value={emailSelected}
                      onChange={ev => setEmailSelected(ev.target.value)}
                      style={{
                        ...styles.input,
                        backgroundColor: 'white',
                        border: 'rgb(232, 232, 232) 1px solid'
                      }}
                    >
                      <option>Seleccionar email</option>
                      {emails.map((item, index) => (
                        <option key={`actions-${index}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomSelect>
                  </div>
                </FormGroup>
              </>
            )}
          </>
        )
      default:
        return null
    }
  }

  const renderInputs = () => {
    switch (evento) {
      case 'Fecha y hora':
        return (
          <>
            <div style={styles.field} className='field'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  value={date}
                  disableToolbar
                  margin='normal'
                  format='MM/dd/yyyy'
                  id='date-picker-inline'
                  style={{ width: '100%' }}
                  label='Selecionar fecha (UTC)'
                  onChange={newDate => setDate(newDate)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
                <KeyboardTimePicker
                  value={time}
                  disableToolbar
                  margin='normal'
                  id='time-picker'
                  style={{ width: '100%' }}
                  label='Selecionar hora (UTC)'
                  onChange={newTime => setTime(newTime)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time'
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ marginBottom: 15 }}>
              <div style={styles.titleblack}>Acción</div>
              <div style={styles.subtitle}>
                Que acción se ejecutará cuando el evento suceda?
              </div>
            </div>
            <div style={styles.field} className='field'>
              <div style={styles.label}>Acción</div>
              <CustomSelect
                value={accion}
                disabled={loading}
                onChange={ev => setAccion(ev.target.value)}
                style={{
                  ...styles.input,
                  backgroundColor: 'white',
                  border: 'rgb(232, 232, 232) 1px solid'
                }}
              >
                <option>Ninguna</option>
                {['Asignar curso', 'Enviar correo'].map((item, index) => (
                  <option key={`actions-${index}`} value={item}>
                    {item}
                  </option>
                ))}
              </CustomSelect>
            </div>
            {renderOptions()}
          </>
        )
      default:
        return null
    }
  }

  const renderResumen = () => {
    if (title && description && evento && date && time && accion) {
      switch (accion) {
        case 'Asignar curso':
          if (
            coursesSelected.length &&
            subAccion &&
            formSelected &&
            forms.length
          ) {
            const form = forms.find(item => item.id === formSelected)
            const coursesTemp = courses.filter(item =>
              coursesSelected.includes(item.id)
            )
            const coursesTitles = coursesTemp.map(item => item.title)
            return (
              <>
                <b style={{ textAlign: 'left' }}>Resumen:</b>
                <div style={{ textAlign: 'left' }}>
                  El <b>{moment(new Date(date)).format('DD/MM/YYYY')}</b> a las
                  <b>{` ${moment(new Date(time)).format('HH:mm')}`}</b>,{' '}
                  <b>{accion}(s):</b>
                  <b>{` ${coursesTitles.join(', ')}`}</b>, a los
                  <b>{` ${subAccion}`}</b>, Formulario seleccionado:{' '}
                  <b>{form.title}</b>
                </div>
                <div style={{ textAlign: 'left' }}>
                  <b>Notas:</b> Al asignar un curso, se generará de manera
                  automática un usuario al contacto en dado caso de no contar
                  con uno y se enviarán por correo electrónico sus accesos
                </div>
              </>
            )
          }
          break
        case 'Enviar correo':
          const webinarTemp = events.find(item => item.id === eventSelected)
          return (
            <>
              <b style={{ textAlign: 'left' }}>Resumen:</b>
              <div style={{ textAlign: 'left' }}>
                El <b>{moment(new Date(date)).format('DD/MM/YYYY')}</b> a las
                <b>{` ${moment(new Date(time)).format('HH:mm')}`}</b>,{' '}
                <b>{accion}(s):</b>, a los suscriptores del evento seleccionado:{' '}
                <b>{webinarTemp && webinarTemp.title}</b>
              </div>
            </>
          )
        default:
          break
      }
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>
          {event ? 'Editar' : 'Crear'} automatización
        </div>
        <IconButton onClick={() => props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        <div style={{ marginBottom: 15 }}>
          <div style={styles.titleblack}>Evento</div>
          <div style={styles.subtitle}>
            Que evento inicia la automatización?
          </div>
        </div>
        <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
          <div style={styles.field} className='field'>
            <CustomInput
              id='title'
              type='title'
              value={title}
              disabled={loading}
              style={styles.input}
              aria-describedby='titleHelp'
              placeholder='Introduce el Título'
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div
            style={{ textAlign: 'left', marginBottom: 20 }}
            className='field'
          >
            <TextArea
              id='description'
              value={description}
              disabled={loading}
              style={{ height: 56, borderRadius: 14 }}
              placeholder='Ingresa la Descripción'
              aria-describedby='descriptionHelp'
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div style={styles.field} className='field'>
            <div style={styles.subtitle}>Evento</div>
            <CustomSelect
              value={evento}
              disabled={loading}
              onChange={ev => setEvento(ev.target.value)}
              style={{
                ...styles.input,
                backgroundColor: 'white',
                border: 'rgb(232, 232, 232) 1px solid'
              }}
            >
              <option>Ninguna</option>
              {['Fecha y hora'].map((item, index) => (
                <option key={`actions-${index}`} value={item}>
                  {item}
                </option>
              ))}
            </CustomSelect>
          </div>
          {renderInputs()}
          {renderResumen()}
        </FormControl>
      </div>
      <div style={styles.actions}>
        <button
          disabled={loading}
          onClick={ev => handleSubmit(ev, true)}
          style={styles.actionButton}
          className='button is-light'
        >
          {loading ? (
            <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
          ) : event ? (
            'Editar'
          ) : (
            'Crear'
          )}
        </button>

        <button
          onClick={() => props.onCloseModal()}
          style={styles.actionButton}
          className='button is-secondary'
        >
          Cancelar
        </button>
      </div>
    </div>
  )
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0
  },
  title: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  titleblack: {
    textAlign: 'left',
    color: '#333',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: '600'
  },
  subtitle: {
    textAlign: 'left',
    color: '#333',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  }
}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchAllUsers,
      fetchEvents,
      fetchEvent,
      postEvent,
      putEvent,
      fetchAllEmailTemplates,
      getAllWebinars
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
