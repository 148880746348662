import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_COURSES = 'FETCHING_COURSES';
export const FAIL_FETCH_COURSES = 'FAIL_FETCH_COURSES';
export const SUCCESS_FETCH_COURSES = 'SUCCESS_FETCH_COURSES';

export const FETCHING_COURSES_INFINITE = 'FETCHING_COURSES_INFINITE';
export const FAIL_FETCH_COURSES_INFINITE = 'FAIL_FETCH_COURSES_INFINITE';
export const SUCCESS_FETCH_COURSES_INFINITE = 'SUCCESS_FETCH_COURSES_INFINITE';

export const FETCHING_COURSE = 'FETCHING_COURSE';
export const FAIL_FETCH_COURSE = 'FAIL_FETCH_COURSE';
export const SUCCESS_FETCH_COURSE = 'SUCCESS_FETCH_COURSE';

export const POST_COURSE = 'POST_COURSE';
export const POST_FAIL_COURSE = 'POST_FAIL_COURSE';
export const POST_SUCCESS_COURSE = 'POST_SUCCESS_COURSE';

export const PUT_COURSE = 'PUT_COURSE';
export const PUT_FAIL_COURSE = 'PUT_FAIL_COURSE';
export const PUT_SUCCESS_COURSE = 'PUT_SUCCESS_COURSE';

export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_FAIL_COURSE = 'DELETE_FAIL_COURSE';
export const DELETE_SUCCESS_COURSE = 'DELETE_SUCCESS_COURSE';

export const FETCHING_SUBSCRIBERS_COURSES = 'FETCHING_SUBSCRIBERS_COURSES';
export const FAIL_FETCH_SUBSCRIBERS_COURSES = 'FAIL_FETCH_SUBSCRIBERS_COURSES';
export const SUCCESS_FETCH_SUBSCRIBERS_COURSES = 'SUCCESS_FETCH_SUBSCRIBERS_COURSES';

export const fetchCourses = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_COURSES });
    try {
      let url = parseUrl(`site/${siteId}/course?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_COURSES,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_COURSES,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_COURSES,
      });
    }
  };
};

export const fetchCoursesInfinite = (siteId, limit, offset, reset, search, searchtext) => {
  return async dispatch => {
    dispatch({ type: FETCHING_COURSES_INFINITE });
    try {
      const url = parseUrl(`site/${siteId}/course?`, limit, offset, null, null, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_COURSES_INFINITE,
        });
      } else { 
        let hasMore = false
        let page = offset + 1 
        if (reset) {
          page = 1
        }
        if (response.data.data.length >= limit){
          hasMore = true
        }
        dispatch({
          reset,
          data: {
            ...response.data,
            hasMore,
            page,
          },
          type: SUCCESS_FETCH_COURSES_INFINITE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_COURSES_INFINITE,
      });
    }
  };
};

export const fetchAllCourses = async (siteId) => {
  try {
    const response = await network.get(`site/${siteId}/allcourses`);
    if (response.data && response.data.error) {
      throw response.data.error
    } else {
      return response.data
    }
  } catch (error) {
    throw error
  }
};

export const fetchAllCoursesByEvent = async (siteId, webinar) => {
  try {
    const response = await network.get(`site/${siteId}/webinar/${webinar}/instructors/free`);
    if (response.data && response.data.error) {
      throw response.data.error
    } else {
      return response.data
    }
  } catch (error) {
    throw error
  }
};

export const fetchCoursesSubscribers = (siteId, course, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_SUBSCRIBERS_COURSES });
    try {
      let url = parseUrl(`site/${siteId}/course/${course}/subscribers?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_SUBSCRIBERS_COURSES,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_SUBSCRIBERS_COURSES,
        });
      }
    } catch (error) {
      console.log('error', error)
      dispatch({
        error,
        type: FAIL_FETCH_SUBSCRIBERS_COURSES,
      });
    }
  };
};

export const postCourseSubscriptor = (siteId, course, data) => {
  return async dispatch => {
    try {
      const response = await network.post(`site/${siteId}/course/${course}/subscriber`, data);
      if (response.data && response.data.error) {
        throw response.data.error
      } else {
        return response.data
      }
    } catch (error) {
      throw error
    }
  }
};

export const deleteCourseSubscriptor = (siteId, course, ids) => {
  return async dispatch => {
    try {
      const promises = ids.map(async item => {
        await network.del(`site/${siteId}/course/${course}/subscriber/${item}`);
      });
      await Promise.all(promises);
    } catch (error) {
      throw error
    }
  }
};

export const fetchCourse = (siteId, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_COURSE });
    try {
      const response = await network.get(`site/${siteId}/course/${id}`);
      if ((response.data && response.data.error) || response.data === null) {
        dispatch({
          error: response.message ? response.message : 'Error al cargar curso',
          type: FAIL_FETCH_COURSE,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCH_COURSE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_COURSE,
      });
    }
  };
};

export const postCourse = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_COURSE });
    try {
      const { data } = await network.post(`site/${siteId}/course`, body);
      dispatch({
        data: data,
        type: POST_SUCCESS_COURSE,
      });
      return data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_COURSE,
      });
    }
  };
};

export const putCourse = (siteId, id, body) => {
  return async dispatch => {
    dispatch({ type: PUT_COURSE });
    try {
      await network.put(`site/${siteId}/course/${id}`, body);
      dispatch({
        type: PUT_SUCCESS_COURSE,
      });
    } catch (error) {
      dispatch({
        error,
        type: PUT_FAIL_COURSE,
      });

    }
  };
};

export const deleteCourse = (id, ids = []) => {
  return async dispatch => {
    const promises = ids.map(async item => {
      await network.del(`site/${id}/course/${item}`);
    });
    dispatch({ type: DELETE_COURSE });
    try {
      await Promise.all(promises);
      dispatch({
        type: DELETE_SUCCESS_COURSE,
      });
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_COURSE,
      });

    }
  };
};