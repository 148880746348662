import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { useParams, useHistory } from 'react-router-dom';
import { FormControl, CircularProgress } from '@material-ui/core';
import { getUser, createUser, updateUser } from '../../../helpers/people';
import { validateEmail, filterChanges } from '../../../utils';
import { fetchUsers } from '../../../redux/actions/people';
import { Label, Input } from '../StyledComponents';
import { makeToast } from '../../../utils'

function UserCreate(props) {

  const { user } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  let response = {};

  const [data, setData] = useState({});
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [fullname, setFullname] = useState('');
  const [loading, setLoading] = useState(true);

  const loadPeople = async () => {
    if (user) {
      const { sites } = props;
      response = await getUser(sites.defaultSite.id, user);
      await setData(response);
      await setEmail(response.email);
      await setFullname(response.fullname);
      await setCountry(response.country);
      await setState(response.state);
      await setCity(response.city);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadPeople();
  }, [])

  const validate = () => {
    if (email.trim() === '') return { success: false, error: 'Email requerido' };
    if (!validateEmail(email)) return { success: false, error: 'Email valido requerido' };
    if (fullname.trim() === '') return { success: false, error: 'Nombre requerido' };
    return { success: true }
  }

  const cleanInputs = () => {
    setCity('');
    setState('');
    setEmail('');
    setCountry('');
    setFullname('');
  }

  const handleSubmit = async (ev, toEdit) => {
    ev.preventDefault();
    const isValid = validate();
    if (isValid.success) {
      await setLoading(true);
      try {
        const { sites } = props;
        const siteId = sites.defaultSite.id;
        if (user) {
          const newBody = filterChanges({ email, fullname, city, state, country }, data)
          await updateUser(siteId, user, newBody);
        } else {
          const data = await createUser(siteId, { email, fullname, city, state, country });
          if (toEdit) {
            history.push(`/people/view/${data.id}`);
          }
        }
        props.fetchUsers(siteId);
        cleanInputs();
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>Crear Contacto</div>
        <IconButton onClick={()=>props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Correo</Label>
            <Input
              id="email"
              type="email"
              value={email}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el correo"
              aria-describedby="emailHelp"
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Nombre completo</Label>
            <Input
              type="text"
              id="fullname"
              value={fullname}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el nombre completo"
              aria-describedby="fullnameHelp"
              onChange={(e)=>setFullname(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>País</Label>
            <Input
              type="text"
              id="country"
              value={country}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el país"
              aria-describedby="countryHelp"
              onChange={(e)=>setCountry(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Estado</Label>
            <Input
              id="state"
              type="text"
              value={state}
              disabled={loading}
              style={styles.input}
              aria-describedby="stateHelp"
              placeholder="Introduce el estado"
              onChange={(e)=>setState(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Ciudad</Label>
            <Input
              id="city"
              type="text"
              value={city}
              disabled={loading}
              style={styles.input}
              aria-describedby="cityHelp"
              placeholder="Introduce la ciudad"
              onChange={(e)=>setCity(e.target.value)}
            />
          </div>
        </FormControl>
      </div>
      <div style={styles.actions}>

        <button 
          disabled={loading} 
          onClick={(ev) => handleSubmit(ev)}
          style={styles.actionButton} 
          className="button is-primary"
        >
          Crear y añadir otro
        </button>

        <button 
          disabled={loading} 
          onClick={(ev) => handleSubmit(ev, true)}
          style={styles.actionButton} 
          className="button is-light"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Crear'}
        </button>

        <button 
          onClick={()=>props.onCloseModal()}
          style={styles.actionButton}
          className="button is-secondary"
        >
          Cancelar
        </button>

      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    textAlign: 'left', 
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
} 

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchUsers }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);