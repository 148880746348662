import network from "../../utils/network";

export const FETCHING_SUBMODULES = 'FETCHING_SUBMODULES';
export const FAIL_FETCH_SUBMODULES = 'FAIL_FETCH_SUBMODULES';
export const SUCCESS_FETCH_SUBMODULES = 'SUCCESS_FETCH_SUBMODULES';

export const POST_SUBMODULE = 'POST_SUBMODULE';
export const POST_FAIL_SUBMODULE = 'POST_FAIL_SUBMODULE';
export const POST_SUCCESS_SUBMODULE = 'POST_SUCCESS_SUBMODULE';

export const PUT_SUBMODULE = 'PUT_SUBMODULE';
export const PUT_FAIL_SUBMODULE = 'PUT_FAIL_SUBMODULE';
export const PUT_SUCCESS_SUBMODULE = 'PUT_SUCCESS_SUBMODULE';

export const DELETE_SUBMODULE = 'DELETE_SUBMODULE';
export const DELETE_FAIL_SUBMODULE = 'DELETE_FAIL_SUBMODULE';
export const DELETE_SUCCESS_SUBMODULE = 'DELETE_SUCCESS_SUBMODULE';

export const UPDATE_SUBMODULE = 'UPDATE_SUBMODULE';
export const UPDATE_FAIL_SUBMODULE = 'UPDATE_FAIL_SUBMODULE';
export const UPDATE_SUCCESS_SUBMODULE = 'UPDATE_SUCCESS_SUBMODULE';

export const UPDATE_SORT_SUBMODULE = 'UPDATE_SORT_SUBMODULE';

export const fetchSubModules = (siteId, course, parent) => {
  return async dispatch => {
    dispatch({ type: FETCHING_SUBMODULES });
    try {
      const response = await network.get(`site/${siteId}/course/${course}/modules?parentModule=${parent}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_SUBMODULES,
        });
      } else {
        const ordered = response.data.sort((a, b) => (a.order - b.order));
        dispatch({
          parent,
          data: ordered,
          type: SUCCESS_FETCH_SUBMODULES,
        });
        return ordered;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_SUBMODULES,
      });
    }
  };
};

export const postSubModule = (siteId, course, body) => {
  return async dispatch => {
    dispatch({ type: POST_SUBMODULE });
    try {
      const { data } = await network.post(`site/${siteId}/course/${course}/modules`, body);
      dispatch({
        data,
        type: POST_SUCCESS_SUBMODULE,
      });
      return data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_SUBMODULE,
      });
      throw error;
    }
  };
};

export const deleteSubModule = (siteId, course, id) => {
  return async dispatch => {
    dispatch({ type: DELETE_SUBMODULE });
    try {
      await network.del(`site/${siteId}/course/${course}/modules/${id}`);
      dispatch({
        type: DELETE_SUCCESS_SUBMODULE,
      });
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_SUBMODULE,
      });
      throw error;
    }
  };
};

export const updateSubModule = (siteId, course, id, body) => {
  return async dispatch => {
    dispatch({ type: UPDATE_SUBMODULE });
    try {
      await network.put(`site/${siteId}/course/${course}/modules/${id}`, body);
      dispatch({
        type: UPDATE_SUCCESS_SUBMODULE,
      });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_SUBMODULE,
      });
    }
  };
};

export const updateSubModuleSortLocal = (newSort, parent) => {
  return async dispatch => {
    try {
      dispatch({
        parent,
        data: newSort,
        type: UPDATE_SORT_SUBMODULE,
      });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_SUBMODULE,
      });
    }
  };
}

export const updateSubModuleSort = (site, course, newSort) => {
  return async dispatch => {
    try {
      const modules = newSort.map((item, order) => ({ ...item, order }));
      await network.put(`site/${site}/course/${course}/modules/orderModules`, { modules });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_SUBMODULE,
      });
    }
  };
}