import axios from 'axios';
import { PubSub } from 'aws-amplify';
import network from '../utils/network';

const signedUrl = async (
  site, 
  { name, type }, 
  { courseId=``, moduleId=``, contentId=``},
  isPublic = false) => {
  const url = `site/${site}/attachment/sign?key=${name}&filetype=${type}&courseId=${courseId}&moduleId=${moduleId}&contentId=${contentId}&expires=300&isPublic=${isPublic}&duration=10`;
  const { data } = await network.get(url);
  return data;
}

const putFile = async (site, file, specific, isPublic, onProgress, onSigning) => {
  const { url, fileId } = await signedUrl(site, file, specific, isPublic);
  await onSigning()
  const uploadResponse = await axios.put(url, file, {
    headers: {
      'Content-Disposition': `Attachment; filename=${file.name}`
    },
    onUploadProgress: onProgress,
  })
  if (uploadResponse.status !== 200) {
    throw Error(uploadResponse.statusText)
  }
  return fileId;
};

export const listening = (site, fileId) => {
  console.log('listening...', `uploads/${fileId}`);
  return new Promise((resolve, reject) => {
    PubSub.subscribe(`uploads/${fileId}`).subscribe({
      next: data => resolve(data.value),
      error: error => reject(error),
      close: () => { console.log('PubSub.subscribe close') },
    });
  });
}
export const listeningUpload = (fileId) => {
  console.log('listeningUpload...', `rawUploads/${fileId}`);
  return new Promise((resolve, reject) => {
    PubSub.subscribe(`rawUploads/${fileId}`).subscribe({
      next: data => resolve(data.value),
      error: error => reject(error),
      close: () => { console.log('PubSub.subscribe close') },
    });
  });
}

export const uploadFile = async (site, file, specific = {}, listen = true, isPublic = false, onProgress = () => ({}), onSigning = () => ({})) => {
  try {
    const fileId = await putFile(site, file, specific, isPublic, onProgress, onSigning);
    if(!listen) return fileId;
    const { url } = await listening(site, fileId);
    return url;
  } catch (error) {
    console.log('error', error);
  }
}

export const unlock = async (site, url) => {
  try {
    const { data } = await network.get(`/site/${site}/content/sign?url=${url}&expires=10&duration=10`);
    return data;
  } catch (error) {
    console.log('unlock error', error);
  }
}

export const thumbnails = async (site, id, body) => {
  try {
    const { data } = await network.post(`/site/${site}/video/${id}/thumbnails`, body);
    return data;
  } catch (error) {
    console.log('unlock error', error);
  }
}
