import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';
import {Label, Input, Row, Link, Button} from '../StyledComponents';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import styled from 'styled-components';

class ForgotPassword extends Component {
  state = {
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPassword(this.state.email);
      this.props.history.push('/forgotpasswordemailsent');
    }
    catch (error) {
      console.log(error);
    }

  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <>
        <Navbar />
        <section style={styles.container} className="auth">
          <Box className="content">
            <h1 style={styles.title}>¿Olvidaste tu contraseña?</h1>
            <p style={styles.subtitle}>
              Ingresa la cuenta de correo electrónico que
              registraste para recibir instrucciones para
              restablecer tu contraseña
            </p>
            <hr style={{marginBottom: 0, marginTop: 0}} />
            <FormErrors formerrors={this.state.errors} />

            <form style={{marginTop: 32}} onSubmit={this.forgotPasswordHandler}>

              <div style={{ display: 'flex', flexDirection: 'column'}} className="field">
                <Label style={{ fontSize: 16, lineHeight: '16px'}}>Correo electrónico</Label>
                <Input
                  type="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                  aria-describedby="emailHelp"
                />
              </div>
              <Row justify="center">
                <Label style={{fontSize: 14, fontWeight: '500'}}>Recorde mi contraseña <Link style={{fontSize: 14,fontWeight: '600'}} className="link link-bold" href="/login">
                  Iniciar Sesión
                    </Link>
                </Label>
              </Row>
              <div style={{marginTop: 12}} className="field">
                <p className="control">
                  <Button style={{fontSize: 16, padding: 10}} backgroundColor="#1a9e75" color="#fff">Enviar Instrucciones</Button>
                </p>
              </div>
            </form>
          </Box>
        </section>
        <Footer />
      </>
    );
  }
}

const styles = {
  container: {
    width: '100%',
    padding: 30,
    minHeight: 'calc(100vh - 206.99px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f8fa'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 30,
    marginBottom: 15
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    color: '#959ca8',
    lineHeight: '28px',
    marginBottom: 20
  }
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
`;

export const Box = styled.div`
  padding: 50px 65px;
  width: 570px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;

export default ForgotPassword;
