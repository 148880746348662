import styled from 'styled-components';

export const Container = styled.div`
flex: 1;
    padding: 15px 21px 26px 26px;
`

export const Section = styled.section`
    flex: 1;
    min-height: 80vh;
    flex-wrap: wrap;
    justify-content: flex-start;
`
export const Header = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const Title = styled.div`
    font-size: 38px;
    color: #004976;
    line-height: 38px;
    font-family: Montserrat;
    font-weight: 400;
`
export const ContactCount = styled.div`
    font-family: Montserrat;
    font-size: 14px;
    margin-left: 9px;
    color: #606060;
    font-weight: 600;
`
export const Actions = styled.div`
    column-gap: 20px;
    display: grid;
`
export const ActionsButton = styled.button`
    font-size: 14px;
    border-radius: 4px;
    padding: 7px, 15px, 7px, 15px;
`
export const TableContainer = styled.div`
        marginTop: 0
`
export const theme = {
    overrides: {
        MuiTable: {
            root: {}
        },
        MuiPaper: {
            root: {
                boxShadow: 'none !important'
            }
        },
        MuiToolbar: {
            root: {
                padding: '0px !important',
                marginBottom: 20,
            }
        },
        MUIDataTableHeadRow: {
            root: {
                background: '#f6f6f6 !important'
            }
        },
        MuiTableRow: {
            root: {
                cursor: 'pointer'
            }
        },
        MuiTableCell: {
            head: {
                fontFamily: 'Montserrat',
                verticalAlign: 'middle !important',
                fontWeight: '900',
                fontSize: 16,
                background: 'transparent !important',
                border: 'none !important'
            },
            body: {
                textAlign: 'left !important',
                verticalAlign: 'middle !important',
                fontFamily: 'Montserrat',
                fontWeight: '600',
                padding: '.75em .75em !important',
                fontSize: 13,
                '& div': {
                    fontFamily: 'Montserrat !important',
                    fontWeight: '600',
                },
                '&:first-child': {
                    borderLeft: '1px solid rgba(224, 224, 224, 1)'
                }
            },
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            },
            footer: {
                paddingRight: '0px !important',
                paddingLeft: '0px !important'
            }
        }
    }
}
