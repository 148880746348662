/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { TextField, makeStyles, FormControl, useMediaQuery, CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { updateSite, stripeConnect, getSite } from '../../helpers/sites'
import { loadSites, fetchSite } from '../../redux/actions/sites'
import { filterChanges, makeToast } from '../../utils'
import '../../App.css'

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
})

const generateStyles = isMobile => {
  return {
    container: {
      width: '100%',
      minHeight: '90vh',
      padding: '15px 21px 26px 26px',

    },
    input: {
      height: 56,
      borderRadius: 14
    },
    content: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      padding: '30px 30px',
      rowGap: 20
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderBottom: 'gainsboro 2px solid'
    },
    title: {
      fontSize: 34,
      color: '#024976',
      fontFamily: 'Montserrat',
      lineHeight: '38px'
    },
    subtitle: {
      fontSize: 18,
      color: '#7d8595',
      fontFamily: 'Montserrat',
      textAlign: 'left',
      marginTop: 8
    },
    section: {
      flex: 1,
      display: 'flex',
      padding: '30px 30px',
      flexDirection: 'column'
    },
    sectionTitle: {
      color: '#144974',
      fontSize: 24,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      textAlign: 'left'
    },
    form: {
      marginTop: 15,
      width: '100%'
    },
    actions: {
      marginTop: 15,
      width: '100%',
      display: 'flex'
    },
    field: {
      textAlign: 'left',
      marginBottom: 20
    },
    stripeSectionTitle: {
      color: 'rgb(20, 73, 116)',
      fontSize: 18,
      fontFamily: 'Montserrat',
      fontWeight: '600',
      textAlign: 'left',
      paddingTop: 16
    },
    stripeItemName: {
      fontSize: 16,
      color: 'rgb(125, 133, 149)',
      fontFamily: 'Montserrat',
      textAlign: 'left'
    },
    stripeItemValue: {
      fontSize: 16,
      color: 'rgb(125, 133, 149)',
      fontFamily: 'Montserrat',
      textAlign: 'left',
      paddingLeft: 18
    },
    card: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    cardBankIconContainer: {
      borderRadius: 6,
      backgroundColor: '#e3e8ee',
      padding: 8
    },
    cardHeader: {
      display: 'flex',
      columnGap: 12
    },
    cardBank: {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      color: 'rgb(20, 73, 116)'
    },
    cardCurrency: {
      textTransform: 'uppercase',
      backgroundColor: '#e3e8ee',
      color: '#6a7386',
      fontWeight: 900,
      fontFamily: 'Montserrat',
      fontSize: 14,
      padding: '2px 8px',
      borderRadius: 6
    },
    cardDefaultForCurrency: {
      backgroundColor: '#d5ebff',
      color: '#4759b2',
      fontWeight: 900,
      fontFamily: 'Montserrat',
      fontSize: 14,
      padding: '2px 8px',
      borderRadius: 6
    },
    cardBody: {
      display: 'flex',
      columnGap: 12
    },
    cardRoutingIcon: {
      width: 13,
      height: 13
    },
    cardRoutingNumber: {
      color: 'rgb(106, 115, 134)',
      fontWeight: '900',
      fontFamily: 'Montserrat',
      fontSize: 16,
      padding: '0px 8px'
    },
    cardLast4: {
      color: 'rgb(106, 115, 134)',
      fontWeight: '900',
      fontFamily: 'Montserrat',
      fontSize: 16,
      padding: '0px 8px'
    }
  }
}

function Settings(props) {
  const isMediumDevice = useMediaQuery('(max-width: 980px)')
  const isMobile = useMediaQuery('(max-width: 768px)')

  const classes = useStyles()

  const { sites } = props

  const [data, setData] = useState({})
  const [title, setTitle] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [zoomLoading, setZoomLoading] = useState(true)
  const [description, setDescription] = useState('')
  const [emailSupport, setEmailSupport] = useState('')
  const [stripeAccount, setStripeAccount] = useState(null)
  const [stripeLoading, setStripeLoading] = useState(false)
  const [gogoleTag, setGogoleTag] = useState('')
  const [baseUrl, setBaseUrl] = useState('')
  const [tiktokPixel, setTiktokPixel] = useState('')
  const [facebookPixel, setFacebookPixel] = useState('')

  useEffect(() => {
    preload()
  }, [sites.defaultSite.title, sites.defaultSite.description])

  const preload = async () => {
    try {
      await props.fetchSite(sites.defaultSite.id)
      await setData(sites.defaultSite)
      await setTitle(sites.defaultSite.title || '')
      await setStripeAccount(sites.defaultSite.stripeAccount || {})
      await setGogoleTag(sites.defaultSite.gogoleTag || '')
      await setTiktokPixel(sites.defaultSite.tiktokPixel || '')
      await setDescription(sites.defaultSite.description || '')
      await setEmailSupport(sites.defaultSite.emailSupport || '')
      await setFacebookPixel(sites.defaultSite.facebookPixel || '')
      await setBaseUrl(sites.defaultSite.baseUrl || '')
      await setZoomLoading(false)
    } catch (error) {
      console.log(error)
      makeToast(enqueueSnackbar, error, 'error')
    }
    loadSiteInfo()
  }

  const validate = () => {
    if (title.trim() === '')
      return { success: false, error: 'Título del sitio requerido' }
    if (description.trim() === '')
      return { success: false, error: 'Descripción del sitio requerido' }
    return { success: true }
  }

  const loadSiteInfo = async () => {
    setStripeLoading(true)
    try {
      const siteId = sites.defaultSite.id
      let res = await getSite(siteId)
      setStripeAccount(res.stripeAccount)
    } catch (error) {
      console.log('handleStripeConnect', error)
      makeToast(enqueueSnackbar, error, 'error')
    }
    setStripeLoading(false)
  }

  const handleSubmit = async (ev, extras = {}) => {
    ev && ev.preventDefault()
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true)
      try {
        const siteId = sites.defaultSite.id
        const body = {
          title,
          description,
          emailSupport,
          gogoleTag,
          tiktokPixel,
          facebookPixel,
          baseUrl,
          ...extras,
        }
        makeToast(enqueueSnackbar, 'Guardando', 'info')
        const newBody = filterChanges(body, data)
        console.log('body, data, newBody', body, data, newBody)
        await updateSite(siteId, newBody)
        makeToast(enqueueSnackbar, 'El sitio ha sido actualizado')
        props.loadSites()
      } catch (error) {
        console.log('handleSubmit', error)
        makeToast(enqueueSnackbar, error, 'error')
      }
      setLoading(false)
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const handleStripeConnect = async ev => {
    ev.preventDefault()
    await setLoading(true)
    try {
      const siteId = sites.defaultSite.id
      makeToast(enqueueSnackbar, 'Conectando', 'info')
      let res = await stripeConnect(siteId)
      makeToast(enqueueSnackbar, 'Conexion exitosa')
      window.open(res.url)
    } catch (error) {
      console.log('handleStripeConnect', error)
      makeToast(enqueueSnackbar, error, 'error')
    }
    setLoading(false)
  }

  const stripeConnected = () => {
    return stripeAccount && stripeAccount != null
      ? stripeAccount.charges_enabled === true
        ? true
        : false
      : false
  }

  const styles = generateStyles(isMobile)

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.title}>Configuración</div>
          <div style={styles.subtitle}>Configuración del sitio</div>
        </div>
        <div style={styles.content}>
          <div style={styles.section}>
            <div style={{ marginBottom: 25 }}>
              <div style={styles.sectionTitle}>General</div>
              <div style={styles.subtitle}>
                Configuración general de la tienda
              </div>
              <FormControl style={styles.form} onSubmit={e => handleSubmit(e)}>
                <div style={styles.field} className='field'>
                  <TextField
                    id='title'
                    type='text'
                    value={title}
                    disabled={loading}
                    label='Título del sitio'
                    aria-describedby='titleHelp'
                    onChange={e => setTitle(e.target.value)}
                    variant='outlined'
                    classes={{ root: classes.textFieldRoot }}
                  />
                </div>
                <div style={styles.field} className='field'>
                  <TextField
                    id='emailSupport'
                    type='textarea'
                    disabled={loading}
                    value={emailSupport}
                    label='Email de soporte'
                    aria-describedby='emailSupportHelp'
                    onChange={e => setEmailSupport(e.target.value)}
                    variant='outlined'
                    classes={{ root: classes.textFieldRoot }}
                  />
                </div>
                <div style={styles.field} className='field'>
                  <TextField
                    id='baseUrl'
                    type='text'
                    disabled={loading}
                    value={baseUrl}
                    label='URL Base'
                    aria-describedby='baseUrlHelp'
                    onChange={e => setBaseUrl(e.target.value)}
                    variant='outlined'
                    classes={{ root: classes.textFieldRoot }}
                  />
                </div>
                {/* <div style={{ ...styles.actions, marginTop: 16 }}>
                  <button
                    onClick={e => handleSubmit(e)}
                    className='button is-light'
                  >
                    Guardar
                  </button>
                </div> */}
              </FormControl>
            </div>
            <div style={{ marginBottom: 25 }}>
              <div style={styles.sectionTitle}>Integraciones</div>
              <div style={styles.subtitle}>
                Ingresa los id's para configurar integraciones con terceros
              </div>
              <FormControl style={styles.form} onSubmit={e => handleSubmit(e)}>
                <div style={styles.field} className='field'>
                  <TextField
                    type='text'
                    value={gogoleTag}
                    disabled={loading}
                    variant='outlined'
                    label='Google analytics TAG'
                    aria-describedby='titleHelp'
                    onChange={e => setGogoleTag(e.target.value)}
                    classes={{ root: classes.textFieldRoot }}
                  />
                </div>
                <div style={styles.field} className='field'>
                  <TextField
                    type='text'
                    disabled={loading}
                    variant='outlined'
                    value={facebookPixel}
                    label='Facebook Pixel ID'
                    aria-describedby='titleHelp'
                    onChange={e => setFacebookPixel(e.target.value)}
                    classes={{ root: classes.textFieldRoot }}
                  />
                </div>
                {/* <div style={styles.field} className='field'>
                  <TextField
                    type='text'
                    disabled={loading}
                    variant='outlined'
                    value={tiktokPixel}
                    label='Tiktok Pixel ID'
                    aria-describedby='titleHelp'
                    onChange={e => setTiktokPixel(e.target.value)}
                    classes={{ root: classes.textFieldRoot }}
                  />
                </div> */}
                <div style={{ ...styles.actions, marginTop: 16 }}>
                  <button
                    onClick={e => handleSubmit(e)}
                    className='button is-light'
                  >
                    Guardar
                  </button>
                </div>
              </FormControl>
            </div>
          </div>
          <div style={styles.section}>
            <div style={{ marginBottom: 25 }}>
              <div style={styles.sectionTitle}>Correos Electrónicos</div>
              <div style={styles.subtitle}>
                Configuración general para correos electrónicos de salida
              </div>
              <div style={{ ...styles.actions }}>
                <div
                  style={{ width: '100%', textAlign: 'left', marginBottom: 20 }}
                  className='field'
                >
                  <Link to="/admin/settings/emails" >Administrar mensajes por defecto en correos transaccionales</Link>
                </div>
              </div>
            </div>


            <div style={{ marginBottom: 25 }}>
              <div style={styles.sectionTitle}>Zoom</div>
              <div style={styles.subtitle}>Configurar eventos con zoom</div>
              <div style={{ ...styles.actions }}>
                {
                  zoomLoading ? (
                    <CircularProgress style={{ color: '#00A8D8' }} />
                  ) : (
                    <div style={{ display: 'flex', columnGap: 42, flexDirection: 'column' }}>
                      {
                        sites.defaultSite.zoom && (
                          <div style={{ overflowWrap: 'anywhere', textAlign: 'left' }}>
                            <b style={{ color: 'rgb(20, 73, 116)' }}>Zoom email: </b> {sites.defaultSite.zoom.email}<br />
                            <b style={{ color: 'rgb(20, 73, 116)' }}>Zoom fullname: </b> {sites.defaultSite.zoom.first_name} {sites.defaultSite.zoom.last_name}<br />
                            <b style={{ color: 'rgb(20, 73, 116)' }}>Zoom personal meeting url: </b> {sites.defaultSite.zoom.personal_meeting_url}<br />
                            <b style={{ color: 'rgb(20, 73, 116)' }}>Zoom account id: </b> {sites.defaultSite.zoom.account_id}<br />
                            <b style={{ color: 'rgb(20, 73, 116)' }}>Zoom status: </b> {sites.defaultSite.zoom.status}<br />
                          </div>
                        )
                      }
                      <div>
                        <a href="https://zoom.us/oauth/authorize?response_type=code&client_id=DtiZzCAFRQuO5y6l0g_4fw&redirect_uri=https%3A%2F%2Fdev.l3rn.io%2Fzoom-auth">
                          {sites.defaultSite.zoom ? 'Re-conectar con' : 'Conectar con'} <img src="/images/zoom.svg" alt="zoom" />
                        </a>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

            <div style={{ marginBottom: 25 }}>
              <div style={styles.sectionTitle}>Pagos</div>
              <div style={styles.subtitle}>Configurar pagos con stripe</div>
              <div style={{ ...styles.actions }}>
                {stripeLoading ? (
                  <CircularProgress style={{ color: '#00A8D8' }} />
                ) : stripeConnected() ? (
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 42,
                      flexDirection: 'column'
                    }}
                  >
                    <table style={styles.stripe}>
                      <tbody>
                        <tr>
                          <td style={styles.stripeSectionTitle} colSpan='5'>
                            Negocio
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.stripeItemName}>Nombre</td>
                          <td style={styles.stripeItemValue}>
                            {stripeAccount.business_profile.name}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.stripeItemName}>URL</td>
                          <td style={styles.stripeItemValue}>
                            {stripeAccount.business_profile.url}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.stripeItemName}>País</td>
                          <td style={styles.stripeItemValue}>
                            {stripeAccount.country}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.stripeItemName}>Moneda</td>
                          <td style={styles.stripeItemValue}>
                            {stripeAccount.default_currency}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={styles.stripe}>
                      <tbody>
                        <tr>
                          <td style={styles.stripeSectionTitle} colSpan='5'>
                            Capacidades
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.stripeItemName}>Transferencias</td>
                          <td style={styles.stripeItemValue}>
                            {stripeAccount.capabilities.transfers === 'active'
                              ? 'activas'
                              : 'inactivas'}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...styles.stripeSectionTitle,
                              paddingBottom: 8
                            }}
                            colSpan='5'
                          >
                            Tarjetas
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              ...styles.stripeItemValue,
                              paddingLeft: 0
                            }}
                            colSpan='5'
                          >
                            {stripeAccount.external_accounts.data.map(
                              (element, index) => (
                                <div key={index} style={styles.card}>
                                  <div style={styles.cardBankIconContainer}>
                                    <AccountBalanceIcon
                                      style={{
                                        width: 30,
                                        height: 30,
                                        fill: '#6a7386'
                                      }}
                                    />
                                  </div>
                                  <div style={{ paddingLeft: 12 }}>
                                    <div style={styles.cardHeader}>
                                      <div style={styles.cardBank}>
                                        {element.bank_name}
                                      </div>
                                      <div style={styles.cardCurrency}>
                                        {element.currency}
                                      </div>
                                      {element.default_for_currency && (
                                        <div
                                          style={styles.cardDefaultForCurrency}
                                        >
                                          Default
                                        </div>
                                      )}
                                    </div>
                                    <div style={styles.cardBody}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <img
                                          style={styles.cardRoutingIcon}
                                          src='/images/card-routing.png'
                                        />
                                        <div style={styles.cardRoutingNumber}>
                                          {element.routing_number}
                                        </div>
                                        <img
                                          style={styles.cardRoutingIcon}
                                          src='/images/card-routing.png'
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {[...Array(4)].map((e, i) => (
                                          <FiberManualRecordIcon
                                            key={i}
                                            style={{ width: 10, height: 10 }}
                                          />
                                        ))}
                                      </div>
                                      <div style={styles.cardLast4}>
                                        {element.last4}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <button
                    onClick={e => handleStripeConnect(e)}
                    className='button'
                    style={{ width: 220, padding: 0, border: 0 }}
                  >
                    <img src='/images/stripe-connect.png' />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ loadSites, fetchSite }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
