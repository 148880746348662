import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_EMAIL_TEMPLATES = 'FETCHING_EMAIL_TEMPLATES';
export const FAIL_FETCH_EMAIL_TEMPLATES = 'FAIL_FETCH_EMAIL_TEMPLATES';
export const SUCCESS_FETCH_EMAIL_TEMPLATES = 'SUCCESS_FETCH_EMAIL_TEMPLATES';

export const FETCHING_EMAIL_TEMPLATES_SYSTEM = 'FETCHING_EMAIL_TEMPLATES_SYSTEM';
export const FAIL_FETCH_EMAIL_TEMPLATES_SYSTEM = 'FAIL_FETCH_EMAIL_TEMPLATES_SYSTEM';
export const SUCCESS_FETCH_EMAIL_TEMPLATES_SYSTEM = 'SUCCESS_FETCH_EMAIL_TEMPLATES_SYSTEM';

export const FETCHING_EMAIL_TEMPLATE = 'FETCHING_EMAIL_TEMPLATE';
export const FAIL_FETCH_EMAIL_TEMPLATE = 'FAIL_FETCH_EMAIL_TEMPLATE';
export const SUCCESS_FETCH_EMAIL_TEMPLATE = 'SUCCESS_FETCH_EMAIL_TEMPLATE';

export const POST_EMAIL_TEMPLATE = 'POST_EMAIL_TEMPLATE';
export const POST_FAIL_EMAIL_TEMPLATE = 'POST_FAIL_EMAIL_TEMPLATE';
export const POST_SUCCESS_EMAIL_TEMPLATE = 'POST_SUCCESS_EMAIL_TEMPLATE';

export const PUT_EMAIL_TEMPLATE = 'PUT_EMAIL_TEMPLATE';
export const PUT_FAIL_EMAIL_TEMPLATE = 'PUT_FAIL_EMAIL_TEMPLATE';
export const PUT_SUCCESS_EMAIL_TEMPLATE = 'PUT_SUCCESS_EMAIL_TEMPLATE';

export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
export const DELETE_FAIL_EMAIL_TEMPLATE = 'DELETE_FAIL_EMAIL_TEMPLATE';
export const DELETE_SUCCESS_EMAIL_TEMPLATE = 'DELETE_SUCCESS_EMAIL_TEMPLATE';

export const fetchEmailTemplates = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_EMAIL_TEMPLATES });
    try {
      let url = parseUrl(`site/${siteId}/emailTemplate?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_EMAIL_TEMPLATES,
        });
      } else { 
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_EMAIL_TEMPLATES,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_EMAIL_TEMPLATES,
      });
    }
  };
};

export const fetchEmailTemplatesSystem = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_EMAIL_TEMPLATES_SYSTEM });
    try {
      let url = parseUrl(`site/${siteId}/emailTemplate?system=true&`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_EMAIL_TEMPLATES_SYSTEM,
        });
      } else { 
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_EMAIL_TEMPLATES_SYSTEM,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_EMAIL_TEMPLATES_SYSTEM,
      });
    }
  };
};

export const fetchEmailTemplate = (siteId, emailTemplate) => {
  return async dispatch => {
    dispatch({ type: FETCHING_EMAIL_TEMPLATE });
    try {
      const response = await network.get(`site/${siteId}/emailTemplate/${emailTemplate}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_EMAIL_TEMPLATE,
        });
      } else {
        dispatch({
          emailTemplate: response.data,
          type: SUCCESS_FETCH_EMAIL_TEMPLATE,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_EMAIL_TEMPLATE,
      });
    }
  };
};

export const fetchAllEmailTemplates = (siteId) => {
  return async dispatch => {
    try {
      const response = await network.get(`site/${siteId}/emailTemplate/all`);
      if (response.data && response.data.error) {
        throw response.data.error
      } else {
        return response.data
      }
    } catch (error) {
      throw error
    }
  }
};

export const postEmailTemplate = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_EMAIL_TEMPLATE });
    try {
      const response = await network.post(`site/${siteId}/emailTemplate`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_EMAIL_TEMPLATE,
      });
      return response.data
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_EMAIL_TEMPLATE,
      });

    }
  };
};

export const putEmailTemplate = (siteId, emailTemplate, body) => {
  return async dispatch => {
    dispatch({ type: POST_EMAIL_TEMPLATE });
    try {
      const response = await network.put(`site/${siteId}/emailTemplate/${emailTemplate}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_EMAIL_TEMPLATE,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_EMAIL_TEMPLATE,
      });
    }
  };
};

export const putEmail = (siteId, email, body) => {
  return async dispatch => {
    dispatch({ type: POST_EMAIL_TEMPLATE });
    try {
      const response = await network.put(`site/${siteId}/email/${email}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_EMAIL_TEMPLATE,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_EMAIL_TEMPLATE,
      });
    }
  };
};

export const deleteEmailTemplate = (siteId, ids = []) => {
  return async dispatch => {
    dispatch({ type: DELETE_EMAIL_TEMPLATE });
    try {
      const promises = ids.map(async item => {
        await network.del(`site/${siteId}/emailTemplate/${item}`);
      });
      const response = await Promise.all(promises)
      dispatch({
        type: DELETE_SUCCESS_EMAIL_TEMPLATE,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_EMAIL_TEMPLATE,
      });
      return error;
    }
  };
};