import network from '../utils/network';

export const getCourse = async (site, id) => {
  try {
    const { data } = await network.get(`site/${site}/course/${id}`);
    return data;
  } catch (error) {
    console.error('getCourse', error);
    return error;
  }
}

export const getModule = async (site, course, id) => {
  try {
    const { data } = await network.get(`site/${site}/course/${course}/modules/${id}`);
    return data;
  } catch (error) {
    console.error('getCourse', error);
    return error;
  }
}

export const getContent = async (site, course, module, id) => {
  try {
    const { data } = await network.get(`site/${site}/course/${course}/modules/${module}/content/${id}`);
    return data;
  } catch (error) {
    console.error('getCourse', error);
    return error;
  }
}

export const getContentInstructors = async (site, courseId, id) => {
  try {
    const { data } = await network.get(`site/${site}/instructors?courseId=${courseId}&contentId=${id}`);
    return data;
  } catch (error) {
    console.error('getContentInstructors', error);
    return error;
  }
}

export const postContentInstructors = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/instructors/content`, body);
    return data;
  } catch (error) {
    console.error('postContentInstructors', error);
    return error;
  }
}

export const deleteContentMedia = async (site, course, module, id) => {
  try {
    const { data } = await network.del(`site/${site}/course/${course}/modules/${module}/content/${id}/media`);
    return data;
  } catch (error) {
    console.error('deleteContentMedia', error);
    return error;
  }
}

export const getSubModules = async (site, course, id) => {
  try {
    const { data } = await network.get(`site/${site}/course/${course}/modules?parentModule=${id}`);
    return data;
  } catch (error) {
    console.error('getCourse', error);
    return error;
  }
}

export const updateModule = async (site, course, id, body) => {
  try {
    const { data } = await network.put(`site/${site}/course/${course}/modules/${id}`, body);
    return data;
  } catch (error) {
    console.error('getCourse', error);
    return error;
  }
}

export const updateCourse = async (site, name, body) => {
  try {
    const { data } = await network.put(`site/${site}/course/${name}`, body);
    return data;
  } catch (error) {
    console.error('updateCourse', error);
    return error;
  }
}

export const deleteCourse = async (site, name) => {
  try {
    await network.del(`site/${site}/course/${name}`);
  } catch (error) {
    console.error('updateCourse', error);
    return error;
  }
}

export const deleteModule = async (site, course, module) => {
  try {
    await network.del(`site/${site}/course/${course}/modules/${module}`);
  } catch (error) {
    console.error('updateCourse', error);
    return error;
  }
}

export const createCourse = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/course`, body);
    if (data.error) {
      throw(data.error);
    }
    return data;
  } catch (error) {
    console.error('createCourse', error);
    return error;
  }
}