import React from 'react'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import Apps from '@material-ui/icons/Apps'
import { CircularProgress, Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Refresh from '@material-ui/icons/Refresh'
import DeleteIcon from '@material-ui/icons/Delete'
import ViewList from '@material-ui/icons/ViewList'
import InfiniteScroll from 'react-infinite-scroller'
import IconButton from '@material-ui/core/IconButton'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { debounceSearchRender } from './DebounceSearchRender'
import { Alert } from '../components/Alert'
import TableFooter from './TableFooter'

const theme = {
  overrides: {
    MuiTable: {
      root: {}
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 20,
        marginTop: 20,
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        overflowWrap: 'anywhere',

        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600'
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

export default class DataTable extends React.PureComponent {
  state = {
    rowsSelected: []
  }

  componentDidMount() {
    this.props.preload()
  }

  sort = tableState => {
    this.props.sort(tableState)
  }

  changePageSize = tableState => {
    this.props.changePageSize(tableState)
  }

  changePage = tableState => {
    this.props.changePage(tableState)
  }

  search = tableState => {
    this.props.search(tableState)
  }

  getMuiTheme = () => createMuiTheme(theme)

  render() {
    const { table } = this.state
    const {
      columns,
      title,
      data,
      limit,
      count,
      offset,
      sortOrder,
      onRowPress,
      selectableRows = true
    } = this.props

    const options = {
      filter: false,
      serverSide: true,
      count: count,
      rowsPerPage: limit,
      sortOrder: sortOrder,
      print: true,
      download: true,
      selectableRows,
      viewColumns: true,
      search: false,
      searchText: ' ',
      searchOpen: true,
      rowsSelected: this.state.rowsSelected,
      filterType: 'dropdown',
      responsive: 'vertical',
      rowsPerPageOptions: [25, 50, 100],
      onRowClick: onRowPress,
      customSearchRender: debounceSearchRender(500),
      onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
        this.setState({ rowsSelected: rowsSelected })
      },
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => (
        <TableFooter
          count={count}
          offset={offset}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={value => {
            changeRowsPerPage(value)
            changePage(0)
          }}
          rowsPerPageOptions={[25, 50, 100]}
          onChangePage={(_, page) => changePage(page)}
        />
      ),
      onRowsDelete: (rowsDeleted, dataRows) => {
        return true
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <Tooltip title='Eliminar'>
          <IconButton
            onClick={() => {
              const selected = []
              const row = []
              const idsToDelete = selectedRows.data.map(item => item.dataIndex)
              for (let index of idsToDelete) {
                row.push(data[index])
                selected.push(data[index].id)
              }
              Alert({
                title: '¿Estas seguro?',
                message: `Se eliminaran ${selectedRows.data.length} ${selectedRows.data.length > 1 ? 'elementos' : 'elemento'
                  }`,
                onSuccess: async () => {
                  this.props.onRowsDelete &&
                    (await this.props.onRowsDelete(selected, row))
                  this.setState({ rowsSelected: [] })
                },
                onFail: () => ({})
              })
            }}
          >
            <DeleteIcon style={{ fill: '#f00' }} />
          </IconButton>
        </Tooltip>
      ),
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changeRowsPerPage':
            this.changePageSize(tableState)
            break
          case 'changePage':
            this.changePage(tableState)
            break
          case 'sort':
            this.sort(tableState)
            break
          case 'search':
            this.search(tableState)
            break
          default:
          // console.log('action not handled.')
        }
      },
      textLabels: {
        body: {
          noMatch: 'Lo sentimos, no se encontraron registros coincidentes',
          toolTip: 'Ordenar'
        },
        pagination: {
          next: 'Siguiente pagina',
          previous: 'Pagina anterior',
          rowsPerPage: 'Filas por página:',
          displayRows: 'de'
        },
        toolbar: {
          search: 'Buscar',
          downloadCsv: 'Descargar CSV',
          print: 'Imprimir',
          viewColumns: 'Ver columnas',
          filterTable: 'Tabla de filtros'
        },
        filter: {
          all: 'Todo',
          title: 'Filtros',
          reset: 'REINICIAR'
        },
        viewColumns: {
          title: 'Mostrar columnas',
          titleAria: 'Mostrar / Ocultar columnas de tabla'
        },
        selectedRows: {
          text: 'fila(s) seleccionadas',
          delete: 'Eliminar',
          deleteAria: 'Eliminar filas seleccionadas'
        }
      }
    }

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
          title={
            <Typography align='left' variant='h6'>
              {title}
            </Typography>
          }
          data={data}
          options={options}
          columns={columns}
        />
      </MuiThemeProvider>
    )
  }
}
