import {
  FETCHING_COURSES,
  FAIL_FETCH_COURSES,
  SUCCESS_FETCH_COURSES,
  FETCHING_COURSES_INFINITE,
  FAIL_FETCH_COURSES_INFINITE,
  SUCCESS_FETCH_COURSES_INFINITE,
  FETCHING_COURSE,
  FAIL_FETCH_COURSE,
  SUCCESS_FETCH_COURSE,
  PUT_COURSE,
  PUT_FAIL_COURSE,
  PUT_SUCCESS_COURSE,
  DELETE_COURSE,
  DELETE_FAIL_COURSE,
  DELETE_SUCCESS_COURSE,
  FETCHING_SUBSCRIBERS_COURSES,
  FAIL_FETCH_SUBSCRIBERS_COURSES,
  SUCCESS_FETCH_SUBSCRIBERS_COURSES
} from '../actions/courses'

const initialState = {
  data: [],
  error: null,
  loading: false,
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
  hasMore: true,
  page: 0,
  course: {
    data: {},
    error: null,
    loading: false
  },
  users: {
    data: [],
    order: {},
    count: 0,
    limit: 25,
    offset: 0,
    error: null,
    loading: false
  },
  modules: {
    data: [],
    error: null,
    loading: false
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_COURSES:
      return {
        ...state,
        error: null,
        loading: true
      }
    case SUCCESS_FETCH_COURSES:
      return {
        ...state,
        ...action.data,
        loading: false
      }
    case FAIL_FETCH_COURSES:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error
      }
    case FETCHING_COURSES_INFINITE:
      return {
        ...state,
        error: null,
        loading: true
      }
    case SUCCESS_FETCH_COURSES_INFINITE:
      return {
        ...state,
        ...action.data,
        loading: false,
        data: action.reset ? action.data.data : [...state.data, ...action.data.data],
      }
    case FAIL_FETCH_COURSES_INFINITE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error
      }
    case FETCHING_COURSE:
      return {
        ...state,
        course: {
          data: {},
          error: null,
          loading: true
        }
      }
    case SUCCESS_FETCH_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
          data: action.data
        }
      }
    case FAIL_FETCH_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
          error: action.error
        }
      }
    case PUT_COURSE:
      return {
        ...state
      }
    case PUT_FAIL_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          error: action.error
        }
      }
    case PUT_SUCCESS_COURSE:
      return {
        ...state
      }
    case DELETE_COURSE:
      return {
        ...state,
        loading: true
      }
    case DELETE_FAIL_COURSE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case DELETE_SUCCESS_COURSE:
      return {
        ...state,
        loading: false
      }
    case FETCHING_SUBSCRIBERS_COURSES:
      return {
        ...state,
        users: {
          ...state.users,
          error: null,
          loading: true
        }
      }
    case SUCCESS_FETCH_SUBSCRIBERS_COURSES:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.data,
          loading: false
        }
      }
    case FAIL_FETCH_SUBSCRIBERS_COURSES:
      return {
        ...state,
        users: {
          ...state.users,
          error: action.error,
          loading: false
        }
      }
    default:
      return state
  }
}
