import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { KeyboardBackspace, Visibility, VisibilityOff } from '@material-ui/icons';
// import { Mail, NoteAdd, ShoppingCart, MonetizationOn, DateRange } from '@material-ui/icons';
import { FormControl, CircularProgress, Avatar, FormControlLabel, FormLabel, RadioGroup, Radio, IconButton, makeStyles } from '@material-ui/core';
import { getUser, createUser, updateUser, resetUser } from '../../../helpers/users';
import { fetchUsers } from '../../../redux/actions/users';
import ActivitiesList from '../ActivitiesList';
import { Input } from '../StyledComponents';
// import ActivityItem from "../ActivityItem";
// import VisitsChart from '../VisitsChart';
import { Alert } from '../Alert';
import Loader from '../Loader';
// import Navbar from "../Navbar";

let response = {};

const useStyles = makeStyles({
  formControlLabelRoot: {
    '& .Mui-checked .MuiIconButton-label': {
      color: '#00ace7 !important'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '500',
      color: 'rgb(2, 73, 118)'
    }
  }
});

function UserCreate(props) {

  const { sites } = props;
  const classes = useStyles();
  const { user } = useParams();
  const { enqueueSnackbar } = useSnackbar()

  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [userReady, setUserReady] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [UserCreateDate, setUserCreateDate] = useState('');
  const [passwordMasked, setPasswordMasked] = useState(true);

  const loadUser = async () => {
    if (user) {
      const { data } = await getUser(sites.defaultSite.id, user);
      await setName(data.fullname);
      await setRole(data.role);
      await setEmail(data.email);
      await setSubscribed(data.email_verified);
      await setUserCreateDate(data.UserCreateDate);
    }
    setUserReady(true);
    setLoading(false);
  }

  useEffect(() => {
    loadUser();
  }, []);

  const validate = (name) => {
    if (name.trim() === '') return { success: false, error: 'Nombre requerido' };
    return { success: true }
  }

  const togglePasswordMasked = () => {
    setPasswordMasked(!passwordMasked)
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const isValid = validate(name);
    if (isValid.success) {
      await setLoading(true);
      try {
        const siteId = sites.defaultSite.id;
        if (user) {
          await updateUser(siteId, user, { 
            fullname: name, 
            name, 
            role,
            ...(password !== '' && { userPassword: password }) 
          });
        } else {
          await createUser(siteId, { 
            fullname: name, 
            name, 
            role,
            ...(password !== '' && { userPassword: password }) 
          });
        }
        props.fetchUsers(siteId);
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const onReset = async (ev) => {
    ev.preventDefault();
    Alert({
      title: '¿Estas seguro?',
      message: 'Se enviará una contraseña temporal al usuario y una liga para restablecer su contraseña',
      onFail: () => { },
      onSuccess: async () => {
        try {
          await resetUser(sites.defaultSite.id, user);
          makeToast(enqueueSnackbar, 'Se ha enviado correctamente')
        } catch (error) {
          makeToast(enqueueSnackbar, 'Ocurrio un error', 'error')
        }
      },
    });
  }

  useEffect(() => {
    if (email !== response.email) return setEnableEdit(true);
    if (name !== response.name) return setEnableEdit(true);
    if (password !== '') return setEnableEdit(true);
    setEnableEdit(false);
  }, [email, name, password]);

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={styles.container}>

        <div style={styles.header}>
          <div onClick={() => window.history.back()} style={styles.title}>
            <KeyboardBackspace style={{ fill: '#024976' }} />
                Usuarios
            </div>
        </div>

        <div style={styles.content}>

          <div style={{ ...styles.panel, ...styles.profilePanel }}>
            {
              userReady ? (
                <>
                  <div style={{ ...styles.profile, ...styles.panelSection }}>

                    <Avatar style={{ width: 70, height: 70, margin: 'auto' }} alt={name} src="#" />

                    <div style={styles.name}>
                      {name}
                    </div>

                    <div style={styles.email}>
                      {email}
                    </div>

                    <div style={styles.UserCreateDate}>
                      {`Creado el: ${moment(UserCreateDate).format('L')}`}
                    </div>

                    <div style={styles.UserCreateDate}>
                      {role}
                    </div>


                    <button
                      disabled={loading || !enableEdit}
                      onClick={(ev) => onReset(ev)}
                      className="button is-light"
                      style={styles.submitButton}
                    >
                      {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Restablecer Contraseña'}
                    </button>

                  </div>

                  <div style={styles.separator} />

                  <div style={{ ...styles.form, ...styles.panelSection }}>
                    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
                      <div style={styles.field} className="field">
                        <Input
                          id="email"
                          type="email"
                          value={email}
                          disabled={true}
                          style={styles.input}
                          placeholder="Correo"
                          aria-describedby="emailHelp"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div style={styles.field} className="field">
                        <Input
                          type="text"
                          id="name"
                          value={name}
                          disabled={loading}
                          style={styles.input}
                          placeholder="Nombre"
                          aria-describedby="nameHelp"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>

                      <div style={{...styles.field, position: 'relative'}} className="field">
                        <Input
                          id="password"
                          type={passwordMasked ? 'password' : 'text'}
                          value={password}
                          disabled={loading}
                          style={styles.input}
                          placeholder="Contraseña"
                          aria-describedby="passwordHelp"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <IconButton style={styles.passwordMaskedButton} onClick={()=>togglePasswordMasked()}>
                          { passwordMasked ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                      </div>

                      <div style={styles.field} className="field">
                        <FormLabel component="legend">Roles</FormLabel>
                        <RadioGroup aria-label="role" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
                          <FormControlLabel value="admin" control={<Radio />} label="Administrador" />
                          <FormControlLabel value="editor" control={<Radio />} label="Editor" />
                          <FormControlLabel value="reader" control={<Radio />} label="Soporte" />
                        </RadioGroup>
                      </div>
                      <button
                        disabled={loading || !enableEdit}
                        onClick={(ev) => handleSubmit(ev)}
                        className="button is-light"
                        style={styles.submitButton}
                      >
                        {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Guardar'}
                      </button>
                    </FormControl>
                  </div>
                </>
              ) : <Loader />
            }
          </div>

          <div style={{ ...styles.panel, ...styles.feedPanel }}>
            {
              userReady ? (
                <>
                  <div style={{ ...styles.panelTitle }}>Actividad</div>
                  <ActivitiesList url={`site/${sites.defaultSite.id}/collab/${user}/activity`} users={true} />
                </>
              ) : <Loader />
            }
          </div>

          {/* <div style={{...styles.panel, ...styles.statsPanel}}>

            <div style={{...styles.panelSection, paddingBottom: 15}}>
              <div style={{...styles.panelTitle}}>Estadisticas</div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <ShoppingCart style={{fill: '#00ace7'}}/>Compras
                </div>
                <div style={styles.statValue}>3</div>
              </div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <MonetizationOn style={{fill: '#00ace7'}}/>Gastado
                </div>
                <div style={styles.statValue}>100.00 $</div>
              </div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <DateRange style={{fill: '#00ace7'}}/>Última Actividad
                </div>
                <div style={styles.statValue}>10/10/2020</div>
              </div>

            </div>

            <div style={{...styles.panelSection, paddingTop: 0}}>
              <div style={{...styles.panelTitle, marginBottom: 12}}>Etiquetas</div>
              <div style={styles.tagList}>
                <div style={styles.tag}>Tag 01</div>
                <div style={styles.tag}>Tag 02</div>
                <div style={styles.tag}>Tag 03</div>
              </div>
            </div>

            <div style={{...styles.panelSection}}>
              <div style={styles.panelTitle}>Visitas</div>
              <VisitsChart
                data={visits}
                title={'Visitas'}
                color='#00ace7'
              />
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#f6f6f6',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    padding: '30px 30px 15px 30px',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    padding: '0px 30px 30px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 30
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  panel: {
    minWidth: 330,
    backgroundColor: 'white',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  profilePanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  feedPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  statsPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  panelSection: {
    padding: '20px 30px',
    width: '100%'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)',
  },
  name: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginTop: 12
  },
  email: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '500',
    color: '#565656',
    marginTop: 3
  },
  UserCreateDate: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  actions: {
    display: 'flex',
    columnGap: 10,
    justifyContent: 'space-between',
    marginTop: 15
  },
  actionButton: {
    fontSize: 14,
    padding: '26px 36px',
    borderRadius: 8
  },
  form: {
    marginTop: 10
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  passwordMaskedButton: {
    position: 'absolute',
    right: 8,
    bottom: 4
  },
  submitButton: {
    fontSize: 16,
    padding: '26px 0px',
    borderRadius: 8,
    width: '100%'
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  statValue: {
    fontFamily: 'Montserrat',
    color: '#024976'
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 8
  },
  tag: {
    backgroundColor: 'rgb(246, 246, 246)',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: 12,
    borderRadius: 10,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    color: 'rgb(177, 177, 177)'
  }
}

const activity = [
  {
    id: 1,
    type: 'note',
    text: 'Et sed gravida irna, a lorem, id a turpis diam mattis ullamcorper pulvinar. Libero tortor id risus diam congue vitae, libero urna. Sagittis sem congue pellentesque vitae nunc egestas aliquam diam porta.',
    UserCreateDate: '10/10/2020'
  },
  {
    id: 2,
    type: 'purchase',
    text: 'Valdez Bruce bought the course Lorem',
    UserCreateDate: '10/10/2020'
  },
  {
    id: 3,
    type: 'registration',
    text: 'Registered user created by',
    UserCreateDate: '10/10/2020',
    link: {
      label: 'John Doe.',
      value: 'http://#'
    }
  },
  {
    id: 4,
    type: 'form-completition',
    text: 'Completed form',
    UserCreateDate: '10/10/2020',
    link: {
      label: null,
      value: 'http://#'
    }
  },
  {
    id: 5,
    type: 'contact-created',
    text: 'Contact was created...',
    UserCreateDate: '10/10/2020'
  }
]

const visits = [
  {
    "label": "Lun",
    "value": 780
  },
  {
    "label": "Mar",
    "value": 500
  },
  {
    "label": "Mie",
    "value": 270
  },
  {
    "label": "Jue",
    "value": 760
  },
  {
    "label": "Vie",
    "value": 480
  },
  {
    "label": "Sab",
    "value": 1000
  },
  {
    "label": "Dom",
    "value": 280
  },
]

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchUsers }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);