import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Edit as EditIcon, AddCircle } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { fetchSites, deleteSites, selectSite } from "../../../redux/actions/sites";
import { makeToast } from '../../../utils'
import FullDataTable from "../CoursesMain/FullDataTable";
import ModalRight from "../ModalRight";
import Create from "./Create";
class UsersList extends React.Component {

  state = {
    edit: false,
    createModal: false,
    editModal: false,
    siteId: null
  };

  toggleCreateModal = async () => {
    await this.setState((prev) => ({ createModal: !prev.createModal }));
  }

  toggleEditModal = async () => {
    await this.setState((prev) => ({ editModal: !prev.editModal }));
  }

  close = async () => {
    await this.setState({ edit: false });
  }

  handleEditSite = (id) => {
    console.log('id', id)
    this.setState({
      siteId: id,
      createModal: false,
      editModal: true
    });
  }

  cleanEdit = async () => {
    await this.setState({
      siteId: null,
      createModal: false,
      editModal: false,
    });
    const { sites: { limit, offset } } = this.props;
    await this.props.fetchSites(limit, offset);
  }

  onRowsDelete = async (rows) => {
    try {
      await this.props.deleteSites(rows);
      makeToast(this.props.enqueueSnackbar, 'Se han eliminado los elementos')
      const { sites: { limit, offset } } = this.props;
      this.props.fetchSites(limit, offset);
    } catch (error) {
      console.log('error', error)
      makeToast(this.props.enqueueSnackbar, error, 'error')
    }
  }

  render() {
    const { createModal, editModal, siteId } = this.state;
    const { sites: { table, loading, limit, offset, count, sort } } = this.props;

    const columns = [
      {
        label: 'Título',
        name: 'title',
      },
      {
        label: 'Descripción',
        name: 'description',
      },
      {
        label: "Acciones",
        name: 'id',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value) {
              return (
                <IconButton onClick={() => this.handleEditSite(value)}>
                  <EditIcon style={{ fill: '#007bff' }} />
                </IconButton>
              );
            }
          }
        }
      }
    ];

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={styles.content} className="content">
          <div style={styles.tableContainer}>
            <FullDataTable
              data={table}
              title={'Sitios'}
              loading={loading}
              columns={columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              onRowPress={(rowData, meta, rowIndex) => {
                // this.props.history.push(`/admin/sites/view/${table[meta.dataIndex].id}`);
                this.handleEditSite(table[meta.dataIndex].id);
                // this.props.selectSite(table[meta.dataIndex]);
              }}
              onRowsDelete={this.onRowsDelete}
              preload={() => this.props.fetchSites(limit, offset)}
              changePageSize={({ rowsPerPage }) => this.props.fetchSites(rowsPerPage, offset)}
              search={({ rowsPerPage, page, sortOrder, searchText, columns }) => {
                if (searchText && searchText !== '') {
                  const names = columns.map(item => (item.name));
                  this.props.fetchSites(rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
                } else {
                  this.props.fetchSites(rowsPerPage, page, sortOrder.name, sortOrder.direction);
                }
              }}
              changePage={({ rowsPerPage, page, sortOrder }) => {
                this.props.fetchSites(rowsPerPage, page, sortOrder.name, sortOrder.direction);
              }}
              sort={({ rowsPerPage, page, sortOrder }) => {
                this.props.fetchSites(rowsPerPage, page, sortOrder.name, sortOrder.direction);
              }}
              renderCreate={() => (
                <div style={styles.actions}>
                  <button style={styles.actionButton} onClick={this.toggleCreateModal} className="button is-light">
                    <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} /> Crear Sitio
                    </button>
                </div>
              )}
            />
          </div>

        </section>
        {
          createModal && (
            <ModalRight show={createModal} onClick={this.toggleCreateModal} hideCloseButton={true}>
              <Create onCloseModal={this.cleanEdit} />
            </ModalRight>
          )
        }
        {
          editModal && (
            <ModalRight show={editModal} onClick={this.toggleEditModal} hideCloseButton={true}>
              <Create sitioId={siteId} onCloseModal={this.cleanEdit} />
            </ModalRight>
          )
        }
      </div>
    );
  }
};

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  subtitle: {
    fontSize: 18,
    color: '#7d8595',
    fontFamily: 'Montserrat',
    textAlign: 'left'
  },
  siteCount: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const mapStateToProps = ({ people, sites }) => {
  return { people, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchSites, deleteSites, selectSite }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(UsersList)));