export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const escapeText = (text = '') => {
  let result = text.toLowerCase();
  result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9-]/gi, '');
  return result;
}

export const sleep = (time = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  })
}

export const makeToast = (fn, message, type = 'success') => {
  fn(message, {
    variant: type,
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    }
  })
}

export const filterChanges = (values = {}, defaultData = {}) => {
  const body = {}
  Object.keys(defaultData).forEach((key) => {
    if (values.hasOwnProperty(key) && values[key] !== defaultData[key]) {
      body[key] = values[key];
    }
  });
  return body;
}