import React, { useState, useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import AssignCourseModal from './AssignCourseModal'
import { getUserCourses } from '../../../helpers/people'
import { AddCircle } from '@material-ui/icons';
import styled from 'styled-components'

export default function CoursesTab({ siteId, userId }) {

    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(false)
    const [assignCourseModalVisible, setAssignCourseModalVisible] = useState(false)

    useEffect(() => {
        preload();
    }, []);

    const preload = async () => {
        await setLoading(true);
        const courses = await getUserCourses(siteId, userId)
        setCourses(courses)
        await setLoading(false);
    }

    const onSubmit = async () => {
        await preload()
        await setAssignCourseModalVisible(false)
    }

    const onClose = async () => {
        await setAssignCourseModalVisible(false)
    }

    return (
        <div>
            <Header>
                <div />
                <AsignCourse
                    onClick={() => setAssignCourseModalVisible(true)}
                    className="button is-light"
                >
                    <AddCircle style={{ width: 20, height: 20, marginRight: 8 }} />Agregar Curso
                </AsignCourse>
            </Header>
            <CoursesList>
                {
                    loading && (
                        <LoaderContainer>
                            <CircularProgress />
                        </LoaderContainer>
                    )
                }
                {
                    courses.map(element => (
                        <Wrapper>
                            <Container>
                                <PreviewImage style={{ backgroundImage: `url(${element.background})` }} />
                                <Content>
                                    <Title>{element.title}</Title>
                                    <Description>{element.description}</Description>
                                </Content>
                            </Container>
                        </Wrapper>
                    ))
                }
            </CoursesList>
            <AssignCourseModal
                siteId={siteId}
                userId={userId}
                assignedCourses={courses}
                onClose={() => onClose()}
                onSubmit={() => onSubmit()}
                visible={assignCourseModalVisible}
            />
        </div>
    )
}

const Header = styled.div`
    width: 100%; 
    padding: 18px;
    display: flex;
    justify-content: space-between;
`
const AsignCourse = styled.div`
    border-radius: 4;
    padding: '7px 15px',
    backgroundColor: '#00ace7',
    marginBottom: 0,
    fontSize: 13,
`
const CoursesList = styled.div`
    width: 100%;
    display: flex; 
    flex-wrap: wrap;
    padding: 16px 12px 0 12px;
    column-gap: 10px; 
    row-gap: 10px;
`

const LoaderContainer = styled.div`
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
`

const Wrapper = styled.div`
    width: 25%;
    display: flex;
    justify-content: center;
`

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  margin-bottom: 26px;
  overflow: hidden;
  background-color: white;
  border: 0.5px solid #dcdcdc;
  display: flex;
  flex-direction: column;
`

const PreviewImage = styled.div`
    height: 141px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
`

const Content = styled.div`
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Title = styled.div`
    color: #43434a;
    font-size: 19px;
    font-family: Muli;
    font-weight: 800;
    text-align: left;
`

const Description = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 57.6px;
    text-align: left;
    margin-top: 3px;
    color: #999ea7;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
`