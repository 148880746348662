import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../../utils'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { ExpandMore, Edit, Delete, DragIndicator } from '@material-ui/icons'
import {
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  Typography,
  makeStyles,
  CircularProgress,
  Tooltip
} from '@material-ui/core'
import { Menu, MenuItem } from '@material-ui/core';
import { AddCircle, Image, Videocam, Subject } from '@material-ui/icons';
import {
  deleteModule,
  fetchModules,
  updateModule
} from '../../../../redux/actions/modules'
import {
  postSubModule,
  fetchSubModules
} from '../../../../redux/actions/submodules'
import { postContent, fetchContent } from '../../../../redux/actions/content'
import ListSubModules from './ListSubModules'
import AddSubModule from './AddSubModule'
import ListContent from './ListContent'
import AddContent from './AddContent'
import AddModule from './AddModule'
import { Alert } from '../../Alert'

const useStyles = makeStyles({
  accordionRoot: {
    boxShadow: 'none',
    borderRadius: 6,
    transition: 'background-color 0.4s',
    backgroundColor: '#f6f6f6',
    '&.Mui-expanded': {
      backgroundColor: '#e5f7fd'
    },
    '&:before': {
      visibility: 'hidden'
    }
  },
  accordionSummaryRoot: {
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
      '&.Mui-expanded': {
        transform: 'rotate(0deg)'
      },
      '& span svg': {
        fill: '#024976'
      }
    },
    '&:hover .MuiAccordionSummary-content div div:last-child': {
      visibility: 'visible !important'
    },
    '& .MuiAccordionSummary-content div div:last-child': {
      visibility: 'hidden'
    }
  },
  typographySubtitle1Root: {
    color: '#024976',
    margin: '0px !important',
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontWeight: 200,
    paddingLeft: 10
  },
  accordionActionsRoot: {
    padding: '0px 50px 20px'
  },
  accordionDetailsRoot: {
    paddingLeft: 50,
    paddingRight: 12
  },
  accordionDetailsRootAlt: {
    padding: 16
  }
})

const styles = {
  submitButton: {
    borderRadius: 8,
    backgroundColor: '#00ace7',
    marginBottom: 0,
    fontSize: 14,
    textTransform: 'none',
    padding: '18px 20px'
  },
  itemAction: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    borderRadius: 5,
    backgroundColor: '#00ACE6',
  },
  iconAction: {
    color: '#fff',
    fontSize: 16,
  },
}

const ModuleItem = ({
  sites,
  id,
  title,
  submodules,
  description,
  content,
  deleteModule,
  fetchModules,
  updateModule,
  postSubModule,
  fetchSubModules,
  fetchContent,
  postContent
}) => {
  const classes = useStyles()
  const { course } = useParams()
  const [menu, setMenu] = useState(null)
  const [type, setType] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [deleting, setDeleting] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [contentId, setContentId] = useState(null)
  const [modalEdit, setModalEdit] = useState(false)
  const [subLoading, setSubLoading] = useState(true)
  const [modalModulo, setModalModulo] = useState(false)
  const [modalSubModulo, setModalSubModulo] = useState(false)

  const onChange = () => {
    setExpanded(prev => !prev)
  }

  const onDeleteModule = () => {
    Alert({
      onSuccess: async () => {
        await setDeleting(true)
        await deleteModule(sites.defaultSite.id, course, id)
        makeToast(enqueueSnackbar, 'Modulo eliminado')
        await fetchModules(sites.defaultSite.id, course)
        await setDeleting(false)
      },
      onFail: () => console.log('cancel')
    })
  }

  const onUpdateModule = async (id, data) => {
    await updateModule(sites.defaultSite.id, course, id, data)
    makeToast(enqueueSnackbar, 'Modulo actualizado')
    await setModalModulo(false)
    await fetchModules(sites.defaultSite.id, course)
  }

  const saveSubModule = async data => {
    await setModalSubModulo(false)
    await postSubModule(sites.defaultSite.id, course, data)
    makeToast(enqueueSnackbar, 'Modulo creado')
    await fetchSubModules(sites.defaultSite.id, course, id)
  }

  const onSaveContent = async (response) => {
    await fetchContent(sites.defaultSite.id, course, id)
    setContentId(response.id)
    // setModalEdit(false);
  }

  const renderSubModules = () => {
    if (expanded) {
      return <ListSubModules status={subLoading} loaded={() => setSubLoading(false)} parentModule={id} />
    }
    return null
  }

  const renderContent = () => {
    if (expanded) {
      return <ListContent status={subLoading} loaded={() => setSubLoading(false)} parentModule={id} />
    }
    return null
  }

  const handleClick = (event) => {
    setMenu(event.currentTarget);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const createContent = async () => {
    let title = 'Lección Nueva'
    let status = 'Borrador'
    try {
      // await setLoading(true);
      const last = Math.max(...content.data[id].map(item => item.order))
      const res = await postContent(sites.defaultSite.id, course, id, {
        title,
        status,
        content: {
          description: '<p>Ingresa la lección</p>'
        },
        allowComments: 'true',
        order: last + 1,
      })
      await onSaveContent()
      setContentId(res.id)
      // setModalEdit(true)
    } catch (error) {
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      // setLoading(false);
    }
  }

  const createContentType = (type) => {
    handleClose()
    setType(type)
    setModalEdit(true)
    setContentId(null)
  }

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={onChange}
        classes={{
          root: classes.accordionRoot
        }}
      >
        <AccordionSummary
          id='panel1a-header'
          aria-controls='panel1a-content'
          expandIcon={<ExpandMore />}
          classes={{
            root: classes.accordionSummaryRoot
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DragIndicator style={{ fill: '#024976', cursor: 'pointer' }} />
              <Typography
                classes={{
                  root: classes.typographySubtitle1Root
                }}
                variant='subtitle1'
              >
                {title}
              </Typography>
              {
                deleting && (
                  <div 
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0px 10px'
                    }}
                  >
                    <CircularProgress style={{ color: '#00A8D8' }} size={20} />
                  </div>
                )
              }
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 16,
                columnGap: 8
              }}
            >
              <Tooltip title="Editar" aria-label="Edit">
                <div className="actions-edit" style={styles.itemAction} 
                  onClick={ev => {
                    ev.stopPropagation()
                    setModalModulo(true)
                  }}
                >
                  <Edit style={styles.iconAction} />
                </div>
              </Tooltip>
              <Tooltip className="actions-delete" title="Eliminar" aria-label="Delete">
                <div style={styles.itemAction} 
                  onClick={ev => {
                    ev.stopPropagation()
                    onDeleteModule(id)
                  }}
                >
                  <Delete style={styles.iconAction} />
                </div>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>

        <AccordionActions classes={{ root: classes.accordionActionsRoot }}>
          <Grid
            item
            xs={12}
            style={{
              columnGap: 12,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button aria-controls="simple-menu" aria-haspopup="true" style={{backgroundColor: '#004976'}} onClick={handleClick}>
              <AddCircle style={{fill: '#fff'}} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={menu}
              keepMounted
              open={Boolean(menu)}
              onClose={handleClose}
            >

              {(!content.data[id] || !content.data[id].length) && (
                <MenuItem onClick={() => setModalSubModulo(true)}><Subject style={{fill: '#00C18A', marginRight: 10}} />Agregar Submodulo</MenuItem>
              )}
              {(!submodules.data[id] || !submodules.data[id].length) && (
                <div>
                  <MenuItem onClick={() => createContentType("video")}><Videocam style={{fill: '#00C18A', marginRight: 10}} />Agregar en video</MenuItem>
                  <MenuItem onClick={() => createContentType("image")}><Image style={{fill: '#00C18A', marginRight: 10}} />Agregar con imágen</MenuItem>
                </div>
              )}
            </Menu>
          </Grid>
        </AccordionActions>

        <AccordionDetails
          classes={{
            root:
              !submodules.data[id] || !submodules.data[id].length
                ? classes.accordionDetailsRootAlt
                : classes.accordionDetailsRoot
          }}
        >
          <Grid container>
            {subLoading && (
              <div style={{width: '100%', height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress size={20} style={{ color: '#00A8D8' }} />
              </div>
            )}
            {(!content.data[id] || !content.data[id].length) && (
              <Grid item xs={12}>
                {renderSubModules()}
              </Grid>
            )}
            {(!submodules.data[id] || !submodules.data[id].length) && (
              <Grid item xs={12}>
                {renderContent()}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {modalModulo && (
        <AddModule
          id={id}
          course={course}
          show={modalModulo}
          site={sites.defaultSite.id}
          onUpdate={onUpdateModule}
          onClose={() => setModalModulo(false)}
        />
      )}
      {modalSubModulo && (
        <AddSubModule
          course={course}
          parentModule={id}
          show={modalSubModulo}
          onSave={saveSubModule}
          submodules={submodules}
          site={sites.defaultSite.id}
          onClose={() => setModalSubModulo(false)}
        />
      )}
      {modalEdit && (
        <AddContent
          module={id}
          type={type}
          id={contentId}
          course={course}
          open={modalEdit}
          onSave={onSaveContent}
          onUpdate={onSaveContent}
          site={sites.defaultSite.id}
          onClose={() => setModalEdit(false)}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ sites, modules, submodules, content }) => {
  return { sites, modules, submodules, content }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchModules,
      deleteModule,
      updateModule,
      postSubModule,
      fetchSubModules,
      fetchContent,
      postContent
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleItem)
