import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack'
import { makeToast } from '../../../../utils'
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Visibility, AddCircle } from '@material-ui/icons';
import { fetchUsers, deleteUser } from '../../../../redux/actions/people';
import { loadSites } from "../../../../redux/actions/sites";
import Avatar from '@material-ui/core/Avatar';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import FullDataTable from "../../CoursesMain/FullDataTable";
import ModalRight from "../../ModalRight";
import Create from "../Create";
import './List.scss'
import { theme, Section, Actions, ActionsButton, TableContainer } from './ListStyledComponents'

const baseCustomBodyRenderInt = (value, tableMeta, updateValue) => (
  <>
    {value || '0'}
  </>
)

const baseCustomBodyRenderMoney = (value, tableMeta, updateValue) => (
  <>
    ${value || '0.00'}
  </>
)

const baseCustomBodyRender = (value, tableMeta, updateValue) => (
  <>
    {value || ''}
  </>
)

const columns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    label: 'Nombre',
    name: 'fullname',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <div className='avatar'>
              <Avatar alt={value} src="#" />
              <div>{value}</div>
            </div>
          );
        }
      }
    }
  },
  {
    label: 'Correo',
    name: 'email',
    options: {
      customBodyRender: baseCustomBodyRender,
    }
  },
  {
    label: 'Es usuario',
    name: 'userId',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value && value !== '') {
          return (
            <CheckBoxIcon style={{ fill: '#007bff' }} />
          )
        } else {
          return <CheckBoxOutlineBlank style={{ fill: '#007bff' }} />
        }
      }
    }
  },
  {
    label: 'País',
    name: 'country',
    options: {
      display: false,
      customBodyRender: baseCustomBodyRender,
    }
  },
  {
    label: 'Estado',
    name: 'state',
    options: {
      display: false,
      customBodyRender: baseCustomBodyRender,
    }
  },
  {
    label: 'Ciudad',
    name: 'city',
    options: {
      display: false,
      customBodyRender: baseCustomBodyRender,
    }
  },
  {
    label: 'Cantidad invertida',
    name: 'quantity',
    options: {
      customBodyRender: baseCustomBodyRenderMoney,
    }
  },
  {
    label: 'Cursos',
    name: 'courses',
    options: {
      customBodyRender: baseCustomBodyRenderInt,
    }
  },
  {
    label: 'Eventos',
    name: 'events',
    options: {
      customBodyRender: baseCustomBodyRenderInt,
    }
  },
  {
    label: "Acciones",
    name: 'id',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <Link to={`/people/view/${value}`}>
              <Visibility />
            </Link>
          );
        }
      }
    }
  }
];

class PeopleList extends React.Component {

  state = {
    edit: false,
  };

  toggle = async () => {
    await this.setState((prev) => ({ edit: !prev.edit }));
  }

  close = async () => {
    await this.setState({ edit: false });
  }

  customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns = [] }) => {
    const remove = ['quantity', 'events', 'courses']
    const { sites: { defaultSite } } = this.props;
    if (searchText && searchText !== '') {
      const names = columns.map(item => (item.name)).filter(item => !remove.includes(item))
      console.log('names', names)
      this.props.fetchUsers(defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
    } else {
      this.props.fetchUsers(defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
    }
  }

  render() {
    const { edit } = this.state;
    const { sites: { defaultSite }, people: { data, loading, limit, offset, count, sort } } = this.props;

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <Section>
          <TableContainer>
            <FullDataTable
              data={data}
              title={"Contactos"}
              loading={loading}
              columns={columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              theme={theme}
              onRowPress={(data, meta, rowIndex) => {
                this.props.history.push(`/people/view/${data[0]}`)
              }}
              onRowsDelete={async (rows) => {
                await this.props.deleteUser(defaultSite.id, rows);
                makeToast(this.props.enqueueSnackbar, 'Se han eliminado los elementos')
                this.props.fetchUsers(defaultSite.id, limit, offset);
              }}
              preload={() => this.props.fetchUsers(defaultSite.id, limit, offset)}
              sort={this.customSearch}
              search={this.customSearch}
              changePage={this.customSearch}
              changePageSize={this.customSearch}
              renderCreate={() => (
                <Actions>
                  <ActionsButton onClick={this.toggle} className="button is-light">
                    <AddCircle className='button-create' /> Crear Contacto
                </ActionsButton>
                </Actions>
              )
              }
            />
          </TableContainer>
        </Section>
        {
          edit && (
            <ModalRight show={edit} onClick={this.toggle} hideCloseButton={true}>
              <Create onCloseModal={() => this.close()} />
            </ModalRight>
          )
        }
      </div>
    );
  }
};

const mapStateToProps = ({ people, sites }) => {
  return { people, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchUsers, loadSites, deleteUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(PeopleList)));