import {
  FETCH_EVENTS,
  FAIL_FETCH_EVENTS,
  SUCCESS_FETCH_EVENTS,
  SUCCESS_FETCH_EVENTS_TABLE,
  FETCHING_EVENT,
  FAIL_FETCHING_EVENT,
  SUCCESS_FETCHING_EVENT,
  UPDATE_SORT_EVENT,
  PUT_EVENT,
  PUT_FAIL_EVENT,
  PUT_SUCCESS_EVENT,
  DELETE_EVENT,
  DELETE_FAIL_EVENT,
  DELETE_SUCCESS_EVENT
} from '../actions/events'

const initialState = {
  data: [],
  error: null,
  loading: false,
  form: {},
  order: {},
  count: 0,
  limit: 10,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FAIL_FETCH_EVENTS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SUCCESS_FETCH_EVENTS:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
      };
    case SUCCESS_FETCH_EVENTS_TABLE:
      return {
        ...state,
        error: null,
        loading: false,
        ...action.data,
      };
    case FETCHING_EVENT:
      return {
        ...state,
        error: null,
        loading: true,
        form: initialState.form,
      };
    case SUCCESS_FETCHING_EVENT:
      return {
        ...state,
        loading: false,
        form: action.data,
        error: action.error,
      };
    case FAIL_FETCHING_EVENT:
      return {
        ...state,
        loading: false,
        error: action.error,
        form: initialState.form,
      };
    case PUT_EVENT:
      return {
        ...state,
        error: null,
      };
    case PUT_SUCCESS_EVENT:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case PUT_FAIL_EVENT:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_SORT_EVENT:
      return {
        ...state,
        form: {
          ...state.form,
          fields: action.data
        },
      };
    case DELETE_EVENT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_FAIL_EVENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SUCCESS_EVENT:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
