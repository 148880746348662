import network from '../utils/network';

export const getUser = async (site, username) => {
  try {
    const { data } = await network.get(`site/${site}/collab/${username}`);
    return data;
  } catch (error) {
    console.error('getUser', error);
    throw error;
  }
}

export const updateUser = async (site, username, body) => {
  try {
    const { data } = await network.put(`site/${site}/collab/${username}`, body);
    return data;
  } catch (error) {
    console.error('updateUser', error);
    throw error;
  }
}

export const deleteUser = async (site, username) => {
  try {
    await network.del(`site/${site}/collab/${username}`);
  } catch (error) {
    console.error('updateUser', error);
    throw error;
  }
}

export const resetUser = async (site, username) => {
  return network.get(`site/${site}/collab/${username}/resetpassword`);
}

export const createUser = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/collab`, body);
    if (data.error) {
      throw(data.error);
    }
    return data;
  } catch (error) {
    console.error('createUser', error);
    throw error;
  }
}

export const resendVerification = async (uid) => {
  try {
    const { data } = await network.get(`user/${uid}/resendVerification`);
    return data;
  } catch (error) {
    console.error('resendVerification', error);
    throw error;
  }
}