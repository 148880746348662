import {
    FETCHING_EMAIL_TEMPLATES,
    FAIL_FETCH_EMAIL_TEMPLATES,
    SUCCESS_FETCH_EMAIL_TEMPLATES,
    FETCHING_EMAIL_TEMPLATES_SYSTEM,
    FAIL_FETCH_EMAIL_TEMPLATES_SYSTEM,
    SUCCESS_FETCH_EMAIL_TEMPLATES_SYSTEM,
    FETCHING_EMAIL_TEMPLATE,
    FAIL_FETCH_EMAIL_TEMPLATE,
    SUCCESS_FETCH_EMAIL_TEMPLATE,
    DELETE_EMAIL_TEMPLATE,
    DELETE_FAIL_EMAIL_TEMPLATE,
    DELETE_SUCCESS_EMAIL_TEMPLATE,
  } from '../actions/emailTemplates'
  
  const initialState = {
    data: [],
    emailTemplate: null,
    error: null,
    loading: false,
    order: {},
    count: 0,
    limit: 25,
    offset: 0,
    emails: {
      data: [],
      emailTemplate: null,
      error: null,
      loading: false,
      order: {},
      count: 0,
      limit: 25,
      offset: 0,
    }
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCHING_EMAIL_TEMPLATES:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_EMAIL_TEMPLATES:
        let count = action.data ? action.data.data ? action.data.data.length ? action.data.data.length : 0 : 0 : 0
        return {
          ...state,
          ...action.data,
          count: count,
          loading: false,
        };
      case FAIL_FETCH_EMAIL_TEMPLATES:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.error,
        };
      case FETCHING_EMAIL_TEMPLATES_SYSTEM:
        return {
          ...state,
          emails: {
            ...state.emails,
            error: null,
            loading: true,
          }
        };
      case SUCCESS_FETCH_EMAIL_TEMPLATES_SYSTEM:
        return {
          ...state,
          emails: {
            ...action.data,
            loading: false,
          }
        };
      case FAIL_FETCH_EMAIL_TEMPLATES_SYSTEM:
        return {
          ...state,
          emails: {
            ...state.emails,
            data: [],
            loading: false,
            error: action.error,
          }
        };
      case FETCHING_EMAIL_TEMPLATE:
        return {
          ...state,
          emailTemplate: [],
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_EMAIL_TEMPLATE:
        return {
          ...state,
          loading: false,
          emailTemplate: action.data,
          error: action.error,
        };
      case FAIL_FETCH_EMAIL_TEMPLATE:
        return {
          ...state,
          emailTemplate: [],
          loading: false,
          error: action.error,
        };
        case DELETE_EMAIL_TEMPLATE:
        return {
          ...state,
          loading: true,
        };
      case DELETE_FAIL_EMAIL_TEMPLATE:
        return {
          ...state,
          loading: false,
        };
      case DELETE_SUCCESS_EMAIL_TEMPLATE:
        return {
          ...state,
          loading: false,
        };
      default:
        return state
    }
  };
  