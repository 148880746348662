export const parseUrl = (url, limit = null, offset = null, column = null, direction = null, search = null, searchtext = null, tags = []) => {
  if (search !== null && searchtext !== null && searchtext.trim() !== '') {
    for (const obj of search) {
      url += (`search=${obj}&`);
    }
    url += (`searchtext=${searchtext}&`);
  }
  if (column !== null && direction !== null) {
    url += (`orderBy=${column}&orderDir=${direction.toUpperCase()}&`);
  }
  if (offset !== null) {
    url += (`offset=${offset * limit}&`);
  }
  if (limit !== null) {
    url += (`limit=${limit}&`);
  }
  if (tags !== null) {
    url += (`tags=`);
    tags.forEach(item => {
      url += (`${item},`);
    })
  }
  return url;
}

export const isVideo = (name = '') => {
  const formats = ['mp4'];
  for (let i = 0; i < formats.length; i++) {
    const format = formats[i];
    if (name.indexOf(format) !== -1) {
      return true;
    }
  }
}