import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES';
export const FAIL_FETCH_CATEGORIES = 'FAIL_FETCH_CATEGORIES';
export const SUCCESS_FETCH_CATEGORIES = 'SUCCESS_FETCH_CATEGORIES';

export const FETCHING_CATEGORY = 'FETCHING_CATEGORY';
export const FAIL_FETCH_CATEGORY = 'FAIL_FETCH_CATEGORY';
export const SUCCESS_FETCH_CATEGORY = 'SUCCESS_FETCH_CATEGORY';

export const POST_CATEGORY = 'POST_CATEGORY';
export const POST_FAIL_CATEGORY = 'POST_FAIL_CATEGORY';
export const POST_SUCCESS_CATEGORY = 'POST_SUCCESS_CATEGORY';

export const PUT_CATEGORY = 'PUT_CATEGORY';
export const PUT_FAIL_CATEGORY = 'PUT_FAIL_CATEGORY';
export const PUT_SUCCESS_CATEGORY = 'PUT_SUCCESS_CATEGORY';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_FAIL_CATEGORY = 'DELETE_FAIL_CATEGORY';
export const DELETE_SUCCESS_CATEGORY = 'DELETE_SUCCESS_CATEGORY';

export const fetchCategories = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  let url = parseUrl(`site/${siteId}/category?`, limit, offset, column, direction, search, searchtext)
  return async dispatch => {
    dispatch({ type: FETCHING_CATEGORIES });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_CATEGORIES,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_CATEGORIES,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_CATEGORIES,
      });
    }
  };
};

export const fetchCategory = (siteId, user) => {
  return async dispatch => {
    dispatch({ type: FETCHING_CATEGORY });
    try {
      const response = await network.get(`site/${siteId}/category/${user}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_CATEGORY,
        });
      } else {
        dispatch({
          user: response.data,
          type: SUCCESS_FETCH_CATEGORY,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_CATEGORY,
      });
    }
  };
};

export const postCategory = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_CATEGORY });
    try {
      const response = await network.post(`site/${siteId}/category`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_CATEGORY,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_CATEGORY,
      });
    }
  };
};

export const putCategory = (id, body) => {
  return async dispatch => {
    dispatch({ type: POST_CATEGORY });
    try {
      const response = await network.put(`site/${id}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_CATEGORY,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_CATEGORY,
      });
    }
  };
};

export const deleteCategory = (id, ids) => {
  return async dispatch => {
    const promises = ids.map(item => {
      return network.del(`site/${id}/category/${item}`);
    });
    dispatch({ type: POST_CATEGORY });
    try {
      const response = await Promise.all(promises);
      return dispatch({
        data: response.data,
        type: POST_SUCCESS_CATEGORY,
      });
    } catch (error) {
      return dispatch({
        error,
        type: POST_FAIL_CATEGORY,
      });
    }
  };
};
