import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { Edit as EditIcon, AddCircle } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { fetchCategories, deleteCategory } from '../../../redux/actions/categories';
import FullDataTable from "../CoursesMain/FullDataTable"
import ModalRight from "../ModalRight";
import Create from "./Create";

class UsersList extends React.Component {

  state = {
    createModal: false,
    editModal: false,
    editCategoryId: null
  };

  toggleCreateModal = async () => {
    await this.setState((prev) => ({ createModal: !prev.createModal }));
  }

  toggleEditModal = async () => {
    await this.setState((prev) => ({ editModal: !prev.editModal }));
  }

  onDelete = async (id) => {
    const { sites } = this.props;
    await deleteCategory(sites.defaultSite.id, id);
    this.props.fetchCategories(sites.defaultSite.id);
  }

  customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    const { sites } = this.props;
    if (searchText && searchText != '') {
      const names = columns.map(item => (item.name));
      this.props.fetchCategories(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
    } else {
      this.props.fetchCategories(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
    }
  }

  handleEditCategory = (id) => {
    this.setState({
      editCategoryId: id,
      createModal: false,
      editModal: true
    });
  }

  columns = [
    {
      name: 'id',
      options: {
        display: 'excluded'
      }
    },
    {
      label: 'Nombre',
      name: 'name',
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <>
                <IconButton onClick={() => this.handleEditCategory(value)}>
                  <EditIcon style={{ fill: '#007bff' }} />
                </IconButton>
              </>
            );
          }
        },
      },
    },
  ]

  render() {
    const { createModal, editModal, editCategoryId } = this.state;
    const { sites, categories: { data, loading, limit, offset, count, sort } } = this.props;
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={styles.content} className="content">
          <div>
            <FullDataTable
              data={data}
              title={"Categorías"}
              columns={this.columns}
              count={count}
              limit={limit}
              offset={offset}
              sortOrder={sort}
              loading={loading}
              theme={theme}
              onRowPress={(data, meta, rowIndex) => {
                this.handleEditCategory(data[0])
              }}
              onRowsDelete={async (rows) => {
                await this.props.deleteCategory(sites.defaultSite.id, rows);
                makeToast(this.props.enqueueSnackbar, 'Se han eliminado los elementos')
                this.props.fetchCategories(sites.defaultSite.id, limit, offset);
              }}
              preload={() => this.props.fetchCategories(sites.defaultSite.id, limit, offset)}
              sort={this.customSearch}
              search={this.customSearch}
              changePage={this.customSearch}
              changePageSize={this.customSearch}
              renderCreate={() => (
                <div style={styles.actions}>
                  <button onClick={this.toggleCreateModal} style={styles.actionButton} className="button is-light">
                    <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} /> Crear Categoría
                </button>
                </div>
              )}
            />
          </div>
        </section>
        {
          createModal && (
            <ModalRight show={createModal} onClick={this.toggleCreateModal} hideCloseButton={true}>
              <Create site={sites.defaultSite.id} onCloseModal={this.toggleCreateModal} />
            </ModalRight>
          )
        }
        {
          editModal && (
            <ModalRight show={editModal} onClick={this.toggleEditModal} hideCloseButton={true}>
              <Create site={sites.defaultSite.id} categoryId={editCategoryId} onCloseModal={this.toggleEditModal} />
            </ModalRight>
          )
        }
      </div>
    );
  }
};


const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  count: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {

      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600',
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ categories, sites }) => {
  return { categories, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchCategories, deleteCategory }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(UsersList));