import styled from 'styled-components';

export const Box = styled.div`
  padding: 60px 80px;
  width: 762px;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;

export const Card = styled.div`
  margin: 0px 10px;
  box-shadow: 0px 0px 7px 5px rgba(225, 225, 225,1);
  -moz-box-shadow: 0px 0px 7px 5px rgba(225, 225, 225,1);
  -webkit-box-shadow: 0px 0px 7px 5px rgba(225, 225, 225,1);
`;

export const ContainerBackground = styled.div`
  background-size: 40% 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.background})`};
`;

export const Input = styled.input`
    font-family: Montserrat;
    width: 100%;
    color: #495057;
    font-size: 1rem;
    display: block;
    border-width: 0;
    border-radius: 3;
    font-weight: 400;
    line-height: 1.5;
    border-radius: .25rem;
    padding: .375rem .75rem;
    background-color: #F7F7F7;
    background-clip: padding-box;
    height: calc(1.5em + .75rem + 2px);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {
      outline: 0;
      box-shadow: 0 0 0 1pt black;
    }
    ::-webkit-input-placeholder {
      color: #8c8c8c;
    }
    ::-moz-placeholder {
      color: #8c8c8c;
    }
    :-ms-input-placeholder {
      color: #8c8c8c;
    }
    :-moz-placeholder {
      color: #8c8c8c;
    }
`;

export const Select = styled.select`
    font-family: Montserrat;
    width: 100%;
    color: #495057;
    font-size: 1rem;
    display: block;
    border-width: 0;
    font-weight: 400;
    line-height: 1.5;
    border-radius: .25rem;
    padding: .375rem .75rem;
    background-color: #F7F7F7;
    background-clip: padding-box;
    height: calc(1.5em + .75rem + 2px);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {
      outline: 0;
      box-shadow: 0 0 0 1pt black;
    }
    ::-webkit-input-placeholder {
      color: #8c8c8c;
    }
    ::-moz-placeholder {
      color: #8c8c8c;
    }
    :-ms-input-placeholder {
      color: #8c8c8c;
    }
    :-moz-placeholder {
      color: #8c8c8c;
    }
    -moz-appearance: none; 
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
`;

export const TextArea = styled.textarea`
    font-family: Montserrat;
    width: 100%;
    color: #495057;
    font-size: 1rem;
    display: block;
    border-width: 0;
    font-weight: 400;
    line-height: 1.5;
    border-radius: .25rem;
    padding: 1rem .75rem;
    background-color: #F7F7F7;
    background-clip: padding-box;
    height: calc(1.5em + .75rem + 2px);
    min-height: 110px;
    resize: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus {
      outline: 0;
      box-shadow: 0 0 0 1pt black;
    }
    ::-webkit-input-placeholder {
      color: #8c8c8c;
    }
    ::-moz-placeholder {
      color: #8c8c8c;
    }
    :-ms-input-placeholder {
      color: #8c8c8c;
    }
    :-moz-placeholder {
      color: #8c8c8c;
    }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.justify || 'space-between')};
`;

export const HR = styled.div`
  width: 30%;
  height: 1px;
  background-color: #C7CFDD;
`;

export const Title = styled.p`
  text-align: center;
  font: normal normal 600 40px/65px Montserrat;
  letter-spacing: 0px;
  color: #313540;
  opacity: 1;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const SubTitle = styled.p`
  color: #7D8595;
  text-align: center;
  letter-spacing: 0px;
  font: normal normal 600 22px/40px Montserrat;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const TextCard = styled.p`
  text-align: center;
  font: normal normal 600 20px/40px Montserrat;
  letter-spacing: 0.2px;
  color: #7D8595;
  text-transform: uppercase;
`;

export const Label = styled.label`
  color: #7D8595;
  text-align: left;
  letter-spacing: 0px;
  font: normal normal 600 18px/40px Montserrat;
`;

export const Text = styled.p`
  color: #7D8595;
  text-align: left;
  letter-spacing: 0px;
  font: normal normal 600 16px Montserrat;
`;

export const Link = styled.a`
  color: #7D8595;
  font-size: 18px;
  letter-spacing: 0px;
  text-decoration: underline;
  font: normal normal normal 16px/40px Montserrat;
`;

export const Icon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 13px;
`;

export const B = styled.b`
  color: #7D8595;
  text-align: center;
  letter-spacing: 0px;
  text-decoration: underline;
  font: normal normal bold 20px/40px Montserrat;
`;

export const Button = styled.button`
  width: 100%;
  display: flex;
  padding: 12px;
  display: block;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 0px;
  margin-bottom: 10px;
  justify-content: center;
  border: 1px solid #CCD0D6;
  color: ${props => (props.color || '#fff')};
  font: normal normal bold 20px/24px Montserrat;
  background: ${props => (props.backgroundColor || '#1A9E75')} 0% 0% no-repeat padding-box;
  :disabled {
    background: #bdbdbd;
  }
`;

export const TouchableOpacity = styled.div`
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.4s;
  :hover {
    opacity: 0.7;
  }
`;

export const ScrollView = styled.div`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #e6eaf4;  
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #1a9e75;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;