import {
  FETCH_SESSION,
  FAIL_FETCH_SESSION,
  SUCCESS_FETCH_SESSION,
  LOGIN,
  FAIL_LOGIN,
  SUCCESS_LOGIN,
  LOGOUT,
  FAIL_LOGOUT,
  SUCCESS_LOGOUT,
} from '../actions/session'

const initialState = {
  user: null,
  error: null,
  isAuthenticated: false,
  isAuthenticating: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SESSION:
      return {
        ...state,
        user: null,
        error: null,
        isAuthenticated: false,
        isAuthenticating: true,
      };
    case FAIL_FETCH_SESSION:
      return {
        ...state,
        user: null,
        error: action.error,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case SUCCESS_FETCH_SESSION:
      return {
        ...state,
        error: null,
        user: action.data,
        isAuthenticated: true,
        isAuthenticating: false,
      };
    case LOGIN:
      return {
        ...state,
        user: null,
        error: null,
        isAuthenticated: false,
        isAuthenticating: true,
      };
    case FAIL_LOGIN:
      return {
        ...state,
        user: null,
        error: action.error,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case SUCCESS_LOGIN:
      return {
        ...state,
        error: null,
        user: action.data,
        isAuthenticated: true,
        isAuthenticating: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        error: null,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case FAIL_LOGOUT:
      return {
        ...state,
        user: null,
        error: action.error,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case SUCCESS_LOGOUT:
      return {
        ...state,
        user: null,
        error: null,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    default:
      return state;
  }
};
