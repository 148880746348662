import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_PAGES = 'FETCHING_PAGES';
export const FAIL_FETCH_PAGES = 'FAIL_FETCH_PAGES';
export const SUCCESS_FETCH_PAGES = 'SUCCESS_FETCH_PAGES';

export const FETCHING_PAGE = 'FETCHING_PAGE';
export const FAIL_FETCH_PAGE = 'FAIL_FETCH_PAGE';
export const SUCCESS_FETCH_PAGE = 'SUCCESS_FETCH_PAGE';

export const POST_PAGE = 'POST_PAGE';
export const POST_FAIL_PAGE = 'POST_FAIL_PAGE';
export const POST_SUCCESS_PAGE = 'POST_SUCCESS_PAGE';

export const PUT_PAGE = 'PUT_PAGE';
export const PUT_FAIL_PAGE = 'PUT_FAIL_PAGE';
export const PUT_SUCCESS_PAGE = 'PUT_SUCCESS_PAGE';

export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_FAIL_PAGE = 'DELETE_FAIL_PAGE';
export const DELETE_SUCCESS_PAGE = 'DELETE_SUCCESS_PAGE';

export const fetchPages = (site, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_PAGES });
    try {
      let url = parseUrl(`site/${site}/pages?type=Landing&`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_PAGES,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_PAGES,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_PAGES,
      });
    }
  };
};

export const fetchPage = (site, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_PAGE });
    try {
      const response = await network.get(`site/${site}/pages/${id}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_PAGE,
        });
      } else {
        dispatch({
          page: response.data,
          type: SUCCESS_FETCH_PAGE,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_PAGE,
      });
    }
  };
};

export const fetchLanding = (site, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_PAGE });
    try {
      const response = await network.get(`site/${site}/landing/${id}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_PAGE,
        });
      } else {
        dispatch({
          page: response.data,
          type: SUCCESS_FETCH_PAGE,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_PAGE,
      });
      throw error;
    }
  };
};

export const duplicate = (site, id, body) => {
  return async dispatch => {
    try {
      const response = await network.post(`site/${site}/pages/${id}/duplicate`, body);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: POST_FAIL_PAGE,
        });
      } else {
        dispatch({
          page: response.data,
          type: POST_SUCCESS_PAGE,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_PAGE,
      });
    }
  };
};

export const postPage = (site, body) => {
  return async dispatch => {
    try {
      dispatch({ type: POST_PAGE });
      const response = await network.post(`site/${site}/pages`, body)
      dispatch({
        data: response.data,
        type: POST_SUCCESS_PAGE,
      });
      return response.data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_PAGE,
      });
    }
  };
};

export const putPage = (site, id, body) => {
  return async dispatch => {
    try {
      dispatch({ type: POST_PAGE });
      const response = await network.put(`site/${site}/pages/${id}`, body)
      dispatch({
        data: response.data,
        type: POST_SUCCESS_PAGE,
      });
      return response.data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_PAGE,
      });

    }
  };
};

export const deletePage = (site, ids) => {
  return async dispatch => {
    try {
      dispatch({ type: DELETE_PAGE });
      const promises = ids.map(item => (
        network.del(`site/${site}/pages/${item}`)
      ));
      const response = await Promise.all(promises);
      dispatch({
        type: DELETE_SUCCESS_PAGE,
      });
      return response;
    } catch (error) {
      return dispatch({
        error,
        type: DELETE_FAIL_PAGE,
      });
    }
  };
};
