import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Close, AddBox } from '@material-ui/icons'
import { FormControl, FormGroup, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, makeStyles, IconButton } from '@material-ui/core';
import { makeToast } from '../../../utils'
import { Select } from '../StyledComponents'
import { ArrowDropDown } from '@material-ui/icons'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCourses } from '../../../redux/actions/courses'
import { assignCourse } from '../../../helpers/people'

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot:{
    padding: 2
  }
});

const AssignCourseModal = (props) => {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [selectedCourse, setSelectedCourse] = useState(-1);
  const [loading, setLoading] = useState(false);

  const { courses } = props;

  const loadCourses = () => {
    props.fetchCourses(props.siteId)
  }

  const filterAssignedCourses = (courses) => {
    let assignedCourses = props.assignedCourses
    if(assignedCourses){
      let mappedAssignedCourses = assignedCourses.map(element => {
        return element.id
      })
      return courses.filter(element => {
        return !mappedAssignedCourses.includes(element.id)
      })
    }
    return courses
  }

  useEffect(()=>{
    loadCourses()
  },[])

  const submit = async () => {
    try {
      await setLoading(true);
      await assignCourse(props.siteId, props.userId, selectedCourse);
      props.onSubmit()
    } catch (error) {
      console.log(error);
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      await setLoading(false);
    }
  }

  return (
    <Dialog classes={{paper:classes.dialogPaper}} open={props.visible} onClose={props.onClose} aria-labelledby="form-dialog-title">
      
      <DialogContent style={{paddingBottom: 18}}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{display:'flex', columnGap: 12, alignItems: 'center'}}>
            <AddBox style={{fill: '#024976'}}/>
            <DialogTitle 
                classes={{root:classes.dialogTitleRoot}} 
                id="form-dialog-title">Asignar curso</DialogTitle>
          </div>
          <IconButton classes={{root:classes.iconButtonRoot}} onClick={props.onClose}>
            <Close style={{fill: '#333333'}}/>
          </IconButton>
        </div>
      </DialogContent>
      <div style={{height: 1.6, width: '100%', backgroundColor: '#e4e4e4'}}/>
      <DialogContent style={{paddingTop: 24}}>
        {
          courses && (
            <FormControl style={{ width: '100%' }} onSubmit={()=>submit()}>
              {
                courses.loading ? (
                  <CircularProgress style={{color: '#00A8D8', margin: 'auto'}}/>
                ) :
                  <FormGroup>
                    <div style={{ position: 'relative' }}>
                      <Select
                        value={selectedCourse}
                        disabled={loading}
                        style={{
                          height: 56,
                          borderRadius: 14,
                          backgroundColor: 'white',
                          border: 'rgb(232, 232, 232) 1px solid'
                        }}
                        onChange={ev => setSelectedCourse(ev.target.value)}
                      >
                        <option value={-1}>
                          Selecciona un curso
                        </option>
                        {
                          filterAssignedCourses(courses.data).map(item => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))
                        }
                      </Select>
                      <ArrowDropDown
                        style={{
                          fill: '#144974',
                          position: 'absolute',
                          top: 16,
                          right: 16
                        }}
                      />
                    </div>
                  </FormGroup>
              }
            </FormControl>
          )
        }
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px 24px' }}>
        
        <button 
          onClick={props.onClose}
          disabled={loading || courses.loading}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }}
          className="button is-secondary"
        >
          Cancelar
        </button>

        <button 
          disabled={loading || courses.loading || selectedCourse === -1} 
          onClick={()=>submit()}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }} 
          className="button is-light"
        >
          {(loading || courses.loading) ? <CircularProgress size={18} style={{ color: '#00A8D8' }} /> : 'Asignar Curso'}
        </button>

      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ courses, sites }) => {
  return { courses, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchCourses }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignCourseModal);