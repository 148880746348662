import network from '../utils/network';

export const getTags = async (site) => {
  try {
    const { data } = await network.get(`site/${site}/tagvideo`);
    return data;
  } catch (error) {
    console.log('getTags error', error);
  }
}
