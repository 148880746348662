import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { CircularProgress } from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { loadSites } from '../../../redux/actions/sites'
import { updateSiteZoom } from '../../../helpers/sites'

function Zoom (props) {
  const history = useHistory()
  let { search } = useLocation()
  const [error, setError] = useState(false)
  const { sites } = props

  const getParam = key => {
    const query = new URLSearchParams(search)
    return query.get(key)
  }

  const setZoom = async () => {
    try {
      const code = getParam('code')
      if (code) {
        await updateSiteZoom(sites.defaultSite.id, { code })
        await props.loadSites()
        history.push(`/admin/settings`)
      } else {
        setError(true)
      }
    } catch (error) {
      console.log('error', error)
      setError(true)
    }
  }

  useEffect(() => {
    setZoom()
  }, [])

  return (
    <div style={{ display: 'flex', flex: 1, minHeight: '100vh' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        <section style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <img style={{marginBottom: 25}} src="/images/zoom.svg" alt="zoom"/>
            {
              error ? (
                <div>
                  <h3>Ocurrio un error al conectar zoom, intenta de nuevo.</h3>
                  <Link to="/admin/settings">Ir a Configuración</Link>
                </div>
              ) : (
                <CircularProgress style={{ color: '#00A8D8' }}/>
              )
            }
          </div>
        </section>
      </div>
    </div>
  )
}

Zoom.propTypes = {}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ loadSites }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Zoom)
