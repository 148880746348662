import React, { useState } from 'react'
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const styles = {
    cell: {
        padding: '25px 0px 0px 0px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    rowsPerPageWrapper: {
        position: 'relative'
    },
    rowsPerPage: {
        display: 'flex',
        color: '#626e83',
        marginLeft: 10
    },
    rowsPerPageDropDown: {
        position: 'absolute',
        zIndex: 100,
        left: 0,
        top: -100,
        width: '100%',
        background: 'white',
        borderRadius: 6,
        boxShadow: 'black 0px 0px 13px -7px'
    },
    paginationContainer: {
        display: 'flex'
    },
    paginationContent: {
        backgroundColor: '#f6f6f6',
        borderRadius: 24,
        margin: '0px 10px',
        display: 'flex'
    }
}

export default function TableFooter(props) {

    const [rowsPerPageDropDownVisible, setRowsPerPageDropDownVisible] = useState(false);

    const pagesArray = () => {
        let arr = []
        for(let i=0;i<pagesCount();i++){
            arr.push(i);
        }
        return arr;
    }

    const pagesCount = () => {
        let residual = props.count % props.rowsPerPage
        return parseInt(props.count / props.rowsPerPage) + (residual > 0 ? 1 : 0) 
    }

    const page = () => {
        return props.page ? props.page : props.offset ? props.offset : 0
    }

    let dots = false

  return (
    <tfoot>
        <tr>
            <td style={styles.cell}>
                <div style={styles.rowsPerPageWrapper}>
                    <RowsPerPageContainer 
                        onClick={()=>setRowsPerPageDropDownVisible(!rowsPerPageDropDownVisible)}
                    >
                        <div style={styles.rowsPerPageLabel}>Filas por página</div>
                        <div style={styles.rowsPerPage}>{props.rowsPerPage} <ArrowDropDownIcon style={{fill: '#626e83'}}/></div>
                    </RowsPerPageContainer>
                    {
                        rowsPerPageDropDownVisible && (
                            <div style={styles.rowsPerPageDropDown}>
                                {
                                    props.rowsPerPageOptions.map(element => (
                                        <RowsPerPageOption
                                            key={element}
                                            style={{...(element === props.rowsPerPage ? {backgroundColor: 'rgb(246, 246, 246)'} : {} )}}
                                            onClick={() => {
                                                props.onChangeRowsPerPage(element);
                                                setRowsPerPageDropDownVisible(!rowsPerPageDropDownVisible);
                                            }} 
                                        >
                                            {element}
                                        </RowsPerPageOption>
                                    )) 
                                }
                            </div>
                        )
                    }
                </div>
                <div style={styles.paginationContainer}>
                    <PaginationNavigationButton onClick={(e)=>props.onChangePage(e, page() - 1)} disabled={page() <= 0}>
                        <ChevronLeftIcon style={{fill: '#626e83'}}/>
                    </PaginationNavigationButton>
                    <div style={styles.paginationContent}>
                        {
                            pagesArray().map((element, index) => {
                                if (pagesArray().length < 10) {
                                    return (
                                        <Page key={`page-${index}`} style={{...(element === page() ? { backgroundColor: '#024976', color: 'white'} : {})}} onClick={(e) => props.onChangePage(e, element)}>
                                            {element + 1}
                                        </Page>
                                    )
                                } else if (index < 4 || index > pagesArray().length - 5) {
                                    return (
                                        <Page key={`page-${index}`} style={{...(element === page() ? { backgroundColor: '#024976', color: 'white'} : {})}} onClick={(e) => props.onChangePage(e, element)}>
                                            {element + 1}
                                        </Page>
                                    )
                                } else if (!dots && (index > 4 || index < pagesArray().length - 5)) {
                                    dots = true
                                    return (
                                        <Page key={`page-${index}`} style={{...(element === page() ? { backgroundColor: '#024976', color: 'white'} : {})}}>
                                            {element === page() ? element + 1 : '...'}
                                        </Page>
                                    )
                                } else if (dots && element === page()) {
                                    return (
                                        <Page key={`page-${index}`} style={{...(element === page() ? { backgroundColor: '#024976', color: 'white'} : {})}} onClick={(e) => props.onChangePage(e, element)}>
                                            {element + 1}
                                        </Page>
                                    )
                                }return null;
                            })
                        }
                    </div>
                    <PaginationNavigationButton onClick={(e)=>props.onChangePage(e, page() + 1)} disabled={page() >= (pagesCount()-1)}>
                        <ChevronRightIcon style={{fill: '#626e83'}}/>
                    </PaginationNavigationButton>
                </div>

            </td>
        </tr>
    </tfoot>
  )
}

export const RowsPerPageContainer = styled.div`
    padding: 10px 16px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 6px;
    font-family: 'Montserrat';
    color: #979797;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.4s;
    :hover{
      background-color: #efefef;
    }
`;

export const RowsPerPageOption = styled.div`
    width: 100%;
    outline: none;
    font-family: Montserrat;
    color: #979797;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.4s;
    :hover{
        background-color: rgb(246, 246, 246);
    }
`;

export const PaginationNavigationButton = styled.button`
    background-color: #f6f6f6;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: none;
    transition: opacity 0.4s, background-color 0.4s;
    :focus{
        outline: none;
    }
    :hover{
        background-color: #efefef; 
    }
    :disabled{
        opacity: 0.5
    }
`;

export const Page = styled.button`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    color: #626e83;
    font-family: 'Montserrat';
    border: none;
    font-weight: 600;
    cursor: pointer;
    background-color: transparent;
    transition: background 0.4s, color 0.4s;
    :focus{
        outline: none;
    }
    :hover{
        background-color: #efefef; 
    }
`;