import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Visibility, AddCircle } from '@material-ui/icons';
import { fetchCourses, fetchCoursesInfinite, deleteCourse, postCourse } from '../../../redux/actions/courses';
import CourseCard from '../courses/components/Course';
import FullDataTable from '../CoursesMain/FullDataTable';
import ModalRight from "../ModalRight";
import Create from "./Create";
import moment from 'moment';

const columns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    label: 'Título',
    name: 'title',
  },
  {
    label: 'Descripción',
    name: 'description',
  },
  {
    label: 'Duracion',
    name: 'expected_duration',
  },
  {
    label: 'Fecha de creación',
    name: 'createdAt',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <div>
              {moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}
            </div>
          )
        }
      }
    }
  },
  {
    label: 'Acciones',
    name: 'id',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <>
              <Link to={`/content/courses/edit/${value}`} >
                <Visibility />
              </Link>
            </>
          );
        }
      },
    },
  },
];

function UsersList(props) {

  const { sites } = props;
  const { courses: { data, loading, limit, offset, count, sort, hasMore, page } } = props;

  const { enqueueSnackbar } = useSnackbar()
  const [createModalVisible, setCreateModalVisible] = useState(false)

  const toggleCreateModalVisible = (option) => {
    option ? setCreateModalVisible(option) : setCreateModalVisible(!createModalVisible)
  }

  const customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    if (searchText && searchText !== '') {
      const names = columns.map(item => (item.name));
      props.fetchCourses(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
    } else {
      props.fetchCourses(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
    }
  }

  return (
    <>
      <FullDataTable
        data={data}
        page={page}
        count={count}
        limit={limit}
        theme={theme}
        title="Cursos"
        offset={offset}
        sortOrder={sort}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        site={sites.defaultSite.id}
        customRenderBlocks={(course, index) => (
          <CourseCard
            title={course.title}
            createdAt={moment(new Date(course.createdAt)).format('MMMM D YYYY')}
            background={course.background}
            key={`course-Card-${index}`}
            onChange={() => props.history.push(`/content/courses/edit/${course.id}`)}
          />
        )}
        infiniteScrollLoad={async (size, page, reset, search, searchText) => {
          if (!page) {
            reset = true
          }
          await props.fetchCoursesInfinite(sites.defaultSite.id, size, page, reset, search, searchText)
        }}
        onRowPress={(data, meta, rowIndex) => {
          return data[0] !== "" ? props.history.push(`/content/courses/edit/${data[0]}`) : null
        }}
        onRowsDelete={async (rows) => {
          await props.deleteCourse(sites.defaultSite.id, rows);
          makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
          props.fetchCourses(sites.defaultSite.id, limit, offset);
        }}
        preload={() => props.fetchCourses(sites.defaultSite.id, limit, offset)}
        sort={customSearch}
        search={customSearch}
        changePage={customSearch}
        changePageSize={customSearch}
        renderCreate={() => (
          <button onClick={() => toggleCreateModalVisible()} style={styles.actionButton} className="button is-light">
            <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} /> Crear Curso
          </button>
        )}
      />
      {
        createModalVisible && (
          <ModalRight show={createModalVisible} onClick={() => toggleCreateModalVisible()} hideCloseButton={true}>
            <Create onCloseModal={() => toggleCreateModalVisible(false)} />
          </ModalRight>
        )
      }
    </>
  )
}

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    padding: '20px 0px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  count: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {

      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600',
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ courses, sites }) => {
  return { courses, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchCourses, fetchCoursesInfinite, deleteCourse, postCourse }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersList));