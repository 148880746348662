import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export const Title = memo(function Title(props) {
  return (
    <h5 className="section-title">
      {props.children}
    </h5>
  )
})

Title.propTypes = {
  children: PropTypes.node.isRequired,
}
