import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { TinaProvider, TinaCMS } from 'tinacms'
// contexts
import {
  PersistentContext,
  PersistentContextProvider
} from './context/PersistentContext'
import { CustomMediaStore } from './MediaStore'
// templates
import Template from './templates/template1/src/Template'
// landings
import Landing from './templates/template1/src/Landing'
import { BackButtonWidget } from './components/plugins/BackButtonWidget'
import SessionContextProvider from './templates/template1/src/context/SessionContext'

function Tina () {
  const params = useParams()
  let { search } = useLocation()

  const getParam = key => {
    const query = new URLSearchParams(search)
    return query.get(key)
  }

  let enabled = true
  if (getParam('preview')) {
    enabled = false
  }

  const cms = new TinaCMS({
    enabled,
    sidebar: {
      buttons: {
        save: 'Guardar',
        reset: 'Restablecer'
      }
    },
    media: new CustomMediaStore(params.id),
    toolbar: {
      hidden: false,
      buttons: {
        save: 'Guardar',
        reset: 'Restablecer'
      }
    }
  })

  cms.plugins.add(BackButtonWidget)

  return (
    <PersistentContextProvider {...params}>
      <SessionContextProvider>
        <TinaProvider cms={cms}>
          <PersistentContext.Consumer>
            {({ loading, data }) => {
              if (loading) return <></>
              if (data && data.template) {
                return <Landing {...params}/>
              } else {
                return  <Template {...params} />
              }
            }}
          </PersistentContext.Consumer>
        </TinaProvider>
      </SessionContextProvider>
    </PersistentContextProvider>
  )
}

export default Tina
