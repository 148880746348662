import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Update, Delete, Add, Visibility } from '@material-ui/icons'
export default function ActivityItem (props) {
  const iconSwitch = type =>
    ({
      READ: <Visibility style={styles.icon} />,
      WRITE: <Add style={styles.icon} />,
      DELETE: <Delete style={styles.icon} />,
      UPDATE: <Update style={styles.icon} />
    }[type])

  const actionSwitch = type =>
    ({
      READ: 'Visto',
      WRITE: 'Creado',
      DELETE: 'Eliminado',
      UPDATE: 'Actualizado'
    }[type])

  const user = props.user
    ? props.user.UserAttributes.find(item => item.Name === 'email')
    : {}

  return (
    <div style={styles.container} key={props.id}>
      <div style={styles.iconContainer}>{iconSwitch(props.action)}</div>
      <div style={styles.content}>
        <div style={styles.text}>
          {actionSwitch(props.action)} el{' '}
          {moment(props.date).format('DD/MM/YYYY hh:mm')}
          <Link to={`/admin/users/view/${props.userId}`} target='_blank'>
            {user.Value ? ` por ${user.Value} ` : ''}
          </Link>
        </div>
        {props.users && props.type && props.type !== 'ALL' && (
          <div style={styles.text}>
            Tabla {props.tableName}, Id: {props.type}
          </div>
        )}
        <div style={styles.createdAt}>{props.createdAt}</div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 8,
    marginBottom: 15
  },
  iconContainer: {
    padding: 19.7,
    backgroundColor: '#f6f6f6',
    borderRadius: 6
  },
  icon: {
    fill: '#00ace7'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 20,
    backgroundColor: 'rgb(246, 246, 246)',
    borderRadius: 6,
    flexGrow: 1,
    flexShrink: 1,
    flexWrap: 'wrap',
    rowGap: 4
  },
  text: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    flexGrow: 1,
    flexShrink: 1,
    fontWeight: '600',
    fontSize: 15
  },
  createdAt: {
    fontFamily: 'Montserrat',
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: '600',
    fontSize: 14,
    color: 'rgb(177 177 177)'
  },
  link: {
    color: '#00ace7',
    textDecoration: 'none',
    marginLeft: 6,
    fontFamily: 'Montserrat'
  }
}
