import React, { useState } from 'react';
import moment from 'moment'
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { Close } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns'
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { FormControl, CircularProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { Label, Input, TextArea } from '../StyledComponents';
import { postWebinar } from '../../../redux/actions/webinars';
import { makeToast } from '../../../utils'

function CourseWebinar(props) {

  const history = useHistory();
 
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState(new Date())
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('')

  const validate = () => {
    if (title.trim() === '') return { success: false, error: 'Título requerido' };
    if (description.trim() === '') return { success: false, error: 'Descripción requerida' };
    const tempDate = combineDateAndTime(date, time)
    if (moment(tempDate).diff(new Date(), 'days') < 0) return { success: false, error: 'Fecha invalido' }
    const diff = moment(tempDate).diff(new Date(), 'hours')
    if (diff < 0) return { success: false, error: 'Hora invalida' }
    if (diff < 0 && moment(tempDate).diff(new Date(), 'minutes') < 0) return { success: false, error: 'Minutos invalidos' }
    return { success: true }
  }

  const cleanInputs = () => {
    setTitle('');
    setDescription('');
  }

  const createAndNavigate = async () => {
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true);
      try {
        const { sites, postWebinar } = props;
        const data = await postWebinar(sites.defaultSite.id, { 
            title,
            description,
            date: combineDateAndTime(date, time),
        });
        history.push(`/content/events/edit/${data.id}`)
        cleanInputs();
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const combineDateAndTime = (date, time) => {
    let timeString = time.getHours() + ':' + time.getMinutes() + ':00';
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);
    return combined;
};

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>Crear Evento</div>
        <IconButton onClick={()=>props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        <FormControl style={{ width: '100%' }} onSubmit={()=>createAndNavigate()}>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Título</Label>
            <Input
              type="text"
              id="title"
              value={title}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el título"
              aria-describedby="titleHelp"
              onChange={(e)=>setTitle(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Descripción</Label>
            <TextArea
              id="description"
              value={description}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce la descripción"
              aria-describedby="descriptionHelp"
              onChange={(e)=>setDescription(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Fecha del evento</Label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                value={date}
                disableToolbar
                margin='normal'
                format='MM/dd/yyyy'
                id='date-picker-inline'
                style={{ width: '100%' }}
                label='Selecionar fecha (UTC)'
                onChange={newDate => setDate(newDate)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
              <KeyboardTimePicker
                value={time}
                disableToolbar
                margin='normal'
                id='time-picker'
                style={{ width: '100%' }}
                label='Selecionar hora (UTC)'
                onChange={newTime => setTime(newTime)}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </FormControl>
      </div>
      <div style={styles.actions}>

        <button 
          disabled={loading} 
          onClick={()=>createAndNavigate()}
          style={styles.actionButton} 
          className="button is-light"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Crear Evento'}
        </button>

        <button 
          onClick={()=>props.onCloseModal()}
          style={styles.actionButton}
          className="button is-secondary"
        >
          Cancelar
        </button>

      </div>
    </div>
  );
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    textAlign: 'left', 
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
} 

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ postWebinar }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseWebinar);