import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { FormControl, CircularProgress } from '@material-ui/core';
import { Label, Input, TextArea, TouchableOpacity } from '../StyledComponents';
import { Close, Image } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { postCourse } from '../../../redux/actions/courses';
import { useHistory } from 'react-router-dom';

function CourseCreate(props) {

  const history = useHistory();
 
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [description, setDescription] = useState('')
  const [structure, setStructure] = useState(null)
  const [loading, setLoading] = useState(false);

  const validate = () => {
    if (title.trim() === '') return { success: false, error: 'Título requerido' };
    if (description.trim() === '') return { success: false, error: 'Descripción requerida' };
    if (structure === null) return { success: false, error: 'Estructura requerida' };
    return { success: true }
  }

  const cleanInputs = () => {
    setTitle('');
    setDescription('');
    setStructure(null);
  }

  const createAndNavigate = async () => {
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true);
      try {
        const { sites, postCourse } = props;
        const data = await postCourse(sites.defaultSite.id, { 
            title: title, 
            description: description,
            structure,
        });
        history.push(`/content/courses/edit/${data.id}`)
        cleanInputs();
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>Crear Curso</div>
        <IconButton onClick={()=>props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        <FormControl style={{ width: '100%' }} onSubmit={()=>createAndNavigate()}>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Título</Label>
            <Input
              type="text"
              id="title"
              value={title}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el título"
              aria-describedby="titleHelp"
              onChange={(e)=>setTitle(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Descripción</Label>
            <TextArea
              id="description"
              value={description}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce la descripción"
              aria-describedby="descriptionHelp"
              onChange={(e)=>setDescription(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Elegir la estructura inicial del curso</Label>
            <div style={styles.structureList}>
                {
                    structures.map((element, index) => {
                        return (
                            <TouchableOpacity key={`structures-${index}`} onClick={()=>setStructure(element.id)} style={styles.structureItem}>
                                <div style={styles.structureImageContainer}>
                                    <Image style={{fill: '#495057', width: 24, height: 24}} />
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', marginTop: 8}}>
                                    <div style={{...styles.structureCheckbox, ...(structure === element.id ? styles.structureCheckboxActive : {})}}>
                                        {
                                            structure === element.id && ( <div style={styles.structureCheckboxDot}/> )
                                        }
                                    </div>
                                    <div style={{...styles.structureName, ...(structure === element.id ? styles.structureNameActive : {})}}>{element.name}</div>
                                </div>
                            </TouchableOpacity>
                        )
                    })
                }
            </div>
          </div>
        </FormControl>
      </div>
      <div style={styles.actions}>

        <button 
          disabled={loading} 
          onClick={()=>createAndNavigate()}
          style={styles.actionButton} 
          className="button is-light"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : 'Crear Curso'}
        </button>

        <button 
          onClick={()=>props.onCloseModal()}
          style={styles.actionButton}
          className="button is-secondary"
        >
          Cancelar
        </button>

      </div>
    </div>
  );
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    textAlign: 'left', 
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
  structureList: {
    display: 'flex',
    justifyContent: 'space-between', 
    columnGap: 9
  },
  structureImageContainer: {
    backgroundColor: '#f7f7f7',
    width: 165,
    height: 90,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  structureCheckbox: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    border: '#f7f7f7 2px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  structureCheckboxActive: {
    backgroundColor: '#00ace7'
  },
  structureCheckboxDot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'white'
  },
  structureName: {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    paddingLeft: 8,
    color: '#a2a2a2'
  },
  structureNameActive: {
      color: '#00ace7'
  }
} 

const structures = [
    {
        id: 1,
        name: 'Empezar de cero',
        value: {}
    },
    {
        id: 2,
        name: 'Mini curso',
        value: {}
    },
    {
        id: 3,
        name: 'Curso completo',
        value: {}
    }
];

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ postCourse }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreate);