import {
  FETCHING_PEOPLE,
  FAIL_FETCH_PEOPLE,
  SUCCESS_FETCH_PEOPLE,
  FETCHING_ALL_PEOPLE,
  FAIL_FETCH_ALL_PEOPLE,
  SUCCESS_FETCH_ALL_PEOPLE,
  FETCHING_PERSON,
  FAIL_FETCH_PERSON,
  SUCCESS_FETCH_PERSON,
  DELETE_PERSON,
  DELETE_FAIL_PERSON,
  DELETE_SUCCESS_PERSON,
} from '../actions/people'

const initialState = {
  all: [],
  data: [],
  user: null,
  error: null,
  loading: false,
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_PEOPLE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_PEOPLE:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case FAIL_FETCH_PEOPLE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
    case FETCHING_ALL_PEOPLE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_ALL_PEOPLE:
      return {
        ...state,
        all: action.data,
        loading: false,
      };
    case FAIL_FETCH_ALL_PEOPLE:
      return {
        ...state,
        all: [],
        loading: false,
        error: action.error,
      };
    case FETCHING_PERSON:
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_PERSON:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case FAIL_FETCH_PERSON:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
      case DELETE_PERSON:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FAIL_PERSON:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SUCCESS_PERSON:
      return {
        ...state,
        loading: false,
      };
    default:
      return state
  }
};
