import { parseUrl } from "../../helpers/utils";
import network from "../../utils/network";

export const FETCH_FORM_ENTRIES = 'FETCH_FORMS_ENTRIES';
export const FAIL_FETCH_FORM_ENTRIES = 'FAIL_FETCH_FORM_ENTRIES';
export const SUCCESS_FETCH_FORM_ENTRIES = 'SUCCESS_FETCH_FORM_ENTRIES';

export const FETCH_FORMS = 'FETCH_FORMS';
export const FAIL_FETCH_FORMS = 'FAIL_FETCH_FORMS';
export const SUCCESS_FETCH_FORMS = 'SUCCESS_FETCH_FORMS';
export const SUCCESS_FETCH_FORMS_TABLE = 'SUCCESS_FETCH_FORMS_TABLE';

export const SELECT_FORM = 'SELECT_FORM';
export const FAIL_SELECT_FORM = 'FAIL_SELECT_FORM';
export const SUCCESS_SELECT_FORM = 'SUCCESS_SELECT_FORM';

export const FETCHING_FORM = 'FETCHING_FORM';
export const FAIL_FETCHING_FORM = 'FAIL_FETCHING_FORM';
export const SUCCESS_FETCHING_FORM = 'SUCCESS_FETCHING_FORM';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FAIL_FETCH_PAGE = 'FAIL_FETCH_PAGE';
export const SUCCESS_FETCH_PAGE = 'SUCCESS_FETCH_PAGE';

export const POST_FORM = "POST_FORM"
export const POST_FAIL_FORM = "POST_FAIL_FORM"
export const POST_SUCCESS_FORM = "POST_SUCCESS_FORM"

export const PUT_FORM = "PUT_FORM"
export const PUT_FAIL_FORM = "PUT_FAIL_FORM"
export const PUT_SUCCESS_FORM = "PUT_SUCCESS_FORM"

export const DELETE_FORM = "DELETE_FORM"
export const DELETE_FAIL_FORM = "DELETE_FAIL_FORM"
export const DELETE_SUCCESS_FORM = "DELETE_SUCCESS_FORM"

export const UPDATE_SORT_FORM = "UPDATE_SORT_FORM"
export const UPDATE_FAIL_FORM = "UPDATE_FAIL_FORM"

export const fetchForms = (site, limit, offset = null, column, direction, search, searchtext) => {
  const url = parseUrl(`site/${site}/form?`, limit, offset, column, direction, search, searchtext)
  return async dispatch => {
    dispatch({ type: FETCH_FORMS });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_FORMS,
        });
      } else {
        dispatch({
          data: {
            limit,
            offset,
            search,
            searchtext,
            data: response.data.data,
            count: response.data.count,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_FORMS_TABLE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_FORMS,
      });
    }
  };
};

export const fetchFormEntries = (site, fid, limit, offset = null, column, direction, search, searchtext) => {
  const url = parseUrl(`site/${site}/form/${fid}/entries?`, limit, offset, column, direction, search, searchtext)
  return async dispatch => {
    dispatch({ type: FETCH_FORM_ENTRIES });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_FORMS,
        });
      } else {
        dispatch({
          data: {
            limit,
            offset,
            search,
            searchtext,
            data: response.data.data,
            count: response.data.count,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_FORM_ENTRIES,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_FORM_ENTRIES,
      });
    }
  };
};

export const fetchAllForms = async (siteId) => {
  try {
    const response = await network.get(`site/${siteId}/forms/all`);
    if (response.data && response.data.error) {
      throw response.data.error
    } else {
      return response.data
    }
  } catch (error) {
    throw error
  }
};

export const fetchForm = (site, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_FORM });
    try {
      const response = await network.get(`site/${site}/form/${id}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCHING_FORM,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCHING_FORM,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCHING_FORM,
      });
    }
  };
};

export const updateFormSortLocal = (newSort = []) => {
  return dispatch => {
    dispatch({
      data: newSort,
      type: UPDATE_SORT_FORM,
    });
  };
}

export const getLandings = async (site) => {
  try {
    const { data } = await network.get(`site/${site}/pages?type=Landing&offset=0`);
    return data;
  } catch (error) {
    console.error('getLandings', error);
    return error;
  }
}

export const putForm = (site, id, body) => {
  return async dispatch => {
    dispatch({ type: PUT_FORM });
    try {
      await network.put(`site/${site}/form/${id}`, body);
      dispatch({
        data: body,
        type: PUT_SUCCESS_FORM,
      });
    } catch (error) {
      dispatch({
        error,
        type: PUT_FAIL_FORM,
      });
    }
  };
};

export const postForm = (site, body) => {
  return async dispatch => {
    dispatch({ type: POST_FORM });
    try {
      const response = await network.post(`site/${site}/form`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_FORM,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_FORM,
      });
    }
  };
};

export const deleteForms = (site, ids = []) => {
  const promises = ids.map((item) => {
    return network.del(`/site/${site}/form/${item}`);
  });
  return async dispatch => {
    dispatch({ type: DELETE_FORM });
    try {
      const response = await Promise.all(promises)
      dispatch({
        data: response.data,
        type: DELETE_SUCCESS_FORM,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_FORM,
      });
      return error;
    }
  };
};

export const deleteEntries = (site, form, ids = []) => {
  const promises = ids.map((item) => {
    return network.del(`/site/${site}/form/${form}/entries/${item}`);
  });
  return async dispatch => {
    dispatch({ type: DELETE_FORM });
    try {
      const response = await Promise.all(promises)
      dispatch({
        data: response.data,
        type: DELETE_SUCCESS_FORM,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_FORM,
      });
      return error;
    }
  };
};