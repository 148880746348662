import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppBar, Tabs, Tab, Box, makeStyles } from '@material-ui/core'
import {
  postModule,
  fetchModules,
  deleteModule,
  updateModule
} from '../../../redux/actions/modules'
import ActivitiesList from '../ActivitiesList'
import Suscriptores from '../courses/Suscriptores'
import Infomation from './Infomation'
import Modules from './Modules'

const useStyles = makeStyles(theme => ({
  appBarRoot: {
    width: '100%',
    border: 0,
    marginBottom: 15,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
  },
  tabsRoot: {
    backgroundColor: theme.palette.background.paper,
    float: 'left',
  },
  tabsIndicator: {
    border: '2px solid #2196F3 !important',
  },
  tabRoot: {
    fontFamily: 'Montserrat',
    backgroundColor: '#FFFFFF',
    color: '#2196F3',
    fontWeight: 'bold',
    border: 0,
    textTransform: 'none',
    transition: 'background-color 0.4s',
    fontSize: 14,
    marginRight: 12,
    height: 40,
    minHeight: 40,
    '&:hover': {
      backgroundColor: 'rgba(33,150,243,0.2)',
      color: '#2196F3',
      outline: 'none',
    },
    '&[aria-selected="false"]': {
      color: '#2196F3',
      backgroundColor: 'white',
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  paper: {
    width: 400,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  }
}))

export function TabPanel(props) {
  const { children, value, index } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ padding: 20, width: '100%' }}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function ModulesTabs(props) {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div style={{ width: '100%' }}>
      <AppBar
        classes={{
          root: classes.appBarRoot
        }}
        position='static'
      >
        <Tabs
          value={value}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator
          }}
          onChange={handleChange}
        >
          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='INFORMACIÓN'
          />

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='ESTUDIANTES'
          />

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='LECCIONES'
          />

          <Tab
            classes={{
              root: classes.tabRoot
            }}
            label='ACTTVIDAD'
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Infomation />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Suscriptores />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Modules />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <ActivitiesList
          url={`site/${props.site}/course/${props.course}/activity`}
        />
      </TabPanel>
    </div>
  )
}

const mapStateToProps = ({ sites, courses, modules }) => {
  return { sites, courses, modules }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { postModule, fetchModules, deleteModule, updateModule },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulesTabs)
