import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { Close, AddBox } from '@material-ui/icons'
import { ArrowDropDown } from '@material-ui/icons'
import {
  FormControl,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  makeStyles,
  IconButton
} from '@material-ui/core'
import { fetchAllCoursesByEvent } from '../../../redux/actions/courses'
import { makeToast } from '../../../utils'
import { Select } from '../StyledComponents'

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot: {
    padding: 2
  }
})

const AssignInstructorModal = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedInstructor, setSelectedInstructor] = useState(-1)

  const { eventId } = props

  const loadCourses = async () => {
    await setLoading(true)
    try {
      const { data } = await fetchAllCoursesByEvent(props.siteId, eventId)
      setData(data)
    } catch (error) {
      console.log('error', error)
    } finally {
      await setLoading(false)
    }
  }

  useEffect(() => {
    loadCourses()
  }, [])

  const submit = async () => {
    try {
      await setLoading(true)
      await props.onSubmit(selectedInstructor)
    } catch (error) {
      console.log(error)
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      await setLoading(false)
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.visible}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogContent style={{ paddingBottom: 18 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
            <AddBox style={{ fill: '#024976' }} />
            <DialogTitle
              classes={{ root: classes.dialogTitleRoot }}
              id='form-dialog-title'
            >
              Asignar instructor
            </DialogTitle>
          </div>
          <IconButton
            classes={{ root: classes.iconButtonRoot }}
            onClick={props.onClose}
          >
            <Close style={{ fill: '#333333' }} />
          </IconButton>
        </div>
      </DialogContent>
      <div style={{ height: 1.6, width: '100%', backgroundColor: '#e4e4e4' }} />
      <DialogContent style={{ paddingTop: 24 }}>
        <FormControl style={{ width: '100%' }} onSubmit={() => submit()}>
          {loading ? (
            <CircularProgress style={{color: '#00A8D8', margin: 'auto' }} />
          ) : (
            <>
              {data.length ? (
                <FormGroup>
                  <div style={{ position: 'relative' }}>
                    <Select
                      value={selectedInstructor}
                      disabled={loading}
                      style={{
                        height: 56,
                        borderRadius: 14,
                        backgroundColor: 'white',
                        border: 'rgb(232, 232, 232) 1px solid'
                      }}
                      onChange={ev => setSelectedInstructor(ev.target.value)}
                    >
                      <option value={-1}>Selecciona un instructor</option>
                      {data.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                    <ArrowDropDown
                      style={{
                        fill: '#144974',
                        position: 'absolute',
                        top: 16,
                        right: 16
                      }}
                    />
                  </div>
                </FormGroup>
              ) : (
                <div style={{textAlign: 'center'}}>No hay instructores para asignar</div>
              )}
            </>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 24px 24px'
        }}
      >
        <button
          onClick={props.onClose}
          disabled={loading}
          style={{
            fontSize: 16,
            padding: '28px 42px',
            borderRadius: 8,
            width: '48%'
          }}
          className='button is-secondary'
        >
          Cancelar
        </button>

        <button
          disabled={loading || selectedInstructor === -1}
          onClick={() => submit()}
          style={{
            fontSize: 16,
            padding: '28px 42px',
            borderRadius: 8,
            width: '48%'
          }}
          className='button is-light'
        >
          {loading ? <CircularProgress size={18} style={{ color: '#00A8D8' }}/> : 'Asignar instructor'}
        </button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

export default connect(mapStateToProps)(AssignInstructorModal)
