import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'
import {
  fetchFormEntries,
  deleteForms,
  deleteEntries
} from '../../../redux/actions/forms'
import { makeToast } from '../../../utils'
import CustomTable from '../DataTable'

function Registros (props) {
  const { form } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const {
    sites,
    forms: {
      entries: { data, loading, limit, offset, count, sort }
    }
  } = props

  const generateColumns = () => {
    const result = []
    if (data.length) {
      const keys = {}
      for (let index = 0; index < data.length; index++) {
        const item = data[index]
        for (let jndex = 0; jndex < item.results.length; jndex++) {
          const result = item.results[jndex]
          keys[result.name] = null
        }
      }
      Object.keys(keys).forEach(key => {
        result.push({
          label: key,
          name: 'results',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              if (value && value && value.length) {
                const index = value.findIndex(item => item.name === key)
                if (index !== -1 && value[index]) {
                  return <>{value[index].value}</>
                }
              }
            }
          }
        })
      })
      return result
    }
    return result
  }

  const result = generateColumns()

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded'
      }
    },
    ...result,
    {
      label: 'Fecha de creación',
      name: 'createdAt',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div>
                {moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}
              </div>
            )
          }
        }
      }
    }
  ]

  return (
    <>
      <div style={styles.header}>
        <div style={styles.title}>Registros</div>
        <div style={styles.subtitle}>
          ({count} {count !== 1 ? 'Registros' : 'Registro'})
        </div>
      </div>
      {loading && <LinearProgress />}
      <div style={styles.tableContainer}>
        <CustomTable
          data={data}
          columns={columns}
          count={count}
          limit={limit}
          offset={offset}
          sortOrder={sort}
          onRowPress={(rowData, meta, rowIndex) => {}}
          onRowsDelete={async rows => {
            await props.deleteEntries(sites.defaultSite.id, form, rows)
            makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
            props.fetchFormEntries(
              props.sites.defaultSite.id,
              form,
              limit,
              offset
            )
          }}
          preload={() =>
            props.fetchFormEntries(
              props.sites.defaultSite.id,
              form,
              limit,
              offset
            )
          }
          changePageSize={({ rowsPerPage }) =>
            props.fetchFormEntries(
              props.sites.defaultSite.id,
              form,
              rowsPerPage,
              offset
            )
          }
          search={({ rowsPerPage, page, sortOrder, searchText, columns }) => {
            if (searchText && searchText !== '') {
              const names = columns.map(item => item.name)
              props.fetchFormEntries(
                props.sites.defaultSite.id,
                form,
                rowsPerPage,
                page,
                sortOrder.name,
                sortOrder.direction,
                names,
                searchText
              )
            } else {
              props.fetchFormEntries(
                props.sites.defaultSite.id,
                form,
                rowsPerPage,
                page,
                sortOrder.name,
                sortOrder.direction
              )
            }
          }}
          changePage={({ rowsPerPage, page, sortOrder }) => {
            props.fetchFormEntries(
              props.sites.defaultSite.id,
              form,
              rowsPerPage,
              page,
              sortOrder.name,
              sortOrder.direction
            )
          }}
          sort={({ rowsPerPage, page, sortOrder }) => {
            props.fetchFormEntries(
              props.sites.defaultSite.id,
              form,
              rowsPerPage,
              page,
              sortOrder.name,
              sortOrder.direction
            )
          }}
        />
      </div>
    </>
  )
}

const styles = {
  tableContainer: {
    marginTop: 0
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  subtitle: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}

Registros.propTypes = {}

const mapStateToProps = ({ forms, sites }) => {
  return { forms, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { fetchFormEntries, deleteForms, deleteEntries },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Registros)
