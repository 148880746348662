import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { LinearProgress } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { fetchEmailTemplatesSystem, deleteEmailTemplate, putEmail } from '../../../redux/actions/emailTemplates';
import EditSubjectModal from "./EditSubjectModal";
import Create from "../emailTemplates/Create";
import CustomTable from "../DataTable";
import ModalRight from "../ModalRight";

class EmailList extends React.Component {

  state = {
    subject: null,
    editModal: false,
    createModal: false,
    emailTemplateId: null,
    editModalSubject: false,
  };

  columns = [
    // {
    //   label: 'ID',
    //   name: 'id',
    //   options: {
    //     display: 'excluded'
    //   }
    // },
    {
      label: 'Id',
      name: 'id',
    },
    {
      label: 'Nombre',
      name: 'name',
    },
    {
      label: 'Subject',
      name: 'subject',
    },
    {
      label: 'Descripción',
      name: 'description',
    },
    {
      label: "Acciones",
      name: 'id',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Link to={`/marketing/email-templates/view/${value}`} style={styles.button}>
                  Plantilla
                </Link>
                <div onClick={() => this.setState({ editModalSubject: true, emailTemplateId: value, subject: tableMeta.rowData[2] })} style={styles.button}>
                  Subject
                </div>
                <IconButton onClick={(e) => this.handleEditEmailTemplate(e, value)}>
                  <EditIcon style={{ fill: '#007bff' }} />
                </IconButton>
              </div>
            );
          }
        }
      }
    }
  ]

  toggleEditModal = async () => {
    await this.setState((prev) => ({ editModal: !prev.editModal }));
  }

  toggleCreateModal = async () => {
    await this.setState((prev) => ({ createModal: !prev.createModal }));
  }

  handleEditEmailTemplate = (e, id) => {
    e.stopPropagation()
    this.setState({
      emailTemplateId: id,
      createModal: false,
      editModal: true
    });
  }

  customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    const { sites: { defaultSite } } = this.props;
    if (searchText && searchText !== '') {
      const names = columns.map(item => (item.name));
      this.props.fetchEmailTemplatesSystem(defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
    } else {
      this.props.fetchEmailTemplatesSystem(defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
    }
  }

  onSubmit = async (data) => {
    const { emailTemplateId } = this.state
    if (emailTemplateId) {
      try {
        const { sites: { defaultSite }, emailTemplates: { emails: { limit, offset } } } = this.props;
        await this.props.putEmail(defaultSite.id, emailTemplateId, data)
        this.props.fetchEmailTemplatesSystem(defaultSite.id, limit, offset)
        makeToast(this.props.enqueueSnackbar, 'Se creo el form')
        this.setState({ editModalSubject: false, subject: null })
      } catch (error) {
        console.log('error', error)
        makeToast(this.props.enqueueSnackbar, 'Se creo el form')
      }
    }
  }

  render() {
    const { editModalSubject, createModal, editModal, subject } = this.state
    const { sites: { defaultSite }, emailTemplates: { emails: { data, loading, limit, offset, count, sort } } } = this.props;
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={styles.container}>
            <section style={styles.content} className="content">

              <div style={styles.header}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <div style={styles.title}>Mensajes de correo transaccionales</div>
                  <div style={styles.emailTemplateCount}>({count} {count !== 1 ? 'Mensajes de correo transaccionales' : 'Mensaje de correo transaccional'})</div>
                </div>
              </div>

              {loading && <LinearProgress />}

              <div style={styles.tableContainer}>
                <CustomTable
                  data={data}
                  columns={this.columns}
                  count={count}
                  limit={limit}
                  offset={offset}
                  sortOrder={sort}
                  theme={theme}
                  selectableRows={false}
                  preload={() => this.props.fetchEmailTemplatesSystem(defaultSite.id, limit, offset)}
                  sort={this.customSearch}
                  search={this.customSearch}
                  changePage={this.customSearch}
                  changePageSize={this.customSearch}
                />
              </div>
            </section>
            {
              editModalSubject && (
                <EditSubjectModal
                  subject={subject}
                  visible={editModalSubject}
                  onSubmit={(data) => this.onSubmit(data)}
                  onClose={() => this.setState({ editModalSubject: false, subject: null })}
                />
              )
            }
            {
              createModal && (
                <ModalRight show={createModal} onClick={this.toggleCreateModal} hideCloseButton={true}>
                  <Create onCloseModal={this.toggleCreateModal} />
                </ModalRight>
              )
            }
            {
              editModal && (
                <ModalRight show={editModal} onClick={this.toggleEditModal} hideCloseButton={true}>
                  <Create emailTemplate={this.state.emailTemplateId} onCloseModal={this.toggleEditModal} />
                </ModalRight>
              )
            }
          </div>
        </div>
      </div>
    );
  }
};

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  button: {
    color: '#fff',
    textDecoration: 'none',
    backgroundColor: '#2096F3',
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    marginRight: 10,
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  emailTemplateCount: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {

      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600',
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ emailTemplates, sites }) => {
  return { emailTemplates, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchEmailTemplatesSystem, deleteEmailTemplate, putEmail }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(EmailList)));