import network from '../utils/network';

export const updateSite = async (site, body) => {
  try {
    const { data } = await network.put(`site/${site}`, body);
    return data;
  } catch (error) {
    console.error('updateSite', error);
    return error;
  }
}

export const updateSiteZoom = async (site, body) => {
  try {
    const { data } = await network.put(`site/${site}/zoom`, body);
    return data;
  } catch (error) {
    console.error('updateSiteZoom', error);
    return error;
  }
}

export const getSite = async (site) => {
  try {
    const { data } = await network.get(`site/${site}`);
    return data;
  } catch (error) {
    console.error('getSite', error);
    return error;
  }
}

export const stripeConnect = async (site) => {
  try {
    const { data } = await network.get(`site/${site}/stripe/connect`);
    return data;
  } catch (error) {
    console.error('stripeConnect', error);
    return error;
  }
}