import React, { useState } from 'react'
import { Input, Label } from './StyledComponents';
import { Modal } from 'react-bootstrap';  

const PageBuilderTitle = ({show, onSubmit, onDismiss}) => {

    const [templateTitle, setTemplateTitle] = useState('')
    const [templateDescription, setTemplateDescription] = useState('')

    return (
        <Modal centered show={show} onHide={onDismiss}>
            <div style={{ padding: 30 }}>
            <div style={{ position: 'relative' }}>
                <Label>Título del template</Label>
                <Input
                    type='text'
                    value={templateTitle}
                    onChange={(e)=>setTemplateTitle(e.target.value)}
                />

                <Label>Descripción del template</Label>
                <Input
                    type='text'
                    value={templateDescription}
                    onChange={(e)=>setTemplateDescription(e.target.value)}
                />
            </div>
            <button 
                onClick={onDismiss}
                style={{marginTop: 24}}
                className="button is-secondary"
            >
                Cancelar
            </button>
            <button 
                onClick={()=>onSubmit({title: templateTitle, description: templateDescription})}
                style={{marginTop: 24}}
                className="button is-primary"
            >
                Guardar
            </button>
            </div>
        </Modal>
    );
}

export default PageBuilderTitle



