import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Dashboard as DashboardIcon, ChevronLeft, ArrowDropUp, DesktopMac, Archive, ExpandMore, Person as PersonContact, Web, Settings, Menu, ExitToApp, VideoLabel, Event, Category, VideoLibrary, Person, PostAdd, ViewList, Email as EmailIcon, SettingsApplications, Settings as SettingsIcon, People, Directions } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';
import { selectSite } from "../../redux/actions/sites";
import { TouchableOpacity } from './StyledComponents'
import { logout } from '../../redux/actions/session';
import styled from 'styled-components';
import '../../App.css'

const routes = [
  {
    to: '/',
    name: 'Estadísticas',
    protected: true,
    icon: <DashboardIcon />,
    routes: false
  },
  {
    to: '/people',
    name: 'Contactos',
    protected: true,
    icon: <PersonContact />,
    routes: false,
    matches: ['/people/view']
  },
  {
    to: '/website',
    name: 'Páginas del sitio',
    protected: true,
    icon: <Web />,
    routes: false
  },
  {
    name: 'Contenido',
    protected: true,
    icon: <Archive />,
    routes: [
      { to: '/content/courses', name: 'Cursos', protected: true, matches: ['/content/courses/edit/'], icon: <VideoLabel /> },
      { to: '/content/events', name: 'Eventos', protected: true, matches: ['/content/events/edit/'], icon: <Event /> },
      { to: '/content/categories', name: 'Categorías', protected: true, matches: ['/content/categories/'], icon: <Category /> },
      { to: '/content/videos', name: 'Videos', protected: true, icon: <VideoLibrary /> },
      { to: '/content/instructors', name: 'Instructores', protected: true, matches: ['/content/instructors/'], icon: <Person /> },
    ]
  },
  {
    name: 'Marketing',
    protected: true,
    icon: <DesktopMac />,
    routes: [
      // { to: '/offers', name: 'Ofertas / Paquetes', protected: true },
      { to: '/marketing/pages', name: 'Páginas de destino', protected: true, matches: ['/marketing/pages/view/'], icon: <PostAdd /> },
      { to: '/marketing/forms', name: 'Formularios', protected: true, matches: ['/marketing/forms/view/'], icon: <ViewList /> },
      { to: '/marketing/email-templates', name: 'Templates de correo', protected: true, matches: ['/marketing/email-templates/view'], icon: <EmailIcon /> },
      { to: '/marketing/automate', name: 'Automatizaciones', protected: true, matches: ['/marketing/automate/'], icon: <SettingsApplications /> }
    ]
  },
  {
    name: 'Administrador',
    protected: true,
    icon: <Settings />,
    routes: [
      // { to: '/admin/domains', name: 'Mis dominios', protected: true, icon: <Directions /> },
      { to: '/admin/sites', name: 'Mis sitios', protected: true, icon: <PostAdd /> },
      { to: '/admin/settings', name: 'Configuración', protected: true, icon: <SettingsIcon /> },
      { to: '/admin/users', name: 'Usuarios', protected: true, matches: ['/admin/users/view/'], icon: <People /> },
    ]
  }
]

const publicRoutes = [
  '/welcome',
  '/PrivacyPolicy',
  '/Terms',
  '/changepassword',
  '/login',
  '/register',
  '/changepasswordconfirmation',
  '/forgotpassword',
  '/forgotpasswordverification',
  '/forgotpasswordemailsent'
]

class Navbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navbarMenuActive: false,
      initialState: true,
      expanded: false,
      sitesDropdownOpen: false
    };
  }

  handleLogOut = async event => {
    event.preventDefault();
    await this.props.logout();
    this.props.history.push('/')
  }

  pathMatch = (currentPath, link, otherMatches) => {
    if (otherMatches) {
      let res = false
      otherMatches.forEach(match => {
        if (currentPath.includes(match)) {
          res = true
        }
      })
      if (res) return true
    }
    return currentPath === link ? true : false
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ initialState: false, expanded: isExpanded ? panel : false })
  }

  toggleSitesDropdown = (option) => {
    this.setState(prevState => ({ sitesDropdownOpen: option ? option : !prevState.sitesDropdownOpen }))
  }

  handleCurtainClick = (e) => {
    e.stopPropagation()
    this.toggleSitesDropdown(false)
  }

  onSelectSite = (id) => {
    const newSite = this.props.sites.data.find(item => id === item.id)
    this.props.selectSite(newSite)
    this.toggleSitesDropdown(false)
  }

  renderNavs = () => {
    const navs = [];
    const pathname = this.props.location.pathname;
    const { classes } = this.props;

    routes.forEach(item => {

      let finded = false
      if (item.routes && item.routes.length) {
        item.routes.forEach(route => {
          if (this.pathMatch(pathname, route['to'], route['matches'])) {
            finded = true
          }
        });
      }

      const nav = (
        <>
          {
            item.routes ?
              <>
                <Accordion
                  classes={{ root: classes.accordionRoot }}
                  className={!this.props.expanded && `collapsed`}
                  expanded={(this.state.initialState && finded) || this.state.expanded === item.name} onChange={this.handleChange(item.name)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{ root: classes.accordionSummaryRoot }}
                  >
                    {item['icon']}
                    {this.props.expanded && (<RouteName>{item['name']}</RouteName>)}
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}
                  >
                    {
                      item.routes.map((route, index) => (
                        <Link
                          to={route['to']}
                          key={`link-${index}`}
                          className={`navbar-item disable-underline c-tooltip ${this.pathMatch(pathname, route['to'], route['matches']) ? `navbar-item-active` : ``}`}
                        >
                          {this.props.expanded ?
                            <RouteName style={{ fontSize: 12, marginLeft: 0 }}>{route['name']}</RouteName>
                            :
                            <>
                              {route['icon']}
                              <span class="c-tooltiptext">{route['name']}</span>
                            </>
                          }
                        </Link>
                      ))
                    }
                  </AccordionDetails>
                </Accordion>
              </>
              :
              <Link
                key={item['to']}
                to={item['to']}
                className={`navbar-item disable-underline c-tooltip ${this.pathMatch(pathname, item['to'], item['matches']) ? `navbar-item-active` : ``}`}
              >

                {item['icon']}
                {
                  this.props.expanded ? (
                    <RouteName>{item['name']}</RouteName>
                  ) :
                    <span class="c-tooltiptext">{item['name']}</span>
                }
              </Link>
          }
        </>
      )

      if (this.props.session.isAuthenticated) {
        navs.push(nav);
      } else if (!item['protected']) {
        navs.push(nav);
      }

    });
    return navs;
  }

  render() {
    const { sites: { data, defaultSite } } = this.props;
    const { isAuthenticated, user } = this.props.session;
    const pathname = this.props.location.pathname;

    return (
      <>
        {
          isAuthenticated ?
            publicRoutes.includes(pathname) ?
              <UnAuthContainer>
                <div className="navbar-brand" style={{ padding: 0 }}>
                  <a className="navbar-item" href="/">
                    <img src="/images/logo.webp" alt="l3rn - tu propia academia digital" />
                  </a>
                </div>
              </UnAuthContainer>
              :
              <>
                <Container
                  style={{ width: this.props.expanded ? 250 : 50 }}
                  role="navigation"
                  aria-label="main navigation"
                >
                  <NavbarBrand style={{ ...(!this.props.expanded ? { padding: '8px' } : {}) }}>
                    <CollapseButton
                      expanded={this.props.expanded}
                      style={{ ...(!this.props.expanded && { position: 'initial', justifyContent: 'center', marginBottom: 6 }) }}
                      onClick={() => this.props.toggleExpanded()}>
                      {this.props.expanded ? <ChevronLeft style={{ color: '#00ACE7' }} /> : <Menu />}
                    </CollapseButton>
                    <Link style={{ display: 'block' }} to="/">
                      <img src={this.props.expanded ? "/images/logo.webp" : "/images/icon.png"} alt="l3rn - tu propia academia digital" />
                    </Link>
                  </NavbarBrand>
                  <div
                    style={{ width: '100%' }}
                    id="navbarBasicExample"
                    className={`navbar-menu ${!isAuthenticated ? `is-active not-authenticated` : this.state.navbarMenuActive ? `is-active` : null}`}
                  >

                    <div style={{ flexDirection: 'column', width: '100%' }} className="navbar-start">
                      {this.renderNavs()}
                    </div>

                  </div>
                  {
                    isAuthenticated && user && (
                      <User>
                        <TouchableOpacity onClick={() => this.toggleSitesDropdown()} style={{ padding: this.props.expanded ? '5px' : '8px 2px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: this.props.expanded ? 'space-between' : 'center' }}>
                          <Avatar alt={user.attributes.name} src="#" style={{ backgroundColor: '#2196F3', ...(!this.props.expanded && { height: 24, width: 24, fontSize: 12 }) }} />
                          {
                            this.props.expanded && (
                              <>
                                <UserInfo>
                                  <Email>{user.attributes.email}</Email>
                                  <Title>{defaultSite.title}</Title>
                                </UserInfo>
                                <ArrowDropUp style={{ width: '15%', height: '15%', display: 'flex', justifyContent: 'flex-end', color: 'rgba(0, 0, 0, 0.87)' }} />
                              </>
                            )
                          }
                        </TouchableOpacity>
                        {
                          this.state.sitesDropdownOpen && (
                            <>
                              <SitesDropdown className={!this.props.expanded && 'collapsed'}>
                                {this.props.sites.data.map((item, index) => (
                                  <TouchableOpacity
                                    key={`link-${index}`}
                                    onClick={() => defaultSite.id === item.id ? null : this.onSelectSite(item.id)}
                                    style={{
                                      fontSize: 15,
                                      fontFamily: 'Montserrat',
                                      fontWeight: defaultSite.id === item.id ? '700' : '400'
                                    }}
                                  >
                                    {item.title}
                                  </TouchableOpacity>
                                ))}
                              </SitesDropdown>
                              <Curtain onClick={(e) => this.handleCurtainClick(e)} />
                            </>
                          )
                        }
                      </User>
                    )
                  }
                  <Actions>
                    <UserMenuButton onClick={this.handleLogOut}>
                      {this.props.expanded ? (<div> <ExitToApp /> Cerrar sesión</div>) : (<ExitToApp />)}
                    </UserMenuButton>
                  </Actions>

                </Container>
                <Spacer style={{ width: this.props.expanded ? 250 : 50 }} />
              </>
            :
            <UnAuthContainer>
              <div className="navbar-brand" style={{ padding: 0 }}>
                <Link className="navbar-item" to="/">
                  <img src="/images/logo.webp" alt="l3rn - tu propia academia digital" />
                </Link>
              </div>

              <div className="buttons">
                <Link to="/register" className="button is-primary">
                  <strong>Registro</strong>
                </Link>
                <Link to="/login" className="button is-light">
                  Iniciar sesión
              </Link>
              </div>
            </UnAuthContainer>
        }
      </>
    )
  }
}

const useStyles = theme => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  accordionRoot: {
    width: '100%',
    overflow: 'auto',
    border: 'none',
    boxShadow: 'none',
    '&::before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: 0
    },
    '&.collapsed': {
      position: 'relative'
    },
    '&.collapsed.Mui-expanded > .MuiButtonBase-root': {
      borderBottom: '#363636 1px solid'
    },
    '&.collapsed .MuiCollapse-container .MuiCollapse-wrapper .MuiCollapse-wrapperInner > div .MuiAccordionDetails-root': {
      padding: 0
    },
    '&.collapsed .MuiButtonBase-root .MuiButtonBase-root': {
      position: 'absolute',
      right: 12,
      padding: 0
    },
    '&.collapsed .MuiButtonBase-root .MuiButtonBase-root > span > svg': {
      width: 16,
      height: 16
    }
  },
  accordionSummaryRoot: {
    padding: '0px 12px',
    '&.Mui-expanded': {
      minHeight: 48
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  accordionDetailsRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 16px 0px 42px',
    [theme.breakpoints.only('md')]: {
      maxHeight: '15vh',
    },
  },
})


const Container = styled.nav`
  flex: 1;
  display: flex;
  position: fixed;
  z-index: 1;
  min-height: 100vh;
  max-height: 50vh;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0 0.5em 1em -0.125em rgba(20, 73, 116, 0.3);
  transition: width 0.2s;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #e6eaf4;  
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #bdbdbd;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`

const UnAuthContainer = styled.nav`
  width: 100%;
  display: flex;
  z-index: 30;
  justify-content: space-between;
  min-height: 70px;
  background-color: #f1f0f2;
  padding: .5rem 1rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(20 73 116 / 30%);
`

const NavbarBrand = styled.div`
  padding: 24px 38px;
  border-bottom: 1px #b7b7b7 solid;
  position: relative;
`

const User = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  border-bottom: 1px #b7b7b7 solid;
`

const UserInfo = styled.div`
  display: flex; 
  flex-direction: column; 
  padding: 5px; 
  margin-left: 5px;
  align-items: flex-start;
  flex-grow: 1; 
`

const Title = styled.strong`
  font-size: 12px; 
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.38);
  font-family: Montserrat;
`

const Email = styled.strong`
  font-weight: 100; 
  font-size: 14px;
  font-family: Montserrat;
  color: #0B79D0;
`

const RouteName = styled.strong`
  margin-left: 12px;
  font-family: Montserrat;
  font-size: 16px;
align-items: center;
letter-spacing: 0.15px;
line-height: 175%;
`

const Actions = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 24px -4px #ececec;
  padding: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
const CollapseButton = styled.div`
  position: absolute;
  top: 45px;
  right: -13px;
  background-color: ${props => props.expanded ? "#FFFFFF" : "transparent"};
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  font-family: Montserrat;
  color: ${props => props.expanded ? "#ffffff" : "#004976"};
  font-weight: bold;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;
  transition: opacity 0.4s;
  cursor: pointer;
  &:hover{
    opacity: 0.7
  }
  padding: 3px;
`

const Spacer = styled.div`
  transition: width 0.2s;
`

const UserMenuButton = styled.div`
  font-family: Montserrat;
  color: #144974;
  font-weight: bold;
  text-align: left;
  transition: opacity 0.4s;
  cursor: pointer;
  &:hover{
    opacity: 0.7
  }
`

const SitesDropdown = styled.div`
  position: absolute;
  z-index: 3;
  top: auto;
  bottom: 100%;
  right: 0px;
  width: calc(100%);
  background-color: white;
  border: 1px solid #F6F6F6;
  border: 1px solid rgb(226 226 226);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  &.collapsed {
    width: 300px;
    left: 50px;
    top: auto;
    bottom: 0;
  }
`

const Curtain = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100vw; 
  height: 100vh; 
  z-index: 2;
`

const mapStateToProps = ({ session, sites }) => {
  return { session, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout, selectSite }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(useStyles)(Navbar)));
