import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { Label, Input, Row, Link, Button } from '../StyledComponents'
import { trackUserIdforPinpoint } from '../../../utils/cognito'
import { loadSites } from '../../../redux/actions/sites'
import Validate from '../utility/FormValidation'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import FormErrors from '../FormErrors'

const PasswordValidation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`

const PVItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const PVDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #ddd;
  transition: background 0.2s;
`

const PVText = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  color: rgb(136, 145, 166);
`

class ChangePassword extends Component {
  state = {
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    },
    passwordValidationStatus: {},
    passwordMasked: true
  }

  componentDidMount () {
    const query = new URLSearchParams(window.location.search)
    const oldpassword = query.get('password')
    if (oldpassword) {
      this.setState({ oldpassword })
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    })
  }

  notAuthUser = async () => {
    try {
      const query = new URLSearchParams(window.location.search)
      const email = query.get('user')
      const oldpassword = query.get('password')
      if (email && oldpassword) {
        const tempUser = await Auth.signIn(email, oldpassword)
        if (tempUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(tempUser, this.state.newpassword)
          this.redirect()
        } else {
          await Auth.changePassword(
            tempUser,
            this.state.oldpassword,
            this.state.newpassword
          )
          this.redirect()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  redirect = async () => {
    await this.props.loadSites()
    await trackUserIdforPinpoint()
    this.props.history.push('changepasswordconfirmation')
  }

  authUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        await Auth.changePassword(
          user,
          this.state.oldpassword,
          this.state.newpassword
        )
      }
      this.redirect()
      return true
    } catch (error) {
      console.log(error)
    }
    return false
  }

  handleSubmit = async event => {
    event.preventDefault()
    this.clearErrorState()
    const error = Validate(event, this.state)
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      })
    }
    try {
      const response = await this.authUser(  )
      if (!response) {
        await this.notAuthUser()
      }
    } catch (error) {
      console.log(error)
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
    document.getElementById(event.target.id).classList.remove('is-danger')
  }

  validatePassword = event => {
    let value = event.target.value
    this.setState({
      passwordValidationStatus: {
        uppercase: value.match('(?=.*[A-Z])') != null ? true : false,
        lowercase: value.match('(?=.*[a-z])') != null ? true : false,
        special:
          value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) != null
            ? true
            : false,
        number: value.match('([0-9])') != null ? true : false,
        min: value.length >= 8 ? true : false
      }
    })
  }

  submitEnabled = () => {
    let { oldpassword, newpassword, confirmpassword } = this.state
    let {
      lowercase,
      uppercase,
      special,
      number,
      min
    } = this.state.passwordValidationStatus
    return (
      oldpassword !== '' &&
      newpassword !== '' &&
      confirmpassword !== '' &&
      (newpassword === confirmpassword) &&
      lowercase &&
      uppercase &&
      special &&
      number &&
      min
    )
  }

  render () {
    return (
      <>
        <Navbar />
        <section style={styles.container} className='auth'>
          <Box className='content'>
            <h1 style={styles.title}>Cambiar Contraseña</h1>
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
            <FormErrors formerrors={this.state.errors} />

            <form style={{ marginTop: 32 }} onSubmit={this.handleSubmit}>
              <div style={styles.field} className='field'>
                <Label style={styles.fieldLabel}>Contraseña Actual</Label>
                <Input
                  type='password'
                  id='oldpassword'
                  value={this.state.oldpassword}
                  onChange={this.onInputChange}
                  style={styles.input}
                  aria-describedby='oldpasswordHelp'
                />
                <i style={styles.icon} className='fas fa-lock'></i>
              </div>

              <div
                style={{ ...styles.field, position: 'relative' }}
                className='field'
              >
                <Label style={styles.fieldLabel}>Nueva Contraseña</Label>
                <Input
                  type='password'
                  id='newpassword'
                  value={this.state.newpassword}
                  onChange={e => {
                    this.onInputChange(e)
                    this.validatePassword(e)
                  }}
                  style={styles.input}
                  aria-describedby='newpasswordHelp'
                />
                <i style={styles.icon} className='fas fa-lock'></i>
              </div>

              <PasswordValidation className='field'>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.lowercase
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un carácter en minúscula</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.uppercase
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un carácter en mayúscula</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.special
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un carácter especial</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.number
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>Un número</PVText>
                </PVItem>
                <PVItem>
                  <PVDot
                    style={
                      this.state.passwordValidationStatus.min
                        ? { background: '#09C269' }
                        : null
                    }
                  />
                  <PVText>8 caracteres como mínimo</PVText>
                </PVItem>
              </PasswordValidation>

              <div style={styles.field} className='field'>
                <Label style={styles.fieldLabel}>
                  Vuelva a escribir la nueva contraseña
                </Label>
                <Input
                  type='password'
                  id='confirmpassword'
                  style={styles.input}
                  onChange={this.onInputChange}
                  value={this.state.confirmpassword}
                  aria-describedby='confirmpasswordHelp'
                />
                <i style={styles.icon} className='fas fa-lock'></i>
              </div>

              <Row justify='center'>
                <Label style={{ fontSize: 14, fontWeight: '500' }}>
                  <Link
                    style={{ fontSize: 14, fontWeight: '600' }}
                    className='link link-bold'
                    href='/forgotpassword'
                  >
                    ¿Se te olvidó tu contraseña?
                  </Link>
                </Label>
              </Row>

              <div style={{ marginTop: 12 }} className='field'>
                <p className='control'>
                  <Button
                    disabled={!this.submitEnabled()}
                    onClick={this.handleSubmit}
                    style={{ fontSize: 16, padding: 10 }}
                    backgroundColor='#1a9e75'
                    color='#fff'
                  >
                    Cambiar Contraseña
                  </Button>
                </p>
              </div>
            </form>
          </Box>
        </section>
        <Footer />
      </>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    padding: 30,
    minHeight: 'calc(100vh - 206.99px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f8fa'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 30,
    marginBottom: 28
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 600,
    color: '#959ca8',
    lineHeight: '28px',
    marginBottom: 20
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: 25
  },
  fieldLabel: {
    fontSize: 16,
    lineHeight: '16px'
  },
  icon: {
    position: 'absolute',
    top: 34,
    left: 14
  },
  input: {
    paddingLeft: 42
  }
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
`

export const Box = styled.div`
  padding: 50px 65px;
  width: 570px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`

const mapStateToProps = ({ session }) => {
  return { session }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ loadSites }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
