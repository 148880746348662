import {
    FETCHING_VIDEOS,
    FAIL_FETCH_VIDEOS,
    SUCCESS_FETCH_VIDEOS,
    FETCHING_VIDEOS_INFINITE,
    FAIL_FETCH_VIDEOS_INFINITE,
    SUCCESS_FETCH_VIDEOS_INFINITE,
    FETCHING_ALL_VIDEOS,
    FAIL_FETCH_ALL_VIDEOS,
    SUCCESS_FETCH_ALL_VIDEOS,
    FETCHING_VIDEO,
    FAIL_FETCH_VIDEO,
    SUCCESS_FETCH_VIDEO,
    DELETE_VIDEO,
    DELETE_FAIL_VIDEO,
    DELETE_SUCCESS_VIDEO,
  } from '../actions/videos'
  
  const initialState = {
    all: [],
    data: [],
    video: {},
    template: null,
    error: null,
    loading: false,
    hasMore: true,
    order: {},
    count: 0,
    page: 0,
    limit: 25,
    offset: 0
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCHING_VIDEOS:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_VIDEOS:
        return {
          ...state,
          ...action.data,
          loading: false,
        };
      case FAIL_FETCH_VIDEOS:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.error,
        };
      case FETCHING_VIDEOS_INFINITE:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_VIDEOS_INFINITE:
        return {
          ...state,
          ...action.data,
          loading: false,
          data: action.reset ? action.data.data : [...state.data, ...action.data.data],
        };
      case FAIL_FETCH_VIDEOS_INFINITE:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.error,
        };
      case FETCHING_ALL_VIDEOS:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_ALL_VIDEOS:
        return {
          ...state,
          loading: false,
          all: action.data,
        };
      case FAIL_FETCH_ALL_VIDEOS:
        return {
          ...state,
          all: [],
          loading: false,
          error: action.error,
        };
      case FETCHING_VIDEO:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_VIDEO:
        return {
          ...state,
          loading: false,
          video: action.data,
        };
      case FAIL_FETCH_VIDEO:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
        case DELETE_VIDEO:
        return {
          ...state,
          loading: true,
        };
      case DELETE_FAIL_VIDEO:
        return {
          ...state,
          loading: false,
        };
      case DELETE_SUCCESS_VIDEO:
        return {
          ...state,
          loading: false,
        };
      default:
        return state
    }
  };
  