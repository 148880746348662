import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_VIDEOS = 'FETCHING_VIDEOS';
export const FAIL_FETCH_VIDEOS = 'FAIL_FETCH_VIDEOS';
export const SUCCESS_FETCH_VIDEOS = 'SUCCESS_FETCH_VIDEOS';

export const FETCHING_VIDEOS_INFINITE = 'FETCHING_VIDEOS_INFINITE';
export const FAIL_FETCH_VIDEOS_INFINITE = 'FAIL_FETCH_VIDEOS_INFINITE';
export const SUCCESS_FETCH_VIDEOS_INFINITE = 'SUCCESS_FETCH_VIDEOS_INFINITE';

export const FETCHING_ALL_VIDEOS = 'FETCHING_ALL_VIDEOS';
export const FAIL_FETCH_ALL_VIDEOS = 'FAIL_FETCH_ALL_VIDEOS';
export const SUCCESS_FETCH_ALL_VIDEOS = 'SUCCESS_FETCH_ALL_VIDEOS';

export const FETCHING_VIDEO = 'FETCHING_VIDEO';
export const FAIL_FETCH_VIDEO = 'FAIL_FETCH_VIDEO';
export const SUCCESS_FETCH_VIDEO = 'SUCCESS_FETCH_VIDEO';

export const POST_VIDEO = 'POST_VIDEO';
export const POST_FAIL_VIDEO = 'POST_FAIL_VIDEO';
export const POST_SUCCESS_VIDEO = 'POST_SUCCESS_VIDEO';

export const PUT_VIDEO = 'PUT_VIDEO';
export const PUT_FAIL_VIDEO = 'PUT_FAIL_VIDEO';
export const PUT_SUCCESS_VIDEO = 'PUT_SUCCESS_VIDEO';

export const DELETE_VIDEO = 'DELETE_VIDEO';
export const DELETE_FAIL_VIDEO = 'DELETE_FAIL_VIDEO';
export const DELETE_SUCCESS_VIDEO = 'DELETE_SUCCESS_VIDEO';

export const fetchVideos = (siteId, limit, offset, column, direction, search, searchtext = '', tags) => {
  return async dispatch => {
    dispatch({ type: FETCHING_VIDEOS });
    try {
      let url = parseUrl(`site/${siteId}/videos?`, limit, offset, column, direction, search, searchtext, tags)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_VIDEOS,
        });
      } else { 
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_VIDEOS,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_VIDEOS,
      });
    }
  };
};

export const fetchVideo = (siteId, id) => {
  return async dispatch => {
    dispatch({ type: FETCHING_VIDEO });
    try {
      const response = await network.get(`site/${siteId}/video/${id}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_VIDEO,
        });
      } else { 
        dispatch({
          data: response.data,
          type: SUCCESS_FETCH_VIDEO,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_VIDEO,
      });
    }
  };
};

export const fetchVideosInfinite = (siteId, limit, offset, reset, search, searchtext, tags) => {
  return async dispatch => {
    dispatch({ type: FETCHING_VIDEOS_INFINITE });
    try {
      const url = parseUrl(`site/${siteId}/videos?`, limit, offset, null, null, search, searchtext, tags)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_VIDEOS_INFINITE,
        });
      } else { 
        let hasMore = false
        let page = offset + 1 
        if (reset) {
          page = 1
        }
        if (response.data.data.length >= limit){
          hasMore = true
        }
        dispatch({
          reset,
          data: {
            ...response.data,
            hasMore,
            page,
          },
          type: SUCCESS_FETCH_VIDEOS_INFINITE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_VIDEOS_INFINITE,
      });
    }
  };
};

export const putVideo = (siteId, id, body) => {
  return async dispatch => {
    dispatch({ type: POST_VIDEO });
    try {
      const response = await network.put(`site/${siteId}/videos/${id}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_VIDEO,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_VIDEO,
      });
    }
  };
};

export const deleteVideo = (siteId, ids = []) => {
  return async dispatch => {
    dispatch({ type: DELETE_VIDEO });
    try {
      const promises = ids.map(async id => {
        await network.del(`site/${siteId}/videos/${id}`);
      });
      const response = await Promise.all(promises)
      dispatch({
        type: DELETE_SUCCESS_VIDEO,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_VIDEO,
      });
      return error;
    }
  };
};