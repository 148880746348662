import React from 'react'
import { Button } from '@tinacms/styles'
import { useHistory } from 'react-router-dom';

function BackButton() {
    const history = useHistory();
    return <Button onClick={() => history.goBack()}>Regresar</Button>
}
  
export const BackButtonWidget = {
    __type: 'toolbar:widget',
    name: 'howdy',
    weight: 5,
    component: BackButton,
}