import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import {
  Edit,
  Delete,
  DragIndicator,
} from '@material-ui/icons'
import {
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core'
import { fetchForm, putForm } from '../../../redux/actions/forms'
import { makeToast } from '../../../utils'
import AddField from './AddField';
import { Alert } from '../Alert'

const useStyles = makeStyles({
  accordionRoot: {
    boxShadow: 'none',
    borderRadius: 6,
    transition: 'background-color 0.4s',
    backgroundColor: '#f6f6f6',
    '&.Mui-expanded': {
      backgroundColor: '#e5f7fd'
    },
    '&:before': {
      visibility: 'hidden'
    }
  },
  accordionSummaryRoot: {
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
      '&.Mui-expanded': {
        transform: 'rotate(0deg)'
      },
      '& span svg': {
        fill: '#024976'
      }
    },
    '&:hover .MuiAccordionSummary-content div div:last-child': {
      visibility: 'visible !important'
    },
    '& .MuiAccordionSummary-content div div:last-child': {
      visibility: 'hidden'
    }
  },
  typographySubtitle1Root: {
    color: '#024976',
    margin: '0px !important',
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontWeight: 200,
    paddingLeft: 10
  },
  accordionActionsRoot: {
    padding: '0px 50px 20px'
  },
  accordionDetailsRoot: {
    paddingLeft: 50,
    paddingRight: 12
  },
  accordionDetailsRootAlt: {
    padding: 16
  }
})

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
    columnGap: 8
  },
  actionsBtn: {
    padding: 1,
    borderRadius: 5,
    backgroundColor: '#00ACE6'
  },
  itemContent: {
    padding: 10,
    width: '100%',
    display: 'flex',
    borderRadius: 5,
    alignItems: 'flex-start',
    backgroundColor: '#F6F6F6',
    justifyContent: 'space-between',
  },
  itemAction: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    borderRadius: 5,
    backgroundColor: '#00ACE6'
  },
  iconAction: {
    color: '#fff',
    fontSize: 16
  }
}

export const FieldItem = ({
  id,
  name,
  type,
  title,
  forms,
  sites,
  putForm,
}) => {
  const classes = useStyles()
  const { form } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [modalEdit, setModalEdit] = useState(false)
  const [editByName, setEditByName] = useState(null)

  const onDelete = name => {
    Alert({
      onSuccess: async () => {
        const index = forms.form.fields.findIndex(item => item.name === name)
        if (index !== -1) {
          forms.form.fields.splice(index, 1)
          const body = {
            ...forms.form,
            fields: [...forms.form.fields]
          }
          await putForm(sites.defaultSite.id, form, body)
          makeToast(enqueueSnackbar, 'El campo se guardo correctamente')
        }
      },
      onFail: () => {
        console.log('cancel')
      },
    });
  }

  const onEdit = async (name) => {
    await setEditByName(name)
    await setModalEdit(true)
  }

  const onClose = async () => {
    await setModalEdit(false)
    await setEditByName(null)
  }

  return (
    <>
      <div className='content-item-content' style={styles.itemContent}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex'}}>
          <DragIndicator style={{ fill: '#024976', cursor: 'pointer' }} />
          <Typography
            classes={{ root: classes.typographySubtitle1Root }}
            variant='subtitle1'
          >
            {title} - {type}
          </Typography>
          </div>
          <div className="content-item-actions" style={styles.actions}>
            <Tooltip title="Editar" aria-label="Edit">
              <div className="actions-edit" style={styles.itemAction} onClick={(ev) => {
                ev.stopPropagation();
                onEdit(name);
              }}>
                <Edit style={styles.iconAction} />
              </div>
            </Tooltip>
            {
              name !== 'email' && (
                <Tooltip title="Eliminar" aria-label="Delete">
                  <div className="actions-delete"  style={styles.itemAction} onClick={(ev) => {
                    ev.stopPropagation();
                    onDelete(name);
                  }}>
                    <Delete style={styles.iconAction} />
                  </div>
                </Tooltip>
              )
            }
          </div>
        </div>
      </div>
      {
        modalEdit &&
        <AddField
          formId={form}
          show={modalEdit}
          onClose={onClose}
          editByName={editByName}
        />
      }
    </>
  )
}

const mapStateToProps = ({ sites, forms }) => {
  return { sites, forms }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({fetchForm, putForm}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldItem)
