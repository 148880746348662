import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowDropDown, Visibility } from '@material-ui/icons';
// import { PlayCircleFilled, Delete } from '@material-ui/icons';
import { FormControl, FormGroup, Checkbox, RadioGroup, Radio, FormControlLabel, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { fetchWebinars, putWebinar, deleteWebinar } from '../../../redux/actions/webinars';
import { fetchAllEmailTemplates } from '../../../redux/actions/emailTemplates';
import { createCourse } from '../../../helpers/courses';
import ImageUploader from './components/ImageUploader';
import { uploadFile } from '../../../helpers/files';
import { filterChanges } from '../../../utils';
import { Select } from '../StyledComponents';
import { Alert } from '../Alert';

function WebinarForm(props) {
  const history = useHistory();

  const { event } = useParams();
  const { sites, webinars } = props;
  const classes = useStyles();

  const [data, setData] = useState({});
  const [price, setPrice] = useState(0);
  const [emails, setEmails] = useState([]);
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState(-1);
  const [currency, setCurrency] = useState('mxn');
  const [published, setPublished] = useState(false);
  const [background, setBackground] = useState(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const [showOnHome, setShowOnHome] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [originalPrice, setOriginalPrice] = useState('');

  useEffect(() => {
    preload()
  }, []);

  const preload = async () => {
    try {
      if (event) {
        const emails = await props.fetchAllEmailTemplates(sites.defaultSite.id)
        await setEmails(emails)
        const data = webinars.webinar.data;
        await setData(data);
        await setTitle(data.title);
        await setSubject(data.subject);
        await setTemplateId(data.templateId);
        await setPublished(data.published ? data.published : false)
        await setShowOnHome(data.show_on_home ? data.show_on_home : false)
        await setOriginalPrice(data.originalPrice ? centsToDecimal(data.originalPrice) : '')
        await setHasDiscount(data.originalPrice ? true : false)
        if (data.price) {
          await setPrice(centsToDecimal(data.price.unit_amount))
          await setCurrency(data.price.currency)
        }
        if (data.background) {
          await setBackground(data.background);
        }
      }
    } catch (error) {
      console.log(error);
      makeToast(enqueueSnackbar, 'Error precargando', 'error')
    }
  }

  useEffect(() => {
    if (Object.keys(data).length === 0) return setEnableEdit(false);
    if (title !== data.title) return setEnableEdit(true);
    if (templateId !== data.templateId) return setEnableEdit(true);
    if (subject !== data.subject) return setEnableEdit(true);
    if (background !== data.background) return setEnableEdit(true);
    if (published !== data.published) return setEnableEdit(true);
    if (showOnHome !== data.showOnHome) return setEnableEdit(true);
    if (originalPrice !== data.originalPrice) return setEnableEdit(true);
    setEnableEdit(false);
  }, [files, title, background, published, templateId, showOnHome, originalPrice]);

  const validate = () => {
    if (title.trim() === '') return { success: false, error: 'Título requerido' };
    if (hasDiscount && (decimalToCents(originalPrice) <= decimalToCents(price))) return { success: false, error: 'El precio original debe ser mayor al precio con descuento' };
    return { success: true };
  }

  const handleSubmit = async (ev, body = {}) => {
    ev && ev.preventDefault();
    const isValid = validate();
    if (isValid.success) {
      await setLoading(true);
      try {
        const siteId = sites.defaultSite.id;
        const updateCourse = {
          title,
          subject,
          templateId,
          background,
          published: published,
          show_on_home: showOnHome,
          originalPrice: (!hasDiscount || !isValidPrice(originalPrice)) ? null : decimalToCents(originalPrice),
          price: {
            unit_amount: decimalToCents(price),
            currency: currency
          },
          ...body,
        };
        if (event) {
          const newBody = filterChanges(updateCourse, {...data, price: ''});
          await props.putWebinar(siteId, event, newBody);
          makeToast(enqueueSnackbar, "El evento ha sido actualizado");
        } else {
          await createCourse(siteId, updateCourse);
          makeToast(enqueueSnackbar, "El evento ha sido creado");
        }
      } catch (error) {
        console.log('handleSubmit', error);
        makeToast(enqueueSnackbar, error, 'error')
      }
      setLoading(false);
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning');
    }
  }

  const setImage = async (acceptedFiles) => {
    makeToast(enqueueSnackbar, "Cargando contenido", 'info');
    await setLoading(true);
    const siteId = sites.defaultSite.id;
    const url = await uploadFile(siteId, acceptedFiles[0], {courseId: event}, true, true);
    makeToast(enqueueSnackbar, "Contenido cargado");
    await setBackground(url);
    await setFiles([]);
    await handleSubmit(null, {background: url});
  }

  const isValidPrice = (p) => {
    return !(p === '' || p === 0 || p === null)
  }

  // 100.00 -> 10000
  const decimalToCents = (p) => {
    return parseInt(p * 100)
  }

  // 10000 -> 100.00
  const centsToDecimal = (p) => {
    return parseFloat(p / 100).toFixed(2)
  }

  // 1300.111 -> 1300.11
  const formatPrice = (p) => {
    return parseFloat(parseFloat(p).toFixed(2))
  }

  const onPreview = async (ev) => {
    window.open(`/tina/${sites.defaultSite.id}/event-detail/${event}?preview=true`, '_blank');
  }

  const onEdit = async (ev) => {
    window.open(`/tina/${sites.defaultSite.id}/event-detail/${event}`, '_blank');
  }

  const onDelete = async (ev) => {
    ev.preventDefault()
    Alert({
      onSuccess: async () => {
        await props.deleteWebinar(sites.defaultSite.id, [event])
        history.replace(`/content/events`);
      },
      onFail: () => console.log('cancel'),
    });
  }

  return (
    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
      <div style={{...styles.panelTitle, marginTop: 0}}>Imágen del evento</div>
      <ImageUploader
        preview={true}
        onChange={setImage}
        previousImage={background}
        placeholder="Selecciona o arrastra"
        dropContainerStyle={{width: 270, height: 130}}
      />
      <FormGroup style={{ marginBottom: 10 }}>
        <div style={styles.field} className="field">

          <TextField 
            type="text"
            id="titulo"
            value={title}
            disabled={loading}
            label="Título"
            aria-describedby="titleHelp"
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            classes={{root:classes.textFieldRoot}}
          />

        </div>
      </FormGroup>


      <div style={{ display: 'flex', columnGap: 6, marginBottom: 20 }}>
        <button
          onClick={onPreview}
          disabled={loading}
          style={{ ...styles.actionButton, marginBottom: 0, fontSize: 13, fontWeight: '500', padding: '16px 16px' }}
          className="button is-tertiary"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : <><Visibility style={{ fill: 'white', marginRight: 8 }} />Visualizar</>}
        </button>
        <button
          onClick={onEdit}
          disabled={loading}
          style={{ ...styles.actionButton, marginBottom: 0, fontSize: 13, fontWeight: '500', padding: '16px 16px' }}
          className="button is-light"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : 'Editar'}
        </button>
        <button
          onClick={onDelete}
          disabled={loading}
          style={{ ...styles.actionButton, marginBottom: 0, fontSize: 13, fontWeight: '500', padding: '16px 16px' }}
          className="button is-red"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : 'Eliminar'}
        </button>
      </div>

      <div style={{ ...styles.separator, width: 'calc(100% + 60px)', marginLeft: -30 }} />

      <FormGroup>
        <div style={styles.panelTitle}>Estado</div>
        <RadioGroup 
          classes={{ root: classes.radioGroupRoot }} 
          onChange={(ev) => setPublished(ev.target.value === 'true' ? true : false )} 
          value={published.toString()}
          name="status"
        >

          <FormControlLabel
            classes={{ root: classes.formControlLabelRoot }}
            value={'true'}
            control={
              <Radio classes={{ root: classes.radioRoot }} />
            }
            label="Público"
          />

          <FormControlLabel
            classes={{ root: classes.formControlLabelRoot }}
            value={'false'}
            control={
              <Radio classes={{ root: classes.radioRoot }} />
            }
            label="Borrador"
          />

        </RadioGroup>
      </FormGroup>

      <FormGroup style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 26}}>
        <div style={styles.panelTitle}>Mostrar en homepage</div>
        <FormControlLabel
          classes={{ root: classes.formControlLabelRootCheckbox }}
          control={
            <Checkbox
              color="primary"
              checked={showOnHome}
              onChange={(e) => setShowOnHome(e.target.checked)}
            />
          }
        />
      </FormGroup>

      <div style={{ ...styles.separator, width: 'calc(100% + 60px)', marginLeft: -30 }} />

      <FormGroup>

        <div style={{ marginTop: 0, marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={styles.panelTitle}>Email subject</div>
        </div>

        <div style={styles.field} className="field">

          <TextField 
            type="text"
            id="subject"
            value={subject}
            disabled={loading}
            label="Subject"
            variant="outlined"
            aria-describedby="titleHelp"
            classes={{root:classes.textFieldRoot}}
            onChange={(e) => setSubject(e.target.value)}
          />

        </div>
      </FormGroup>

      <FormGroup style={{ marginBottom: 30 }}>

        <div style={{ marginTop: 0, marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={styles.panelTitle}>Email template</div>
        </div>

        <div style={{ position: 'relative' }}>
          <Select
            id="templateId"
            value={templateId}
            disabled={loading}
            style={{ ...styles.input, backgroundColor: 'white', border: 'rgb(232, 232, 232) 1px solid' }}
            onChange={(ev) => setTemplateId(ev.target.value)}
          >
            <option value={-1}>
              Selecciona un email
            </option>
            {
              emails.map(item => (
                <option value={item.id}>
                  {item.name}
                </option>
              ))
            }
          </Select>
          <ArrowDropDown style={{ fill: '#144974', position: 'absolute', top: 16, right: 16 }} />
        </div>
      </FormGroup>

      <div style={{ ...styles.separator, width: 'calc(100% + 60px)', marginLeft: -30 }} />

      <div style={{ ...styles.separator, width: 'calc(100% + 60px)', marginLeft: -30 }} />

      <FormGroup style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
        <div style={styles.panelTitle}>Precio con descuento</div>
        <FormControlLabel
          classes={{ root: classes.formControlLabelRootCheckbox }}
          control={
            <Checkbox
              color="primary"
              checked={hasDiscount}
              onChange={(e) => setHasDiscount(e.target.checked)}
            />
          }
        />
      </FormGroup>
      
      {
        hasDiscount && (
          <FormGroup style={{ marginBottom: 10, marginTop: 15 }}>
            <div style={{...styles.field, marginBottom: 0}} className="field">
              <TextField
                type="number"
                id="originalPrice"
                value={originalPrice}
                disabled={loading || !hasDiscount}
                label="Precio original"
                aria-describedby="originalPriceHelp"
                onChange={(e) => {
                  if(e.target.value){
                    setOriginalPrice(formatPrice(e.target.value))
                  }else{
                    setOriginalPrice(e.target.value)
                  }
                }}
                variant="outlined"
                classes={{root:classes.textFieldRoot}}
              />
            </div>
          </FormGroup>
        )
      }

      <FormGroup style={{ marginBottom: 10, marginTop: 15 }}>
        <div style={{...styles.field, marginBottom: 0}} className="field">
          <TextField
            type="number"
            id="price"
            value={price}
            disabled={loading}
            label="Precio"
            aria-describedby="priceHelp"
            onChange={(e) => {
              if(e.target.value){
                setPrice(formatPrice(e.target.value))
              }else{
                setPrice(e.target.value)
              }
            }}
            variant="outlined"
            classes={{root:classes.textFieldRoot}}
          />
        </div>
      </FormGroup>

      <FormGroup style={{ marginBottom: 30 }}>

        <div style={{ marginTop: 0, marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={styles.panelTitle}>Moneda</div>
        </div>

        <div style={{ position: 'relative' }}>
          <Select
            id="currencyId"
            value={currency}
            disabled={loading}
            style={{ ...styles.input, backgroundColor: 'white', border: 'rgb(232, 232, 232) 1px solid' }}
            onChange={(ev) => setCurrency(ev.target.value)}
          >
            <option value={'mxn'}>
              MXN
            </option>
            <option value={'usd'}>
              USD
            </option>
          </Select>
          <ArrowDropDown style={{ fill: '#144974', position: 'absolute', top: 16, right: 16 }} />
        </div>
      </FormGroup>

      <button
        disabled={webinars.webinar.loading || !enableEdit}
        onClick={handleSubmit}
        className="button is-light"
        style={styles.submitButton}
      >
        {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : 'Guardar'}
      </button>

    </FormControl>
  );
}

const useStyles = makeStyles({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  textAreaRoot: {
    minHeight: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  formLabelRoot: {
    fontSize: 16,
    color: '#024976',
    fontFamily: 'Montserrat',
    fontWeight: '900'
  },
  formControlLabelRoot: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: '#9e9e9e'
    },
    '& .Mui-checked + span.MuiTypography-root': {
      color: '#00ace7'
    }
  },
  formControlLabelRootCheckbox: {
    margin: 0,
    '& .Mui-checked .MuiIconButton-label': {
      color: '#00ace7 !important'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '500',
      color: 'rgb(2, 73, 118)'
    }
  },
  radioGroupRoot: {
    flexDirection: 'row',
    columnGap: 28
  },
  radioRoot: {
    '&:not(.Mui-checked) .MuiIconButton-label': {
      color: 'gainsboro'
    },
    '&.Mui-checked .MuiIconButton-label': {
      color: 'transparent'
    },
    '&.Mui-checked .MuiIconButton-label div svg:first-child': {
      backgroundColor: '#00ace7',
      borderRadius: '50%'
    },
    '&.Mui-checked .MuiIconButton-label div svg:last-child': {
      fill: 'white'
    }
  },
});

const styles = {
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
  submitButton: {
    fontSize: 16,
    padding: '26px 0px',
    borderRadius: 8,
    width: '100%'
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)',
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginTop: 25,
    marginBottom: 5,
  },
}

const mapStateToProps = ({ sites, webinars }) => {
  return { sites, webinars };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchWebinars, putWebinar, deleteWebinar, fetchAllEmailTemplates }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WebinarForm);