import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { AddCircle,  MoreVert } from '@material-ui/icons';
import { Menu, MenuItem, IconButton, CircularProgress, makeStyles } from '@material-ui/core';
import { fetchVideos, fetchVideosInfinite, deleteVideo } from '../../../redux/actions/videos';
import VideoCard from '../courses/components/Video';
import { unlock } from '../../../helpers/files';
import FullDataTable from '../CoursesMain/FullDataTable';
import { getTags } from '../../../helpers/VideoTags';
import { makeToast } from '../../../utils'
import { Alert } from '../Alert';
import Create from "./Create";
import View from "./View";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.5) !important',
    }
  }
}));

export const MenuList = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton style={styles.actions} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        className={classes.root}
      >
        <MenuItem onClick={async (ev) => {
          await props.onEdit()
          handleClose(ev)
        }}>Editar</MenuItem>
        <MenuItem onClick={async (ev) => {
          await props.onDownload()
          handleClose(ev)
        }}>{props.loadingDownload && <CircularProgress size={10} style={{color: '#00A8D8', marginRight: 10 }} />} Descargar</MenuItem>
        <MenuItem
          style={{ color: 'red' }}
          onClick={async (ev) => {
            await props.onDelete()
            handleClose(ev)
          }}>Eliminar</MenuItem>
      </Menu>
    </>

  )
}

function UsersList(props) {

  const { sites } = props;
  const { videos: { data, loading, limit, offset, count, sort, hasMore, page } } = props;

  const { enqueueSnackbar } = useSnackbar()
  const [tags, setTags] = useState([]);
  const [edit, setEdit] = useState(null)
  const [viewModal, setViewModal] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [createModalVisible, setCreateModalVisible] = useState(false)

  useEffect(() => {
    loadTags()
  }, [])

  const loadTags = async () => {
    const data = await getTags(sites.defaultSite.id)
    setTags(data)
  }

  const customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    if (searchText && searchText !== '') {
      const remove = ['urls']
      const names = columns.map(item => (item.name)).filter(item => !remove.includes(item))
      props.fetchVideos(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
    } else {
      props.fetchVideos(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
    }
  }

  const onEdit = (id) => {
    setCreateModalVisible(true)
    setEdit(id)
    // handleClose()
  }

  const onDelete = async (ids) => {
    Alert({
      title: '¿Estas seguro?',
      message: `Se eliminaran ${ids.length} ${ids.length > 1 ? 'elementos' : 'elemento'
        }`,
      onSuccess: async () => {
        await props.deleteVideo(sites.defaultSite.id, ids);
        makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
        props.fetchVideos(sites.defaultSite.id, limit, offset);
      },
      onFail: () => ({})
    })
    // handleClose()
  }

  const onCloseModal = () => {
    setCreateModalVisible(false)
    setEdit(null)
    // handleClose()
  }

  const onCloseModalView = () => {
    setViewModal(false)
    setEdit(null)
    // handleClose()
  }

  const onDownload = async (url) => {
    await setLoadingDownload(true)
    const response = await unlock(sites.defaultSite.id, url)
    window.open(response.url, '_blank')
    await setLoadingDownload(false)
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      }
    },
    {
      label: ' ',
      name: 'urls',
      nosearch: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && value.length) {
            return (
              <img src={value[0]} style={{ maxWidth: 100, height: 'auto' }} />
            );
          } else {
            return (
              <img src="/images/video.png" style={{ maxWidth: 100, height: 'auto' }} />
            )
          }
        },
      },
    },
    {
      label: 'Título',
      name: 'name',
    },
    {
      label: 'Tamaño',
      name: 'size',
      nosearch: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div>
                {formatBytes(value)}
              </div>
            )
          }
        }
      }
    },
    {
      label: 'Duración',
      name: 'duration',
      nosearch: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div>
                {msToTime(value)}
              </div>
            )
          }
        }
      }
    },
    {
      label: 'Estatus',
      name: 'media_status',
    },
    {
      label: 'Fecha de creación',
      name: 'createdAt',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div>
                {moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}
              </div>
            )
          }
        }
      }
    },
    {
      label: "Acciones",
      name: 'id',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <MenuList
                value={value}
                loadingDownload={loadingDownload}
                onEdit={async () => await onEdit(value)}
                onDelete={async () => await onDelete([value])}
                onDownload={async () => await onDownload(data[tableMeta.rowIndex].url)}
              />
            );
          }
        }
      }
    }
  ];

  return (
    <>
      <FullDataTable
        title="Videos"
        data={data}
        page={page}
        count={count}
        limit={limit}
        theme={theme}
        offset={offset}
        sortOrder={sort}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        site={sites.defaultSite.id}
        tags={tags}
        searchByTags={true}
        customRenderBlocks={(video, index) => (
          <VideoCard
            name={video.name}
            size={formatBytes(video.size)}
            duration={msToTime(video.duration)}
            createdAt={moment(new Date(video.createdAt)).format('MMMM D YYYY')}

            value={video.id}
            loadingDownload={loadingDownload}
            onEdit={async () => await onEdit(video.id)}
            onDelete={async () => await onDelete([video.id])}
            onDownload={async () => await onDownload(video.url)}

            sort={video.customSearch}
            search={video.customSearch}
            changePage={video.customSearch}
            changePageSize={video.customSearch}

            urls={video.urls || []}
            key={`Video-Card-${index}`}
            onChange={() => console.log(video, index)}
          />
        )}
        onRowPress={(data, meta, rowIndex) => {
          onEdit(data[0])
          setViewModal(true)
          setCreateModalVisible(false)
        }}
        onRowsDelete={async (ids, data) => {
          await props.deleteVideo(sites.defaultSite.id, ids);
          makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
          props.fetchVideos(sites.defaultSite.id, limit, offset);
        }}
        infiniteScrollLoad={async (size, page, reset, search, searchText, tags) => {
          if (!page) {
            reset = true
          }
          await props.fetchVideosInfinite(sites.defaultSite.id, size, page, reset, search, searchText, tags)
        }}
        preload={() => props.fetchVideos(sites.defaultSite.id, limit, offset)}
        sort={customSearch}
        search={customSearch}
        changePage={customSearch}
        changePageSize={customSearch}
        renderCreate={() => (
          <button
            onClick={() => setCreateModalVisible(true)}
            style={styles.actionButton}
            className='button is-light'
          >
            <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} />{' '}
            Subir video
          </button>
        )
        }
      />
      {
        createModalVisible && (
          <Create open={createModalVisible} edit={edit} onCloseModal={onCloseModal} />
        )
      }
      {
        viewModal && (
          <View open={viewModal} edit={edit} onCloseModal={onCloseModalView} onEdit={() => {
            setViewModal(false)
            setCreateModalVisible(true)
          }} />
        )
      }
    </>
  )
}

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 34,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  count: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20,
    border: '1px solid #F6F6F6',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  actionsModal: {
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {

      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600',
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ videos, sites }) => {
  return { videos, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchVideos, deleteVideo, fetchVideosInfinite }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersList));