import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { Close, AddBox } from '@material-ui/icons'
import { ArrowDropDown } from '@material-ui/icons'
import {
  FormControl,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  makeStyles,
  IconButton
} from '@material-ui/core'
import { fetchAllCoursesByEvent } from '../../../redux/actions/courses'
import { Label, Input } from '../StyledComponents'
import { makeToast, validateEmail } from '../../../utils'

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot: {
    padding: 2
  }
})

const AddSubscriberModal = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [loading, setLoading] = useState(false)

  const validate = () => {
    if (email.trim() === '') return { success: false, error: 'Email requerido' };
    if (!validateEmail(email)) return { success: false, error: 'Email valido requerido' };
    if (fullname.trim() === '') return { success: false, error: 'Nombre requerido' };
    return { success: true }
  }

  const submit = async () => {
    try {
      const isValid = validate();
      if (isValid.success) {
        await setLoading(true)
        await props.onSubmit({email, fullname})
      } else {
        makeToast(enqueueSnackbar, isValid.error, 'warning')
      }
    } catch (error) {
      console.log(error)
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      await setLoading(false)
    }
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.visible}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogContent style={{ paddingBottom: 18 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', columnGap: 12, alignItems: 'center' }}>
            <AddBox style={{ fill: '#024976' }} />
            <DialogTitle
              classes={{ root: classes.dialogTitleRoot }}
              id='form-dialog-title'
            >
              Crear contacto
            </DialogTitle>
          </div>
          <IconButton
            classes={{ root: classes.iconButtonRoot }}
            onClick={props.onClose}
          >
            <Close style={{ fill: '#333333' }} />
          </IconButton>
        </div>
      </DialogContent>
      <div style={{ height: 1.6, width: '100%', backgroundColor: '#e4e4e4' }} />
      <DialogContent style={{ paddingTop: 24 }}>
        <FormControl style={{ width: '100%' }} onSubmit={() => submit()}>
        <div style={styles.field} className="field">
            <Label style={styles.label}>Correo</Label>
            <Input
              id="email"
              type="email"
              value={email}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el correo"
              aria-describedby="emailHelp"
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Nombre completo</Label>
            <Input
              type="text"
              id="fullname"
              value={fullname}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el nombre completo"
              aria-describedby="fullnameHelp"
              onChange={(e)=>setFullname(e.target.value)}
            />
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 24px 24px'
        }}
      >
        <button
          onClick={props.onClose}
          disabled={loading}
          style={{
            fontSize: 16,
            padding: '28px 42px',
            borderRadius: 8,
            width: '48%'
          }}
          className='button is-secondary'
        >
          Cancelar
        </button>

        <button
          disabled={loading}
          onClick={() => submit()}
          style={{
            fontSize: 16,
            padding: '28px 42px',
            borderRadius: 8,
            width: '48%'
          }}
          className='button is-light'
        >
          {loading ? <CircularProgress size={18} style={{ color: '#00A8D8' }} /> : 'Crear'}
        </button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    textAlign: 'left', 
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
} 

const mapStateToProps = ({ sites }) => {
  return { sites }
}

export default connect(mapStateToProps)(AddSubscriberModal)
