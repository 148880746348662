import React from 'react'
import PropTypes from 'prop-types'
import { Delete } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import './Attachment.scss'

function Attachment (props) {
  return (
    <div className="attachment-container">
      <label className="attachment-item-label">
        {props.name}
      </label>
      <IconButton style={{ padding: 0 }} onClick={ev => props.onClick(ev)}>
        <Delete style={{ fill: 'rgb(235, 87, 87)' }} />
      </IconButton> 
    </div>
  )
}

Attachment.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Attachment
