import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import {Label, Row} from '../StyledComponents';
import { resendVerification } from '../../../helpers/users';
import styled from 'styled-components';

export default function Welcome(props) {

  const [uid, setUid] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setUid(props.location.state.uid)
  }, [])

  const resendVerificationEmail = async () => {
    let response = await resendVerification(uid)
    if(response){
      makeToast(enqueueSnackbar, 'Correo enviado exitosamente')
    }
  }

  return (
    <>
      <Navbar />
        <div style={styles.container} className="auth">
          <Box className="content">
            <img style={styles.image} src="/images/email.png"/>
            <h1 style={styles.title}>Te has registrado exitosamente</h1>
            <a style={styles.link} href="#">Para iniciar debes verificar tu cuenta</a>
            <p style={styles.subtitle}>
              Revisa tu bandeja de entrada de la cuenta de
              correo que registraste, ahi te enviaremos un
              mail para que puedas verificar tu cuenta.
            </p>
            <Row justify="center">
              <Label style={{fontSize: 14, fontWeight: '500'}}>No recibí el correo <span onClick={resendVerificationEmail} style={{fontSize: 14,fontWeight: '600', cursor: 'pointer'}} className="link link-bold">
                Enviar Nuevamente
                  </span>
              </Label>
            </Row>
          </Box>
        </div>
      <Footer />
    </>
  )
}

const styles = {
  container: {
    width: '100%',
    padding: 30,
    minHeight: 'calc(100vh - 206.99px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f8fa'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: 800,
    fontSize: 26,
    marginBottom: 15
  },
  link: {
    fontFamily: 'Montserrat',
    fontSize: 19,
    fontWeight: 800,
    color: 'rgb(129 131 134)',
    lineHeight: '28px',
    display: 'block',
    marginTop: 22,
    textDecoration: 'underline'
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 19,
    fontWeight: 600,
    color: '#959ca8',
    lineHeight: '32px',
    margin: '22px 0px',
    letterSpacing: '0.5px'
  },
  image: {
    borderRadius: '50%',
    width: 110,
    boxShadow: '2px 2px 25px 0px #ddd'
  }
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
`;

export const Box = styled.div`
  padding: 50px 65px;
  width: 570px;
  background-color: white;
  -webkit-box-shadow: 2px 2px 25px 10px #ddd;
  -moz-box-shadow: 2px 2px 25px 10px #ddd;
  box-shadow: 2px 2px 25px 10px #ddd;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`;
