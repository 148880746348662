import network from '../utils/network';

export const getDashboard = async (site, initial, final) => {
  try {
    const { data } = await network.get(`site/${site}/dashboard?initial=${initial}&final=${final}`);
    return data;
  } catch (error) {
    console.error('getDashboard', error);
    throw error;
  }
}

export const getChart = async (site, body) => {
  try {
    const { data } = await network.post(`site/${site}/analytics`, body);
    return data;
  } catch (error) {
    console.error('getChart', error);
    throw error;
  }
}
