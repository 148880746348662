import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { Close, FileCopy } from '@material-ui/icons'
import {
  FormControl,
  CircularProgress,
  Modal,
  makeStyles,
  IconButton
} from '@material-ui/core'
import { fetchDomains, postDomain } from '../../../redux/actions/domains'
import { Label, Input } from '../StyledComponents'
import { makeToast } from '../../../utils'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 10,
    top: '50%',
    left: '50%',
    width: '60%',
    borderRadius: 4,
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'absolute',
    boxShadow: theme.shadows[5],
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper
  },
  root: {
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff'
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#fff'
    }
  }
}))

function CourseCreate (props) {
  const { sites, edit } = props
  const { domains: { limit, offset, table } } = props;
  const classes = useStyles()

  const [name, setName] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [cnames, setCnames] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    preload()
  }, [])

  const preload = () => {
    if (edit) {
      const domain = table.find(item => item.id === edit)
      setCnames(domain)
    }
  }

  const validate = () => {
    if (name.trim() === '') return { success: false, error: 'Dominio requerido' }
    return { success: true }
  }

  const cleanInputs = () => {
    setName('')
  }

  const onCreate = async () => {
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true)
      try {
        const response = await props.postDomain(sites.defaultSite.id, name)
        setCnames(response)
        cleanInputs()
        props.fetchDomains(sites.defaultSite.id, limit, offset)
        // props.onCloseModal()
      } catch (error) {
        console.log('handleSubmit', error)
      } finally {
        await setLoading(false)
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const createAndNavigate = async () => {
    onCreate()
  }

  const onCopy = async (text) => {
    await navigator.clipboard.writeText(text)
    makeToast(enqueueSnackbar, `${text} copiado`, 'info')
  }

  return (
    <Modal open={props.open} onClose={props.onCloseModal}>
      <div className={classes.paper}>
        <div style={styles.container}>
          <div style={styles.header}>
            <div style={styles.name}>Asociar dominio</div>
            <IconButton onClick={() => props.onCloseModal()}>
              <Close style={styles.close} />
            </IconButton>
          </div>
          <div style={styles.content}>
            {
              cnames ? (
                <>
                  <div>Introduce estos datos en tu administrador DNS donde compraste tu dominio:</div>
                  <div style={{width: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <b>Tipo</b>
                      <b>Nombre</b>
                      <b>Valor</b>
                    </div>
                    <div>
                      {
                        cnames.dnsResourceRecord.map((item, index) => (
                          <div key={`row-${index}`}  style={{display: 'flex'}}>
                            <div style={{display: 'flex', width: '10%'}}><div style={{display: 'flex', alignItems: 'center', overflowX: 'auto'}}>{item.Type}</div></div>
                            <div style={{display: 'flex', width: '45%'}}><div style={{display: 'flex', alignItems: 'center', overflowX: 'auto'}}>{item.Name}</div><IconButton onClick={() => onCopy(item.Name)}><FileCopy style={{fill: 'rgba(0, 0, 0, 0.54)'}} /></IconButton></div>
                            <div style={{display: 'flex', width: '45%'}}><div style={{display: 'flex', alignItems: 'center', overflowX: 'auto'}}>{item.Value}</div><IconButton onClick={() => onCopy(item.Value)}><FileCopy style={{fill: 'rgba(0, 0, 0, 0.54)'}} /></IconButton></div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </>
              ) : (
                <FormControl
                style={{ width: '100%' }}
                onSubmit={() => createAndNavigate()}
              >
                <div style={styles.field} className='field'>
                  <Label style={styles.label}>Dominio</Label>
                  <Input
                    type='text'
                    id='name'
                    value={name}
                    disabled={loading}
                    style={styles.input}
                    aria-describedby='nameHelp'
                    placeholder='Introduce el domonio'
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </FormControl>
              )
            }
          </div>
          {
            !cnames && (
              <div style={styles.actions}>
                <button
                  onClick={() => props.onCloseModal()}
                  style={styles.actionButtonCancel}
                  className='button is-light'
                >
                  Cancelar
                </button>
                <button
                  disabled={loading}
                  onClick={() => createAndNavigate()}
                  stedityle={styles.actionButton}
                  className='button is-light'
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
                  ) : edit ? (
                    'Guardar'
                  ) : (
                    'Asociar dominio'
                  )}
                </button>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    padding: '10px 10px',
    flexDirection: 'column'
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  name: {
    fontSize: 22,
    color: '#004976',
    fontWeight: '600',
    textAlign: 'left',
    fontFamily: 'Montserrat'
  },
  close: {
    fill: '#000'
  },
  content: {
    padding: 1,
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#004976',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    padding: '15px 30px 0px 30px',
    justifyContent: 'space-between',
  },
  actionButton: {
    fontSize: 16,
    color: '#fff',
    borderRadius: 8,
    marginBottom: 15,
  },
  actionButtonCancel: {
    fontSize: 16,
    color: '#004976',
    borderRadius: 8,
    marginBottom: 15,
    backgroundColor: '#F6F6F6'
  },
  structureList: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 9
  }
}

const mapStateToProps = ({ sites, domains }) => {
  return { sites, domains }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ postDomain, fetchDomains }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreate)
