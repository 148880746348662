import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function AlertDialog({ title, message, onSuccess, onFail, cancelText = 'Cancelar', acceptText = 'Aceptar', onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={true}
      onClose={() => ({})}
      fullScreen={fullScreen}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            onClose();
            onFail(false);
          }}
          color="primary">
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            onClose();
            onSuccess(true);
          }}
          color="primary"
          autoFocus>
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export const Alert = ({
  onFail,
  onSuccess,
  message = '¿Estas seguro?',
  title = 'Vas a eliminar este recurso',
}) => {
  confirmAlert({
    title,
    message,
    customUI: (props) => (
      <AlertDialog
        {...props}
        onFail={onFail}
        onSuccess={onSuccess}
      />
    ),
  });
}
