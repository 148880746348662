import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { Edit, Delete, DragIndicator, Public, Comment } from '@material-ui/icons';
import { Accordion, AccordionSummary, Typography, makeStyles, CircularProgress, Tooltip } from '@material-ui/core';
import { fetchContent, deleteContent, updateContent } from '../../../../redux/actions/content';
import AddContent from './AddContent';
import { Alert } from '../../Alert';

const useStyles = makeStyles({
  accordionRoot: {
    boxShadow: 'none',
    borderRadius: '6px !important',
    transition: 'background-color 0.4s',
    backgroundColor: 'white',
    '&.Mui-expanded': {
      backgroundColor: 'white',
      margin: 0,
    },
    '&:before': {
      visibility: 'hidden'
    }
  },
  accordionSummaryRoot: {
    minHeight: '44px !important',
    height: 44,
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
      '&.Mui-expanded': {
        transform: 'rotate(0deg)'
      },
      '& span svg': {
        fill: '#024976'
      }
    },
    '& .MuiAccordionSummary-content .content-item-content .content-item-actions1': {
      display: 'flex !important'
    },
    '& .MuiAccordionSummary-content:hover .content-item-content .content-item-actions1': {
      display: 'none !important'
    },
    '& .MuiAccordionSummary-content .content-item-content .content-item-actions2': {
      display: 'none !important'
    },
    '& .MuiAccordionSummary-content:hover .content-item-content .content-item-actions2': {
      display: 'flex !important'
    },
  },
  typographySubtitle1Root: {
    color: '#024976',
    margin: '0px !important',
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Montserrat',
    fontWeight: 200,
    paddingLeft: 10
  },
  accordionDetailsRoot: {
    paddingLeft: 50,
    paddingRight: 12,
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 200,
    '& .MuiGrid-root .MuiGrid-root div p': {
      fontSize: 14
    }
  }
});

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 16,
    columnGap: 8,
  },
  actionsBtn: {
    padding: 1,
    borderRadius: 5,
    backgroundColor: '#00ACE6',
  },
  itemContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  itemAction: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 2,
    borderRadius: 5,
    backgroundColor: '#00ACE6',
  },
  iconAction: {
    color: '#fff',
    fontSize: 16,
  },
}

export const ContentItem = ({ id, sites, title, parentModule, typeMedia, status, allowComments, description, content, deleteContent, fetchContent, updateContent }) => {
  const classes = useStyles();
  const { course } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const onDeleteModule = (id) => {   
    Alert({
      onSuccess: async () => {
        setLoading(true)
        await deleteContent(sites.defaultSite.id, course, parentModule, id);
        await fetchContent(sites.defaultSite.id, course, parentModule);
        setLoading(false)
      },
      onFail: () => {
        console.log('cancel')
      },
    });
  }

  const onUpdate = async () => {
    // setLoading(true)
    await fetchContent(sites.defaultSite.id, course, parentModule);
    // setLoading(false)
    // setModalEdit(false);
  }

  const update = async (body) => {
    await updateContent(sites.defaultSite.id, course, parentModule, id, body);
  }

  const onPublish = async (ev, value) => {
    ev.stopPropagation();
    if (!loading) {
      setLoading(true)
      const status = value !== "Publicado" ? 'Publicado' : 'Borrador'
      await update({status})
      await fetchContent(sites.defaultSite.id, course, parentModule);
      setLoading(false)
    }
  }

  const onComments = async (ev, value) => {
    ev.stopPropagation();
    if (!loading) {
      setLoading(true)
      await update({allowComments: !value})
      await fetchContent(sites.defaultSite.id, course, parentModule);
      setLoading(false)
    }
  }

  return (
    <>
      <Accordion style={{opacity: loading ? 0.7 : 1}} classes={{root:classes.accordionRoot}} expanded={true}>
        <AccordionSummary
          classes={{root:classes.accordionSummaryRoot}}
          id="panel1a-header"
          aria-controls="panel1a-content"
          onClick={(ev) => {
            ev.stopPropagation();
            setModalEdit(true);
          }}
        >
          <div className="content-item-content" style={styles.itemContent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DragIndicator style={{fill: '#024976', cursor: 'pointer'}}/>
              <Typography classes={{root:classes.typographySubtitle1Root}} variant="subtitle1">
                {title}
              </Typography>
              {
                loading && (
                  <div 
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      margin: '0px 10px'
                    }}
                  >
                    <CircularProgress style={{ color: '#00A8D8' }} size={20} />
                  </div>
                )
              }
            </div>
            <div className="content-item-actions1" style={styles.actions}>
              <Public style={{...styles.iconAction, color: status !== "Borrador" ? '#03C18A' : '#B8B8B8'}} />
              <Comment style={{...styles.iconAction, color: allowComments ? '#03C18A' : '#B8B8B8'}} />
            </div>
            <div className="content-item-actions2" style={styles.actions}>
              <Tooltip title={status !== "Borrador" ? 'Publicado' : 'Borrador'} aria-label="Public">
                <div onClick={(ev) => onPublish(ev, status)} style={{...styles.itemAction, backgroundColor: status !== "Borrador" ? '#03C18A' : '#B8B8B8' }}>
                  <Public style={styles.iconAction} />
                </div>
              </Tooltip>
              <Tooltip title={allowComments ? 'Comentarios permitidos' : 'Comentarios no permitidos'} aria-label="Comment">
                <div onClick={(ev) => onComments(ev, allowComments)} style={{...styles.itemAction, backgroundColor: allowComments ? '#03C18A' : '#B8B8B8' }}>
                  <Comment style={styles.iconAction} />
                </div>
              </Tooltip>
              <Tooltip title="Editar" aria-label="Edit">
                <div className="actions-edit" style={styles.itemAction} onClick={(ev) => {
                  ev.stopPropagation();
                  setModalEdit(true);
                }}>
                  <Edit style={styles.iconAction} />
                </div>
              </Tooltip>
              <Tooltip title="Eliminar" aria-label="Delete">
                <div className="actions-delete"  style={styles.itemAction} onClick={(ev) => {
                  ev.stopPropagation();
                  onDeleteModule(id);
                }}>
                  <Delete style={styles.iconAction} />
                </div>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
      {
        modalEdit &&
        <AddContent
          id={id}
          course={course}
          type={typeMedia}
          open={modalEdit}
          onUpdate={onUpdate}
          module={parentModule}
          site={sites.defaultSite.id}
          onClose={() => setModalEdit(false)}
        />
      }
    </>
  );
}

const mapStateToProps = ({ sites, courses, modules, submodules }) => {
  return { sites, courses, modules, submodules };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchContent, deleteContent, updateContent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentItem);