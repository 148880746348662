import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { FormGroup, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress, makeStyles } from '@material-ui/core';
import { fetchEmailTemplates, fetchEmailTemplatesSystem, fetchEmailTemplate, postEmailTemplate, putEmailTemplate } from '../../../redux/actions/emailTemplates';
import { Label, Input, TextArea } from '../StyledComponents';
import { makeToast } from '../../../utils'
import { defaultTemplate, defaultTemplateHtml, baseNewsletterJson, baseNewsletterHtml } from './TemplateOptions'
import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'

const templateOptions = [
  {
    id: 1,
    name: 'Default',
    preview: '/images/email_template_preview_1.png',
    json: defaultTemplate,
    html: defaultTemplateHtml
  },
  {
    id: 2,
    name: 'Base Newsletter',
    preview: '/images/email_template_preview_2.png',
    json: baseNewsletterJson,
    html: baseNewsletterHtml
  }
]

function EmailTemplateCreate(props) {

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles();

  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [template, setTemplate] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [description, setDescription] = useState('');

  const loadEmailTemplate = async () => {
    if (props.emailTemplate) {
      await setFetching(true)
      const { sites } = props;
      const res = await props.fetchEmailTemplate(sites.defaultSite.id, props.emailTemplate);
      await setData(res.emailTemplate)
      await setName(res.emailTemplate.name)
      await setDescription(res.emailTemplate.description)
      await setFetching(false)
    }
    await setLoading(false);
  }

  useEffect(() => {
    loadEmailTemplate();
  }, [])

  const validate = () => {
    if (name.trim() === '') return { success: false, error: 'Nombre requerido' };
    return { success: true }
  }

  const cleanInputs = () => {
    setName('');
    setDescription('');
  }

  const handleSubmit = async (ev, toEdit, closeModal = false) => {
    ev.preventDefault();
    const isValid = validate();
    if (isValid.success) {
      await setLoading(true);
      try {
        const { sites, postEmailTemplate } = props;
        const siteId = sites.defaultSite.id;
        let selectedTemplate = templateOptions.find(element => element.id === template)
        if(props.emailTemplate){
          await props.putEmailTemplate(siteId, props.emailTemplate, { name, description });
        }else{
          const { emailTemplate } = await postEmailTemplate(siteId, { name, description, body: selectedTemplate.html, bee_json: selectedTemplate.json });
          if (toEdit) {
            history.push(`/marketing/email-templates/view/${emailTemplate.id}`);
          }
        }
        if(closeModal){
          props.onCloseModal()
        }
        props.fetchEmailTemplates(siteId);
        props.fetchEmailTemplatesSystem(siteId);
        cleanInputs();
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>{props.emailTemplate ? 'Editar Email Template' : 'Crear Email Template'}</div>
        <IconButton onClick={()=>props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      {
        fetching ? (
          <div style={{padding: 30}}>
            <Skeleton variant="text" animation="pulse" height={70} style={{marginBottom: 15}} />
            <Skeleton variant="rect" animation="pulse" height={140} style={{marginBottom: 15}} />
          </div>
        ) :
          <>
            <div style={styles.content}>
              <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
                <div style={styles.field} className="field">
                  <Label style={styles.label}>Nombre</Label>
                  <Input
                    type="text"
                    id="name"
                    value={name}
                    disabled={loading}
                    style={styles.input}
                    placeholder="Introduce el nombre"
                    aria-describedby="nameHelp"
                    onChange={(e)=>setName(e.target.value)}
                  />
                </div>
                <div style={styles.field} className='field'>
                  <div
                    style={{
                      fontSize: 12,
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontFamily: 'Montserrat',
                      job: 'relative',
                      zIndex: 1,
                      padding: '0px 13px'
                    }}
                  >
                    Ingresa la descripción
                  </div>
                  <TextArea
                    value={description}
                    disabled={loading}
                    aria-describedby='descriptionHelp'
                    onChange={e => setDescription(e.target.value)}
                    style={{ height: 200, borderRadius: 14 }}
                    placeholder='Ingresa la descripción'
                  />
                </div>
              </FormControl>
              {
                !props.emailTemplate && (
                  <FormGroup>
                    <div style={styles.field} className="field">
                      <Label style={{ ...styles.label, color: '#024976', fontSize: 16, fontFamily: 'Montserrat', fontWeight: '900' }}>Elegir una plantilla</Label>
    
                      <RadioGroup
                        aria-label="template"
                        name="template"
                        value={template}
                        style={styles.templateList}
                        classes={{ root: classes.radioGroupRoot }}
                        onChange={(ev) => { setTemplate(parseInt(ev.target.value)) }}
                      >
                        {
                          templateOptions.map(element => {
                            return (
                              <FormControlLabel classes={{ root: classes.formControlLabelRoot }} key={element.id} style={{ margin: 0, width: '48%' }} value={element.id} control={
                                <TemplateItem
                                  style={{ 
                                    ...(template === element.id && styles.templateItemActive ), 
                                    backgroundImage: `url(${element.preview})` 
                                  }}
                                >
                                  <div style={styles.templateItemFilter} />
                                  <Radio classes={{ root: classes.radioRoot }} value={element.id} />
                                </TemplateItem>
                              }
                              />
                            )
                          })
                        }
                      </RadioGroup>
    
                    </div>
                  </FormGroup>
                )
              }
            </div>
            <div style={styles.actions}>

              {
                props.emailTemplate ? (
                  <button 
                    disabled={loading} 
                    onClick={(ev) => handleSubmit(ev, false, true)}
                    style={styles.actionButton} 
                    className="button is-light"
                  >
                    {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : 'Guardar'}
                  </button>
                ) : (
                  <>
                    <button 
                      disabled={loading} 
                      onClick={(ev) => handleSubmit(ev)}
                      style={styles.actionButton} 
                      className="button is-primary"
                    >
                      Crear y añadir otro
                    </button>

                    <button 
                      disabled={loading} 
                      onClick={(ev) => handleSubmit(ev, true)}
                      style={styles.actionButton} 
                      className="button is-light"
                    >
                      {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }} /> : (props.emailTemplate ? 'Guardar' : 'Crear')}
                    </button>
                  </>
                )
              }

              <button 
                onClick={()=>props.onCloseModal()}
                style={styles.actionButton}
                className="button is-secondary"
              >
                Cancelar
              </button>

            </div>
          </>   
      }
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  formControlLabelRoot: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: '#9e9e9e'
    },
    '& .Mui-checked + span.MuiTypography-root': {
      color: '#00ace7'
    }
  },
  radioGroupRoot: {
    flexDirection: 'row',
    columnGap: 28
  },
  radioRoot: {
    '&:not(.Mui-checked) .MuiIconButton-label': {
      color: 'white'
    },
    '&.Mui-checked .MuiIconButton-label': {
      color: 'transparent'
    },
    '&.Mui-checked .MuiIconButton-label div svg:first-child': {
      backgroundColor: '#00ace7',
      borderRadius: '50%'
    },
    '&.Mui-checked .MuiIconButton-label div svg:last-child': {
      fill: 'white'
    }
  },
}));

const TemplateItem = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 3px white solid;
  background-position-y: 0%;
  transition: border 0.4s, opacity 0.4s;
  &:hover{
    animation: auto-scroll-animation 10s infinite;
    opacity: 0.7;
  }
  @keyframes auto-scroll-animation {
    0% {
      background-position-y: 0%;
    }
    50% {
      background-position-y: 100%;
    }
    100% {
      background-position-y: 0%;
    }
  }  
`

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: { 
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', 
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    textAlign: 'left', 
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
  templateList: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    columnGap: 9,
    rowGap: 15
  },
  templateItemActive: {
    border: '3px #00ace7 solid',
  },
  templateItemFilter: {
    position: 'absolute',
    top: -3,
    left: -3,
    width: 'calc(100% + 6px)',
    height: 'calc(100% + 6px)',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(0deg, #0000002e, transparent)'
  }
} 

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchEmailTemplates, fetchEmailTemplate, postEmailTemplate, putEmailTemplate, fetchEmailTemplatesSystem }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateCreate);