import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';
import { FormControl, CircularProgress, FormControlLabel, makeStyles, RadioGroup, FormLabel, Radio } from '@material-ui/core';
import { fetchUsers } from '../../../redux/actions/users';
import { createUser } from '../../../helpers/users';
import { Label, Input } from '../StyledComponents';
import { validateEmail } from '../../../utils';
import { makeToast } from '../../../utils'

const useStyles = makeStyles({
  formControlLabelRoot: {
    '& .Mui-checked .MuiIconButton-label': {
      color: '#00ace7 !important'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '500',
      color: 'rgb(2, 73, 118)'
    }
  }
});

function UserCreate(props) {

  const classes = useStyles();
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [role, setRole] = useState('admin');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordMasked, setPasswordMasked] = useState(true)
  const [passwordValidationStatus, setPasswordValidationStatus] = useState({});

  const validate = () => {
    if (email.trim() === '') return { success: false, error: 'Email requerido' };
    if (!validateEmail(email)) return { success: false, error: 'Email valido requerido' };
    if (name.trim() === '') return { success: false, error: 'Nombre requerido' };
    return { success: true }
  }

  const cleanInputs = () => {
    setEmail('');
    setName('');
    setPassword('');
  }

  const togglePasswordMasked = () => {
    setPasswordMasked(!passwordMasked)
  }

  const handleSubmit = async (ev, toEdit) => {
    ev.preventDefault();
    const isValid = validate();
    if (isValid.success) {
      await setLoading(true);
      try {
        const { sites } = props;
        const siteId = sites.defaultSite.id;
        const data = await createUser(siteId, {
          email, 
          name, 
          role,
          ...(password !== '' && { userPassword: password }) 
        });
        makeToast(enqueueSnackbar, 'El usuario ha sido creado')
        if (toEdit) {
          history.push(`/admin/users/view/${data.id}`);
        }
        props.fetchUsers(siteId);
        cleanInputs();
      } catch (error) {
        console.log('handleSubmit', error);
        makeToast(enqueueSnackbar, error, 'error')
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const submitEnabled = () => {
    if (password) {
      let { lowercase, uppercase, special, number, min } = passwordValidationStatus
      return password !== '' && lowercase && uppercase && special && number && min
    } else {
      return true
    }
  }

  const validatePassword = event => {
    let value = event.target.value
    setPasswordValidationStatus({
      uppercase: value.match('(?=.*[A-Z])') !== null ? true : false,
      lowercase: value.match('(?=.*[a-z])') !== null ? true : false,
      special: value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) !== null ? true : false,
      number: value.match('([0-9])') !== null ? true : false,
      min: value.length >= 8 ? true : false,
    })
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>Crear Usuario</div>
        <IconButton onClick={() => props.onCloseModal()}>
          <Close style={styles.close} />
        </IconButton>
      </div>
      <div style={styles.content}>
        <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Nombre</Label>
            <Input
              id="name"
              type="text"
              value={name}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el nombre"
              aria-describedby="nameHelp"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <Label style={styles.label}>Correo</Label>
            <Input
              id="email"
              type="email"
              value={email}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce el correo"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div style={{...styles.field, position: 'relative'}} className="field">
            <Label style={styles.label}>Contraseña</Label>
            <Input
              id="password"
              type={passwordMasked ? 'password' : 'text'}
              value={password}
              disabled={loading}
              style={styles.input}
              placeholder="Introduce la contraseña"
              aria-describedby="passwordHelp"
              onChange={(e) => {
                setPassword(e.target.value)
                validatePassword(e)
              }}
            />
            <IconButton style={styles.passwordMaskedButton} onClick={()=>togglePasswordMasked()}>
              { passwordMasked ? <VisibilityOff /> : <Visibility /> }
            </IconButton>
          </div>
          <div style={styles.field} className="field">
            <PasswordValidation className="field">
              <PVItem>
                <PVDot style={passwordValidationStatus.lowercase ? {background: '#09C269'} : null }/>
                <PVText>Un carácter en minúscula</PVText>
              </PVItem>
              <PVItem>
                <PVDot style={passwordValidationStatus.uppercase ? {background: '#09C269'} : null }/>
                <PVText>Un carácter en mayúscula</PVText>
              </PVItem>
              <PVItem>
                <PVDot style={passwordValidationStatus.special ? {background: '#09C269'} : null }/>
                <PVText>Un carácter especial</PVText>
              </PVItem>
              <PVItem>
                <PVDot style={passwordValidationStatus.number ? {background: '#09C269'} : null }/>
                <PVText>Un número</PVText>
              </PVItem>
              <PVItem>
                <PVDot style={passwordValidationStatus.min ? {background: '#09C269'} : null }/>
                <PVText>8 caracteres como mínimo</PVText>
              </PVItem>
            </PasswordValidation>
          </div>
          <div style={styles.field} className="field">
            <FormLabel classes={{ root: classes.formControlLabelRoot }} >
              Si no se establece una contraseña se enviará un correo a la dirección del usuario con una contraseña temporal para que el usuario establezca su contraseña permanente.
            </FormLabel>
          </div>
          <div style={styles.field} className="field">
            <FormLabel component="legend">Roles</FormLabel>
            <RadioGroup aria-label="role" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
              <FormControlLabel value="admin" control={<Radio />} label="Administrador" />
              <FormControlLabel value="editor" control={<Radio />} label="Editor" />
              <FormControlLabel value="reader" control={<Radio />} label="Soporte" />
            </RadioGroup>
          </div>
        </FormControl>
      </div>
      <div style={styles.actions}>

        <button
          disabled={loading || !submitEnabled()}
          onClick={(ev) => handleSubmit(ev)}
          style={styles.actionButton}
          className="button is-primary"
        >
          Crear y añadir otro
        </button>

        <button
          disabled={loading  || !submitEnabled()}
          onClick={(ev) => handleSubmit(ev, true)}
          style={styles.actionButton}
          className="button is-light"
        >
          {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Crear'}
        </button>

        <button
          onClick={() => props.onCloseModal()}
          style={styles.actionButton}
          className="button is-secondary"
        >
          Cancelar
        </button>

      </div>
    </div>
  );
}

const PasswordValidation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const PVItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const PVDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #ddd;
  transition: background 0.2s;
`;

const PVText = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  color: rgb(136, 145, 166);
`;

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  passwordMaskedButton: {
    position: 'absolute',
    right: 8,
    bottom: 4
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
}

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchUsers }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);