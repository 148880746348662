import { combineReducers } from 'redux';
import sites from './reducers/sites';
import pages from './reducers/pages';
import users from './reducers/users';
import forms from './reducers/forms';
import events from './reducers/events';
import people from './reducers/people';
import videos from './reducers/videos';
import domains from './reducers/domains';
import courses from './reducers/courses';
import session from './reducers/session';
import modules from './reducers/modules';
import content from './reducers/content';
import webinars from './reducers/webinars';
import templates from './reducers/templates';
import submodules from './reducers/submodules';
import categories from './reducers/categories';
import instructors from './reducers/instructors';
import emailTemplates from './reducers/emailTemplates';

export default combineReducers({
  emailTemplates,
  instructors,
  submodules,
  categories,
  templates,
  content,
  webinars,
  courses,
  domains,
  modules,
  session,
  people,
  events,
  videos,
  sites,
  forms,
  pages,
  users,
});