import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import network from '../utils/network'
import Bee from '@mailupinc/bee-plugin'
import { Modal } from 'react-bootstrap'
import { useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { ArrowDropDown } from '@material-ui/icons'
import reactModal from '@prezly/react-promise-modal'
import { Select } from '../admin/components/StyledComponents'
import { postTemplate } from '../redux/actions/templates'
import { fetchAllForms } from '../redux/actions/forms'
import Globals from '../Globals'

let response = null

const PageBuilder = props => {
  const { sites } = props
  const { id, page } = useParams()
  const [template, setTemplate] = useState({})
  const [loading, setLoading] = useState(true)

  let forms = []

  const savePage = async (jsonFile, body, templateId) => {
    try {
      if (id) {
        const data = {
          data: JSON.parse(jsonFile),
          url: template.title,
        }
        if (body) data.body = body
        if (templateId) data.templateId = templateId
        await network.put(`site/${id}/pages/${page}`, data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadTemplate = async () => {
    try {
      if (id) {
        const { data } = await network.get(`site/${id}/pages/${page}`)
        await setTemplate(data)
        response = data
        return data.data
      }
    } catch (error) {
      console.log(error)
    } finally {
      await setLoading(false)
    }
  }

  const openModal = async () => {
    const result = await reactModal(({ show, onSubmit, onDismiss }) => (
      <Modal centered show={show} onHide={onDismiss}>
        <div style={{ padding: 30 }}>
          <div style={{ position: 'relative' }}>
            <Select
              value={-1}
              style={{
                height: 56,
                borderRadius: 14,
                backgroundColor: 'white',
                border: 'rgb(232, 232, 232) 1px solid'
              }}
              onChange={ev => {
                onSubmit(ev.target.value)
              }}
            >
              <option key={-1} value={-1}>
                Selecciona un formulario
              </option>
              {forms.map(form => (
                <option key={form.id} value={form.id}>
                  {form.title}
                </option>
              ))}
            </Select>
            <ArrowDropDown
              style={{
                fill: '#144974',
                position: 'absolute',
                top: 16,
                right: 16
              }}
            />
          </div>
          <button
            onClick={onDismiss}
            style={{ marginTop: 24 }}
            className='button is-secondary'
          >
            Cancelar
          </button>
        </div>
      </Modal>
    ))

    if (result === undefined) {
      return {error: 'The modal was dismissed or cancelled'}
    } else {
      return result
    }
  }

  const onSaveAsTemplate = async jsonFile => {
    try {
      const siteId = sites.defaultSite.id
      const data = await props.postTemplate(siteId, {
        name: template.title,
        description: response.config.seo.description,
        bee_json: jsonFile
      })
      await savePage(jsonFile, null, data.template.id)
    } catch (error) {
      console.log('onSave Error', error)
    }
  }

  const addMetas = (config, htmlFile) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlFile, 'text/html')

    doc.head.querySelector('title').innerText = config.title

    let script = window.document.createElement('script')
    script.setAttribute("fbpixel", "true")
    script.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${config.site.facebookPixel}');  
      fbq('track', 'PageView');
    `;
    doc.head.appendChild(script)

    let img = window.document.createElement('img')
    img.src = `https://www.facebook.com/tr?id=${config.site.facebookPixel}&ev=PageView&noscript=1`
    img.style = "display:none;";
    img.height = 1;
    img.width = 1;
    
    let noscript = window.document.createElement('noscript')
    noscript.setAttribute("fbpixel", "true")
    noscript.appendChild(img)
    doc.head.appendChild(noscript)

    let meta = window.document.createElement('meta')
    meta.setAttribute('name', 'keywords')
    meta.setAttribute('content', config.keywords)
    doc.head.appendChild(meta)

    script = window.document.createElement('script')
    script.setAttribute("customform", "true")
    script.text = `
    const form = document.querySelector('form')
    const redirect = (url) => {
      if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
        window.open(url)
      } else {
        window.location.pathname = "/" + url;
      }
    }
    form.addEventListener('submit', (ev) => {
      ev.preventDefault();
      const formData = new FormData(form)
      const data = []
      for (var pair of formData.entries()) {
        data.push({name: pair[0], value: pair[1]})
      }
      fetch(form.getAttribute("action"), {
        method: "POST",
        body: JSON.stringify({results: data}),
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        redirect(json.redirect)
      })
      .catch(error => {
        console.log('error', error)
      })
    })
    `
    doc.body.appendChild(script)

    script = window.document.createElement('script')
    script.setAttribute("track", "true")
    script.text = `
      function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
      let session = sessionStorage.getItem('l3rn-session')
      if (!session) {
        session = uuidv4()
        sessionStorage.setItem('l3rn-session', session)
      }
      function initFingerprintJS() {
        FingerprintJS.load().then(fp => {
          fp.get().then(result => {
            const visitorId = result.visitorId;
            const eventData = {
              session_id: session,
              uuid: result.visitorId,
              event_time: new Date().toISOString(),
              source: 'web',
              event_type: 'traffic',
              url_origin: window.location.origin,
              url_path: window.location.pathname,
              referrer: document.referrer,
              os_platform: window.navigator.platform,
              device_lang: window.navigator.language,
              extra_data: {}
            }
            const apiUrl = "${Globals.API_URL}public/${config.site.id}/track"
            fetch(apiUrl, {
              method: "POST",
              body: JSON.stringify({eventData}),
            })
            .then(response => {
              return response.json();
            })
            .then(json => {
              redirect(json.redirect)
            })
            .catch(error => {
              console.log('error', error)
            })
          });
        });
      }
    `
    doc.body.appendChild(script)

    script = window.document.createElement('script')
    script.setAttribute("fingerprint", "true")
    script.async = true
    script.src = "https://cdn.jsdelivr.net/npm/@fingerprintjs/fingerprintjs@3/dist/fp.min.js"
    doc.body.appendChild(script)
    doc.body.querySelector('script[fingerprint="true"]').setAttribute("onload", "initFingerprintJS()")

    meta = window.document.createElement('meta')
    meta.setAttribute('name', 'description')
    meta.setAttribute('content', config.description)
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', 'website')
    meta.setAttribute('property', 'og:type')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.socialShare)
    meta.setAttribute('property', 'og:title')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.url)
    meta.setAttribute('property', 'og:url')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', `${config.title} | ${config.description}`)
    meta.setAttribute('property', 'og:site_name')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.socialImage)
    meta.setAttribute('property', 'og:image')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.socialDescription)
    meta.setAttribute('property', 'og:description')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.socialImage)
    meta.setAttribute('property', 'og:image:alt')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', 'es_mx')
    meta.setAttribute('type', 'og:locale')
    doc.head.appendChild(meta)

    meta = window.document.createElement('link')
    meta.setAttribute('rel', 'icon')
    meta.setAttribute('href', config.icon)
    doc.head.appendChild(meta)

    meta = window.document.createElement('link')
    meta.setAttribute('href', config.icon)
    meta.setAttribute('rel', 'apple-touch-icon')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.socialShare)
    meta.setAttribute('property', 'twitter:title')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('content', config.socialDescription)
    meta.setAttribute('property', 'twitter:description')
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('name', 'twitter:image')
    meta.setAttribute('content', config.socialImage)
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('name', 'twitter:image:alt')
    meta.setAttribute('content', config.socialImage)
    doc.head.appendChild(meta)

    meta = window.document.createElement('meta')
    meta.setAttribute('name', 'twitter:card')
    meta.setAttribute('content', 'summary_large_image')
    doc.head.appendChild(meta)

    if (!config.allow) {
      meta = window.document.createElement('meta')
      meta.setAttribute('name', 'robots')
      meta.setAttribute('content', 'noindex')
      doc.head.appendChild(meta)
    }

    return doc.documentElement.outerHTML
  }

  const onSave = async (jsonFile, htmlFile) => {
    try {
      let html = htmlFile
      if (response.config && response.config.seo) {
        html = addMetas({...response.config.seo, site: response.config.site}, htmlFile)
      }
      await savePage(jsonFile, html)
    } catch (error) {
      console.log('onSave Error', error)
    }
  }

  const beeConfig = {
    uid: `${sites.defaultSite.id}`,
    container: 'bee-plugin-container',
    language: 'es-ES',
    onSave,
    onSaveAsTemplate,
    onSend: htmlFile => {
      console.log('onSend', htmlFile)
    },
    onError: errorMessage => {
      console.log('onError ', errorMessage)
    },
    advancedPermissions: {
      content: {
        form: {
          properties: {
            editField: {
              show: false
            }
          }
        }
      }
    },
    contentDialog: {
      manageForm: {
        label: 'Seleccionar formulario',
        handler: async (resolve, reject, args) => {
          console.log('handler', args)
          await openModal()
            .then(formId => {
              let form = forms.find(element => element.id === formId)
              let fields = {}
              let layout = []

              function getType (type) {
                if (
                  type === 'firstName' ||
                  type === 'fullName' ||
                  type === 'lastName'
                )
                  return 'text'
                if (type === 'phone') return 'tel'
                return type
              }

              function getSelectOptions (options) {
                let res = []
                let arrayOfLines = options.match(/[^\r\n]+/g)
                arrayOfLines.forEach(element => {
                  res.push({
                    type: 'option',
                    label: element,
                    value: element
                  })
                })
                return res
              }

              form.fields.forEach(element => {
                fields[element.name] = {
                  type: getType(element.type),
                  label: element.title,
                  attributes: {
                    required: element.required
                  },
                  ...(element.type === 'select' && {
                    options: getSelectOptions(element.options)
                  }),
                  canBeRemovedFromLayout: false
                }
                layout.push([element.name])
              })

              fields['submit'] = {
                type: 'submit',
                attributes: { value: 'Enviar' },
                canBeRemovedFromLayout: false
              }
              layout.push(['submit'])

              resolve({
                structure: {
                  attributes: {
                    action: `${Globals.API_URL}public/site/${sites.defaultSite.id}/form/${formId}/entries`,
                    method: 'POST',
                    target: '_self',
                    enctype: form.redirect,
                  },
                  description: form.description,
                  fields: fields,
                  layout: layout,
                  title: form.title
                }
              })
            })
            .catch(() => {
              reject()
            })
        }
      }
    },
    rowsConfiguration: {
      emptyRows: true,
      defaultRows: true,
      externalContentURLs: [
        {
          name: 'Rows list 01',
          value: 'https://URL-01'
        },
        {
          name: 'Rows list 02',
          value: 'https://URL-02'
        }
      ]
    }
  }

  const initializeBeeFreePlugin = async () => {
    const beeTest = new Bee()
    const userTemplate = await loadTemplate()
    let myTemplate = {}
    if (userTemplate && userTemplate.page) {
      myTemplate = { page: userTemplate.page }
    }
    beeTest
      .getToken(
        Globals.BEE_FREE_PAGE_BUILDER_CLIENT_ID,
        Globals.BEE_FREE_PAGE_BUILDER_CLIENT_SECRET
      )
      .then(() => beeTest.start(beeConfig, myTemplate))
  }

  const preload = async () => {
    const data = await fetchAllForms(sites.defaultSite.id)
    if (data) {
      forms = data
    }
  }

  useEffect(() => {
    preload()
    initializeBeeFreePlugin()
  }, [])

  if (loading) {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#32373A'
          }}
        ></div>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div
        style={{ width: '100%', height: '100vh', backgroundColor: '#32373A' }}
        id='bee-plugin-container'
      />
    </div>
  )
}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ postTemplate }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PageBuilder)
