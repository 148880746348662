import {
    FETCHING_TEMPLATES,
    FAIL_FETCH_TEMPLATES,
    SUCCESS_FETCH_TEMPLATES,
    FETCHING_ALL_TEMPLATES,
    FAIL_FETCH_ALL_TEMPLATES,
    SUCCESS_FETCH_ALL_TEMPLATES,
    FETCHING_TEMPLATE,
    FAIL_FETCH_TEMPLATE,
    SUCCESS_FETCH_TEMPLATE,
    DELETE_TEMPLATE,
    DELETE_FAIL_TEMPLATE,
    DELETE_SUCCESS_TEMPLATE,
  } from '../actions/templates'
  
  const initialState = {
    all: [],
    data: [],
    template: null,
    error: null,
    loading: false,
    order: {},
    count: 0,
    limit: 25,
    offset: 0
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCHING_TEMPLATES:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_TEMPLATES:
        let count = action.data ? action.data.data ? action.data.data.length ? action.data.data.length : 0 : 0 : 0
        return {
          ...state,
          ...action.data,
          count: count,
          loading: false,
        };
      case FAIL_FETCH_TEMPLATES:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.error,
        };
      case FETCHING_ALL_TEMPLATES:
        return {
          ...state,
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_ALL_TEMPLATES:
        return {
          ...state,
          loading: false,
          all: action.data,
        };
      case FAIL_FETCH_ALL_TEMPLATES:
        return {
          ...state,
          all: [],
          loading: false,
          error: action.error,
        };
      case FETCHING_TEMPLATE:
        return {
          ...state,
          template: [],
          error: null,
          loading: true,
        };
      case SUCCESS_FETCH_TEMPLATE:
        return {
          ...state,
          loading: false,
          template: action.data,
          error: action.error,
        };
      case FAIL_FETCH_TEMPLATE:
        return {
          ...state,
          template: [],
          loading: false,
          error: action.error,
        };
        case DELETE_TEMPLATE:
        return {
          ...state,
          loading: true,
        };
      case DELETE_FAIL_TEMPLATE:
        return {
          ...state,
          loading: false,
        };
      case DELETE_SUCCESS_TEMPLATE:
        return {
          ...state,
          loading: false,
        };
      default:
        return state
    }
  };
  