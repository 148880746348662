import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Visibility, AddCircle } from '@material-ui/icons';
import { fetchWebinars, deleteWebinar, postWebinar } from '../../../redux/actions/webinars';
import ModalRight from "../ModalRight";
import Create from "./Create";
import FullDataTable from "./../CoursesMain/FullDataTable"

const columns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    label: 'Título',
    name: 'title',
  },
  {
    label: 'Descripción',
    name: 'description',
  },
  {
    label: 'Duracion',
    name: 'expected_duration',
  },
  {
    label: 'Acciones',
    name: 'id',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return (
            <>
              <Link to={`/webinars/edit/${value}`} >
                <Visibility />
              </Link>
            </>
          );
        }
      },
    },
  },
];

function WebinarList(props) {
  const { sites } = props;
  const { webinars: { data, loading, limit, offset, count, sort, preload } } = props;

  const { enqueueSnackbar } = useSnackbar()
  const [createModalVisible, setCreateModalVisible] = useState(false)

  const toggleCreateModalVisible = (option) => {
    option ? setCreateModalVisible(option) : setCreateModalVisible(!createModalVisible)
  }

  const customSearch = ({ rowsPerPage, page, sortOrder, searchText, columns }) => {
    if (searchText && searchText !== '') {
      const names = columns.map(item => (item.name));
      props.fetchWebinars(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction, names, searchText);
    } else {
      props.fetchWebinars(sites.defaultSite.id, rowsPerPage, page, sortOrder.name, sortOrder.direction);
    }
  }

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <section style={styles.content} className="content">
        <div>
          <FullDataTable
            data={data}
            title={"Eventos"}
            columns={columns}
            count={count}
            limit={limit}
            offset={offset}
            sortOrder={sort}
            loading={loading}
            theme={theme}
            onRowPress={(data, meta, rowIndex) => {
              return data[0] !== "" ? props.history.push(`/content/events/edit/${data[0]}`) : null
            }}
            onRowsDelete={async (rows) => {
              await props.deleteWebinar(sites.defaultSite.id, rows);
              makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
              props.fetchWebinars(sites.defaultSite.id, limit, offset);
            }}
            preload={() => props.fetchWebinars(sites.defaultSite.id, limit, offset)}
            sort={customSearch}
            search={customSearch}
            changePage={customSearch}
            changePageSize={customSearch}
            renderCreate={() => (
              <div style={styles.actions}>
                <button onClick={() => toggleCreateModalVisible()} style={styles.actionButton} className="button is-light">
                  <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} /> Crear Evento
              </button>
              </div>

            )}

          />
        </div>
      </section>
      {
        createModalVisible && (
          <ModalRight show={createModalVisible} onClick={() => toggleCreateModalVisible()} hideCloseButton={true}>
            <Create onCloseModal={() => toggleCreateModalVisible(false)} />
          </ModalRight>
        )
      }
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    padding: '15px 21px 26px 26px',
  },
  content: {
    flex: 1,
    minHeight: '80vh',
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 38,
    color: '#024976',
    fontFamily: 'Montserrat',
    lineHeight: '38px'
  },
  count: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: '600',
    color: '#818181'
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  actionButton: {
    fontSize: 14,
    padding: '6px 14px',
    borderRadius: 4
  },
  tableContainer: {
    marginTop: 0
  }
}

const theme = {
  overrides: {
    MuiTable: {
      root: {

      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiToolbar: {
      root: {
        padding: '0px !important',
        marginBottom: 28
      }
    },
    MUIDataTableHeadRow: {
      root: {
        background: '#f6f6f6 !important'
      }
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer'
      }
    },
    MuiTableCell: {
      head: {
        fontFamily: 'Montserrat',
        verticalAlign: 'middle !important',
        fontWeight: '900',
        fontSize: 16,
        background: 'transparent !important',
        border: 'none !important'
      },
      body: {
        textAlign: 'left !important',
        verticalAlign: 'middle !important',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        padding: '.75em .75em !important',
        fontSize: 13,
        '& div': {
          fontFamily: 'Montserrat !important',
          fontWeight: '600',
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)'
        }
      },
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
      footer: {
        paddingRight: '0px !important',
        paddingLeft: '0px !important'
      }
    }
  }
}

const mapStateToProps = ({ webinars, sites }) => {
  return { webinars, sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchWebinars, deleteWebinar, postWebinar }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WebinarList));