import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_USERS = 'FETCHING_USERS';
export const FAIL_FETCH_USERS = 'FAIL_FETCH_USERS';
export const SUCCESS_FETCH_USERS = 'SUCCESS_FETCH_USERS';

export const FETCHING_USER = 'FETCHING_USER';
export const FAIL_FETCH_USER = 'FAIL_FETCH_USER';
export const SUCCESS_FETCH_USER = 'SUCCESS_FETCH_USER';

export const POST_USER = 'POST_USER';
export const POST_FAIL_USER = 'POST_FAIL_USER';
export const POST_SUCCESS_USER = 'POST_SUCCESS_USER';

export const PUT_USER = 'PUT_USER';
export const PUT_FAIL_USER = 'PUT_FAIL_USER';
export const PUT_SUCCESS_USER = 'PUT_SUCCESS_USER';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_FAIL_USER = 'DELETE_FAIL_USER';
export const DELETE_SUCCESS_USER = 'DELETE_SUCCESS_USER';

export const fetchUsers = (siteId, limit, offset = null, column, direction, search, searchtext) => {
  const url = parseUrl(`site/${siteId}/users?`, limit, offset)
  return async dispatch => {
    dispatch({ type: FETCHING_USERS });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_USERS,
        });
      } else {
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_USERS,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_USERS,
      });
    }
  };
};

export const fetchUser = (siteId, user) => {
  return async dispatch => {
    dispatch({ type: FETCHING_USER });
    try {
      const response = await network.get(`site/${siteId}/collab/${user}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_USER,
        });
      } else {
        dispatch({
          user: response.data,
          type: SUCCESS_FETCH_USER,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_USER,
      });
    }
  };
};

export const postUser = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_USER });
    try {
      const response = await network.post(`site/${siteId}/collab`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_USER,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_USER,
      });
    }
  };
};

export const putUser = (id, body) => {
  return async dispatch => {
    dispatch({ type: POST_USER });
    try {
      const response = await network.put(`site/${id}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_USER,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_USER,
      });
    }
  };
};

export const deleteUser = (siteId, ids = []) => {
  const promises = ids.map((item) => {
    return network.del(`site/${siteId}/collab/${item.userId}`);
  });
  return async dispatch => {
    dispatch({ type: DELETE_USER });
    try {
      const response = await Promise.all(promises)
      dispatch({
        data: response.data,
        type: DELETE_SUCCESS_USER,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_USER,
      });
      return error;
    }
  };
};
