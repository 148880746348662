import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { Close, AddBox } from '@material-ui/icons'
import {
  FormControl,
  FormGroup,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from '@material-ui/core'
import { fetchForm, putForm } from '../../../redux/actions/forms'
import { Select, TextArea } from '../StyledComponents'
import { escapeText, makeToast } from '../../../utils'

const useStyles = makeStyles({
  formControlLabelRootCheckbox: {
    margin: 0,
    '& .Mui-checked .MuiIconButton-label': {
      color: '#00ace7 !important'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '500',
      color: 'rgb(2, 73, 118)'
    }
  },
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot: {
    padding: 2
  }
})

const AddField = ({
  editByName,
  sites,
  forms,
  formId,
  show,
  onClose,
  fetchForm,
  putForm
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [type, setType] = useState(null)
  const [required, setRequired] = useState(false)
  const [index, setIndex] = useState(null)
  const [options, setOptions] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    preload()
  }, [])

  const preload = async () => {
    if (editByName) {
      const find = forms.form.fields.findIndex(item => item.name === editByName)
      if (find !== -1) {
        const field = forms.form.fields[find]
        await setIndex(find)
        await setType(field.type)
        await setName(field.name)
        await setTitle(field.title)
        await setOptions(field.options)
        await setRequired(field.required)
      }
    }
    await setLoading(false)
  }

  const validate = () => {
    if (!title || title === '')
      return { success: false, error: 'Título es requerido' }
    if (!editByName) {
      const finded = forms.form.fields.findIndex(item => item.title === title)
      if (editByName && finded !== -1)
        return { success: false, error: 'El nombre debe ser unico' }
    }
    if (!type || type === '') return { success: false, error: 'Tipo requerido' }
    if (type === 'select') {
      if (!options || options === '') return { success: false, error: 'Las opciones son requeridas' }
    }
    return { success: true }
  }

  const parseText = (text = '') => {
    const tempUrl = text.replaceAll(" ", '-');
    const newUrl = escapeText(tempUrl);
    return newUrl;
  }

  const dict = {
    'fullName': true,
    'firstName': true,
    'lastName': true,
  }

  const update = async () => {
    setLoading(true)
    try {
      const newName = name
      if (dict[type]) {
        forms.form.fields[index].name = type
      } else if (!newName) {
        console.log('forms.form.fields[index]', forms.form.fields[index])
        forms.form.fields[index].name = parseText(title)
      }
      forms.form.fields[index].type = type
      forms.form.fields[index].title = title
      forms.form.fields[index].options = options
      forms.form.fields[index].required = required
      const body = {
        ...forms.form,
        fields: [...forms.form.fields]
      }
      await putForm(sites.defaultSite.id, formId, body)
      makeToast(enqueueSnackbar, 'El campo se actualizo correctamente')
      onClose()
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const save = async () => {
    setLoading(true)
    try {
      let newName = name;
      if (dict[type]) {
        newName = type
      } else if (!newName) {
        newName = parseText(title)
      }
      const body = {
        ...forms.form,
        fields: [...forms.form.fields, { title, name: newName, type, options, required }]
      }
      await putForm(sites.defaultSite.id, formId, body)
      makeToast(enqueueSnackbar, 'El campo se guardo correctamente')
      onClose()
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const saveField = async ev => {
    ev.preventDefault()
    const isvalid = validate()
    if (isvalid.success) {
      if (editByName) {
        update()
      } else {
        save()
      }
    } else {
      makeToast(enqueueSnackbar, isvalid.error, 'warning')
    }
  }

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent style={{ paddingBottom: 18 }}>
        <div style={styles.content}>
          <div style={styles.header}>
            <AddBox style={{ fill: '#024976' }} />
            <DialogTitle
              classes={{ root: classes.dialogTitleRoot }}
              id='form-dialog-title'
            >
              {editByName ? 'Editar Campo' : 'Agregar Campo'}
            </DialogTitle>
          </div>
          <IconButton
            classes={{ root: classes.iconButtonRoot }}
            onClick={onClose}
          >
            <Close style={{ fill: '#333333' }} />
          </IconButton>
        </div>
      </DialogContent>
      <div style={{ height: 1.6, width: '100%', backgroundColor: '#e4e4e4' }} />
      <DialogContent style={{ paddingTop: 24 }}>
        <FormControl style={{ width: '100%' }} onSubmit={saveField}>
          <FormGroup>
            <div style={styles.formGroup} className='field'>
              <TextField
                type='text'
                value={title}
                disabled={loading}
                variant='outlined'
                label='Ingresa el título'
                aria-describedby='descriptionHelp'
                onChange={e => setTitle(e.target.value)}
                classes={{ root: classes.textFieldRoot }}
              />
            </div>
          </FormGroup>
          {
            name !== 'email' && (
              <FormGroup>
                <div style={styles.formGroup} className='field'>
                  <div style={styles.subtitle}>Tipo de campo</div>
                  <Select
                    value={type}
                    disabled={loading}
                    style={styles.select}
                    onChange={ev => setType(ev.target.value)}
                  >
                    <option disabled selected='selected'>
                      Ninguna
                    </option>
                    {['text', 'email', 'phone', 'textarea', 'select', 'fullName', 'firstName', 'lastName'].map((item, index) => (
                      <option key={`options-${index}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </div>
              </FormGroup>
            )
          }
          {
            type === 'select' && (
              <FormGroup>
                <div style={styles.field} className='field'>
                <div
                  style={{
                    zIndex: 1,
                    fontSize: 12,
                    job: 'relative',
                    padding: '0px 13px',
                    fontFamily: 'Montserrat',
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}
                >
                  Lista de opciones, una por linea
                </div>
                <TextArea
                  value={options}
                  disabled={loading}
                  aria-describedby='biographyHelp'
                  style={{ height: 200, borderRadius: 14 }}
                  onChange={e => setOptions(e.target.value)}
                  placeholder='Lista de opciones, una por linea'
                />
              </div>
              </FormGroup>
            )
          }
          {
            name !== 'email' && (
              <FormGroup style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 26}}>
                <div style={styles.panelTitle}>Requerido</div>
                <FormControlLabel
                  classes={{ root: classes.formControlLabelRootCheckbox }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={required}
                      onChange={(e) => setRequired(e.target.checked)}
                    />
                  }
                />
              </FormGroup>
            )
          }
        </FormControl>
      </DialogContent>
      <DialogActions style={styles.dialogActions}>
        <button
          onClick={onClose}
          disabled={loading}
          style={styles.button}
          className='button is-secondary'
        >
          Cancelar
        </button>
        <button
          disabled={loading}
          onClick={saveField}
          style={styles.button}
          className='button is-light'
        >
          {loading ? (
            <CircularProgress size={18} style={{ color: '#00A8D8' }}/>
          ) : editByName ? (
            'Guardar'
          ) : (
            'Crear'
          )}
        </button>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  formGroup: {
    textAlign: 'left',
    marginBottom: 20
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    columnGap: 12,
    display: 'flex',
    alignItems: 'center'
  },
  dialogActions: {
    display: 'flex',
    padding: '10px 24px 24px',
    justifyContent: 'space-between'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  button: {
    fontSize: 16,
    width: '48%',
    borderRadius: 8,
    padding: '28px 42px'
  },
  select: {
    height: 56,
    borderRadius: 14,
    backgroundColor: 'white',
    border: 'rgb(232, 232, 232) 1px solid'
  }
}

const mapStateToProps = ({ sites, forms }) => {
  return { sites, forms }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchForm, putForm }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddField)
