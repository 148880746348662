import network from "../../utils/network";

export const FETCHING_CONTENT = 'FETCHING_CONTENT';
export const FAIL_FETCH_CONTENT = 'FAIL_FETCH_CONTENT';
export const SUCCESS_FETCH_CONTENT = 'SUCCESS_FETCH_CONTENT';

export const POST_CONTENT = 'POST_CONTENT';
export const POST_FAIL_CONTENT = 'POST_FAIL_CONTENT';
export const POST_SUCCESS_CONTENT = 'POST_SUCCESS_CONTENT';

export const PUT_CONTENT = 'PUT_CONTENT';
export const PUT_FAIL_CONTENT = 'PUT_FAIL_CONTENT';
export const PUT_SUCCESS_CONTENT = 'PUT_SUCCESS_CONTENT';

export const DELETE_CONTENT = 'DELETE_CONTENT';
export const DELETE_FAIL_CONTENT = 'DELETE_FAIL_CONTENT';
export const DELETE_SUCCESS_CONTENT = 'DELETE_SUCCESS_CONTENT';

export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const UPDATE_FAIL_CONTENT = 'UPDATE_FAIL_CONTENT';
export const UPDATE_SUCCESS_CONTENT = 'UPDATE_SUCCESS_CONTENT';

export const UPDATE_SORT_CONTENT = 'UPDATE_SORT_CONTENT';

export const fetchContent = (siteId, course, module) => {
  return async dispatch => {
    dispatch({ type: FETCHING_CONTENT });
    try {
      const response = await network.get(`site/${siteId}/course/${course}/modules/${module}/content`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_CONTENT,
        });
      } else {
        const ordered = response.data.sort((a, b) => (a.order - b.order));
        dispatch({
          data: ordered,
          parent: module,
          type: SUCCESS_FETCH_CONTENT,
        });
        return ordered;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_CONTENT,
      });
    }
  };
};

export const postContent = (siteId, course, module, body) => {
  return async dispatch => {
    dispatch({ type: POST_CONTENT });
    try {
      const { data } = await network.post(`site/${siteId}/course/${course}/modules/${module}/content`, body);
      dispatch({
        data,
        type: POST_SUCCESS_CONTENT,
      });
      return data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_CONTENT,
      });
      return error;
    }
  };
};

export const deleteContent = (siteId, course, module, id) => {
  return async dispatch => {
    dispatch({ type: DELETE_CONTENT });
    try {
      await network.del(`site/${siteId}/course/${course}/modules/${module}/content/${id}`);
      dispatch({
        type: DELETE_SUCCESS_CONTENT,
      });
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_CONTENT,
      });
    }
  };
};

export const updateContent = (siteId, course, module, id, body) => {
  return async dispatch => {
    dispatch({ type: UPDATE_CONTENT });
    try {
      await network.put(`site/${siteId}/course/${course}/modules/${module}/content/${id}`, body);
      dispatch({
        type: UPDATE_SUCCESS_CONTENT,
      });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_CONTENT,
      });
    }
  };
};

export const updateContentSortLocal = (newSort, parent) => {
  return async dispatch => {
    try {
      dispatch({
        parent,
        data: newSort,
        type: UPDATE_SORT_CONTENT,
      });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_CONTENT,
      });

    }
  };
}

export const updateContentSort = (site, course, newSort) => {
  return async dispatch => {
    try {
      const contents = newSort.map((item, order) => ({ ...item, order }));
      await network.put(`site/${site}/course/${course}/modules/orderContent`, { contents });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_CONTENT,
      });

    }
  };
}