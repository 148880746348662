import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress, makeStyles } from '@material-ui/core'
import { DebounceTableSearch } from './SearchGrid'

const styles = {
  container: {
    display: 'flex',
    minHeight: '90vh',
    flexDirection: 'column'
  }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function InfiniteList(props) {
  const classes = useStyles();
  const [option, setOption] = useState([])
  const { loading, page, limit, hasMore, data, customRenderBlocks, infiniteScrollLoad } = props

  const loadData = async () => {
    if (!loading) {
      try {
        await infiniteScrollLoad(
          limit,
          page,
        )
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const parsedTags = () => {
    const parsedTags = []
    for (let i = 0; i < option.length; i++) {
      const tagSelected = option[i];
      const exists = props.tags.find(item => item.name === tagSelected)
      if (exists) {
        parsedTags.push(exists.id)
      }
    }
    return parsedTags;
  }

  useEffect(() => {
    if (option.length) {
      const tags = parsedTags()
      props.customSearchTags(tags)
    }
  }, [option])


  const loadDebounce = _.debounce(loadData, 2000)

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <DebounceTableSearch
          debounceWait={500}
          onSearch={props.customSearch}
        />
        {/* {
          props.searchByTags ? (
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">Filtro por etiqueta</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={option}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {props.tags && props.tags.map((item) => (
                  <MenuItem key={item.name}  value={item.name}>
                    <Checkbox checked={option.indexOf(item.name) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (<div/>)
        } */}
      </div>
      <InfiniteScroll
        pageStart={page}
        hasMore={hasMore}
        loadMore={loadDebounce}
        loader={
          <div style={{ width: '100%' }}>
            <CircularProgress style={{ color: '#00A8D8' }} />
          </div>
        }
        className='video-infinite-scroll'
      >
        {data.map(customRenderBlocks)}
      </InfiniteScroll>
    </div>
  )
}

InfiniteList.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.number.isRequired,
  customSearch: PropTypes.func.isRequired,
  renderCustomSearch: PropTypes.func.isRequired,
  infiniteScrollLoad: PropTypes.func.isRequired,
  customRenderBlocks: PropTypes.func.isRequired,
}

export default InfiniteList
