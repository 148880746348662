import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { Close } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom'
import {
  FormControl,
  FormGroup,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
  IconButton
} from '@material-ui/core'
import { fetchPages, postPage } from '../../../redux/actions/pages'
import { fetchAllTemplates } from '../../../redux/actions/templates'
import { escapeText, makeToast } from '../../../utils'
import { Label, Input } from '../StyledComponents'

const images = {
  '1': '/images/template1.jpg',
  '2': '/images/template2.jpg',
  '3': '/images/template3.jpg',
  '4': '/images/template4.jpg',
  '5': '/images/simple.png',
}

const useStyles = makeStyles(theme => ({
  formControlLabelRoot: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: '#9e9e9e'
    },
    '& .Mui-checked + span.MuiTypography-root': {
      color: '#00ace7'
    }
  },
  radioGroupRoot: {
    flexDirection: 'row',
    columnGap: 28
  },
  radioRoot: {
    '&:not(.Mui-checked) .MuiIconButton-label': {
      color: 'white'
    },
    '&.Mui-checked .MuiIconButton-label': {
      color: 'transparent'
    },
    '&.Mui-checked .MuiIconButton-label div svg:first-child': {
      backgroundColor: '#00ace7',
      borderRadius: '50%'
    },
    '&.Mui-checked .MuiIconButton-label div svg:last-child': {
      fill: 'white'
    }
  }
}))

function UserCreate (props) {
  const { templates, sites } = props

  useEffect(() => {
    props.fetchAllTemplates(sites.defaultSite.id)
  }, [])

  const classes = useStyles()
  const history = useHistory()

  const [title, setTitle] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [template, setTemplate] = useState(1)
  const [loading, setLoading] = useState(false)

  const cleanInputs = () => {
    setTitle('')
  }

  const parseUrl = (url = '') => {
    const tempUrl = url.replaceAll(' ', '-')
    const newUrl = escapeText(tempUrl)
    return newUrl
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    if (title.trim() !== '' && template) {
      try {
        await setLoading(true)
        const siteId = sites.defaultSite.id
        const newUrl = parseUrl(title)
        const response = await props.postPage(siteId, {
          title: newUrl,
          type: 'Landing',
          templateId: template,
          config: {
            seo: { url: newUrl, title }
          }
        })
        history.push(`/marketing/pages/view/${response.id}`)
        cleanInputs()
      } catch (error) {
        makeToast(enqueueSnackbar, 'Error creando página', 'error')
        console.log('handleSubmit', error)
      } finally {
        setLoading(false)
      }
    } else {
      makeToast(enqueueSnackbar, 'Los datos son requeridos', 'warning')
    }
  }

  return (
    <>
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.title}>Crear Página</div>
          <IconButton onClick={() => props.onCloseModal()}>
            <Close style={styles.close} />
          </IconButton>
        </div>

        <div style={styles.content}>
          <FormControl
            style={{ width: '100%' }}
            onSubmit={e => handleSubmit(e)}
          >
            <FormGroup>
              <div style={styles.field} className='field'>
                <Label style={styles.label}>Título</Label>
                <Input
                  type='text'
                  id='title'
                  value={title}
                  disabled={loading}
                  style={styles.input}
                  placeholder='Introduce el título'
                  aria-describedby='titleHelp'
                  onChange={e => setTitle(e.target.value)}
                />
              </div>
            </FormGroup>

            {templates.loading ? (
              <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
            ) : (
              <FormGroup>
                <div style={styles.field} className='field'>
                  <Label
                    style={{
                      ...styles.label,
                      color: '#024976',
                      fontSize: 16,
                      fontFamily: 'Montserrat',
                      fontWeight: '900'
                    }}
                  >
                    Elegir una plantilla
                  </Label>

                  <RadioGroup
                    aria-label='template'
                    name='template'
                    value={template}
                    style={styles.templateList}
                    classes={{ root: classes.radioGroupRoot }}
                    onChange={ev => {
                      setTemplate(parseInt(ev.target.value))
                    }}
                  >
                    {templates.all.map(element => {
                      return (
                        <FormControlLabel
                          classes={{ root: classes.formControlLabelRoot }}
                          key={element.id}
                          style={{ margin: 0, width: '48%' }}
                          value={element.id}
                          control={
                            <TemplateItem
                              style={{
                                ...(template === element.id &&
                                  styles.templateItemActive),
                                backgroundImage: `url(${images[`${element.id}`]})`
                              }}
                            >
                              <div style={styles.templateItemFilter} />
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  backgroundColor: 'rgb(2, 73, 118)'
                                }}
                              >
                                <b style={{ color: '#fff' }}>{element.name}</b>
                                <Radio
                                  classes={{ root: classes.radioRoot }}
                                  value={element.id}
                                />
                              </div>
                            </TemplateItem>
                          }
                        />
                      )
                    })}
                  </RadioGroup>
                </div>
              </FormGroup>
            )}
          </FormControl>
        </div>

        <div style={styles.actions}>
          <button
            disabled={loading}
            onClick={e => handleSubmit(e)}
            style={styles.actionButton}
            className='button is-light'
          >
            {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Crear'}
          </button>

          <button
            onClick={() => props.onCloseModal()}
            style={styles.actionButton}
            className='button is-secondary'
          >
            Cancelar
          </button>
        </div>
      </div>
    </>
  )
}

const TemplateItem = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 3px white solid;
  background-position-y: 0%;
  transition: border 0.4s, opacity 0.4s;
  &:hover {
    animation: auto-scroll-animation 10s infinite;
    opacity: 0.7;
  }
  @keyframes auto-scroll-animation {
    0% {
      background-position-y: 0%;
    }
    50% {
      background-position-y: 100%;
    }
    100% {
      background-position-y: 0%;
    }
  }
`

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    backgroundColor: '#024976',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100,
    padding: '0px 30px',
    flexGrow: 0,
    flexShrink: 0
  },
  title: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: '600'
  },
  close: {
    fill: 'white'
  },
  content: {
    padding: '30px 30px 0px 30px',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '15px 30px 0px 30px'
  },
  actionButton: {
    fontSize: 16,
    padding: '28px 42px',
    borderRadius: 8,
    marginBottom: 15
  },
  templateList: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    columnGap: 9,
    rowGap: 15
  },
  templateItemActive: {
    border: '3px #00ace7 solid'
  },
  templateItemFilter: {
    position: 'absolute',
    top: -3,
    left: -3,
    width: 'calc(100% + 6px)',
    height: 'calc(100% + 6px)',
    borderRadius: '10px',
    backgroundImage: 'linear-gradient(0deg, #0000002e, transparent)'
  }
}

const mapStateToProps = ({ sites, pages, templates }) => {
  return { sites, pages, templates }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { fetchPages, postPage, fetchAllTemplates },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
