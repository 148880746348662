import {
  FETCH_FORM_ENTRIES,
  FAIL_FETCH_FORM_ENTRIES,
  SUCCESS_FETCH_FORM_ENTRIES,
  FETCH_FORMS,
  FAIL_FETCH_FORMS,
  SUCCESS_FETCH_FORMS,
  SUCCESS_FETCH_FORMS_TABLE,
  FETCHING_FORM,
  FAIL_FETCHING_FORM,
  SUCCESS_FETCHING_FORM,
  UPDATE_SORT_FORM,
  PUT_FORM,
  PUT_FAIL_FORM,
  PUT_SUCCESS_FORM,
  DELETE_FORM,
  DELETE_FAIL_FORM,
  DELETE_SUCCESS_FORM
} from '../actions/forms'

const initialState = {
  data: [],
  error: null,
  loading: false,
  form: {
    fields: [],
  },
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
  entries: {
    data: [],
    order: {},
    count: 0,
    limit: 25,
    offset: 0,
    error: null,
    loading: false,
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case FAIL_FETCH_FORMS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SUCCESS_FETCH_FORMS:
      return {
        ...state,
        error: null,
        loading: false,
        data: action.data,
      };
    case SUCCESS_FETCH_FORMS_TABLE:
      return {
        ...state,
        error: null,
        loading: false,
        ...action.data,
      };
    case FETCH_FORM_ENTRIES:
      return {
        ...state,
        entries: {
          ...state.entries,
          error: null,
          loading: true,
        }
      };
    case FAIL_FETCH_FORM_ENTRIES:
      return {
        ...state,
        loading: false,
        entries: {
          ...state.entries,
          loading: false,
          error: action.error,
        }
      };
    case SUCCESS_FETCH_FORM_ENTRIES:
      return {
        ...state,
        entries: {
          ...state.entries,
          error: null,
          loading: false,
          ...action.data,
        }
      };
    case FETCHING_FORM:
      return {
        ...state,
        error: null,
        loading: true,
        form: initialState.form,
      };
    case SUCCESS_FETCHING_FORM:
      return {
        ...state,
        loading: false,
        form: action.data,
        error: action.error,
      };
    case FAIL_FETCHING_FORM:
      return {
        ...state,
        loading: false,
        error: action.error,
        form: initialState.form,
      };
    case PUT_FORM:
      return {
        ...state,
        error: null,
      };
    case PUT_SUCCESS_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case PUT_FAIL_FORM:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_SORT_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          fields: action.data
        },
      };
    case DELETE_FORM:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_FAIL_FORM:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SUCCESS_FORM:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
