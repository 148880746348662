import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Chart from 'chart.js'
import { bindActionCreators } from 'redux';
import { Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { Modal, makeStyles, CircularProgress } from '@material-ui/core';
import { fetchVideos, putVideo, fetchVideo } from '../../../redux/actions/videos';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 20,
    top: '50%',
    left: '50%',
    width: '50%',
    borderRadius: 4,
    minHeight: '40vh',
    overflow: 'hidden',
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'absolute',
    boxShadow: theme.shadows[5],
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
    },
  },
}))

function CourseCreate(props) {

  const { sites, edit } = props
  const { videos: { data } } = props;
  const classes = useStyles()

  const [video, setVideo] = useState({});
  const [visits, setVisits] = useState(0)
  const [loading, setLoading] = useState(true);
  const [tagsSelected, setTagsSelected] = useState([]);

  useEffect(() => {
    if (edit) {
      preload()
    }
  }, [])

  const preload = async () => {
    const videoData = await props.fetchVideo(sites.defaultSite.id, edit)
    const video = data.find(item => edit === item.id)
    const tags = video.tags.map(item => item.name)
    await setVideo(videoData)
    await setTagsSelected(tags)
    await setLoading(false)
    parseAnalitycs(videoData.statics)
  }

  const parseAnalitycs = (response = []) => {
    if (response && response.length) {
      let count = 0;
      const parsed = response.map(item => {
        count += +item.count
        return ({
          x: item.month,
          y: +item.count
        })
      })
      setVisits(count)
      const labels = parsed.map(item => item.x)
      const ctx = document.getElementById('analitycs')
      if (ctx)
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              data: parsed,
              borderColor: '#00ACE750',
              pointBorderColor: '#00ACE7',
              backgroundColor: '#00ACE750',
              label: 'Visitas',
              pointBackgroundColor: '#fff'
            }
          ]
        },
        options: {
          responsive: true,
          title: 'Visitas',
          legend: {
            display: false
          }
        }
      })
    }
  }

  return (
    <Modal open={props.open} onClose={props.onCloseModal}>
      <div className={classes.paper}>
        <div style={styles.container}>
          <div style={styles.header}>
            <div style={styles.name}>{video.name}</div>
            <IconButton onClick={() => props.onCloseModal()}>
              <Close style={styles.close} />
            </IconButton>
          </div>
          <div style={styles.content}>
            <div style={styles.text}>Vista previa</div>
            <div style={{width: '100%', height: 280, display: 'flex', paddingTop: 10, paddingBottom: 10}}>
              {
                loading ? (
                  <CircularProgress size={20} style={{ color: '#00A8D8' }}/>
                ) : (
                  <video src={video.url} style={{width: '100%', height: 250}} controls />
                )
              }
            </div>
            <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', paddingTop: 10, paddingBottom: 10}}>
              {tagsSelected.map((item, index) => (
                <div key={`tag-${index}`} style={{borderRadius: 16, marginRight: 10, padding: 5, backgroundColor: 'rgba(224, 224, 224, 1)'}}>
                  {item}
                </div>
              ))}
            </div>
            <div style={{display: 'flex', width: '100%', paddingBottom: 10, flexDirection: 'column'}}>
              <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={styles.text}>Visitas</div>
                <div style={styles.text}>{visits}</div>
              </div>
              <div className="padding-10">
                <canvas id='analitycs' height="50" />
              </div>
            </div>
          </div>
        </div>
        <div style={styles.actions}>
          <div />
          <button
            disabled={loading}
            className="button is-light"
            stedityle={styles.actionButton}
            onClick={() => props.onEdit()}
          >
            Editar
          </button>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 22,
    color: '#004976',
    fontWeight: '600',
    textAlign: 'left',
    fontFamily: 'Montserrat',
  },
  text: {
    fontSize: 18,
    color: '#004976',
    textAlign: 'left',
    fontFamily: 'Montserrat',
  },
  close: {
    fill: '#000'
  },
  content: {
    padding: 1,
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#004976',
    fontSize: 14,
    marginBottom: 10,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 5
  },
  actions: {
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px 0px 0px 0px',
    justifyContent: 'flex-end',
  },
  actionButton: {
    fontSize: 16,
    color: '#fff',
    borderRadius: 4,
  },
  actionButtonCancel: {
    fontSize: 16,
    color: '#fff',
    borderRadius: 4,
    backgroundColor: '#00C18A',
  },
  structureList: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 9
  },
}

const mapStateToProps = ({ sites, videos }) => {
  return { sites, videos };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ putVideo, fetchVideos, fetchVideo }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreate);