import network from "../../utils/network";

export const FETCHING_MODULES = 'FETCHING_MODULES';
export const FAIL_FETCH_MODULES = 'FAIL_FETCH_MODULES';
export const SUCCESS_FETCH_MODULES = 'SUCCESS_FETCH_MODULES';

export const POST_MODULE = 'POST_MODULE';
export const POST_FAIL_MODULE = 'POST_FAIL_MODULE';
export const POST_SUCCESS_MODULE = 'POST_SUCCESS_MODULE';

export const PUT_MODULE = 'PUT_MODULE';
export const PUT_FAIL_MODULE = 'PUT_FAIL_MODULE';
export const PUT_SUCCESS_MODULE = 'PUT_SUCCESS_MODULE';

export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_FAIL_MODULE = 'DELETE_FAIL_MODULE';
export const DELETE_SUCCESS_MODULE = 'DELETE_SUCCESS_MODULE';

export const UPDATE_MODULE = 'UPDATE_MODULE';
export const UPDATE_FAIL_MODULE = 'UPDATE_FAIL_MODULE';
export const UPDATE_SUCCESS_MODULE = 'UPDATE_SUCCESS_MODULE';

export const UPDATE_SORT = 'UPDATE_SORT';

export const fetchModules = (site, course, parent) => {
  return async dispatch => {
    dispatch({ type: FETCHING_MODULES });
    try {
      const response = await network.get(`site/${site}/course/${course}/modules?parentModule=${parent}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_MODULES,
        });
      } else {
        const newArray = response.data.filter(item => (!item.parentModule));
        const ordered = newArray.sort((a, b) => (a.order - b.order));
        dispatch({
          data: ordered,
          type: SUCCESS_FETCH_MODULES,
        });
        return ordered;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_MODULES,
      });
      return error;
    }
  };
};

export const postModule = (site, course, body) => {
  return async dispatch => {
    dispatch({ type: POST_MODULE });
    try {
      const { data } = await network.post(`site/${site}/course/${course}/modules`, body);
      dispatch({
        data,
        type: POST_SUCCESS_MODULE,
      });
      return data;
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_MODULE,
      });
      return error;
    }
  };
};

export const deleteModule = (site, course, id) => {
  return async dispatch => {
    dispatch({ type: DELETE_MODULE });
    try {
      await network.del(`site/${site}/course/${course}/modules/${id}`);
      dispatch({
        type: DELETE_SUCCESS_MODULE,
      });
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_MODULE,
      });
    }
  };
};

export const updateModule = (site, course, id, body) => {
  return async dispatch => {
    dispatch({ type: UPDATE_MODULE });
    try {
      await network.put(`site/${site}/course/${course}/modules/${id}`, body);
      dispatch({
        type: UPDATE_SUCCESS_MODULE,
      });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_MODULE,
      });
    }
  };
};

export const updateSortLocal = (newSort) => {
  return async dispatch => {
    try {
      dispatch({
        data: newSort,
        type: UPDATE_SORT,
      });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_MODULE,
      });
    }
  };
}

export const updateSort = (site, course, newSort) => {
  return async dispatch => {
    try {
      const modules = newSort.map((item, order) => ({ ...item, order }));
      await network.put(`site/${site}/course/${course}/modules/orderModules`, { modules });
    } catch (error) {
      dispatch({
        error,
        type: UPDATE_FAIL_MODULE,
      });
    }
  };
}