import React, { createContext } from "react";
import network from '../../utils/network';
import { data as templateData } from '../templates/template1/src/data'
import { data as landingsData } from '../templates/template1/src/dataLanding'

export const PersistentContext = createContext();

export class PersistentContextProvider extends React.Component {
  state = {
    id: null,
    data: null,
    keys: null,
    config: null,
    loading: true,
    production: false,
    templateData: null
  };

  componentDidMount() {
    this.preload();
  }

  preload = async () => {
    try {
      const { id, page, param1 } = this.props;
      if (id) {
        let newUrl = page
        if (param1) {
          switch (page) {
            case 'course-detail':
              newUrl = `${page}-${param1}`
              break;
            case 'event-detail':
              newUrl = `${page}-${param1}`
              break;
            default: 
              newUrl = page
              break;
          }
        }
        const { data } = await network.get(`site/${id}/pages/${newUrl}`);
        let config = {}
        if (data.config) {
          config = data.config
        }
        this.setState({ loading: false, data: data.data, config, templateData });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  getItemDataById = (key, id) => {
    const { data, templateData } = this.state;
    const newKey = `${key}-${id}`
    if (data && data[newKey] && Object.keys(data[newKey]).length) {
      return data[newKey];
    } else if (data && data[key] && Object.keys(data[key]).length) {
      return data[key];
    } else {
      return templateData[key];
    }
  }

  getItemData = (key, landing = false) => {
    const { data } = this.state;
    if (data && data[key] && Object.keys(data[key]).length) {
      return data[key];
    } else if (landing) {
      return landingsData[key];
    } else if (templateData) {
      return templateData[key];
    }
  }

  getItemConfig = (key) => {
    const { config } = this.state;
    if (config && config[key] && Object.keys(config[key]).length) {
      return config[key];
    } else if (templateData) {
      return templateData[key];
    }
  }

  saveContent = async (newData, key = null) => {
    try {
      const { id, page, param1 } = this.props;
      const newUrl = param1 ? `${page}-${param1}` : page
      const { data } = this.state;
      if (id) {
        await network.put(`site/${id}/pages/${newUrl}`, { data: {...data, ...newData} });
        await this.setState({ data: {...data, ...newData} });
        return true;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  saveSiteConfig = async (newData, key = null) => {
    try {
      const { id } = this.props;
      const { config } = this.state;
      if (id) {
        await network.put(`site/${id}/pages/config`, { config: {...config, ...newData} });
        await this.setState({ config: {...config, ...newData} });
        return true;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  render() {
    const { id, page, template } = this.props;
    const { loading, data, templateData, production, config } = this.state;

    return (
      <PersistentContext.Provider
        value={{
          id,
          data,
          page,
          config,
          loading,
          template,
          production,
          templateData,
          loadSites: this.loadSites,
          getItemData: this.getItemData,
          saveContent: this.saveContent,
          getItemConfig: this.getItemConfig,
          saveSiteConfig: this.saveSiteConfig,
          getItemDataById: this.getItemDataById,
        }}
      >
        {this.props.children}
      </PersistentContext.Provider>
    );
  }
};