import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const defaultStyles = theme => ({
  main: {
    display: 'flex',
    width: 330,
    position: 'relative'
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    right: 10,
    top: 5,
    zIndex: 1,
    width: 28,
    height: 28,
    fill: '#024976'
  },
  searchText: {},
  input: {
    background: '#f6f6f6',
    height: 60,
    color: '#979797',
    border: 'none',
    borderRadius: 10,
    width: 335,
    height: 40,
    padding: '0px 20px',
    fontFamily: 'Montserrat',
    fontSize: 16,
    transition: 'background 0.4s'
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class _DebounceTableSearch extends React.Component {
  handleTextChangeWrapper = debouncedSearch => {
    return function (event) {
      debouncedSearch(event.target.value);
    };
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = event => {
    if (event.keyCode === 27) {
      this.props.onHide();
    }
  };

  render() {
    const { classes, options, onHide, searchText, debounceWait, loading } = this.props;

    const debouncedSearch = debounce(value => {
      this.props.onSearch(value);
    }, debounceWait);

    return (
      <div className={classes.main}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          disabled={loading}
          className={classes.searchText}
          autoFocus={true}
          InputProps={{
            'data-test-id': options.textLabels.toolbar.search,
            'aria-label': options.textLabels.toolbar.search,
            className: classes.input,
            disableUnderline: true
          }}
          defaultValue={searchText === ' ' ? '' : searchText}
          onChange={this.handleTextChangeWrapper(debouncedSearch)}
          fullWidth={true}
          inputRef={el => (this.searchField = el)}
          label={options.searchPlaceholder}
          placeholder={options.searchPlaceholder ? options.searchPlaceholder : 'Busca aquí'}
          {...(options.searchProps ? options.searchProps : {})}
        />
      </div>
    );
  }
}

var DebounceTableSearch = withStyles(defaultStyles, { name: 'MUIDataTableSearch' })(_DebounceTableSearch);
export { DebounceTableSearch };

export function debounceSearchRender(debounceWait = 200) {
  return (searchText, handleSearch, hideSearch, options) => {
    return (
      <DebounceTableSearch
        searchText={searchText}
        onSearch={handleSearch}
        onHide={hideSearch}
        options={options}
        debounceWait={debounceWait}
      />
    );
  };
}