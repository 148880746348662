import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles, Checkbox } from '@material-ui/core';
import { FormGroup, FormLabel, FormControlLabel, CircularProgress } from '@material-ui/core';
import { getContentInstructors, postContentInstructors } from '../../../../helpers/courses';
import { fetchInstructors } from '../../../../redux/actions/instructors';
import '../../../../App.css';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minWidth: 420
  },
  iconButtonRoot: {
    padding: 2
  },
  formLabelRoot: {
    fontSize: 16,
    color: '#024976',
    fontFamily: 'Montserrat',
    fontWeight: '900'
  },
  formControlLabelRoot: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontFamily: 'Montserrat',
      color: '#9e9e9e'
    },
    '& .Mui-checked + span.MuiTypography-root': {
      color: '#00ace7'
    }
  },
  radioGroupRoot: {
    flexDirection: 'row',
    columnGap: 28
  },
  radioRoot: {
    '&:not(.Mui-checked) .MuiIconButton-label': {
      color: 'gainsboro'
    },
    '&.Mui-checked .MuiIconButton-label': {
      color: 'transparent'
    },
    '&.Mui-checked .MuiIconButton-label div svg:first-child': {
      backgroundColor: '#00ace7',
      borderRadius: '50%'
    },
    '&.Mui-checked .MuiIconButton-label div svg:last-child': {
      fill: 'white'
    }
  },
  indicator: {
    backgroundColor: "#555",
  },
  root: {
    color: '#000',
    backgroundColor: "#ddd",
  },
  paper: {
    width: 400,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper,
  },
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
}));

const CheckInstructors = (props) => {

  const { site, course, content } = props;
  const { fetchInstructors } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [instructores, setInstructores] = useState([]);

  useEffect(() => {
    preload();
  }, []);

  const preload = async () => {
    try {
      const { data } = await fetchInstructors(site);
      const response = await getContentInstructors(site, course, content);
      const result = [];
      for (const instructor of data) {
        const finded = response.data.find(item => (item.id === instructor.id));
        if (finded) {
          result.push({ id: instructor.id, name: instructor.name, checked: true });
        } else {
          result.push({ id: instructor.id, name: instructor.name, checked: false });
        }
      }
      await setInstructores([...result]);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false)
    }
  }

  const onChange = async (ev) => {
    try {
      for (let index = 0; index < instructores.length; index++) {
        if (ev.target.name === instructores[index].id) {
          instructores[index].checked = ev.target.checked;
        }
      }
      await setInstructores([...instructores]);
      const ids = instructores.filter(item => item.checked).map(item => item.id);
      if (props.onChange) {
        await props.onChange(ids)
      } else {
        await postContentInstructors(site, { ids, contentId: content, courseId: course });
      }
      await preload();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <FormGroup style={{ marginTop: 20 }}>
      <FormLabel classes={{ root: classes.formLabelRoot }}>Instructores</FormLabel>
      <FormGroup>
        {
          loading ? (
            <CircularProgress size={20} style={{ color: '#00A8D8' }} />
          ) : (
            <>
              {
                instructores.length ? instructores.map((item) => (
                  <FormControlLabel
                    label={item.name}
                    control={<Checkbox checked={item.checked} onChange={onChange} name={item.id} />}
                  />
                )) : 'No hay instructores agregados al sitio'
              }
            </>
          )
        }
      </FormGroup>
    </FormGroup>
  );
}

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchInstructors }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckInstructors);
