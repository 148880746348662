import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack'
import { Close, AddBox } from '@material-ui/icons';
import { FormControl, FormGroup, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, makeStyles, IconButton } from '@material-ui/core';
import { getModule } from '../../../../helpers/courses';
import { Input, TextArea } from '../../StyledComponents';
import { makeToast, filterChanges } from '../../../../utils'

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: 420
  },
  dialogTitleRoot: {
    margin: '0px !important',
    padding: '0px !important',
    '& h2': {
      fontSize: 16,
      color: '#024976',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontWeight: '900'
    }
  },
  iconButtonRoot:{
    padding: 2
  }
});



const AddModule = ({ id, course, site, show, onClose, onSave, onUpdate }) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (show && id) {
      preload();
    }
  }, []);

  const preload = async () => {
    await setLoading(true);
    const data = await getModule(site, course, id);
    await setData(data)
    await setTitle(data.title);
    await setDescription(data.description);
    await setLoading(false);
  }

  const clean = async () => {
    await setTitle('');
    await setDescription('');
  }

  const saveModule = async () => {
    try {
      await setLoading(true);
      if (!id) {
        await onSave({ title, description });
      } else {
        const newBody = filterChanges({ title, description }, data);
        await onUpdate(id, newBody);
      }
      await clean();
    } catch (error) {
      console.log(error);
      makeToast(enqueueSnackbar, error, 'error')
    } finally {
      await setLoading(false);
    }
  }

  const onSaveModule = async () => {
    if (title.trim !== '') {
      await saveModule();
    } else {
      makeToast(enqueueSnackbar, 'El título es requerido', 'warning')
    }
  }

  return (
    <Dialog classes={{paper:classes.dialogPaper}} open={show} onClose={onClose} aria-labelledby="form-dialog-title">
      
      <DialogContent style={{paddingBottom: 18}}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{display:'flex', columnGap: 12, alignItems: 'center'}}>
            <AddBox style={{fill: '#024976'}}/>
            <DialogTitle classes={{root:classes.dialogTitleRoot}} id="form-dialog-title">{id ? 'Editar módulo' : 'Agregar módulo'}</DialogTitle>
          </div>
          <IconButton classes={{root:classes.iconButtonRoot}} onClick={onClose}>
            <Close style={{fill: '#333333'}}/>
          </IconButton>
        </div>
      </DialogContent>
      <div style={{height: 1.6, width: '100%', backgroundColor: '#e4e4e4'}}/>
      <DialogContent style={{paddingTop: 24}}>
        <FormControl style={{ width: '100%' }} onSubmit={onSaveModule}>

          <FormGroup>
            <div style={{ textAlign: 'left', marginBottom: 20}} className="field">
              <Input
                type="text"
                id="name"
                value={title}
                disabled={loading}
                style={{ height: 56, borderRadius: 14}}
                placeholder="Ingresa el Título del Módulo"
                aria-describedby="nameHelp"
                onChange={(e)=>setTitle(e.target.value)}
              />
            </div>
          </FormGroup>
          
          <FormGroup>
            <div style={{ textAlign: 'left', marginBottom: 20}} className="field">
              <TextArea
                id="description"
                value={description}
                disabled={loading}
                style={{ height: 56, borderRadius: 14}}
                placeholder="Ingresa la Descripción del Módulo"
                aria-describedby="descriptionHelp"
                onChange={(e)=>setDescription(e.target.value)}
              />
            </div> 
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px 24px' }}>
        
        <button 
          onClick={onClose}
          disabled={loading}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }}
          className="button is-secondary"
        >
          Cancelar
        </button>

        <button 
          disabled={loading} 
          onClick={onSaveModule}
          style={{ fontSize: 16, padding: '28px 42px', borderRadius: 8, width: '48%' }} 
          className="button is-light"
        >
          {loading ? <CircularProgress size={18} style={{ color: '#00A8D8' }} /> : id ? 'Guardar' : 'Crear'}
        </button>

      </DialogActions>
    </Dialog>
  );
}

export default AddModule;