import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <div>L3RN.com - Tu propia academia digital (v0.3)</div>
        <a>Terminos y condiciones</a> - <a>Política de privacidad</a> -{" "}
        <a>Política de cookies</a>
      </div>
    </footer>
  );
}
