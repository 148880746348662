import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { ReactSortable } from 'react-sortablejs'
import { KeyboardBackspace } from '@material-ui/icons'
import {
  CircularProgress,
  FormControl,
  makeStyles,
  FormGroup,
  TextField,
  Button,
  AppBar,
  Tab,
  Tabs,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import {
  putForm,
  fetchForm,
  getLandings,
  updateFormSortLocal,
} from '../../../redux/actions/forms'
import { TabPanel } from '../courses/ModulesTabs'
import { TextArea, Select } from '../StyledComponents'
import { makeToast } from '../../../utils'
import FieldItem from './FieldItem'
import Registros from './Registros'
import AddField from './AddField'
import Actions from './Actions'
import Loader from '../Loader'

const paginas = [
  { key: "home", name: "Inicio" },
  { key: "sign-in", name: "Login" },
  { key: "sign-up", name: "Registro" },
  { key: "forgot-password", name: "Recuperar Contraseña" },
  { key: "contact", name: "Contacto" },
  { key: "not-found", name: "404 No encontrado" },
  { key: "faq", name: "Preguntas Frecuentes" },
  { key: "about", name: "Nosotros" },
  { key: "course-catalog", name: "Catálogo" },
  { key: "cart", name: "Carrito de compras" },
  { key: "order-completed", name: "Orden completada" },
  { key: "my-courses", name: "Mis Cursos" },
  { key: "my-favorites", name: "Mis Favoritos" },
  { key: "category", name: "Categorías" },
  { key: "terms", name: "Términos y Condiciones" },
  { key: "legal-advice", name: "Aviso Legal" },
  { key: "privacy-policy", name: "Política de Privacidad" },
  { key: "refund-policy", name: "Política de Reembolso" }
];

const useStyles = makeStyles(theme => ({
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  },
  appBarRoot: {
    padding: 20,
    boxShadow: 'none',
    backgroundColor: '#fff'
  },
  tabsRoot: {
    float: 'left',
    backgroundColor: 'white'
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    fontFamily: 'Montserrat',
    backgroundColor: 'rgba(33,150,243,0.08)',
    color: '#2196F3',
    fontWeight: 'bold',
    border: 0,
    textTransform: 'none',
    transition: 'background-color 0.4s',
    fontSize: 16,
    marginRight: 12,
    height: 40,
    minHeight: 40,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: 'rgba(33,150,243,0.2)',
      color: '#2196F3'
    },
    '&[aria-selected="false"]': {
      color: '#2196F3',
      backgroundColor: 'white'
    }
  },
  paper: {
    width: 400,
    position: 'absolute',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  }
}))

function UserCreate (props) {
  const { sites, forms } = props
  const classes = useStyles()
  const { form } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [data, setData] = useState({})
  const [urls, setUrls] = useState([])
  const [title, setTitle] = useState('')
  const [type, setType] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const [redirect, setRedirect] = useState('')
  const [loading, setLoading] = useState(true)
  const [soporte, setSoporte] = useState(false)
  const [userReady, setUserReady] = useState(false)
  const [enableEdit, setEnableEdit] = useState(false)
  const [modalCreate, setModalCreate] = useState(false)
  const [description, setDescription] = useState('')

  const preload = async () => {
    if (form) {
      const data = await props.fetchForm(sites.defaultSite.id, form)
      if (data) {
        await setData(data)
        await setTitle(data.title)
        await setSoporte(data.support)
        await setDescription(data.description)
        const landings = await getLandings(sites.defaultSite.id)
        const parse = landings.data.map(item => ({name: item.title, key: item.title}))
        const filter = [...paginas, ...parse]
        await setUrls(filter)
        if (data.redirect && data.redirect.trim() !== '') {
          let defaultType = '0'
          const exists = filter.find(item => item.name === data.redirect)
          if (exists) {
            defaultType = '1'
          }
          await setType(defaultType)
        }
        await setRedirect(data.redirect)
      }
    }
    await setUserReady(true)
    await setLoading(false)
  }

  useEffect(() => {
    preload()
  }, [])

  const validate = title => {
    if (title.trim() === '') return { success: false, error: 'Título requerido' }
    return { success: true }
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = validate(title)
    if (isValid.success) {
      await setLoading(true)
      try {
        if (form) {
          await props.putForm(sites.defaultSite.id, form, {
            title,
            redirect,
            description,
            support: soporte,
            fields: forms.form.fields,
          })
        }
        await props.fetchForm(sites.defaultSite.id, form)
      } catch (error) {
        console.log('handleSubmit', error)
      } finally {
        await setLoading(false)
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  useEffect(() => {
    if (description !== data.description) return setEnableEdit(true)
    if (title !== data.title) return setEnableEdit(true)
    if (redirect !== data.redirect) return setEnableEdit(true)
    setEnableEdit(false)
  }, [description, title, redirect])

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={styles.container}>
        <div style={styles.header}>
          <div onClick={() => window.history.back()} style={styles.title}>
            <KeyboardBackspace style={{ fill: '#024976' }} />
            Formularios
          </div>
        </div>

        <div style={styles.content}>
          <div style={{ ...styles.panel, ...styles.profilePanel }}>
            {userReady ? (
              <>
                <div
                  style={{
                    ...styles.form,
                    ...styles.panelSection,
                    flex: 1,
                    display: 'flex',
                    minHeight: '75vh'
                  }}
                >
                  <FormControl
                    style={{ flex: 1, display: 'flex' }}
                    onSubmit={handleSubmit}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <FormGroup style={{ marginBottom: 10 }}>
                        <div style={styles.field} className='field'>
                          <TextField
                            type='text'
                            id='title'
                            value={title}
                            disabled={loading}
                            variant='outlined'
                            label='Ingresa el título'
                            aria-describedby='titleHelp'
                            onChange={e => setTitle(e.target.value)}
                            classes={{ root: classes.textFieldRoot }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup style={{ marginBottom: 10 }}>
                        <div style={styles.field} className='field'>
                          <div
                            style={{
                              fontSize: 12,
                              color: 'rgba(0, 0, 0, 0.54)',
                              fontFamily: 'Montserrat',
                              job: 'relative',
                              zIndex: 1,
                              padding: '0px 13px'
                            }}
                          >
                            Ingresa la descripción
                          </div>
                          <TextArea
                            value={description}
                            disabled={loading}
                            aria-describedby='descriptionHelp'
                            onChange={e => setDescription(e.target.value)}
                            style={{ height: 200, borderRadius: 14 }}
                            placeholder='Ingresa la descripción'
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div style={styles.field} className='field'>
                          <div style={styles.subtitle}>Redirect al completar formulario</div>
                          <Select
                            value={type}
                            disabled={loading}
                            onChange={(ev) => {
                              if (ev.target.value === '0') {
                                setType(ev.target.value)
                                setRedirect(data.redirect)
                              } else if (ev.target.value === '1') {
                                setType(ev.target.value)
                                setRedirect('')
                              }
                            }}
                            style={{
                              ...styles.input,
                              backgroundColor: 'white',
                              border: 'rgb(232, 232, 232) 1px solid'
                            }}
                          >
                            <option disabled selected='selected'>
                              Ninguna
                            </option>
                            {[{title: 'Url interna', key: 0}, {title: 'Url externa', key: 1}].map((item, index) => (
                              <option key={`actions-${index}`} value={item.key}>
                                {item.title}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div style={styles.field} className='field'>
                          <FormControlLabel
                            label="Enviar copia a soporte"
                            classes={{ root: classes.formControlLabelRootCheckbox }}
                            control={
                              <Checkbox
                                color="primary"
                                checked={soporte}
                                onChange={(e) => setSoporte(e.target.checked)}
                              />
                            }
                          />
                        </div>
                      </FormGroup>
                      {
                        type ? (
                          <>
                            {
                              type === 0 ? (
                                <FormGroup>
                                  <div style={styles.field} className='field'>
                                    <div style={styles.subtitle}>Selecciona una url</div>
                                    <Select
                                      value={redirect}
                                      disabled={loading}
                                      onChange={(ev) => setRedirect(ev.target.value)}
                                      style={{
                                        ...styles.input,
                                        backgroundColor: 'white',
                                        border: 'rgb(232, 232, 232) 1px solid'
                                      }}
                                    >
                                      <option disabled selected='selected'>
                                        Ninguna
                                      </option>
                                      {urls.map((item, index) => (
                                        <option key={`actions-${index}`} value={item.key}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </div>
                                </FormGroup>
                              ) : (
                                <FormGroup style={{ marginBottom: 10 }}>
                                  <div style={styles.field} className='field'>
                                    <TextField
                                      type='text'
                                      id='redirect'
                                      value={redirect}
                                      disabled={loading}
                                      variant='outlined'
                                      label='Url externa'
                                      aria-describedby='redirectHelp'
                                      onChange={e => setRedirect(e.target.value)}
                                      classes={{ root: classes.textFieldRoot }}
                                    />
                                  </div>
                                </FormGroup>
                              )
                            }
                          </>
                        ) : null
                      }
                    </div>
                    <button
                      disabled={loading || !enableEdit}
                      onClick={ev => handleSubmit(ev)}
                      className='button is-light'
                      style={styles.submitButton}
                    >
                      {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Guardar'}
                    </button>
                  </FormControl>
                </div>
              </>
            ) : (
              <Loader />
            )}
          </div>

          <div style={{ ...styles.panel, ...styles.feedPanel }}>
            {userReady ? (
              <>
                <AppBar
                  classes={{
                    root: classes.appBarRoot
                  }}
                  position='static'
                >
                  <Tabs
                    value={tabIndex}
                    classes={{
                      root: classes.tabsRoot,
                      indicator: classes.tabsIndicator
                    }}
                    onChange={(ev, index) => setTabIndex(index)}
                  >
                    <Tab
                      classes={{
                        root: classes.tabRoot
                      }}
                      label='Campos'
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot
                      }}
                      label='Registros'
                    />
                    <Tab
                      classes={{
                        root: classes.tabRoot
                      }}
                      label='Acciones'
                    />
                  </Tabs>
                </AppBar>

                <TabPanel value={tabIndex} index={0}>
                  <div
                    style={{
                      flex: 1,
                      width: '100%',
                      display: 'flex',
                      marginBottom: 20,
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      onClick={() => setModalCreate(true)}
                      style={styles.addModuleButton}
                      className='button is-light'
                    >
                      Agregar campo
                    </Button>
                  </div>
                  <div style={styles.tab}>
                    <ReactSortable
                      tag='div'
                      list={forms.form.fields}
                      setList={newArray => props.updateFormSortLocal(newArray)}
                      style={{
                        rowGap: 8,
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      onEnd={() =>
                        props.putForm(sites.defaultSite.id, form, {
                          fields: forms.form.fields
                        })
                      }
                    >
                      {forms.form.fields.map((item, index) => (
                        <FieldItem key={`Field-item-${index}`} {...item} />
                      ))}
                    </ReactSortable>
                    {modalCreate && (
                      <AddField
                        formId={form}
                        show={modalCreate}
                        onClose={() => setModalCreate(false)}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Registros />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <Actions />
                </TabPanel>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  addModuleButton: {
    borderRadius: 8,
    backgroundColor: '#00ace7',
    marginBottom: 0,
    fontSize: 14,
    textTransform: 'none',
    padding: '18px 20px'
  },
  container: {
    flex: 1,
    backgroundColor: '#f6f6f6',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    padding: '30px 30px 15px 30px',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    padding: '0px 30px 30px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 30
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  panel: {
    minWidth: 330,
    backgroundColor: 'white',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  profilePanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  feedPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  statsPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  panelSection: {
    width: '100%',
    padding: '20px 30px'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)'
  },
  title: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginTop: 12
  },
  description: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '500',
    color: '#565656',
    marginTop: 3
  },
  UserCreateDate: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  actions: {
    display: 'flex',
    columnGap: 10,
    justifyContent: 'space-between',
    marginTop: 15
  },
  actionButton: {
    fontSize: 14,
    padding: '26px 36px',
    borderRadius: 8
  },
  form: {
    marginTop: 10
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  subtitle: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  passwordMaskedButton: {
    position: 'absolute',
    right: 8,
    bottom: 4
  },
  submitButton: {
    fontSize: 16,
    padding: '26px 0px',
    borderRadius: 8,
    width: '100%'
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  statValue: {
    fontFamily: 'Montserrat',
    color: '#024976'
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 8
  },
  tag: {
    backgroundColor: 'rgb(246, 246, 246)',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: 12,
    borderRadius: 10,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    color: 'rgb(177, 177, 177)'
  }
}

const mapStateToProps = ({ sites, forms }) => {
  return { sites, forms }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { fetchForm, updateFormSortLocal, putForm },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
