import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  padding-bottom: 32px;
  justify-content:space-between;  
`
export const Filter = styled.div`
  display: none;
  flex-direction: row;
`
export const MainTitle = styled.div`
display: flex;
  align-items: flex-end;
`
export const Title = styled.div`
font-style: normal;
  font-family: Montserrat;
  font-size: 34px;
  line-height: 38px;
  text-align: left;
  color: #004976;
  font-weight: 400;
  letter-spacing: 0.25px;
`
export const DaysCounter = styled.div`
  font-style: normal;
  font-family: Montserrat;
  font-size: 14px;
  margin-left: 9px;
  color: #004976;
  font-weight: 600;
  letter-spacing: 0.25px;
  align-items: flex-start;
`
export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  text-align: left;
  margin-bottom: 34px;
  letter-spacing: 0.2px;
  color: #004976;
`

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const DashboardCounters = styled.div`
@media (max-width: 768px) {
    width: 100%;
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 24px;
}
@media (min-width: 768px) {
    width: 100%;
    display: flex;
    max-height: 121px;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
}
`