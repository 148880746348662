import React from 'react';
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import classes from "./Terms.module.scss";

const Terms = (props) => {
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>L3rn Privacy Policy</title>
        <link rel="canonical" href="https://store.l3rn.io/privacy-policy" />
      </Helmet>
      <Navbar />
      <div className={classes.container__1}>
        <div className={classes.content} className="general-padding">
          <div className={classes.header}>
            <div className={classes.title}>
              Términos y condiciones
            </div>
          </div>
          <div className={classes.separator} />
          <div className="markdown" className={classes.markdown}>

            DE USO DE SITIO WEB Y SERVICIOS DE **“L3RN”**
            <br /><br />
              **FECHA DE ACTUALIZACIÓN**
            <br /><br />
              **(XX/FECHA DE ALTA EN EL SITIO WEB/XX)**<br />
            <br />

              #### I. INTRODUCCIÓN:<br /><br />
            <p>
              TODA PERSONA QUE DESEE HACER USO DE LOS SERVICIOS DE LA PLATAFORMA WEB DE **“L3RN”**, DEBERÁ ACEPTAR LAS DISPOSICIONES DE CARÁCTER OBLIGATORIO Y VINCULATIVOS AQUÍ DESCRITAS; EN CASO DE RECHAZO, DEBERÁ ABSTENERSE DE UTILIZAR LOS SERVICIOS BRINDADOS POR EL SITIO WEB.
              <br /><br />
              LA ACEPTACIÓN DE LAS PRESENTES POLÍTICAS DE USO DEL SITIO WEB, SERÁ UNA FIRMA AUTÓGRAFA CON EL MISMO VALOR LEGAL Y PODRÁ SER ELEGIBLE COMO PRUEBA JUDICIAL EN CASO DE CUALQUIER CONTROVERSIA QUE SE SUSCITE. EL CLIENTE Y/O USUARIO ACEPTA Y SE COMPROMETE A CUMPLIR LAS SIGUIENTES CLÁUSULAS Y TÉRMINOS DE USO.
              <br /><br />
              LOS SERVICIOS Y PRODUCTOS DE ESTA PLATAFORMA ES DESTINADA A MAYORES DE EDAD, O MENORES DE EDAD QUE CUENTEN CON LA AUTORIZACIÓN DE UN TUTOR.<br />
            </p>
              #### II. PRIVACIDAD:<br /><br />
            <p>
              Revise nuestras **Políticas de Privacidad**; estas políticas rigen la visita y el uso de la plataforma de **“L3RN”**. Además de los lineamientos establecidos en leyes nacionales, adicionalmente se establecen las políticas en cumplimiento de las leyes de privacidad para los residentes de la Unión Europea o del EEE.
             </p>
            <br />
              #### III. OBJETO Y RELACIÓN DEL CONVENIO: <br /><br />
            <p>
              El objeto del presente documento es, describir los Términos y Condiciones (en lo sucesivo y para efectos de este documento se le denominará **“TyC”**), que se aplicarán en el uso de la plataforma web **URL o dirección electrónica a través de la cual se accede al sitio we“**, que en lo sucesivo se le denominará el sitio web ofrecida por **“L3RN”**,; así como también para las plataformas conocidas o las que están por conocerse derivadas de **“L3RN”**, sus sociedades representativas, socios, representantes y grupo corporativo.
              <br /><br />
              Toda persona con acceso al portal del Sitio Web y/o los servicios de **“L3RN”**, deberá sujetarse a las políticas y principios descritos en el presente documento y otros convenios derivados del uso correcto de “El Sitio Web” y/o sus plataformas afines.
              <br /><br />
              **“L3RN”**, se reserva el derecho de modificar en cualquier momento los presentes **“TyC”**, así como cualesquiera otras condiciones generales o particulares que resulten del sitio web y/o plataforma.
              <br /><br />
              De la misma, en cualquier momento, bajo su responsabilidad, **“L3RN”**, se reserva el derecho de suspender, interrumpir o dejar de operar el sitio web y/o sus plataformas afines, por diversos motivos que deriven del proceso de operación de sus derivados.<br />
            </p>
              #### IV. PERSONALIDAD Y GENERALES DE “L3RN”,:<br /><br />
            <p>
              Para los efectos que deriven del presente documento y/o sus afines; el nombre **“L3RN”**, es un signo distintivo debidamente registrado bajo las leyes mexicanas de propiedad industrial, misma que se encuentra bajo la representación de la persona física con actividad empresarial el señor **Indique el nombre completo, la razón o denominación social de la persona que es propietari**, persona física con actividad empresarial, con domicilio laboral el ubicado en **Ingrese el domicilio de la empresa**
              <br />
              Que dentro de sus actividades comerciales se encuentra la de administración de Agencia de Marketing, Producción Audiovisual, organización y logística de eventos, así como Academia Online.
            </p>

              #### V. SERVICIOS Y CONTENIDOS DE “L3RN”:<br />
            <br />
            <p>
              Es una plataforma que opera bajo el esquema de educación continua, asesoría, y aprendizaje en diversas materias de desarrollo personal y de negocios. De la misma manera, es una plataforma online, dedicada a la venta y publicación de material didáctico, libros de textos o cursos especializados y/o interés, administrado dentro de la plataforma denominada **Plataforma que utilizan, kajabi.. et**
              <br />
              El usuario podrá suscribirse al material didáctico, contenido de video, audio y material de texto, mediante los diversos paquetes de pago. Los cuales podrán ser cobrados mediante plataforma de terceros proveedores.
            </p>
              #### VI. REGISTRO DE USUARIOS Y ACCESOS<br /><br />
            <p>
              A. Disposición y cumplimiento de los presentes **“TyC”**, **“L3RN”** otorga un permiso de uso ilimitado, no exclusivo, no sublicenciable, perpetuo, para el acceso y uso del sitio web
            <br /><br />
              B. **Restricciones de “L3RN”**<br /><br />
              Con carácter general, informativo y de capacitación* (*material seleccionado), el sólo acceso al sitio web podrá ser libre.
            <br /><br />
              No obstante, **“L3RN”** podrá condicionar los accesos a determinados servicios y áreas de los mismos, para el correcto uso y cumplimiento del presente convenio y políticas.
            <br /><br />
              Para todos los efectos el uso del sitio web se considerará la aceptación sin reserva u objeción alguna de los presentes **“TyC”**.
            <br /><br />
              De la misma manera el objetivo de **“L3RN”**, es poner a disposición de los Usuarios determinada mercancía y/o servicios para cuya utilización pudiera requerir la cumplimentación de registros adicionales y/o satisfacción de determinadas contraprestaciones dinerarias.
            <br /><br />
              El usuario declara, que al utilizar este sitio tiene al menos la mayoría de edad en su estado o provincia de residencia, por lo que se compromete a la veracidad del conjunto de los datos de registro facilitados a **“L3RN”** y es responsable de mantenerlos actualizados en su totalidad mientras dure su condición del mismo.
            <br /><br />
              El usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a **“L3RN”** o a terceros por la información que facilite.<br />
              <br />
              Así mismo el cliente, usuario y colaborador se compromete a:<br />
              1. No retirar nota de derechos de autor, marca registrada;<br />
              2. No reproducir y/o modificar, las marcas, contenido, procesos del sitio web  sin el pago previo y/o licencias de autor;<br />
              3. Intentar obtener un acceso no autorizado o dañar cualquier aspecto del sitio web, sus plataformas, sus sistemas o redes relacionadas.”<br />
              4. No reproducir el contenido libros, manuales y contenido literario sin previa autorización y/u obtención de las debidas licencias de autor.<br />
              5. Sobre los videos y la información del programa especializado el usuario se obliga a no enajenarla, arrendarla, prestarla, grabarla, negociarla, revelarla, publicarla, transmitirla o de alguna otra forma divulgarla para fines de lucro propios autorización expresa o tácita de **“L3RN”** , sus titulares, representantes, grupo corporativo, sociedades, y demás designados.<br />
              <br />
              En caso de la omisión de los puntos anteriores, por parte del usuario y/o persona externa, **“L3RN”**, tomará las medidas legales que crea necesarias, para la protección de sus derechos.
            <br /><br />
              C. Detalles de los servicios.<br />
              Los servicios brindados por parte de **“L3RN”** al usuario será solamente para su uso personal y no para uso comercial con fines de lucro personal, en beneficio del usuario.
            <br /><br />
              D. Responsabilidad.
            <br /><br />
              **“L3RN”** no se ha hace responsable de los resultados obtenidos, esto en consecuencia de la visualización de los videos y los textos, lo anterior es inherente a cada usuario y la capacidad de implementación. Es decir, las habilidades aprendidas dependen de cada usuario.
            <br /><br />
              E. Newsletter
            <br /><br />
              Dentro del registro, el usuario, podrá aceptar recibir el newsletter, con contenido de los cursos, informativo y/o noticioso, ligados a los servicios de la plataforma de **“L3RN”**
            <br /><br />

              F. Licencia de uso
            <br /><br />
              **“L3RN”** otorga al usuario registrado en los programas y cursos proporcionados, por éste, el acceso y uso del sitio web de forma limitada, con derechos de no exclusividad.
            <br /><br />
              Salvo que se indique que sea de dominio público, el contenido del sitio web de **“L3RN”** está protegido por las leyes de derechos de autor internacionales.
            <br /><br />
              A menos que se indique expresamente lo contrario en el sitio web de **“L3RN”** los textos, vídeos, imágenes y otros materiales educativos y de aprendizaje que se proveen con los cursos que se ofrecen en el Sitio Web de **“L3RN”** son para su uso personal, en relación únicamente con los cursos, y la capacitación personal.
            <br /><br />
              Algunos documentos de referencia, citas, procesos, libros, artículos y otra información en el sitio web de **“L3RN”** , se utilizarán con la autorización de terceros o en sea el caso, se hará siempre la mención de los autores, respetando los derechos de autor internacional.
              <br /><br />
              Esta es la concesión de una licencia, no una transferencia de título, y bajo esta licencia usted no puede:<br />
              1. Modificar o copiar los materiales;<br />
              2. Usar los materiales para cualquier propósito comercial, o para cualquier exhibición pública (comercial o no comercial);<br />
              3. Intentar descompilar o realizar ingeniería inversa de cualquier software contenido en **“L3RN”**<br />
              4. Eliminar los derechos de autor u otras anotaciones de propiedad de los materiales;<br />
              5. Transferir los materiales a otra persona o "reflejar" los materiales en cualquier otro servidor.<br />
              <br />
              G. Plataformas
            <br /><br />
              **“L3RN”** puede hacer uso de plataformas online de terceros proveedores, estos para brindar un mejor servicio en el contenido, dirigido al usuario. Dichas plataformas, se basarán por sus propios términos y condiciones, que el usuario deberá respetar, de igual manera los presentes **“TyC”**.
            <br /><br />
              **“L3RN”** no se hace responsable de la operación de dichas plataformas.
            <br /><br />
              H. Código de Conducta
            <br /><br />
              El uso del sitio web se rige bajo ciertas reglas de conducta (“Código de Conducta”); es responsabilidad del Usuario conocer, entender y cumplir con el Código de Conducta.<br />
              **“L3RN”** se reserva el derecho, a su discreción, de tomar medidas disciplinarias u otras observaciones que estime pertinentes en respuesta a una conducta que considere inaceptable, incluyendo, sin limitación, eliminar o alterar cualquier nombre de usuario o la suspensión, terminación o cancelación de la cuenta de usuario, el acceso y uso de los Servicios, o cancelar cualquier licencia concedida en estos **“TyC”**, por cualquier razón que **“L3RN”** estime necesario.<br />
              **“L3RN”** se reserva el derecho a modificar el Código de Conducta en cualquier momento.<br />
              Usuario no podrá realizar las siguientes conductas:<br />
              1. Cualquier intento por su parte de perturbar, o alentar o promover la interrupción de los servicios puede ser una violación de las leyes penales y civiles.<br />
              2. Ser menor de edad, sin consentimiento de un tutor. **“L3RN”** no se hace responsable de los conflictos derivados de situaciones relacionadas en este punto.<br />
              3. El usuario acepta que no violará cualquier ley de su país o leyes internacionales, normas o reglamentos en relación con su uso de los Servicios, ni tampoco interrumpir o intentar interrumpir el funcionamiento de los Servicios de cualquier forma sin limitación.<br />
              4. El Sitio Web se encuentra destinado para personas y empresas, que desean capacitarse e implementar dichos aprendizajes, en el desarrollo personal. El Usuario debe usar este Sitio Web con intenciones legítimas y de acuerdo a las indicaciones de uso dictadas por **“L3RN”**.<br />
              5. Cualquier tipo de omisión a lo anterior y de todos los **“TyC”** será causa de suspensión parcial o completa de la cuenta y suscripción de Usuario y/o colaborador, así mismo podrá ser acreedor de un proceso legal en su contra.<br />
            </p>
              #### VII. REGISTRO DE USUARIOS<br /><br />
            <p>
              A. Cuentas de Usuario
            <br /><br />
              Los servicios de venta online que ofrece o pueda ofrecer **“L3RN”** requerirá el acceso, por lo cual es necesario crear una cuenta **(“Cuenta de Usuario”)**; a pesar de que muchos servicios son accesibles sin el requisito de una cuenta de usuario; los presentes **“TyC”** se aplica por igual a su acceso y uso del sitio web
            <br /><br />
              Con la finalidad de usar correctamente el sitio web y para satisfacción de el Usuario, **“L3RN”** dará de alta al usuario en su base de datos de *Usuarios Registrados*; la cuenta será generada al momento de realizar el pago según corresponda, la información que se llegase a requerir para la creación de la cuenta de usuario será el nombre, correo electrónico del titular y número de teléfono celular. De igual manera, el sistema del sitio web podrá arrojar la acreditación de validación de seguridad correspondiente.
            <br /><br />
              El Usuario acepta que la información brindada a **“L3RN”** se ajusta a los lineamientos del **“Aviso de Privacidad”** que se encuentra en el sitio web
            <br /><br />
              El usuario no puede compartir o transferir su cuenta de usuario o la información de acceso con cualquiera que no esté expresamente establecido en este documento u autorizado expresamente por el Usuario.
            <br /><br />
              El usuario es responsable de proteger la confidencialidad de la información de acceso, y será responsable de todo uso de su cuenta de usuario.
            <br /><br />
              Cualquier acceso no autorizado, uso, distribución o transferencia de su cuenta de usuario o información puede resultar en que se genere suspensión, terminación o cancelación de su cuenta de usuario y su acceso y uso de los Servicios en el sitio web
            <br /><br />
              B. Pago
            <br /><br />
              El usuario acepta que en el uso del sitio web los servicios generarán cargos mismos que serán cobrados mediante los portales digitales de cobro los denominados Stripe®, Pay Pal ®, u otras plataformas necesarias;**“ añadir otros métodos de pago que tenga la empresa”** Así mismo se podrá hacer mediante deposito bancario, el cual mismo recibo de pago deberá ser enviado al correo electrónico **correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa**
            <br /><br />
              **“L3RN”**, en cualquier momento y a su sola discreción, se reserva el derecho de establecer, eliminar y/o revisar los Cargos para alguno o todos los servicios o bienes obtenidos a través del uso de los Servicios.
              El usuario podrá solicitar el reembolso de los cargos generados, siempre y cuando envíe sus comentarios al correo electrónico **correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa**
              Cada caso será evaluado en lo particular, para brindar el mejor servicio.<br />
              <br />
              C. Cancelaciones y Devoluciones<br />
              Las cancelaciones y devoluciones **“L3RN”**, serán bajo los siguientes preceptos.<br />
              1. El usuario no podrá realizar la cancelación de los cursos en línea.<br />
              2. En caso especial, podrá enviar un correo directo a **correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa**
            </p>
              #### VIII. DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL<br /><br />
            <p>
              El contenido aquí desarrollado es directamente propiedad y autoría de **“L3RN”**, para fines de lucro de este, salvo contenido desarrollado y compartido bajo el esquema de publicidad.
            <br /><br />
              Cualquier contenido del sitio web y/o cualquier plataforma móvil derivada de los servicios de **“L3RN”**, no puede ser copiado, reproducido, re-publicado, enviado, transmitido, transcrito, traducido, almacenado, alterado, descargado o distribuido en ningún medio inventado o por inventar, ni por ningún motivo, a excepción de la compra de la licencia de uso correspondiente, misma que será sólo para los fines antes descritos, de forma única.
            </p>
              #### IX. LEY APLICABLE<br /><br />
            <p> A los efectos de practicar las notificaciones que correspondan y necesarias, **“L3RN”**, designa como domicilio de contacto el correo electrónico **correo electrónico al que puedan mandar mensaje los clientes y que sea  de la empresa, correo electrónico identificado con la empresa**<br />
              Salvo que aquí se especifique lo contrario, las presentes Condiciones se regirán e interpretarán exclusivamente en virtud de la legislación de la Ciudad de México, con exclusión de sus normas sobre conflicto de leyes.<br />
              Todas las desavenencias que deriven de este contrato serán resueltas definitivamente de acuerdo con las Reglas de Arbitraje del Centro de Arbitraje de México (CAM), por uno o más árbitros nombrados conforme a dichas Reglas.<br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;