import network from "../../utils/network";
import { parseUrl } from "../../helpers/utils";

export const FETCHING_TEMPLATES = 'FETCHING_TEMPLATES';
export const FAIL_FETCH_TEMPLATES = 'FAIL_FETCH_TEMPLATES';
export const SUCCESS_FETCH_TEMPLATES = 'SUCCESS_FETCH_TEMPLATES';

export const FETCHING_ALL_TEMPLATES = 'FETCHING_ALL_TEMPLATES';
export const FAIL_FETCH_ALL_TEMPLATES = 'FAIL_FETCH_ALL_TEMPLATES';
export const SUCCESS_FETCH_ALL_TEMPLATES = 'SUCCESS_FETCH_ALL_TEMPLATES';

export const FETCHING_TEMPLATE = 'FETCHING_TEMPLATE';
export const FAIL_FETCH_TEMPLATE = 'FAIL_FETCH_TEMPLATE';
export const SUCCESS_FETCH_TEMPLATE = 'SUCCESS_FETCH_TEMPLATE';

export const POST_TEMPLATE = 'POST_TEMPLATE';
export const POST_FAIL_TEMPLATE = 'POST_FAIL_TEMPLATE';
export const POST_SUCCESS_TEMPLATE = 'POST_SUCCESS_TEMPLATE';

export const PUT_TEMPLATE = 'PUT_TEMPLATE';
export const PUT_FAIL_TEMPLATE = 'PUT_FAIL_TEMPLATE';
export const PUT_SUCCESS_TEMPLATE = 'PUT_SUCCESS_TEMPLATE';

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_FAIL_TEMPLATE = 'DELETE_FAIL_TEMPLATE';
export const DELETE_SUCCESS_TEMPLATE = 'DELETE_SUCCESS_TEMPLATE';

export const fetchTemplates = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_TEMPLATES });
    try {
      let url = parseUrl(`site/${siteId}/template?`, limit, offset, column, direction, search, searchtext)
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_TEMPLATES,
        });
      } else { 
        dispatch({
          data: {
            ...response.data,
            limit,
            offset,
            search,
            searchtext,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_TEMPLATES,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_TEMPLATES,
      });
    }
  };
};

export const fetchAllTemplates = (siteId, limit, offset, column, direction, search, searchtext = '') => {
  return async dispatch => {
    dispatch({ type: FETCHING_ALL_TEMPLATES });
    try {
      const response = await network.get(`site/${siteId}/template/all`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_ALL_TEMPLATES,
        });
      } else { 
        dispatch({
          data: response.data,
          type: SUCCESS_FETCH_ALL_TEMPLATES,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_ALL_TEMPLATES,
      });
    }
  };
};

export const fetchTemplate = (siteId, template) => {
  return async dispatch => {
    dispatch({ type: FETCHING_TEMPLATE });
    try {
      const response = await network.get(`site/${siteId}/template/${template}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_TEMPLATE,
        });
      } else {
        dispatch({
          template: response.data,
          type: SUCCESS_FETCH_TEMPLATE,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_TEMPLATE,
      });
    }
  };
};

export const postTemplate = (siteId, body) => {
  return async dispatch => {
    dispatch({ type: POST_TEMPLATE });
    try {
      const response = await network.post(`site/${siteId}/template`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_TEMPLATE,
      });
      return response.data
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_TEMPLATE,
      })
    }
  };
};

export const putTemplate = (siteId, template, body) => {
  return async dispatch => {
    dispatch({ type: POST_TEMPLATE });
    try {
      const response = await network.put(`site/${siteId}/template/${template}`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_TEMPLATE,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_TEMPLATE,
      });
    }
  };
};


export const deleteTemplate = (siteId, ids = []) => {
  return async dispatch => {
    dispatch({ type: DELETE_TEMPLATE });
    try {
      const promises = ids.map(async item => {
        await network.del(`site/${siteId}/template/${item}`);
      });
      const response = await Promise.all(promises)
      dispatch({
        type: DELETE_SUCCESS_TEMPLATE,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_TEMPLATE,
      });
      return error;
    }
  };
};