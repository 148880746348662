import {
  FETCHING_CATEGORIES,
  FAIL_FETCH_CATEGORIES,
  SUCCESS_FETCH_CATEGORIES,
  FETCHING_CATEGORY,
  FAIL_FETCH_CATEGORY,
  SUCCESS_FETCH_CATEGORY,
  DELETE_CATEGORY,
  DELETE_FAIL_CATEGORY,
  DELETE_SUCCESS_CATEGORY
} from '../actions/categories'

const initialState = {
  data: [],
  user: null,
  error: null,
  loading: false,
  order: {},
  count: 0,
  limit: 25,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_CATEGORIES:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_CATEGORIES:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case FAIL_FETCH_CATEGORIES:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
    case FETCHING_CATEGORY:
      return {
        ...state,
        data: [],
        error: null,
        loading: true,
      };
    case SUCCESS_FETCH_CATEGORY:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
      };
    case FAIL_FETCH_CATEGORY:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.error,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SUCCESS_CATEGORY:
      return {
        ...state,
        loading: false,
      };
    case DELETE_FAIL_CATEGORY:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state
  }
};
