import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { AddCircle } from '@material-ui/icons'
import { LinearProgress } from '@material-ui/core'
import { fetchWebinarsSubscribers, postWebinarSubscriptor, deleteWebinarSubscriptor } from '../../../redux/actions/webinars'
import AddSubscriberModal from './AddSubscriberModal'
import { makeToast } from '../../../utils'
import CustomTable from '../DataTable'

function Suscriptores (props) {
  const { event } = useParams()
  const [createModal, setCreateModal] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const {
    sites,
    webinars: {
      users: { data, loading, limit, offset, count, sort }
    }
  } = props

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'fullname',
      label: 'Nombre',
    },
    {
      label: 'Fecha de suscripción',
      name: 'createdAt',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div>
                {moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}
              </div>
            )
          }
        }
      }
    }
  ]

  const onClose = () => {
    toggleCreate()
  }

  const onSubmit = async (data) => {
    try {
      console.log('data', data)
      await props.postWebinarSubscriptor(sites.defaultSite.id, event, data)
      makeToast(enqueueSnackbar, "El contacto se asigno correctamente")
      props.fetchWebinarsSubscribers(
        sites.defaultSite.id,
        event,
        limit,
        offset
      )
    } catch (error) {
      console.log('error', error)
      makeToast(enqueueSnackbar, "Ocurio un error al asignar contacto", "error")
    } finally {
      toggleCreate()
    }
  }

  const toggleCreate = () => {
    setCreateModal(prev => !prev)
  }

  return (
    <>
      <div style={styles.header}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={styles.title}>Suscriptores</div>
          <div style={styles.subtitle}>
            ({count} {count !== 1 ? 'Suscriptores' : 'Suscriptor'})
          </div>
        </div>
        <div style={styles.actions}>
          <button
            onClick={toggleCreate}
            style={styles.actionButton}
            className='button is-light'
          >
            <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} />{' '}
            Agregar suscriptor
          </button>
        </div>
      </div>
      {loading && <LinearProgress />}
      <div style={styles.tableContainer}>
        <CustomTable
          data={data}
          columns={columns}
          count={count}
          limit={limit}
          offset={offset}
          sortOrder={sort}
          onRowPress={(rowData, meta, rowIndex) => {}}
          onRowsDelete={async rows => {
            await props.deleteWebinarSubscriptor(sites.defaultSite.id, event, rows)
            makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
            props.fetchWebinarsSubscribers(
              sites.defaultSite.id,
              event,
              limit,
              offset
            )
          }}
          preload={() =>
            props.fetchWebinarsSubscribers(
              sites.defaultSite.id,
              event,
              limit,
              offset
            )
          }
          changePageSize={({ rowsPerPage }) =>
            props.fetchWebinarsSubscribers(
              sites.defaultSite.id,
              event,
              rowsPerPage,
              offset
            )
          }
          search={({ rowsPerPage, page, sortOrder, searchText, columns }) => {
            if (searchText && searchText !== '') {
              const names = columns.map(item => item.name)
              props.fetchWebinarsSubscribers(
                sites.defaultSite.id,
                event,
                rowsPerPage,
                page,
                sortOrder.name,
                sortOrder.direction,
                names,
                searchText
              )
            } else {
              props.fetchWebinarsSubscribers(
                sites.defaultSite.id,
                event,
                rowsPerPage,
                page,
                sortOrder.name,
                sortOrder.direction
              )
            }
          }}
          changePage={({ rowsPerPage, page, sortOrder }) => {
            props.fetchWebinarsSubscribers(
              sites.defaultSite.id,
              event,
              rowsPerPage,
              page,
              sortOrder.name,
              sortOrder.direction
            )
          }}
          sort={({ rowsPerPage, page, sortOrder }) => {
            props.fetchWebinarsSubscribers(
              sites.defaultSite.id,
              event,
              rowsPerPage,
              page,
              sortOrder.name,
              sortOrder.direction
            )
          }}
        />
        {
          createModal && (
            <AddSubscriberModal
              visible={createModal}
              onClose={() => onClose()}
              onSubmit={(data) => onSubmit(data)}
            />
          )
        }
      </div>
    </>
  )
}

const styles = {
  tableContainer: {
    marginTop: 0
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  subtitle: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}

Suscriptores.propTypes = {}

const mapStateToProps = ({ webinars, sites }) => {
  return { webinars, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchWebinarsSubscribers, postWebinarSubscriptor, deleteWebinarSubscriptor }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Suscriptores)
