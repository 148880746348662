import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { makeToast } from '../../../utils'
import { bindActionCreators } from 'redux';
import { useParams, useHistory } from 'react-router-dom';
import { FormControl, CircularProgress, Checkbox, RadioGroup, Radio, FormControlLabel, Box, AppBar, Tabs, Tab, Select, MenuItem, makeStyles, TextField } from '@material-ui/core';
import { KeyboardBackspace, Publish } from '@material-ui/icons';
// import { Visibility, ShoppingCart, MonetizationOn, DateRange } from '@material-ui/icons';
import { fetchPages, fetchLanding, putPage, postPage, deletePage } from '../../../redux/actions/pages';
import { uploadFile, listeningUpload } from '../../../helpers/files';
import ImageUploader from '../courses/components/ImageUploader';
import { Input, TextArea } from '../StyledComponents';
import ActivitiesList from '../ActivitiesList';
import DuplicatePage from "./DuplicatePage";
import { escapeText } from '../../../utils';
// import VisitsChart from '../VisitsChart';
import { Alert } from '../Alert';
// import Navbar from "../Navbar";
import Loader from '../Loader'; 

let response = {};

const useStyles = makeStyles({
  appBarRoot: {
    boxShadow: 'none',
    marginBottom: 20
  },
  tabsRoot: {
    backgroundColor: 'white',
    float: 'left'
  },
  tabsIndicator: {
    display: 'none'
  },
  tabRoot: {
    fontFamily: 'Montserrat',
    backgroundColor: 'rgba(33,150,243,0.08)',
    color: '#2196F3',
    fontWeight: 'bold',
    border: 0,
    textTransform: 'none',
    transition: 'background-color 0.4s',
    fontSize: 16,
    marginRight: 12,
    height: 40,
    minHeight: 40,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: 'rgba(33,150,243,0.2)',
      color: '#2196F3'
    },
    '&[aria-selected="false"]': {
      color: '#2196F3',
      backgroundColor: 'white'
    }
  },
  formControlLabelRoot: {
    '& .Mui-checked .MuiIconButton-label': {
      color: '#00ace7 !important'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: '500',
      color: 'rgb(2, 73, 118)'
    }
  },
  selectRoot: {
    width: 45,
    backgroundColor: '#f7f7f7',
    paddingLeft: 11,
    color: 'rgb(2, 73, 118)',
    borderRadius: '6px !important',
    fontFamily: 'Montserrat',
    fontSize: 15
  },
  textFieldRoot: {
    height: 56,
    borderRadius: 14,
    fontFamily: 'Montserrat',
    width: '100%',
    color: '#495057',
    fontSize: '1rem',
    display: 'block',
    borderWidth: 0,
    fontWeight: 400,
    backgroundColor: '#F7F7F7',
    backgroundClip: 'padding-box',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      '&.Mui-focused': {
        color: 'rgb(51, 55, 66)'
      }
    },
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: 14,

      '& .MuiInputBase-input': {
        fontFamily: 'Montserrat'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      '& legend': {
        paddingRight: 10
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(51, 55, 66) !important'
    }
  }
});

function UserCreate(props) {

  const { sites } = props;
  const { page } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [allow, setAllow] = useState(true);
  const [keywords, setKeywors] = useState('');
  const [, setFaviconFile] = useState([]);
  const { enqueueSnackbar } = useSnackbar()
  const [favicon, setFavicon] = useState(null);
  const [pageReady, setPageReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setSocialImageFile] = useState([]);
  const [socialImage, setSocialImage] = useState(null);
  const [description, setDescription] = useState('');
  const [socialShare, setSocialShare] = useState('');
  const [enableEdit, setEnableEdit] = useState(false);
  const [published, setPublished] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [socialDescription, setSocialDescription] = useState('');
  const [tab, setTab] = useState(0);
  const [action, setAction] = useState('none');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const addMetas = (config, htmlFile) => {
    if (htmlFile && htmlFile.trim() !== "") {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlFile, 'text/html')
      doc.head.querySelector('title').innerText = config.title
      if (doc.querySelector('script[fbpixel="true"]')) {
        doc.querySelector('script[fbpixel="true"]').text = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${config.site.facebookPixel}');
          fbq('track', 'PageView');
        `;
      } else {
        const script = window.document.createElement('script')
        script.setAttribute('fbpixel', 'true')
        script.text = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${config.site.facebookPixel}');
          fbq('track', 'PageView');
        `;
        doc.head.appendChild(script)
      }
      if (doc.querySelector('noscript[fbpixel="true"]')) doc.querySelector('noscript[fbpixel="true"]').text = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${config.site.facebookPixel}&ev=PageView&noscript=1"/>`
      if (doc.querySelector('meta[name="keywords"]')) doc.querySelector('meta[name="keywords"]').setAttribute("content", config.keywords)
      if (doc.querySelector('meta[name="description"]')) doc.querySelector('meta[name="description"]').setAttribute("content", config.description)
      if (doc.querySelector('meta[property="og:title"]')) doc.querySelector('meta[property="og:title"]').setAttribute("content", config.socialShare)
      if (doc.querySelector('meta[property="og:url"]')) doc.querySelector('meta[property="og:url"]').setAttribute("content", config.url)
      if (doc.querySelector('meta[property="og:site_name"]')) doc.querySelector('meta[property="og:site_name"]').setAttribute("content", `${config.title} | ${config.description}`)
      if (doc.querySelector('meta[property="og:image"]')) doc.querySelector('meta[property="og:image"]').setAttribute("content", config.socialImage)
      if (doc.querySelector('meta[property="og:description"]')) doc.querySelector('meta[property="og:description"]').setAttribute("content", config.socialDescription)
      if (doc.querySelector('meta[property="og:image:alt"]')) doc.querySelector('meta[property="og:image:alt"]').setAttribute("content", config.socialImage)
      if (doc.querySelector('link[rel="icon"]')) doc.querySelector('link[rel="icon"]').setAttribute("href", config.icon || config.favicon)
      if (doc.querySelector('link[rel="apple-touch-icon"]')) doc.querySelector('link[rel="apple-touch-icon"]').setAttribute("href", config.icon || config.favicon)
      if (doc.querySelector('meta[property="twitter:title"]')) doc.querySelector('meta[property="twitter:title"]').setAttribute("content", config.socialShare)
      if (doc.querySelector('meta[property="twitter:description"]')) doc.querySelector('meta[property="twitter:description"]').setAttribute("content", config.socialDescription)
      if (doc.querySelector('meta[name="twitter:image"]')) doc.querySelector('meta[name="twitter:image"]').setAttribute("content", config.socialImage)
      if (doc.querySelector('meta[name="twitter:image:alt"]')) doc.querySelector('meta[name="twitter:image:alt"]').setAttribute("content", config.socialImage)
      if (!config.allow) {
        if (doc.querySelector('meta[name="robots"]')) {
          doc.querySelector('meta[name="robots"]').setAttribute("content", "noindex")
        } else {
            const meta = window.document.createElement('meta')
            meta.setAttribute('name', 'robots')
            meta.setAttribute('content', 'noindex')
            doc.head.appendChild(meta)
          }
      } else {
        if (doc.querySelector('meta[name="robots"]')) {
          doc.querySelector('meta[name="robots"]').remove()
        }
      }
      return doc.documentElement.outerHTML
    }
    return null
  }

  const preload = (page) => {
    if (page) {
      response = page
      setPublished(page.published ? true : false)
      if (page.config) {
        setUrl(page.config.seo.url);
        setTitle(page.config.seo.title);
        setAllow(page.config.seo.allow);
        setFavicon(page.config.seo.favicon);
        setKeywors(page.config.seo.keywords);
        setSocialImage(page.config.seo.socialImage);
        setDescription(page.config.seo.description);
        setSocialShare(page.config.seo.socialShare);
        setSocialDescription(page.config.seo.socialDescription);
      }
    }
  }

  const loadPage = async () => {
    if (page) {
      response = await props.fetchLanding(sites.defaultSite.id, page);
      preload(response)
    }
    setPageReady(true)
    setLoading(false);
  }

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (title !== response.title) return setEnableEdit(true);
    if (published !== response.published) return setEnableEdit(true);
    if (response.config) {
        if (url !== response.config.seo.url) return setEnableEdit(true);
        if (allow !== response.config.seo.allow) return setEnableEdit(true);
        if (favicon !== response.config.seo.favicon) return setEnableEdit(true);
        if (keywords !== response.config.seo.keywords) return setEnableEdit(true);
        if (description !== response.config.seo.description) return setEnableEdit(true);
        if (socialShare !== response.config.seo.socialShare) return setEnableEdit(true);
        if (socialImage !== response.config.seo.socialImage) return setEnableEdit(true);
        if (socialDescription !== response.config.seo.socialDescription) return setEnableEdit(true);
    }
    setEnableEdit(false);
  }, [published, title, url, allow, keywords, description, socialShare, socialDescription, favicon, socialImage]);

  const validate = () => {
    if (title.trim() === '') return { success: false, error: 'Titulo requerido' };
    return { success: true }
  }

  const uploadFavicon = async (acceptedFiles) => {
    makeToast(enqueueSnackbar, 'Cargando contenido', 'info')
    await setLoading(true);
    await setFaviconFile(acceptedFiles);
    const siteId = props.sites.defaultSite.id;
    const fileId = await uploadFile(siteId, acceptedFiles[0], {}, false, true);
    const {url} = await listeningUpload(fileId)
    await handleSubmit(null, { favicon: url });
    setFavicon(url);
    await setLoading(false);
  }

  const uploadSocialImage = async (acceptedFiles) => {
    makeToast(enqueueSnackbar, 'Cargando contenido', 'info')
    await setLoading(true);
    await setSocialImageFile(acceptedFiles);
    const siteId = props.sites.defaultSite.id;
    const fileId = await uploadFile(siteId, acceptedFiles[0], {}, false, true);
    const {url} = await listeningUpload(fileId)
    await handleSubmit(null, { socialImage: url });
    await setSocialImage(url);
    await setLoading(false);
  }

  const parseUrl = (url = '') => {
    const tempUrl = url.replaceAll(" ", '-');
    const newUrl = escapeText(tempUrl);
    return newUrl;
  }

  const handleSubmit = async (ev, body) => {
    ev && ev.preventDefault();
    const isValid = validate();
    if (isValid.success) {
      await setLoading(true);
      try {
        const siteId = sites.defaultSite.id;
        if (page) {
          const newUrl = parseUrl(url);
          await setUrl(newUrl);
          const newConfig = {
            ...response.config,
            seo: {
              title,
              allow,
              favicon,
              keywords,
              socialImage,
              socialShare,
              description,
              url: newUrl,
              icon: favicon,
              socialDescription,
              ...body,
            },
          }
          const html = addMetas({...newConfig.seo, site: response.config.site}, response.body)
          const result = await props.putPage(siteId, response.title, {
            title: newUrl,
            published,
            body: html,
            config: newConfig,
          });
          preload(result)
          makeToast(enqueueSnackbar, 'Se guardó correctamente')
        }
      } catch (error) {
        console.log('handleSubmit', error);
        makeToast(enqueueSnackbar, 'Ocurrion un error guardando', 'error')
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const renderForm = () => {
    return (
      <div style={{ ...styles.form }}>
        <FormControl style={{ width: '100%' }} onSubmit={(ev) => handleSubmit(ev)}>

          <div style={{ width: '100%', borderBottom: 'gainsboro 2px solid', paddingBottom: 15, marginBottom: 20 }}>
            <div style={styles.panelTitle}>Ajustes</div>
            <div style={styles.panelSubtitle}>Optimice el SEO de su página, administre la configuración para compartir en redes sociales y configure analiticas personalizadas.</div>
          </div>

          <div style={styles.panelTitle}>Palabras clave</div>
          <div style={styles.panelSubtitle}>Palabras clave asociadas a tu página que podrían utilizar los usuarios cuando realicen una búsqueda.</div>
          <div style={{ ...styles.field, marginTop: 12 }} className="field">
            <TextArea
              id="keywords"
              type="keywords"
              value={keywords}
              disabled={loading}
              style={styles.input}
              placeholder=""
              aria-describedby="keywordsHelp"
              onChange={(e) => setKeywors(e.target.value)}
            />
          </div>

          <div style={styles.panelTitle}>Descripción</div>
          <div style={styles.panelSubtitle}>Un breve resumen de tu página que será mostrado por los buscadores o plataformas sociales.</div>
          <div style={{ ...styles.field, marginTop: 12 }} className="field">
            <TextArea
              type="text"
              id="description"
              value={description}
              disabled={loading}
              style={styles.input}
              aria-describedby="descriptionHelp"
              placeholder=""
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div style={styles.field} className="field">
            <FormControlLabel
              classes={{ root: classes.formControlLabelRoot }}
              control={
                <Checkbox
                  color="primary"
                  checked={allow}
                  onChange={(e) => setAllow(e.target.checked)}
                />
              }
              label="Permitir que los motores de búsqueda indexen esta página"
            />
          </div>

          <div style={styles.panelTitle}>Título de Social Share</div>
          <div style={styles.panelSubtitle}>El título de esta página aparece en redes sociales como Facebook, Twitter y otras</div>
          <div style={{ ...styles.field, marginTop: 12 }} className="field">
            <Input
              id="socialShare"
              type="socialShare"
              value={socialShare}
              disabled={loading}
              style={styles.input}
              placeholder=""
              aria-describedby="socialShareHelp"
              onChange={(e) => setSocialShare(e.target.value)}
            />
          </div>

          <div style={styles.panelTitle}>Descripción de Social Share</div>
          <div style={styles.panelSubtitle}>La descripción de esta página es visible en los resultados de búsqueda (entre 145-155 caracteres).</div>
          <div style={{ ...styles.field, marginTop: 12 }} className="field">
            <TextArea
              type="text"
              disabled={loading}
              style={styles.input}
              id="socialDescription"
              value={socialDescription}
              placeholder=""
              aria-describedby="socialDescriptionHelp"
              onChange={(e) => setSocialDescription(e.target.value)}
            />
          </div>

          <div style={styles.panelTitle}>Imagen de Social Share</div>
          <div style={styles.panelSubtitle}>Esta imagen aparece en las redes sociales (Facebook, Twitter, etc.) cuando se comparte su página.</div>
          <div style={{ ...styles.field, display: 'flex', marginTop: 12, columnGap: 10 }} className="field">
            <ImageUploader
              preview={true}
              onChange={uploadSocialImage}
              placeholder=""
              showIcon={true}
              previousImage={socialImage}
              dropContainerStyle={{ width: 180, height: 110 }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', rowGap: 10 }}>
              <button
                onClick={() => document.getElementsByClassName('dropzone')[1].click()}
                style={{ ...styles.actionButton, marginBottom: 0, fontSize: 14, padding: '23px 32px' }}
                className="button is-tertiary"
              >
                <><Publish style={{ fill: 'white', marginRight: 6 }} /> Subir Imagen</>
              </button>
              <div style={{ ...styles.panelSubtitle, color: 'grey', fontSize: 14 }}>El tamaño mínimo de imagen recomendado es 600 x 315 px. Nota. Puede pasar un tiempo antes de que vea estos cambios en las redes sociales.</div>
            </div>
          </div>
        </FormControl>
      </div>
    )
  }

  const toEdit = () => {
    window.open(`/marketing/pages/builder/${sites.defaultSite.id}/${url}`);
  }

  // const toPreview = () => {
  //   window.open(`/marketing/pages/builder/${sites.defaultSite.id}/${url}?preview=true`, '_blank');
  // }

  const handleActionSelect = (event) => {
    switch (event.target.value) {
      case 'borrar':
        onDelete();
        break;
      case 'duplicar':
        onDuplicate();
        break;
      default:
        break;
    }
  };

  const onDelete = () => {
    Alert({
      onSuccess: async () => {
        if (response) {
          await props.deletePage(sites.defaultSite.id, [response.id])
          history.replace(`/marketing/pages`);
        }
      },
      onFail: () => console.log('cancel'),
    });
  }

  const onDuplicate = () => {
    setDuplicateModal(true);
  }

  const onDuplicatePage = (response) => {
    if (response) {
      window.open(`/marketing/pages/view/${response.title}`);
    }
    setDuplicateModal(false);
  }

  return (
    <div style={{display: 'flex', flex: 1}}>
      <div style={styles.container}>

        <div style={styles.header}>
          <div onClick={() => window.history.back()} style={styles.mainTitle}>
            <KeyboardBackspace style={{ fill: '#024976' }} />
                Páginas
            </div>
        </div>

        <div style={styles.content}>

          <div style={{ ...styles.panel, ...styles.profilePanel }}>

            {
              pageReady ? (
                <>
                  <div style={{ ...styles.form, ...styles.panelSection }}>
                    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
                      <div style={styles.field} className="field">
                        <TextField
                          id="title"
                          type="text"
                          value={title}
                          disabled={loading}
                          label="Título de la página"
                          aria-describedby="titleHelp"
                          onChange={(e) => setTitle(e.target.value)}
                          variant="outlined"
                          classes={{ root: classes.textFieldRoot }}
                        />

                        <div style={styles.actions}>
                          <div style={{ display: 'flex', columnGap: 6 }}>
                            {/* <button
                              onClick={toPreview}
                              disabled={loading}
                              style={{ ...styles.actionButton, marginBottom: 0, fontSize: 13, fontWeight: '500', padding: '16px 16px' }}
                              className="button is-tertiary"
                            >
                              {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : <><Visibility style={{ fill: 'white', marginRight: 8 }} />Preview</>}
                            </button> */}
                            <button
                              onClick={toEdit}
                              disabled={loading}
                              style={{ ...styles.actionButton, marginBottom: 0, fontSize: 13, fontWeight: '500', padding: '16px 16px' }}
                              className="button is-light"
                            >
                              {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Editar template'}
                            </button>
                          </div>
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="action-select-label"
                              id="action-select"
                              value={action}
                              onChange={handleActionSelect}
                              classes={{ root: classes.selectRoot }}
                            >
                              <MenuItem value='none' disabled>Mas</MenuItem>
                              <MenuItem value={'borrar'}>Borrar</MenuItem>
                              <MenuItem value={'duplicar'}>Duplicar</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </FormControl>
                  </div>

                  <div style={styles.separator} />

                  <div style={{ ...styles.form, ...styles.panelSection }}>
                    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>

                      <div style={styles.panelTitle}>URL de la página</div>
                      <div style={styles.panelSubtitle}>Cambie la URL de su página o agregue su dominio para publicar con su propia URL.</div>

                      <div style={{ ...styles.field, marginTop: 10, display: 'flex', alignItems: 'center', columnGap: 10 }} className="field">
                        <div style={{ ...styles.panelSubtitle, fontSize: 16, color: 'black' }}>example.com/</div>
                        <Input
                          type="text"
                          id="url"
                          value={url}
                          disabled={loading}
                          style={styles.input}
                          aria-describedby="urlHelp"
                          placeholder="Título de la página"
                          onChange={(e) => setUrl(e.target.value)}
                        />
                      </div>
                    </FormControl>
                  </div>

                  <div style={styles.separator} />

                  <div style={{ ...styles.form, ...styles.panelSection }}>
                    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>
                      <div style={styles.panelTitle}>Estado</div>
                      <RadioGroup 
                        classes={{ root: classes.radioGroupRoot }} 
                        onChange={(ev) => setPublished(ev.target.value === 'true' ? true : false )} 
                        value={published.toString()}
                        name="status"
                      >
                        <FormControlLabel
                          classes={{ root: classes.formControlLabelRoot }}
                          value={'true'}
                          control={
                            <Radio classes={{ root: classes.radioRoot }} />
                          }
                          label="Público"
                        />
                        <FormControlLabel
                          classes={{ root: classes.formControlLabelRoot }}
                          value={'false'}
                          control={
                            <Radio classes={{ root: classes.radioRoot }} />
                          }
                          label="Borrador"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div style={styles.separator} />

                  <div style={{ ...styles.form, ...styles.panelSection }}>
                    <FormControl style={{ width: '100%' }} onSubmit={handleSubmit}>

                      <div style={styles.panelTitle}>Favicon</div>
                      <div style={styles.panelSubtitle}>Agregue un favicon para personalizar el pequeño ícono junto al título de su página en las pestañas del navegador.</div>

                      <div style={{ ...styles.field, display: 'flex', columnGap: 10, marginTop: 16 }} className="field">
                        <ImageUploader
                          preview={true}
                          onChange={uploadFavicon}
                          placeholder=""
                          previousImage={favicon}
                          showIcon={true}
                          dropContainerStyle={{ width: 110, height: 110 }}
                        />
                        <button
                          onClick={() => document.getElementsByClassName('dropzone')[0].click()}
                          style={{ ...styles.actionButton, marginBottom: 0, fontSize: 14, padding: '23px 32px' }}
                          className="button is-tertiary"
                        >
                          Subir
                        </button>
                      </div>

                      <button
                        disabled={loading || !enableEdit}
                        onClick={(ev) => handleSubmit(ev)}
                        className="button is-light"
                        style={styles.submitButton}
                      >
                        {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : 'Guardar'}
                      </button>
                    </FormControl>
                  </div>

                </>
              ) : <Loader />
            }

            <DuplicatePage
              pageId={page}
              show={duplicateModal}
              onSave={onDuplicatePage}
              siteId={sites.defaultSite.id}
              onClose={() => setDuplicateModal(false)}
            />

          </div>

          <div style={{ ...styles.panel, ...styles.feedPanel }}>
            {
              pageReady ? (
                <>
                  <div style={{ ...styles.panelSection }}>

                    <AppBar classes={{ root: classes.appBarRoot }} position="static">
                      <Tabs value={tab} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator, }} onChange={handleTabChange}>
                        <Tab classes={{ root: classes.tabRoot }} label="SEO & SOCIAL" />
                        <Tab classes={{ root: classes.tabRoot }} label="Actividad" />
                      </Tabs>
                    </AppBar>

                    {tab === 0 && (
                      <Box>
                        {renderForm()}
                      </Box>
                    )}

                    {tab === 1 && (
                      <Box>
                        <ActivitiesList url={`site/${sites.defaultSite.id}/landing/${page}/activity`} />
                      </Box>
                    )}

                  </div>
                </>
              ) : <Loader />
            }

          </div>

          {/* <div style={{ ...styles.panel, ...styles.statsPanel }}>

            <div style={{ ...styles.panelSection, paddingBottom: 15 }}>
              <div style={{ ...styles.panelTitle }}>Estadisticas</div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <ShoppingCart style={{ fill: '#00ace7' }} />Compras
                </div>
                <div style={styles.statValue}>3</div>
              </div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <MonetizationOn style={{ fill: '#00ace7' }} />Gastado
                </div>
                <div style={styles.statValue}>$100.00</div>
              </div>

              <div style={styles.stat}>
                <div style={styles.statName}>
                  <DateRange style={{ fill: '#00ace7' }} />Última Actividad
                </div>
                <div style={styles.statValue}>10/10/2020</div>
              </div>

            </div>

            <div style={{ ...styles.panelSection, paddingTop: 0 }}>
              <div style={{ ...styles.panelTitle, marginBottom: 12 }}>Etiquetas</div>
              <div style={styles.tagList}>
                <div style={styles.tag}>Tag 01</div>
                <div style={styles.tag}>Tag 02</div>
                <div style={styles.tag}>Tag 03</div>
              </div>
            </div>

            <div style={{ ...styles.panelSection }}>
              <div style={styles.panelTitle}>Visitas</div>
              <VisitsChart
                data={visits}
                title={'Visitas'}
                color='#00ace7'
              />
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#f6f6f6',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    padding: '30px 30px 15px 30px',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    padding: '0px 30px 30px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 30
  },
  mainTitle: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: 'rgb(2, 73, 118)',
    cursor: 'pointer'
  },
  panel: {
    width: 330,
    minWidth: 330,
    backgroundColor: 'white',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  profilePanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  feedPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  statsPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  panelSection: {
    padding: '20px 30px',
    width: '100%'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 8
  },
  panelSubtitle: {
    color: 'rgb(51, 55, 66)',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: '500',
    textAlign: 'left',
    fontFamily: 'Montserrat'
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)',
  },
  name: {
    fontFamily: 'Montserrat',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginTop: 12
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '500',
    color: '#565656',
    marginTop: 3
  },
  createdAt: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: '500',
    color: '#565656',
    marginTop: 5
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10
  },
  actionButton: {
    fontSize: 14,
    padding: '26px 36px',
    borderRadius: 8
  },
  form: {
    marginTop: 10
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  submitButton: {
    fontSize: 16,
    padding: '26px 0px',
    borderRadius: 8,
    width: '100%'
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  statValue: {
    fontFamily: 'Montserrat',
    color: '#024976'
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 8,
    rowGap: 8
  },
  tag: {
    backgroundColor: 'rgb(246, 246, 246)',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: 12,
    borderRadius: 10,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    color: 'rgb(177, 177, 177)'
  }
}

const visits = [
  {
    "label": "Lun",
    "value": 780
  },
  {
    "label": "Mar",
    "value": 500
  },
  {
    "label": "Mie",
    "value": 270
  },
  {
    "label": "Jue",
    "value": 760
  },
  {
    "label": "Vie",
    "value": 480
  },
  {
    "label": "Sab",
    "value": 1000
  },
  {
    "label": "Dom",
    "value": 280
  },
]

const mapStateToProps = ({ sites }) => {
  return { sites };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchPages, fetchLanding, postPage, putPage, deletePage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);