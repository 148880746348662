import React from 'react'
import PropTypes from 'prop-types'
import './Course.scss'


function Video(props) {
  return (
    <div onClick={props.onChange} className='course-item-container'>
      <div className="course-item-label">
        <div className="_info">
          <label className='course-name'>{props.title}</label>
          <label className='course-date'>{props.createdAt}</label>
        </div>
        <div className="_menu">
        </div>
      </div>
      <img className='course-item-image' src={props.background} />
    </div>
  )
}

Video.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Video
