import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export const Container = memo(function Container(props) {
  return (
    <div className="section-container">
      {props.children}
    </div>
  )
})

Container.propTypes = {
  children: PropTypes.node.isRequired,
}
