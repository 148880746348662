import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { ReactSortable } from "react-sortablejs";
import { CircularProgress } from '@material-ui/core';
import { fetchContent, updateContentSort, updateContentSortLocal } from '../../../../redux/actions/content';
import ContentItem from './ContentItem';

const ListContent = ({ content, sites, parentModule, status, loaded, updateContentSort, updateContentSortLocal, fetchContent }) => {
  const { course } = useParams();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const data = await fetchContent(sites.defaultSite.id, course, parentModule);
    if (data.length) {
      loaded()
    } else if (status) {
      loaded()
    }
  }

  if (!content.data[parentModule] || !content.data[parentModule].length) {
    return null;
  }

  return (
    <ReactSortable
      tag="div"
      style={{display: 'flex',flexDirection: 'column', rowGap: 8}}
      list={content.data[parentModule]}
      setList={(newArray) => updateContentSortLocal(newArray, parentModule)}
      onEnd={() => updateContentSort(sites.defaultSite.id, course, content.data[parentModule])}
    >
      {
        content.data[parentModule].map((item, index) => (
          <ContentItem key={`content-item-${index}`} parentModule={parentModule} {...item} />
        ))
      }
    </ReactSortable>
  );
};

const mapStateToProps = ({ sites, content }) => {
  return { sites, content };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateContentSort, updateContentSortLocal, fetchContent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContent);