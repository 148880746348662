import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const defaultStyles = theme => ({
  main: {
    width: 430,
    display: 'flex',
    marginBottom: 20,
    position: 'relative',
  },

  searchIcon: {
    right: 10,
    zIndex: 1,
    width: 28,
    height: 28,
    fill: '#024976',
    position: 'absolute',
    top: 'calc(50% - 5px)',
    color: theme.palette.text.secondary,
  },
  searchText: {
    color: 'red',
  },
  input: {
    background: '#f6f6f6',
    height: 60,
    color: '#979797',
    border: 'none',
    borderRadius: 12,
    width: 430,
    height: 58,
    padding: '0px 20px',
    fontFamily: 'Montserrat',
    fontSize: 18,
    transition: 'background 0.4s'
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

class _DebounceTableSearch extends React.Component {
  handleTextChangeWrapper = debouncedSearch => {
    return function (event) {
      debouncedSearch(event.target.value);
    };
  };

  render() {
    const { classes, searchText, debounceWait, loading } = this.props;

    const debouncedSearch = debounce(value => {
      this.props.onSearch(value);
    }, debounceWait);

    return (
      <div className={classes.main}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          autoFocus={true}
          disabled={loading}
          className={classes.searchText}
          InputProps={{
            'data-test-id': 'Buscar',
            'aria-label': 'Buscar',
            className: classes.input,
            disableUnderline: true
          }}
          fullWidth={true}
          label="Busca aquí"
          placeholder="Busca aquí"
          inputRef={el => (this.searchField = el)}
          defaultValue={searchText === ' ' ? '' : searchText}
          onChange={this.handleTextChangeWrapper(debouncedSearch)}
        />
      </div>
    );
  }
}

var DebounceTableSearch = withStyles(defaultStyles, { name: 'MUIDataTableSearch' })(_DebounceTableSearch);
export { DebounceTableSearch };
