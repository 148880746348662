import config from './config.json';

export default {
  BASE_URL: config.api.BASE_URL,
  BASE_MEDIA: config.api.BASE_MEDIA,
  BASE_VIDEO: config.api.BASE_VIDEO,
  API_URL: config.api.baseUrl,
  BEE_FREE_CLIENT_ID: config.beefree.CLIENT_ID,
  BEE_FREE_CLIENT_SECRET: config.beefree.CLIENT_SECRET,
  BEE_FREE_PAGE_BUILDER_CLIENT_ID: config.beefreepagebuilder.CLIENT_ID,
  BEE_FREE_PAGE_BUILDER_CLIENT_SECRET: config.beefreepagebuilder.CLIENT_SECRET
};
