import { parseUrl } from "../../helpers/utils";
import network from "../../utils/network";

export const FETCH_SITES = 'FETCH_SITES';
export const FAIL_FETCH_SITES = 'FAIL_FETCH_SITES';
export const SUCCESS_FETCH_SITES = 'SUCCESS_FETCH_SITES';
export const SUCCESS_FETCH_SITES_TABLE = 'SUCCESS_FETCH_SITES_TABLE';

export const SELECT_SITE = 'SELECT_SITE';
export const FAIL_SELECT_SITE = 'FAIL_SELECT_SITE';
export const SUCCESS_SELECT_SITE = 'SUCCESS_SELECT_SITE';

export const MENU_COLLAPSE = 'MENU_COLLAPSE';
export const SUCCESS_MENU_COLLAPSE = 'SUCCESS_MENU_COLLAPSE';

export const FETCHING_SITE = 'FETCHING_SITE';
export const FAIL_FETCHING_SITE = 'FAIL_FETCHING_SITE';
export const SUCCESS_FETCHING_SITE = 'SUCCESS_FETCHING_SITE';

export const FETCH_PAGE = 'FETCH_PAGE';
export const FAIL_FETCH_PAGE = 'FAIL_FETCH_PAGE';
export const SUCCESS_FETCH_PAGE = 'SUCCESS_FETCH_PAGE';

export const POST_SITE = "POST_SITE"
export const POST_FAIL_SITE = "POST_FAIL_SITE"
export const POST_SUCCESS_SITE = "POST_SUCCESS_SITE"

export const PUT_SITE = "PUT_SITE"
export const PUT_FAIL_SITE = "PUT_FAIL_SITE"
export const PUT_SUCCESS_SITE = "PUT_SUCCESS_SITE"

export const DELETE_SITE = "DELETE_SITE"
export const DELETE_FAIL_SITE = "DELETE_FAIL_SITE"
export const DELETE_SUCCESS_SITE = "DELETE_SUCCESS_SITE"

export const selectSite = (data) => {
  return dispatch => {
    localStorage.setItem('SITE_DEFAULT', data.id);
    dispatch({ type: SELECT_SITE, default: data });
    window.location.reload()
  };
};

export const setCollapse = (value) => {
  return dispatch => {
    localStorage.setItem('MENU_COLLAPSE', value);
    dispatch({ type: MENU_COLLAPSE, default: value });
  };
};

export const loadSites = () => {
  return async dispatch => {
    dispatch({ type: FETCH_SITES });
    try {
      const response = await network.get('site');
      const defaultData = localStorage.getItem('SITE_DEFAULT');
      const collapse = localStorage.getItem('MENU_COLLAPSE');
      let finded = response.data.find(item => item.id === defaultData);
      if (!finded) finded = response.data[0];
      dispatch({
        default: finded,
        data: response.data,
        type: SUCCESS_FETCH_SITES,
        collapse: collapse === "true" ? true : false,
      });
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_SITES,
      });
    }
  };
};

export const fetchSites = (limit, offset = null, column, direction, search, searchtext) => {
  const url = parseUrl(`site/datatable?`, limit, offset, column, direction, search, searchtext)
  return async dispatch => {
    dispatch({ type: FETCH_SITES });
    try {
      const response = await network.get(url);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCH_SITES,
        });
      } else {
        const defaultData = localStorage.getItem('SITE_DEFAULT');
        let finded = response.data.data.find(item => item.id === defaultData);
        if (!finded) finded = response.data.data[0];
        dispatch({
          data: {
            limit,
            offset,
            search,
            searchtext,
            table: response.data.data,
            count: response.data.count,
            order: { column, direction },
          },
          type: SUCCESS_FETCH_SITES_TABLE,
        });
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_SITES,
      });
    }
  };
};

export const fetchSite = (site) => {
  return async dispatch => {
    dispatch({ type: FETCHING_SITE });
    try {
      const response = await network.get(`site/${site}`);
      if (response.data && response.data.error) {
        dispatch({
          error: response.message,
          type: FAIL_FETCHING_SITE,
        });
      } else {
        dispatch({
          data: response.data,
          type: SUCCESS_FETCHING_SITE,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCHING_SITE,
      });
    }
  };
};

export const loadPage = (site) => {
  return async dispatch => {
    dispatch({ type: FETCH_PAGE });
    try {
      const response = await network.get(`site/${site}/pages`);
      dispatch({
        data: response.data,
        type: SUCCESS_FETCH_PAGE,
      });
    } catch (error) {
      dispatch({
        error,
        type: FAIL_FETCH_PAGE,
      });
    }
  };
};

export const putSite = (id, body) => {
  return async dispatch => {
    dispatch({ type: PUT_SITE });
    try {
      const response = await network.put(`site/${id}`, body);
      dispatch({
        data: response.data,
        type: PUT_SUCCESS_SITE,
      });
    } catch (error) {
      dispatch({
        error,
        type: PUT_FAIL_SITE,
      });
    }
  };
};

export const postSite = (body) => {
  return async dispatch => {
    dispatch({ type: POST_SITE });
    try {
      const response = await network.post(`site`, body);
      dispatch({
        data: response.data,
        type: POST_SUCCESS_SITE,
      });
    } catch (error) {
      dispatch({
        error,
        type: POST_FAIL_SITE,
      });
    }
  };
};

export const deleteSites = (ids = []) => {
  const promises = ids.map((item) => {
    return network.del(`site/${item}`);
  });
  return async dispatch => {
    dispatch({ type: DELETE_SITE });
    try {
      const response = await Promise.all(promises)
      dispatch({
        type: DELETE_SUCCESS_SITE,
      });
      return response;
    } catch (error) {
      dispatch({
        error,
        type: DELETE_FAIL_SITE,
      });
      throw error;
    }
  };
};