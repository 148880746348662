import React from "react";
import { PubSub } from "aws-amplify";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CircularProgress } from "@material-ui/core";
import { Button as ButtonS } from "./WebsiteStyledComponents";
import network from "../../../utils/network";
import { makeToast } from "../../../utils";
import classes from "./Website.module.scss";

const url = "/tina/";

class Website extends React.Component {
  state = {
    loading: false,
    selectedSite: {},
    iframeLoading: true,
  };

  remove = async (e, id) => {
    e.preventDefault();
    await network.del("site/" + id);
  };

  publish = async () => {
    const {
      sites: { defaultSite },
    } = this.props;
    if (defaultSite && defaultSite.id) {
      try {
        await this.setState({ loading: true });
        const response = await network.get(`site/${defaultSite.id}/publish`);
        if (response.data) {
          this.listenPublish(defaultSite.id);
          makeToast(this.props.enqueueSnackbar, "Publicando...", "info");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    this.setState({ loading: false });
  };

  listenPublish = (site) => {
    console.log("listening...", `site/${site}/publish`);
    PubSub.subscribe(`site/${site}/publish`).subscribe({
      error: (error) => console.log(error),
      next: (data) => {
        makeToast(this.props.enqueueSnackbar, "Publicado");
      },
      close: () => {
        console.log("PubSub.subscribe close");
      },
    });
  };

  renderPagesList = () => {
    const {
      sites: { defaultSite },
    } = this.props;

    if (!defaultSite || !defaultSite.id) return null;

    const rows = [];
    const paginas = [

      { image: '', key: 'sitio-section', name: 'Sitio', section: true },
      { image: '/images/inicio.png', key: "home", name: "Inicio" },
      { image: '/images/login.png', key: "sign-in", name: "Login" },
      { image: '/images/registro.png', key: "sign-up", name: "Registro" },
      { image: '/images/forgot-preview.png', key: "forgot-password", name: "Recuperar Contraseña" },
      { image: '/images/contacto.png', key: "contact", name: "Contacto" },
      { image: '/images/404.png', key: "not-found", name: "404 No encontrado" },
      { image: '/images/faq-preview.png', key: "faq", name: "Preguntas Frecuentes" },
      { image: '/images/faq-preview.png', key: "about", name: "Nosotros" },

      { image: '', key: 'tienda-section', name: 'Tienda', section: true },
      // { image: '/images/curso-informacion.png', key: "course-detail", name: "Landing curso" },
      { image: '/images/cursos.png', key: "course-catalog", name: "Catálogo" },
      { image: '/images/cart-preview.png', key: "cart", name: "Carrito de compras" },
      { image: '/images/order-completed-preview.png', key: "order-completed", name: "Orden completada" },
      // { image: '/images/curso.png', key: "course", name: "Player curso" },
      { image: '/images/cursos.png', key: "my-courses", name: "Mis Cursos" },
      { image: '/images/favorites-preview.png', key: "my-favorites", name: "Mis Favoritos" },
      { image: '/images/cursos.png', key: "category", name: "Categorías" },

      { image: '', key: 'politicas-section', name: 'Políticas', section: true },
      { image: '/images/terms-preview.png', key: "terms", name: "Términos y Condiciones" },
      { image: '/images/terms-preview.png', key: "legal-advice", name: "Aviso Legal" },
      { image: '/images/privacy-preview.png', key: "privacy-policy", name: "Política de Privacidad" },
      { image: '/images/refund-preview.png', key: "refund-policy", name: "Política de Reembolso" }
    ];

    !this.state.selectedSite.key &&
      this.setState({
        selectedSite: {
          ...paginas[1],
          url: `${url + (defaultSite && defaultSite.id)}/${paginas[1]["key"]}`,
        },
      });

    paginas &&
      paginas.forEach((page, index) => {
        page["section"]
          ? rows.push(
            <div key={page["name"]} className={classes.section}>
              {page["name"]}
            </div>
          )
          : rows.push(
            <div
              className={classes.touchableOpacity}
              key={page["name"]}
              onClick={() =>
                this.setState({
                  selectedSite: {
                    image: page["image"],
                    key: page["key"],
                    name: page["name"],
                    url: `${url + (defaultSite && defaultSite.id)}/${page["key"]
                      }`,
                  },
                  iframeLoading: true,
                })
              }
              style={{
                ...styles.pageContainer,
                ...(this.state.selectedSite.key === page["key"]
                  ? styles.pageContainerSelected
                  : null),
              }}
            >
              {page["name"]}
            </div>
          );
      });
    return rows;
  };

  render() {
    const {
      sites: { loading, defaultSite },
    } = this.props;
    const {
      selectedSite: { image, key, name },
    } = this.state;
    return (
      <div className={classes.main_container}>
        <div className="">
          <section className={classes.auth} className="auth">
            <div className={classes.content}>
              <div className={classes.pagesListWrapper}>
                <div className={classes.header}>
                  <div className={classes.container1}>
                    <div className={classes.title}>
                      Construye tu sitio ahora
                    </div>
                    <div className={classes.container3}>
                      Selecciona el template de página que quieras usar y
                      comienza ahora
                    </div>
                  </div>

                  <button
                    className={classes.publishBtn}
                    onClick={this.publish}
                    disabled={this.state.loading}
                  >
                    Publicar
                  </button>
                </div>

                <div className={classes.scrollView}>
                  <div className={classes.pagesList}>
                    {!loading && this.renderPagesList()}
                  </div>
                </div>
              </div>

              <div className={classes.pagePreviewWrapper}>
                {key && (
                  <div className={classes.pagePreview}>
                    <div className={classes.pageName}>{name}</div>
                    <div className={classes.pageImageWrapper}>
                      {this.state.iframeLoading && (
                        <div className={classes.container4}>
                          <img className={classes.pageImage} src={image} />
                          <CircularProgress style={{ color: '#00A8D8' }}/>
                        </div>
                      )}
                      <iframe
                        className={classes.iframe}
                        onLoad={() => this.setState({ iframeLoading: false })}
                        src={`/tina/${defaultSite && defaultSite.id}/${key}?preview=true`}
                      />
                    </div>
                    <div className={classes.container5}>
                      <Link
                        style={{ textDecoration: "none", width: "49%" }}
                        to={`tina/${defaultSite && defaultSite.id}/${key}`}
                      >
                        <div className={classes.touchableOpacity}>
                          <button
                            className={classes.button}
                            style={{ fontSize: 16, padding: 10 }}
                            backgroundColor="#1a9e75"
                            color="#fff"
                          >
                            Comenzar a Editar Página
                          </button>
                        </div>
                      </Link>
                      <Link
                        target="_blank"
                        style={{ textDecoration: "none", width: "49%" }}
                        to={`tina/${defaultSite && defaultSite.id
                          }/${key}?preview=true`}
                      >
                        <div className={classes.touchableOpacity}>
                          <ButtonS style={{ fontSize: 16, padding: 10 }} backgroundColor="#2F4B72" color="#fff">Visualizar Página</ButtonS>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const styles = {
  pageContainer: {
    width: '47%',
    height: 50,
    textDecoration: 'none',
    textAlign: 'left',
    font: 'normal normal 600 14px Montserrat',
    letterSpacing: 0.2,
    color: '#7D8595',
    textTransform: 'uppercase',
    boxShadow: '0px 4px 6px 2px #0000000f',
    padding: '12px 18px',
    marginBottom: 18,
    display: 'flex',
    borderRadius: 3,
    alignItems: 'center',
    border: 'transparent 3px solid',
    transition: 'border 0.4s, color 0.4s'
  },
  pageContainerSelected: {
    color: '#1a9e75',
    border: '#1a9e75 3px solid'
  },
}

const mapStateToProps = ({ sites }) => {
  return { sites };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Website));