import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Chart from 'chart.js'
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { CircularProgress } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { getChart, getDashboard } from '../../../helpers/dashboard'
import { Counter } from '../../../components'
import './Dashboard.scss'
import { Header, Title, MainContent, DashboardCounters, Subtitle, Filter, DaysCounter, MainTitle } from './DashboardStyledComponents'
function Dashboard(props) {
  const [requestedData, setrequestedData] = useState([])
  const [loading, setLoading] = useState(true)
  const [pages, setPages] = useState([])
  const [visits, setVisits] = useState(0)
  const [contacts, setContacts] = useState(0)
  const [initial, setInitial] = useState(moment().clone().startOf('month').toDate())
  const [final, setFinal] = useState(moment().clone().endOf('month').toDate())

  useEffect(() => {
    loadDemographics(moment(initial).format('YYYY-MM-DD'), moment(final).format('YYYY-MM-DD'))
    loadHistoric(moment(initial).format('YYYY-MM-DD'), moment(final).format('YYYY-MM-DD'))
  }, [initial, final])

  const loadHistoric = async (initial = null, final = null) => {
    const data = await getDashboard(props.sites.defaultSite.id, initial, final)
    await loadChart(data.people)
    await loadChartVisits(data.visits)
  }

  const loadDemographics = async (initial = null, final = null) => {
    try {
      const data = await getChart(props.sites.defaultSite.id, {
        requestedData: [
          {
            props: ['osPlatform', 'createdAt'],
            groupBy: ['osPlatform'],
            aggregations: ['count(*)'],
            orderBy: ['createdAt'],
            where: [`date(createdAt) between '${initial}' and '${final}'`]
          },
          {
            props: ['browserName', 'createdAt'],
            groupBy: ['browserName'],
            aggregations: ['count(*)'],
            orderBy: ['createdAt'],
            where: [`date(createdAt) between '${initial}' and '${final}'`]
          },
          {
            props: ['urlPath'],
            groupBy: ['urlPath'],
            aggregations: ['count(*)'],
            orderBy: ['count(*) DESC'],
            limit: 10,
            where: [`date(createdAt) between '${initial}' and '${final}'`]
          },
          {
            table: 'course',
            props: [],
            groupBy: [],
            orderBy: [],
            where: [`deleted is NULL and date(createdAt) between '${initial}' and '${final}'`],
            aggregations: ['count(*)'],
          },
          {
            table: 'webinar',
            props: [],
            groupBy: [],
            orderBy: [],
            where: [`deleted is NULL and date(createdAt) between '${initial}' and '${final}'`],
            aggregations: ['count(*)']
          },
          {
            table: 'people',
            props: [],
            orderBy: [],
            groupBy: [],
            where: [`deleted is NULL and date(createdAt) between '${initial}' and '${final}'`],
            aggregations: ['count(*)']
          },
          {
            table: 'people',
            props: [],
            orderBy: [],
            groupBy: [],
            where: [`userid IS NOT NULL and date(createdAt) between '${initial}' and '${final}'`],
            aggregations: ['count(*)']
          },
          {
            table: 'user_course',
            props: [],
            orderBy: [],
            groupBy: [],
            where: [`deleted is NULL and date(createdAt) between '${initial}' and '${final}'`],
            aggregations: ['REPLACE(FORMAT(SUM(amountPaid)/100, 2), ",", "")']
          },
        ]
      })
      await setLoading(false)
      await setPages(data.datasets[2])
      await setrequestedData(data.datasets)
      await parseBrowser(data.datasets[1])
      await parseDemographics(data.datasets[0])
    } catch (error) {
      console.log('error', error)
    }
  }

  const parseBrowser = (response = []) => {
    if (response && response.length) {
      const labels = response.map(item => item.label)
      const data = response.map(item => +item.data)
      const ctx = document.getElementById('browser')
      if (ctx) 
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data,
              backgroundColor: [
                '#5ECCDD',
                '#20525A',
                '#79AB49',
                '#C29737',
                '#F4CE48',
                '#F39D38',
              ],
              label: 'Dispositivos',
              pointBackgroundColor: '#fff'
            }
          ]
        },
        options: {
          responsive: true,
          title: 'Dispositivos',
          legend: {
            display: true,
            align: 'left',
            position: 'top',
            labels: {
              color: 'rgb(255, 99, 132)'
            }
          }
        }
      })
    }
  }

  const parseDemographics = (response = []) => {
    if (response && response.length) {
      const labels = response.map(item => item.label)
      const data = response.map(item => +item.data)
      const ctx = document.getElementById('devices')
      if (ctx)
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data,
              backgroundColor: [
                '#5ECCDD',
                '#20525A',
                '#79AB49',
                '#C29737',
                '#F4CE48',
                '#F39D38',
              ],
              label: 'Dispositivos',
              pointBackgroundColor: '#fff'
            }
          ]
        },
        options: {
          responsive: true,
          title: 'Dispositivos',
          legend: {
            display: true,
            align: 'left',
            position: 'top',
            labels: {
              color: 'rgb(255, 99, 132)'
            }
          }
        }
      })
    }
  }

  const loadChart = (response = []) => {
    if (response && response.length) {
      let count = 0;
      const parsed = response.map(item => {
        count += +item.count
        return ({
          x: item.month,
          y: +item.count
        })
      })
      setContacts(count)
      const labels = parsed.map(item => item.x)
      const ctx = document.getElementById('contacts')
      if (ctx)
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              data: parsed,
              borderColor: '#00ACE750',
              pointBorderColor: '#00ACE7',
              backgroundColor: '#00ACE750',
              label: 'Usuarios registrados',
              pointBackgroundColor: '#fff'
            }
          ]
        },
        options: {
          responsive: true,
          title: 'Usuarios registrados',
          legend: {
            display: false
          }
        }
      })
    }
  }

  const loadChartVisits = (response = []) => {
    if (response && response.length) {
      let count = 0;
      const parsed = response.map(item => {
        count += +item.count
        return ({
          x: item.month,
          y: +item.count
        })
      })
      setVisits(count)
      const labels = parsed.map(item => item.x)
      const ctx = document.getElementById('visits')
      if (ctx)
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              data: parsed,
              borderColor: '#00ACE750',
              pointBorderColor: '#00ACE7',
              backgroundColor: '#00ACE750',
              label: 'Visitas',
              pointBackgroundColor: '#fff'
            }
          ]
        },
        options: {
          responsive: true,
          title: 'Visitas',
          legend: {
            display: false
          }
        }
      })
    }
  }

  return (
    <div className={props.sites.collapse ? 'dashboard-container-collapse' : 'dashboard-container'}>
      <Header>
        <MainTitle>
          <Title>Estadísticas: {props.sites.defaultSite.title}</Title>
          <DaysCounter> (Últimos 30 días) </DaysCounter>
        </MainTitle>
        <Filter>
          <div className="field">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                value={initial}
                disableToolbar
                margin='normal'
                format='MM/dd/yyyy'
                id='date-picker-inline'
                style={{ width: '100%' }}
                label='Selecionar fecha'
                onChange={newDate => setInitial(newDate)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="field">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                value={final}
                disableToolbar
                margin='normal'
                format='MM/dd/yyyy'
                id='date-picker-inline'
                style={{ width: '100%' }}
                label='Selecionar fecha'
                onChange={newDate => setFinal(newDate)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Filter>
      </Header>
      <MainContent>
        <DashboardCounters>
          <Counter
            label='Nuevos contactos'
            loading={!requestedData.length}
            number={requestedData.length ? +requestedData[5][0].data : 0}
            img={"/images/dash_contacts.png"}
          />
          <Counter
            label='Nuevos usuarios'
            loading={!requestedData.length}
            number={requestedData.length ? +requestedData[6][0].data : 0}
            img={"/images/dash_users.png"}
          />

          <Counter
            label='Cursos creados'
            loading={!requestedData.length}
            number={requestedData.length ? +requestedData[3][0].data : 0}
            img={"/images/dash_courses.png"}
          />
          <Counter
            label='Eventos creados'
            loading={!requestedData.length}
            number={requestedData.length ? +requestedData[4][0].data : 0}
            img={"/images/dash_events.png"}
          />
          <Counter
            label='Ventas del periodo'
            format="$"
            loading={!requestedData.length}
            number={requestedData.length ? +requestedData[7][0].data : 0}
            img={"/images/dash_sales.png"}
          />

        </DashboardCounters>
        <div className='dashboard-content-chart'>
          <div className="dashboard-content">
            <div className="dashboard-content-header">
              <Subtitle>Visitas</Subtitle>
              <Subtitle>{visits}</Subtitle>
            </div>
            {
              loading && (
                <CircularProgress style={{ color: '#00A8D8' }} />
              )
            }
            <canvas id='visits' height="250" />
          </div>
          <div className="dashboard-content">
            <div className="dashboard-content-header">
              <Subtitle>Contactos</Subtitle>
              <Subtitle>{contacts}</Subtitle>
            </div>
            {
              loading && (
                <CircularProgress style={{ color: '#00A8D8' }} />
              )
            }
            <canvas id='contacts' height="250" />
          </div>
        </div>
        <div className="charts-container">
          <div className="dashboard-content">
            <Subtitle>Dispositivos</Subtitle>
            {
              loading ? (
                <CircularProgress style={{ color: '#00A8D8' }} />
              ) : (
                <div className="padding-10">
                  <canvas id='devices' height="250" />
                </div>
              )
            }
          </div>
          <div className="dashboard-content">
            <Subtitle>Plataforma</Subtitle>
            {
              loading ? (
                <CircularProgress style={{ color: '#00A8D8' }} />
              ) : (
                <div className="padding-10">
                  <canvas id='browser' height="250" />
                </div>
              )
            }
          </div>
          <div className="dashboard-content">
            <table className="content_table ">
              <thead>
                <tr>
                  <th>Página</th>
                  <th>Visitas</th>
                </tr>
              </thead>
              {
                loading ? (
                  <CircularProgress style={{ color: '#00A8D8' }} />
                ) : (
                  <tbody>
                    {pages.map((item, index) => {
                      return (
                        <tr key={`tr-${index}`}>
                          <td className="text-align-left">{item.label}</td>
                          <td className="text-align-left">{item.data || '0'}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                )
              }
            </table>
          </div>
        </div>
      </MainContent>
    </div>
  )
}

Dashboard.propTypes = {}

const mapStateToProps = ({ sites }) => {
  return { sites }
}

export default connect(mapStateToProps)(Dashboard)