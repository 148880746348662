import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux';
import { Close } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { FormControl, CircularProgress, Modal, makeStyles } from '@material-ui/core';
import { uploadFile, listeningUpload } from '../../../helpers/files';
import { fetchVideos, putVideo } from '../../../redux/actions/videos';
import VideoUploader from '../courses/components/VideoUploader';
import { Label, Input } from '../StyledComponents';
import { makeToast } from '../../../utils'
import { getTags } from '../../../helpers/VideoTags';

import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 20,
    top: '50%',
    left: '50%',
    width: '40%',
    borderRadius: 4,
    minHeight: '20vh',
    overflow: 'hidden',
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'absolute',
    boxShadow: theme.shadows[5],
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    '& .MuiFilledInput-input': {
      backgroundColor: '#fff',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
    },
  },
}))

function CourseCreate(props) {

  const { sites, edit } = props
  const { videos: { limit, offset, data } } = props;
  const classes = useStyles()

  const [name, setName] = useState('');
  const [tags, setTags] = useState([]);
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar()
  const [saving, setSaving] = useState(false)
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [preparing, setPreparing] = useState(false)
  const [uploading, setUploading] = useState(false);
  const [tagsSelected, setTagsSelected] = useState([]);

  useEffect(() => {
    if (edit) {
      preload()
    }
    loadTags()
  }, [])

  const loadTags = async () => {
    const data = await getTags(sites.defaultSite.id)
    setTags(data)
  }

  const preload = () => {
    const video = data.find(item => edit === item.id)
    setName(video.name)
    const tags = video.tags.map(item => item.name)
    setTagsSelected(tags)
  }

  const validate = () => {
    if (name.trim() === '') return { success: false, error: 'Título requerido' };
    if (!edit) {
      if (!files.length) return { success: false, error: 'Video requerido' };
    }
    return { success: true }
  }

  const cleanInputs = () => {
    setName('');
  }

  const onEdit = async () => {
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true);
      try {
        const newTags = parsedTags()
        await props.putVideo(sites.defaultSite.id, edit, {
          name,
          tags: newTags,
        });
        makeToast(enqueueSnackbar, 'Video guardado')
        cleanInputs();
        props.fetchVideos(sites.defaultSite.id, limit, offset)
        props.onCloseModal()
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }

  }

  const parsedTags = () => {
    const parsedTags = []
    for (let i = 0; i < tagsSelected.length; i++) {
      const tagSelected = tagsSelected[i];
      const exists = tags.find(item => item.name === tagSelected)
      if (exists) {
        parsedTags.push({ id: exists.id, name: exists.name })
      } else {
        parsedTags.push({ name: tagSelected })
      }
    }
    return parsedTags;
  }

  const onCreate = async () => {
    const isValid = validate()
    if (isValid.success) {
      await setLoading(true);
      try {
        const id = await loadVideo()
        const newTags = parsedTags()
        await props.putVideo(sites.defaultSite.id, id, {
          name,
          tags: newTags,
        });
        await setSaving(false)
        makeToast(enqueueSnackbar, 'Video guardado')
        cleanInputs();
        props.fetchVideos(sites.defaultSite.id, limit, offset)
        props.onCloseModal()
      } catch (error) {
        console.log('handleSubmit', error);
      } finally {
        await setLoading(false);
      }
    } else {
      makeToast(enqueueSnackbar, isValid.error, 'warning')
    }
  }

  const createAndNavigate = async () => {
    if (edit) {
      onEdit()
    } else {
      onCreate()
    }
  }

  const onProgress = (ev) => {
    const percent = Math.round((100 * ev.loaded) / ev.total)
    if (percent === 100) {
      setProgress(0)
    } else {
      setProgress(percent)
    }
  }

  const onSigning = async () => {
    await setPreparing(false)
  }

  const loadVideo = async () => {
    await setPreparing(true)
    makeToast(enqueueSnackbar, 'Cargando video', 'info')
    const specific = {};
    await setUploading(true)
    let fileId = await uploadFile(sites.defaultSite.id, files[0], specific, false, null, onProgress, onSigning)
    await setUploading(false)
    await setSaving(true)
    let data = await listeningUpload(fileId);
    return data.id
  }

  const setImage = async (acceptedFiles) => {
    await setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
  }

  return (
    <Modal open={props.open} onClose={props.onCloseModal}>
      <div className={classes.paper}>
        <div style={styles.container}>
          <div style={styles.header}>
            <div style={styles.name}>{edit ? 'Editar video' : 'Subir Video'}</div>
            <IconButton onClick={() => props.onCloseModal()}>
              <Close style={styles.close} />
            </IconButton>
          </div>
          <div style={styles.content}>
            <FormControl style={{ width: '100%' }} onSubmit={() => createAndNavigate()}>
              {
                (!uploading && !saving && !preparing) && (
                  <div style={styles.field} className="field">
                    <Label style={styles.label}>Título</Label>
                    <Input
                      type="text"
                      id="name"
                      value={name}
                      disabled={loading}
                      style={styles.input}
                      placeholder="Introduce el título"
                      aria-describedby="nameHelp"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                )
              }
              {
                !edit ? (
                  <div style={styles.field} className="field">
                    <Label style={styles.label}>Video</Label>
                    <VideoUploader
                      files={files}
                      preview={true}
                      showIcon={true}
                      onChange={setImage}
                      saving={saving}
                      progress={progress}
                      uploading={uploading}
                      preparing={preparing}
                      placeholder="Selecciona o arrasta"
                      dropContainerStyle={{ height: 163, textAlign: 'center' }}
                    />
                  </div>
                ) : (
                  <div style={{ height: 0 }} />
                )
              }
              {
                (!uploading && !saving && !preparing) && (
                  <div style={styles.field} className="field">
                    <Autocomplete
                      multiple
                      freeSolo
                      id="tags-filled"
                      className={classes.root}
                      defaultValue={tagsSelected}
                      options={tags.map((option) => option.name)}
                      onChange={(ev, newTags) => setTagsSelected(newTags)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip key={`chip-${index}`} variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} className={classes.root} variant="filled" label="Etiquetas" placeholder="Agrega o busca etiquetas" />
                      )}
                    />
                  </div>
                )
              }
            </FormControl>
          </div>
          <div style={styles.actions}>
            <button
              onClick={() => props.onCloseModal()}
              style={styles.actionButtonCancel}
              className="button is-light"
            >
              Cancelar
            </button>
            <button
              disabled={loading}
              onClick={() => createAndNavigate()}
              stedityle={styles.actionButton}
              className="button is-light"
            >
              {loading ? <CircularProgress size={20} style={{ color: '#00A8D8' }}/> : (edit ? 'Guardar' : 'Subir')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 22,
    color: '#004976',
    fontWeight: '600',
    textAlign: 'left',
    fontFamily: 'Montserrat',
  },
  close: {
    fill: '#000'
  },
  content: {
    padding: 1,
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#004976',
    fontSize: 14,
    marginBottom: 10,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 5
  },
  actions: {
    flexGrow: 0,
    flexShrink: 0,
    columnGap: 15,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px 0px 0px 0px',
    justifyContent: 'flex-end',
  },
  actionButton: {
    fontSize: 16,
    color: '#fff',
    borderRadius: 4,
  },
  actionButtonCancel: {
    fontSize: 16,
    color: '#fff',
    borderRadius: 4,
    backgroundColor: '#00C18A',
  },
  structureList: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 9
  },
}

const mapStateToProps = ({ sites, videos }) => {
  return { sites, videos };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ putVideo, fetchVideos }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreate);