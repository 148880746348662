import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { KeyboardBackspace } from '@material-ui/icons'
import { fetchWebinar } from '../../../redux/actions/webinars'
import { fetchModules } from '../../../redux/actions/modules'
import ModulesTabs from './ModulesTabs'
import WebinarForm from './WebinarForm'
import NotFound from '../NotFound'
import Loader from '../Loader'

function UserCreate (props) {
  const { event } = useParams()
  const { sites, webinars } = props

  useEffect(() => {
    preload()
  }, [])

  const preload = async () => {
    await props.fetchWebinar(sites.defaultSite.id, event)
    // props.fetchModules(sites.defaultSite.id, webinar)
  }

  return (
    <div style={{display: 'flex', flex: 1}}>
      {webinars.webinar.error ? (
        <NotFound
          title='Oops… Curso no encontrado'
          subtitle='No podemos encontrar el evento que estás buscando.'
          hideNavbar={true}
          hideFooter={true}
        />
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <div onClick={() => window.history.back()} style={styles.title}>
              <KeyboardBackspace style={{ fill: '#024976' }} />
              Eventos
            </div>
          </div>

          <div style={styles.content}>
            <div style={{ ...styles.panel, ...styles.leftPanel }}>
              <div style={{ ...styles.panelSection }}>
                {!webinars.webinar.loading ? <WebinarForm /> : <Loader />}
              </div>
            </div>

            <div style={{ ...styles.panel, ...styles.centerPanel }}>
              <div style={{ ...styles.panelSection }}>
                <div style={styles.tab}>
                  {!webinars.webinar.loading ? (
                    <ModulesTabs site={sites.defaultSite.id} webinar={event} />
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f6f6f6',
  },
  header: {
    display: 'flex',
    padding: '30px 30px 15px 30px',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    padding: '0px 30px 30px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 30
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  panel: {
    minWidth: 330,
    backgroundColor: 'white',
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  leftPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  centerPanel: {
    flexShrink: 1,
    flexGrow: 1
  },
  rightPanel: {
    flexShrink: 0,
    flexGrow: 0
  },
  panelSection: {
    padding: '20px 30px',
    width: '100%'
  },
  panelTitle: {
    fontFamily: 'Montserrat',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '900',
    color: '#024976',
    marginBottom: 20
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: 'rgb(232 232 232)'
  },
  field: {
    textAlign: 'left',
    marginBottom: 20
  },
  label: {
    color: '#333742',
    fontSize: 14,
    marginBottom: 5,
    lineHeight: '26px'
  },
  input: {
    height: 56,
    borderRadius: 14
  },
  stat: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  statName: {
    fontFamily: 'Montserrat',
    color: 'rgb(177, 177, 177)',
    display: 'flex',
    columnGap: 6
  },
  statValue: {
    fontFamily: 'Montserrat',
    color: '#024976'
  }
}

const mapStateToProps = ({ sites, webinars, modules }) => {
  return { sites, webinars, modules }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchWebinar, fetchModules }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate)
