
import React from 'react';
import { Close } from '@material-ui/icons';

export default function ModalRight({ show, onClick, children, hideCloseButton }) {
  const styles = {
    wrapper: {
      display: 'flex',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.70)',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      transform: !show ? 'translateX(100%)' : 'translateX(0%)',
      zIndex: 100
    },    
    container: {
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 101,
      width: '50%',
      height: '100%',
      position: 'fixed',
      backgroundColor: '#ffffff',
      boxShadow: 'black 0px 0px 30px -12px',
      transform: !show ? 'translateX(100%)' : 'translateX(0%)',
      transition: 'transform 0.6s ease 0s'
    },
    close: {
      top: 25,
      left: -50,
      width: 50,
      height: 50,
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      backgroundColor: '#ddd',
      borderTopLeftRadius: 20,
      justifyContent: 'center',
      borderBottomLeftRadius: 20,
    }
  }
  return (
    <div onClick={onClick} style={styles.wrapper}>
      <div onClick={(e) => e.stopPropagation()} style={styles.container}>
        {
          !hideCloseButton && (
            <div onClick={onClick} style={styles.close}>
              <Close />
            </div>
          )
        }
        {children}
      </div>
    </div>
  );
}