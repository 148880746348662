import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export const FormGroup = memo(function FormGroup(props) {
  return (
    <div className="form-group">
      {props.children}
    </div>
  )
})

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
}
