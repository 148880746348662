import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { ReactSortable } from "react-sortablejs";
import { CircularProgress } from '@material-ui/core';
import { fetchSubModules, updateSubModuleSort, updateSubModuleSortLocal } from '../../../../redux/actions/submodules';
import SubModuleItem from './SubModuleItem';

const ListSubModules = ({ parentModule, sites, status, loaded, fetchSubModules, updateSubModuleSort, updateSubModuleSortLocal, submodules, content }) => {
  const { course } = useParams();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const data = await fetchSubModules(sites.defaultSite.id, course, parentModule);
    if (data.length) {
      loaded()
    } else if (status) {
      loaded()
    }
  }

  if (!submodules.data[parentModule] || !submodules.data[parentModule].length) {
    return null;
  }

  return (
    <ReactSortable
      tag="div"
      style={{ display: 'flex', flexDirection: 'column', rowGap: 8}}
      list={submodules.data[parentModule]}
      setList={(newSort) => updateSubModuleSortLocal(newSort, parentModule)}
      onEnd={() => updateSubModuleSort(sites.defaultSite.id, course, submodules.data[parentModule])}
    >
      {
        submodules.data[parentModule].map((item, index) => (
          <SubModuleItem key={`module-item-${index}`} {...item} />
        ))
      }
    </ReactSortable>
  );
};

const mapStateToProps = ({ sites, submodules, content }) => {
  return { sites, submodules, content };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchSubModules, updateSubModuleSort, updateSubModuleSortLocal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSubModules);