import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { AddCircle } from '@material-ui/icons'
import { LinearProgress } from '@material-ui/core'
import { fetchWebinarsInstructors, postWebinarInstructor, deleteWebinarInstructor } from '../../../redux/actions/webinars'
import AssignInstructorModal from './AssignInstructorModal'
import { makeToast } from '../../../utils'
import CustomTable from '../DataTable'

function Instructores (props) {
  const { event } = useParams()
  const [createModal, setCreateModal] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const {
    sites,
    webinars: {
      instructors: { data, loading, limit, offset, count, sort }
    }
  } = props

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      label: 'Fecha de creación',
      name: 'createdAt',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div>
                {moment(new Date(value)).format('DD / MM / YYYY - HH:mm')}
              </div>
            )
          }
        }
      }
    }
  ]

  const onClose = () => {
    toggleCreate()
  }

  const onSubmit = async (instructorId) => {
    try {
      await props.postWebinarInstructor(sites.defaultSite.id, event, instructorId)
      makeToast(enqueueSnackbar, "El instructor se asigno correctamente")
      props.fetchWebinarsInstructors(
        sites.defaultSite.id,
        event,
        limit,
        offset
      )
    } catch (error) {
      console.log('error', error)
      makeToast(enqueueSnackbar, "Ocurio un error al asignar instructor", "error")
    } finally {
      toggleCreate()
    }
  }

  const toggleCreate = () => {
    setCreateModal(prev => !prev)
  }

  return (
    <>
      <div style={styles.header}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={styles.title}>Instructores</div>
          <div style={styles.subtitle}>
            ({count} {count !== 1 ? 'Instructores' : 'Instructor'})
          </div>
        </div>
        <div style={styles.actions}>
          <button
            onClick={toggleCreate}
            style={styles.actionButton}
            className='button is-light'
          >
            <AddCircle style={{ width: 20, height: 20, marginRight: 5 }} />{' '}
            Asignar instructor
          </button>
        </div>
      </div>
      {loading && <LinearProgress />}
      <div style={styles.tableContainer}>
        <CustomTable
          data={data}
          columns={columns}
          count={count}
          limit={limit}
          offset={offset}
          sortOrder={sort}
          onRowPress={(rowData, meta, rowIndex) => {}}
          onRowsDelete={async rows => {
            await props.deleteWebinarInstructor(sites.defaultSite.id, event, rows)
            makeToast(enqueueSnackbar, 'Se han eliminado los elementos')
            props.fetchWebinarsInstructors(
              sites.defaultSite.id,
              event,
              limit,
              offset
            )
          }}
          preload={() =>
            props.fetchWebinarsInstructors(
              sites.defaultSite.id,
              event,
              limit,
              offset
            )
          }
          changePageSize={({ rowsPerPage }) =>
            props.fetchWebinarsInstructors(
              sites.defaultSite.id,
              event,
              rowsPerPage,
              offset
            )
          }
          search={({ rowsPerPage, page, sortOrder, searchText, columns }) => {
            if (searchText && searchText !== '') {
              const names = columns.map(item => item.name)
              props.fetchWebinarsInstructors(
                sites.defaultSite.id,
                event,
                rowsPerPage,
                page,
                sortOrder.name,
                sortOrder.direction,
                names,
                searchText
              )
            } else {
              props.fetchWebinarsInstructors(
                sites.defaultSite.id,
                event,
                rowsPerPage,
                page,
                sortOrder.name,
                sortOrder.direction
              )
            }
          }}
          changePage={({ rowsPerPage, page, sortOrder }) => {
            props.fetchWebinarsInstructors(
              sites.defaultSite.id,
              event,
              rowsPerPage,
              page,
              sortOrder.name,
              sortOrder.direction
            )
          }}
          sort={({ rowsPerPage, page, sortOrder }) => {
            props.fetchWebinarsInstructors(
              sites.defaultSite.id,
              event,
              rowsPerPage,
              page,
              sortOrder.name,
              sortOrder.direction
            )
          }}
        />
        {
          createModal && (
            <AssignInstructorModal
              eventId={event}
              visible={createModal}
              onClose={() => onClose()}
              siteId={sites.defaultSite.id}
              onSubmit={(id) => onSubmit(id)}
            />
          )
        }
      </div>
    </>
  )
}

const styles = {
  tableContainer: {
    marginTop: 0
  },
  actions: {
    display: 'flex',
    columnGap: 20
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    color: '#024976',
    cursor: 'pointer'
  },
  subtitle: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}

Instructores.propTypes = {}

const mapStateToProps = ({ webinars, sites }) => {
  return { webinars, sites }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchWebinarsInstructors, postWebinarInstructor, deleteWebinarInstructor }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Instructores)
